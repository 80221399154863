import React from 'react'

const ViewerStyles = ({ theme }) => (
  <style>
    {`.gc-status-icon {
      display: none;
    }
    
    .gc-foreign-object-container {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
    
    [id*="room-"],
    [id*="zone-"] {
      transition: opacity 0.5s ease-in-out;
    }
    
    g.hidden {
      opacity: 0 !important;
      pointer-events: none;
      transition: none;
    }
    
    .gc-foreign-object-icon-container {
      pointer-events: none;
    }
    
    .room-associated,
    .zone-associated {
      cursor: pointer !important;
      transition: fill .5s ease-in-out;
    }
    
    .room-associated.deactive,
    .zone-associated.deactive {
      opacity: 0.2;
      transition: opacity .5s ease-in-out;
    }
    
    .room-associated.active,
    .zone-associated.active {
      opacity: 1;
    }
    
    .room-associated.active {
      fill: rgba(0, 0, 0, 0.25);
    }
    
    .no-room-association {
      opacity: ${theme?.room?.noAssociationFillOpacity ?? '0.3'};
      fill: ${theme?.room?.noAssociationFillColor ?? '#000000'};
    }
    
    .no-zone-association {
      opacity: ${theme?.zone?.noAssociationFillOpacity ?? '0.3'};
      fill: ${theme?.zone?.noAssociationFillColor ?? '#000000'};
    }`}
  </style>
)

export default React.memo(ViewerStyles)
