import React from 'react'
import {
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  BatteryAlert,
  BatteryFull,
  BatteryUnknown,
  CheckCircle,
  Error as ErrorIcon,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular0Bar,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
} from '@material-ui/icons'
import { Grid, Typography } from '@material-ui/core'
import { clamp } from 'ramda'

import { memoize, getShortDateDiff, formattedDate } from '~/Lib/Utils'
import SleepIcon from '~/UI/Icons/Sleep'
import Icon from '~/UI/Shared/Icon'
import Tooltip from '~/UI/Shared/Tooltip'

export const rssiToSignal = rssi => clamp(0, 100, Math.round(rssi / 0.08))

export const getBattery = memoize(battery => {
  let BatteryPercentIcon = BatteryUnknown
  let className = 'text-normal'
  let group = 0
  let range = 'Unknown'

  if ([undefined, null].includes(battery)) {
    range = 'N/A'
    group = 1
    className = 'text-danger'
  } else if (battery >= 0 && battery < 20) {
    BatteryPercentIcon = BatteryAlert
    range = '0%-19%'
    group = 2
    className = 'text-danger'
  } else if (battery < 30) {
    BatteryPercentIcon = Battery20
    range = '20%-29%'
    group = 3
    className = 'text-warning'
  } else if (battery < 50) {
    BatteryPercentIcon = Battery30
    range = '30%-49%'
    group = 4
  } else if (battery < 60) {
    BatteryPercentIcon = Battery50
    range = '50%-59%'
    group = 5
  } else if (battery < 80) {
    BatteryPercentIcon = Battery60
    range = '60%-79%'
    group = 6
  } else if (battery < 90) {
    BatteryPercentIcon = Battery80
    range = '80-89%'
    group = 7
  } else if (battery < 95) {
    BatteryPercentIcon = Battery90
    range = '90%-94%'
    group = 8
  } else if (battery <= 100) {
    BatteryPercentIcon = BatteryFull
    range = '95%-100%'
    group = 9
  }
  return ({
    group,
    range,
    className,
    Icon: props => (
      <Icon
        icon={BatteryPercentIcon}
        className={className}
        {...props}
        fontSize="small"
      />
    )
  })
})

export const getRssi = memoize(rssi => {
  let signalIcon = SignalCellularConnectedNoInternet0Bar
  let className = 'text-normal'
  let group = 0
  let range = 'Unknown'
  const signal = rssiToSignal(rssi)

  if ([undefined, null].includes(rssi)) {
    range = 'N/A'
    group = 1
    className = 'text-danger'
  } else if (signal === 0) {
    range = '0%'
    group = 2
    className = 'text-danger'
  } else if (signal < 20) {
    signalIcon = SignalCellular0Bar
    range = '1%-19%'
    group = 3
    className = 'text-danger'
  } else if (signal < 40) {
    signalIcon = SignalCellular1Bar
    range = '20%-39%'
    group = 4
    className = 'text-warning'
  } else if (signal < 60) {
    signalIcon = SignalCellular2Bar
    range = '40%-59%'
    group = 5
  } else if (signal < 80) {
    signalIcon = SignalCellular3Bar
    range = '60%-79%'
    group = 6
  } else if (signal >= 80) {
    signalIcon = SignalCellular4Bar
    range = '80%-100%'
    group = 7
  }

  return ({
    group,
    range,
    className,
    Icon: props => (
      <Icon
        icon={signalIcon}
        className={className}
        {...props}
        fontSize="small"
      />
    )
  })
})

export const getStatus = memoize((online, deepSleep) => {
  let statusIcon = CheckCircle
  let className = 'text-normal'

  if (deepSleep) {
    statusIcon = SleepIcon
    className = 'text-warning'
  } else if (!online) {
    statusIcon = ErrorIcon
    className = 'text-danger'
  }

  return ({ style }) => (
    <Icon
      icon={statusIcon}
      className={className}
      style={style}
      fontSize="small"
    />
  )
})

export const SORT = {
  name: 'device_name',
  battery: 'battery',
  signal: 'rssi',
  model: 'model__name',
  room: 'room__name'
}

export const HARDWARE = {
  battery: getBattery,
  rssi: getRssi
}

const getGrouped = (acc, name, device) => {
  if (acc[name]) {
    acc[name].push(device)
  } else {
    acc[name] = [device]
  }
  return acc
}

export const getGroupedByHardware = memoize((key, devices) => devices.reduce((acc, device) => {
  const getHardware = HARDWARE[key]
  const level = device[key]
  const name = getHardware(level)?.group ?? 0

  return getGrouped(acc, name, device)
}, {}))

export const getGroupedByName = memoize((key, devices, entity) => devices.reduce((acc, device) => {
  const id = device[key]
  const name = entity[id]?.name || 'Unknown'

  return getGrouped(acc, name, device)
}, {}))

export const updateUnlessNullorUndefined = (obj, newKey, newValue) => {
  if (newValue === undefined || newValue === null) {
    if (obj[newKey]) {
      const filtered = Object.keys(obj).reduce((newObj, key) => {
        if (!(key === newKey)) {
          newObj[key] = obj[key]
        }
        return newObj
      }, {})
      return filtered
    }
    return obj
  }
  return { ...obj, [newKey]: newValue }
}

export const getStatusTooltipText = (dev, lastCommunication) => {
  const formatDateTime = ts => (ts ? formattedDate(ts, 'en-US', {
    ...formattedDate.SHORT_DATE,
    ...formattedDate.SHORT_TIME,
  }) : 'Never')
  if (dev.deepSleep) return `Deep sleep since: ${formatDateTime(dev.deepSleep)}`
  if (lastCommunication && !dev.online) return `Offline since: ${formatDateTime(lastCommunication)}`
  return `Last communicated: ${formatDateTime(lastCommunication)}`
}

export const BatteryIcon = ({ device, dynamicTextSize }) => {
  const battery = device.battery ?? 0
  const { Icon: DeviceBatteryIcon } = getBattery(battery)
  return (
    <Grid item style={{ flexBasis: '30px', textAlign: 'center' }}>
      <DeviceBatteryIcon />
      <Typography variant="body2" style={{ fontSize: dynamicTextSize }}>
        {`${battery}%`}
      </Typography>
    </Grid>
  )
}

export const SignalIcon = ({ device, dynamicTextSize }) => {
  const rssi = device.rssi ?? 0
  const { Icon: DeviceSignalIcon } = getRssi(rssi)
  const signal = rssiToSignal(rssi)
  return (
    <Grid item style={{ flexBasis: '30px', textAlign: 'center' }}>
      <DeviceSignalIcon />
      <Typography variant="body2" style={{ fontSize: dynamicTextSize }}>
        {`${signal}%`}
      </Typography>
    </Grid>
  )
}

export const StatusIcon = ({ device, dense, dynamicTextSize }) => {
  const DeviceStatusIcon = getStatus(device.online, device.deepSleep)
  const lastCommunication = device?.sensor?.lastCommunication ?? device.lastCommunication
  const lastCommunicated = lastCommunication ? getShortDateDiff(lastCommunication) : ''
  const statusTooltip = getStatusTooltipText(device, lastCommunication)
  const communicatedOffset = lastCommunicated.length === 0 ? '10px' : '0'
  return (
    <Tooltip title={statusTooltip} placement="left">
      <Grid
        item
        style={{
          flexBasis: dense ? '40px' : '50px',
          textAlign: 'center',
          paddingBottom: communicatedOffset
        }}
      >
        <DeviceStatusIcon />
        <Typography variant="body2" style={{ fontSize: dynamicTextSize }}>
          {lastCommunicated}
        </Typography>
      </Grid>
    </Tooltip>
  )
}

export const DeviceIcons = ({ device, dense = false, dynamicTextSize }) => (
  <Grid container xs={12} spacing={dense ? 0 : 4} justify="center">
    <BatteryIcon device={device} dynamicTextSize={dynamicTextSize} />
    <SignalIcon device={device} dynamicTextSize={dynamicTextSize} />
    <StatusIcon device={device} dynamicTextSize={dynamicTextSize} dense />
  </Grid>
)
