import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import MuiTextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import CancelIcon from '@material-ui/icons/Cancel'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import addLogger from '~/Lib/Logging'
import { numberOrEmptyType } from '~/Lib/PropTypes'
import { EMPTY_OBJECT, isNumber } from '~/Lib/Utils'
import Icon from '~/UI/Shared/Icon'

const valuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.array,
])
const exists = value => (typeof value === 'number' && !Number.isNaN(value))
  || !!(Array.isArray(value) && value.length)
  || !!value

const getValue = (value, type) => {
  if (value === '') return value
  if (type === 'number') {
    return value === Number(value) ? value : ''
  }
  return isNumber(value) || value ? value : ''
}

export const styles = theme => ({
  root: {
    '& .warning': {
      marginRight: 4,
      color: theme.meter.error.warning,
      cursor: 'default',
    },
  },
  rootDense: {
    margin: theme.spacing(0.5),
    '& $labelDense$labelShrink': {
      left: theme.spacing(),
    },
    '& $labelDense': {
      top: theme.spacing(-2),
      left: theme.spacing(),
    },
    '& $labelDense$labelStartAdornment': {
      left: theme.spacing(5),
    },
    '& input[type="number"]': {
      height: '1.8125rem',
    },
    '& [class*=MuiFormHelperText-root]': {
      marginLeft: theme.spacing()
    }
  },
  rootSuperDense: {
    '& $labelSuperDense': {
      top: theme.spacing(-3.5),
      left: theme.spacing(),
    },
    '& $labelSuperDense$labelShrink': {
      display: 'none',
    },
    margin: 0,
  },
  labelDense: {},
  labelSuperDense: {
    '&.Mui-focused': {
      display: 'none',
    },
  },
  labelShrink: {},
  labelStartAdornment: {},
  inputDense: {
    padding: '0 0 0 8px',
    'label + &': {
      marginTop: 0,
    },
  },
  inputSuperDense: {
    padding: '0 0 0 8px',
    'label + &': {
      marginTop: 0,
    },
    minHeight: 'auto',
    '& input': {
      padding: theme.spacing(1, 0),
    },
    top: 0,
  },
  inputStartAdornment: {
    textIndent: 32,
  },
  labelDisabled: {
    color: theme.meter.text.disabled,
  },
  inputFinished: {
    '&:before': {
      borderBottomColor: theme.palette.primary.light,
    },
  },
  startAdornmentRoot: {
    position: 'relative',
    '& $startAdornmentField label': {
      left: '48px',
    },
    '& $startAdornmentField input': {
      textIndent: '32px',
    },
    '&$startAdornmentDense > $startAdornment > svg, & > .material-icons': {
      position: 'relative',
      top: '21px',
      left: '15px',
    },
    '&$startAdornmentDense label': {
      left: '40px',
    },
  },
  startAdornment: {
    position: 'absolute',
    left: 0,
    top: 0,
    '&  > button': {
      position: 'relative',
      top: '32px',
      left: '8px',
    },
    '& > svg, & > .material-icons': {
      position: 'relative',
      top: '42px',
      left: '20px',
    },
  },
  startAdornmentField: {},
  startAdornmentDense: {},
  inputRoot: {
    '&$inputUnderline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiSelect-icon': {
      right: 8,
    }
  },
  inputUnderline: {},
})

export class TextFieldComponent extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    dense: PropTypes.bool,
    superDense: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    helperText: PropTypes.string,
    onEmpty: PropTypes.func,
    InputProps: PropTypes.shape({
      classes: PropTypes.objectOf(PropTypes.string),
      className: PropTypes.string,
      endAdornment: PropTypes.node,
      startAdornment: PropTypes.node,
    }),
    inputProps: PropTypes.shape({ size: PropTypes.number }),
    InputLabelProps: PropTypes.shape({
      classes: PropTypes.objectOf(PropTypes.string),
      className: PropTypes.string,
      shrink: PropTypes.bool,
    }),
    max: numberOrEmptyType,
    min: numberOrEmptyType,
    maxLength: PropTypes.number,
    name: PropTypes.string,
    select: PropTypes.bool,
    hasStartAdornment: PropTypes.bool,
    SelectProps: PropTypes.shape({ className: PropTypes.string, IconComponent: PropTypes.elementType }),
    type: PropTypes.string,
    // eslint-disable-next-line consistent-return
    value: (props, ...rest) => {
      const invalid = valuePropType(props, ...rest)
      if (invalid) {
        return new Error(
          `${invalid} -- [value: ${JSON.stringify(props.value)}, name: ${
            props.name
          }]`
        )
      }
    },
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: '',
    dense: false,
    superDense: false,
    disabled: false,
    onEmpty: undefined,
    error: '',
    helperText: '',
    InputProps: EMPTY_OBJECT,
    inputProps: EMPTY_OBJECT,
    InputLabelProps: EMPTY_OBJECT,
    SelectProps: EMPTY_OBJECT,
    max: Infinity,
    min: -Infinity,
    maxLength: Infinity,
    name: '',
    select: false,
    type: 'text',
    value: '',
    hasStartAdornment: false,
  }

  onChange = event => {
    const { props: { name, type } } = this
    const {
      target: { value: rawValue },
    } = event
    const value = type === 'number' ? this.getNumberValue(rawValue) : rawValue
    this.props.onChange(name ? { [name]: value } : value)
  }

  getNumberValue(rawValue) {
    if (rawValue === '') return rawValue
    const { min, max } = this.props
    const number = Number(
      rawValue.replace(/[^-.\d]+/g, '').replace(/-?\.$/, '')
    )
    return Number.isNaN(number) ? number : Math.min(Math.max(number, min), max)
  }

  render() {
    const {
      classes,
      className,
      dense,
      superDense,
      disabled,
      error,
      helperText,
      inputProps,
      InputProps: InputPropsRaw,
      InputLabelProps: InputLabelPropsRaw,
      onEmpty,
      name,
      select,
      SelectProps,
      type,
      min,
      max,
      maxLength,
      value,
      hasStartAdornment,
      ...props
    } = this.props
    const { startAdornment, ...InputProps } = InputPropsRaw

    if (onEmpty && value.length) {
      InputProps.endAdornment = (
        <InputAdornment
          position="end"
          onClick={() => onEmpty()}
          style={{ cursor: 'pointer' }}
        >
          <Icon icon={CancelIcon} />
        </InputAdornment>
      )
    }

    const InputLabelProps = select && exists(value)
      ? {
        shrink: true,
        ...InputLabelPropsRaw,
      }
      : InputLabelPropsRaw

    return startAdornment ? (
      <div
        className={classNames(classes.startAdornmentRoot, {
          [classes.startAdornmentDense]: dense,
        })}
      >
        <span className={classes.startAdornment}>
          {startAdornment.props.children}
        </span>
        <TextFieldComponent
          data-testid="text-field"
          {...this.props}
          hasStartAdornment
          className={classNames(props.className, classes.startAdornmentField)}
          InputProps={InputProps}
        />
      </div>
    ) : (
      <MuiTextField
        data-testid="mui-text-field"
        {...props}
        select={select}
        className={classNames({
          [className]: !!className,
          [classes.root]: classes.root,
          [classes.rootDense]: dense,
          [classes.rootSuperDense]: superDense,
        })}
        disabled={disabled}
        error={!!error}
        helperText={error || helperText}
        name={name}
        value={getValue(value, type)}
        type={type}
        onChange={this.onChange}
        InputProps={{
          ...InputProps,
          classes: {
            ...InputProps.classes,
            root: classNames(classes.inputRoot, InputProps?.classes?.root),
            underline: classNames(classes.inputUnderline, InputProps?.classes?.underline),
          },
          className: classNames({
            [classes.inputFinished]: !disabled && value !== '',
            [classes.inputDense]: dense,
            [classes.inputSuperDense]: superDense,
            [InputProps.className]: !!InputProps.className,
          }),
        }}
        inputProps={{ ...inputProps, maxLength }}
        InputLabelProps={{
          ...InputLabelProps,
          classes: {
            ...InputLabelProps.classes,
            root: classNames({
              [classes.labelDense]: dense,
              [classes.labelSuperDense]: superDense,
              [classes.labelStartAdornment]: hasStartAdornment,
              [classes.labelShrink]: exists(value),
              [InputLabelProps?.classes?.root]: !!InputLabelProps?.classes?.root
            }),
            disabled: classNames(classes.labelDisabled, InputLabelProps?.classes?.disabled),
          },
          className: classNames({
            [InputLabelProps.className]: !!InputLabelProps.className,
          }),
        }}
        SelectProps={
          select
            ? {
              ...SelectProps,
              IconComponent: SelectProps?.IconComponent || KeyboardArrowDownIcon,
              className: classNames({
                [classes.inputFinished]: !disabled && !!value,
                [classes.inputDense]: dense,
                [classes.inputSuperDense]: superDense,
                [classes.inputStartAdornment]: hasStartAdornment,
                [SelectProps.className]: !!SelectProps.className,
              }),
            }
            : undefined
        }
      />
    )
  }
}

addLogger(TextFieldComponent)

export default withStyles(styles)(TextFieldComponent)
