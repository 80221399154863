import React, { useState } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import {
  FormHelperText,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import Button from '~/UI/Shared/Button'
import Icon from '~/UI/Shared/Icon'

import { renderableType } from '~/Lib/PropTypes'
import { EMPTY_OBJECT, randomId } from '~/Lib/Utils'

const displayName = 'SingleSelect'

const getMenuId = memoize(props => props.menuId || `aroya-select-${randomId()}`)
const getLabel = memoize(props => {
  const selectedOption = props.options.find(
    option => option.value === props.value
  )
  if (selectedOption) {
    return selectedOption.label || selectedOption.value
  }
  return props.label || `Choose ${props.name || 'One'}`
})
export const SingleSelectComponent = props => {
  const {
    dense,
    disabled,
    error,
    icon,
    label,
    options,
    value,
    name,
    onChange,
    ...rest
  } = props
  const [anchorEl, setAnchor] = useState(null)
  const iconColor = disabled ? 'disabled' : rest.color

  return (
    <div>
      <Button
        {...rest}
        disabled={disabled}
        aria-owns={anchorEl ? getMenuId(props) : undefined}
        aria-haspopup="true"
        onClick={event => setAnchor(event.currentTarget)}
        variant="text"
        style={{ paddingRight: 4 }}
      >
        {icon ? (
          <>
            <Icon icon={icon} color={iconColor} />
            &nbsp;
          </>
        ) : null}
        {getLabel(props)}
        <Icon icon={ArrowDropDownIcon} color={iconColor} />
      </Button>
      <Menu
        id={getMenuId(props)}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchor(null)}
        PaperProps={{
          style: {
            maxHeight: '90vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          },
        }}
      >
        {options.map(({ value: optionValue, label: optionLabel, icon: optionIcon }) => (
          <MenuItem
            key={optionValue}
            onClick={() => {
              const newValue = value === optionValue ? null : optionValue
              onChange(name ? { [name]: newValue } : newValue)
              setAnchor(null)
            }}
            style={{
              ...(value === optionValue
                ? {
                  backgroundColor: 'rgba(255, 255, 255, 0.12)',
                }
                : EMPTY_OBJECT),
              ...(dense
                ? {
                  padding: '6px 16px',
                }
                : EMPTY_OBJECT),
            }}
          >
            {optionIcon ? (
              <>
                <ListItemIcon><Icon icon={optionIcon} /></ListItemIcon>
                <ListItemText>{optionLabel || optionValue}</ListItemText>
              </>
            ) : (
              optionLabel || optionValue
            )}
          </MenuItem>
        ))}
      </Menu>
      {error ? (
        <FormHelperText error>{error}</FormHelperText>
      ) : null}
    </div>
  )
}

const valueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.object,
  PropTypes.bool,
])

SingleSelectComponent.propTypes = {
  color: PropTypes.string,
  dense: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.node,
  icon: renderableType,
  label: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: valueType.isRequired,
    })
  ).isRequired,
  value: valueType,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

SingleSelectComponent.defaultProps = {
  color: 'primary',
  dense: false,
  disabled: false,
  error: null,
  icon: null,
  label: 'Option',
  value: null,
  name: null,
}

SingleSelectComponent.displayName = displayName

export default React.memo(SingleSelectComponent)
