import React from 'react'
import { Field, FieldArray, useFormikContext } from 'formik'
import { sortBy, prop } from 'ramda'
import * as yup from 'yup'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useConnect } from 'redux-bundler-hook'

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core'

import {
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
} from '@material-ui/icons'

import { makeStyles } from '@material-ui/styles'

import {
  FormDialog,
  TextField,
  ToggleField,
  SelectField
} from '~/UI/Shared/Form/FormDialog'
import Icon from '~/UI/Shared/Icon'
import { meter } from '~/UI/Theme/mui'
import { uniqueId } from '~/Lib/Utils'

const styles = () => ({
  topMargin: {
    margin: '16px 0 0 0',
  },
  textField: {
    margin: '-16px 0 0 0',
  },
  toggles: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexBasis: '50%',
    }
  },
  locations: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  roomType: {
    width: '48.5%',
    margin: '24px 0 8px'
  },
  '& div:nth-child(3)': {
    padding: '2px 16px'
  },
  '& div:nth-child(4)': {
    marginLeft: 0
  }
})

const useStyles = makeStyles(styles)

const ZoneFormRow = props => {
  const classes = useStyles()

  const { dragDeleteDisabled, idx, onDelete, zone } = props

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: meter.background.two,
    })
  })

  return (
    <Draggable draggableId={`zone_${zone.key}`} index={idx} isDragDisabled={dragDeleteDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <Grid container alignItems="center" style={{ flexWrap: 'nowrap' }}>
            <Grid item>
              <DragIndicatorIcon color={dragDeleteDisabled ? 'disabled' : 'primary'} />
            </Grid>
            <Grid item style={{ flexGrow: 1, padding: '4px' }}>
              <Field
                className={classes.textField}
                component={TextField}
                label="Name"
                name={`zones[${idx}].name`}
              />
            </Grid>
            <Grid item style={{ flexGrow: 1, padding: '4px' }}>
              <Field
                className={classes.textField}
                component={TextField}
                label="Total Space"
                name={`zones[${idx}].totalSpace`}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ft<sup>2</sup></InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={() => onDelete(idx)} disabled={dragDeleteDisabled}>
                <Icon color={dragDeleteDisabled ? 'disabled' : 'secondary'} icon={DeleteIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      )}
    </Draggable>
  )
}

const ZoneForm = () => {
  const classes = useStyles()
  const { values: { zones } } = useFormikContext()

  const oneZoneInList = (zones.length === 1)

  const getNextZone = () => {
    const lastZone = zones?.[zones.length - 1]
    return {
      name: lastZone ? lastZone.name.replace(/\d+$/, n => parseInt(n, 10) + 1) : '',
      totalSpace: lastZone ? lastZone.totalSpace : 0,
      key: uniqueId('zone_'),
    }
  }

  const onDragEnd = move => result => {
    if (!result.destination) return
    if (result.source.index === result.destination.index) return
    move(result.source.index, result.destination.index)
  }

  return (
    <FieldArray name="zones">
      {({ move, push, remove }) => (
        <>
          <Grid container className={classes.topMargin}>
            <Grid item style={{ flexGrow: 1 }}>
              <Typography variant="h6" color="primary" align="left">
                Zones
              </Typography>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={() => push(getNextZone())}>
                Add Zone
              </Button>
            </Grid>
          </Grid>
          <DragDropContext onDragEnd={onDragEnd(move)}>
            <Droppable droppableId="droppable">
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {zones.map((zone, idx) => (
                    <ZoneFormRow
                      key={zone.key}
                      dragDeleteDisabled={oneZoneInList}
                      idx={idx}
                      onDelete={remove}
                      zone={zone}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </FieldArray>
  )
}

const MetrcLocations = ({ instance }) => {
  const {
    facility,
    availableMetrcLocations,
  } = useConnect(
    'selectFacility',
    'selectAvailableMetrcLocations',
  )

  const assignedLocations = instance?.metrcLocations?.map(
    locId => facility?.metrcLocations?.find(loc => loc.id === locId)
  )

  const licenseIdsWithLocations = [...new Set(facility?.metrcLocations?.map(loc => loc.license))]

  const availableLicenses = sortBy(
    prop('number'),
    facility?.licenses?.filter(l => (
      licenseIdsWithLocations?.includes(l.id)
    ))
  ) || []

  return (
    availableLicenses.map((license, idx) => {
      const locationOptions = availableMetrcLocations
        .filter(loc => loc.license === license.id)
        .map(loc => ({ value: loc.id, label: loc.name }))

      const assignedLocation = assignedLocations?.find(loc => loc.license === license.id)

      if (assignedLocation) {
        locationOptions.unshift({
          value: assignedLocation.id,
          label: assignedLocation.name
        })
      }

      const name = assignedLocation?.name ?? `temp-${idx}`

      return (
        <div
          key={license.id}
          style={{ width: availableLicenses.length > 1 ? '48.5%' : '100%' }}
        >
          <Field
            component={SelectField}
            label={`${license.number} - Metrc Location`}
            name={`metrcLocations[${name}]`}
            options={locationOptions}
            disabled={!!assignedLocation}
          />
        </div>
      )
    })
  )
}

const RoomForm = props => {
  const classes = useStyles()
  const { instance, facility } = props

  const ROOM_TYPES = [
    { value: 'PROP', label: 'Prop' },
    { value: 'VEG', label: 'Veg' },
    { value: 'FLOWER', label: 'Flower' },
    { value: 'DRY', label: 'Dry' },
    { value: 'CURE', label: 'Cure' },
    { value: 'STORAGE', label: 'Storage' },
    { value: 'OTHER', label: 'Other' }
  ]

  const initialValues = {
    name: '',
    totalSpace: 0,
    zones: [
      { name: 'Zone 1', totalSpace: 0, key: uniqueId('zone_') }
    ],
    forPlants: true,
    forPlantBatches: true,
    forHarvests: true,
    forPackages: true,
    metrcLocations: {},
    outdoor: false,
    roomType: 'FLOWER',
  }

  if (instance.id) {
    Object.keys(initialValues).forEach(field => {
      if (field === 'metrcLocations') {
        initialValues[field] = facility.metrcLocations
          .filter(loc => instance.metrcLocations.includes(loc.id))
          .reduce((acc, loc) => ({ ...acc, [loc.name]: loc.id }), {})
      } else if (field === 'roomType') {
        initialValues[field] = instance[field] ?? 'FLOWER'
      } else {
        initialValues[field] = instance[field] ?? ''
      }
    })
  }

  initialValues.zones = initialValues.zones.map((zone, idx) => ({ ...zone, key: idx }))

  const validationSchema = yup.object().shape({
    name: yup.string().max(20).required('Please enter a name'),
    totalSpace: yup.number().min(0).integer().required('Please enter a number'),
    zones: yup.array().of(yup.object().shape({
      name: yup.string().max(20).required('Please enter a name'),
      totalSpace: yup.number().min(0).integer().required('Please enter a number'),
    })),
    forPlantBatches: yup.boolean(),
    forPlants: yup.boolean(),
    forHarvests: yup.boolean(),
    forPackages: yup.boolean(),
    atLeastOne: yup.boolean().when(['forPlants', 'forPlantBatches', 'forHarvests', 'forPackages'], {
      is: (a, b, c, d) => (!a && !b && !c && !d),
      then: yup.bool().required('Please select at least one toggle'),
      otherwise: yup.boolean(),
    }),
    metrcLocations: yup.object(),
    roomType: yup.string().required(),
  })

  return (
    <FormDialog
      formatInstance={obj => obj.name}
      initialValues={initialValues}
      label="Room"
      softDeletes
      validationSchema={validationSchema}
      {...props}
    >
      <Field
        component={TextField}
        label="Name"
        name="name"
      />

      <Box display="flex" justifyContent="space-between">
        <Field
          className={classes.roomType}
          component={SelectField}
          label="Room Type"
          name="roomType"
          options={ROOM_TYPES}
        />

        <Field
          style={{ width: '48.5%', margin: '8px 0' }}
          component={TextField}
          label="Total Space"
          name="totalSpace"
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">ft<sup>2</sup></InputAdornment>
            )
          }}
        />
      </Box>

      <div className={classes.locations}>
        <MetrcLocations instance={instance} />
      </div>

      <div className={classes.toggles}>
        <Field
          component={ToggleField}
          name="forPlantBatches"
          label="For Plant Batches"
        />
        <Field
          component={ToggleField}
          name="forPlants"
          label="For Plants"
        />
        <Field
          component={ToggleField}
          name="forHarvests"
          label="For Harvests"
        />
        <Field
          component={ToggleField}
          name="forPackages"
          label="For Packages"
        />
        <Field
          component={ToggleField}
          label="Outdoor Room"
          name="outdoor"
        />
      </div>
      <ZoneForm />
    </FormDialog>
  )
}

RoomForm.defaultProps = {
  instance: {},
}

export default RoomForm
