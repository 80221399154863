import React from 'react'

import { equals, prop } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core'

import { useBreakpoint } from '~/Lib/Utils'
import Chart from '~/UI/Shared/Chart'
import DatePicker from '~/UI/Shared/Form/DatePicker'
import SelectField from '~/UI/Shared/Form/SelectField'

export default props => {
  const { chart, onClose } = props

  const breakpoint = useBreakpoint()

  const {
    dashboardChart,
    dashboardChartIsLoading,
    dashboardChartParams,
    devices,
    facilities,
    models,
    rooms,
    routeParams,
    zones,
    doDashboardChartSetParams,
  } = useConnect(
    'selectDashboardChart',
    'selectDashboardChartIsLoading',
    'selectDashboardChartParams',
    'selectDevices',
    'selectFacilities',
    'selectModels',
    'selectRooms',
    'selectRouteParams',
    'selectZones',
    'doDashboardChartSetParams',
  )

  const { objType, id: objId } = routeParams

  const {
    start,
    end,
    grouping,
    facilities: facilityFilter,
    rooms: roomFilter,
    zones: zoneFilter,
    devices: deviceFilter,
  } = dashboardChartParams

  const groupingChoices = [
    { label: 'Facility', value: 'FACILITY' },
    { label: 'Room', value: 'ROOM' },
    { label: 'Zone', value: 'ZONE' },
    { label: 'Device', value: 'DEVICE' },
  ]

  const comparator = getter => (a, b) => (
    getter(a).localeCompare(getter(b), 'en', { sensitivity: 'base' })
  )

  const showFacilityFilter = objType === 'organization'
  const orgFacilities = (
    Object.values(facilities)
      .filter(fac => fac.organization === parseInt(objId, 10))
      .sort(comparator(prop('name')))
  )
  const facilityChoices = [
    { label: 'All Facilities', value: orgFacilities.map(fac => fac.id) },
    ...orgFacilities.map(fac => ({ label: fac.name, value: [fac.id] }))
  ]

  const showRoomFilter = ['ROOM', 'ZONE', 'DEVICE'].includes(grouping)
  const facilityRooms = (
    Object.values(rooms)
      .filter(room => facilityFilter.includes(room.facility))
      .sort(comparator(prop('name')))
  )
  const facilityRoomIds = facilityRooms.map(room => room.id)
  const roomChoices = [
    { label: 'All Rooms', value: [] },
    ...facilityRooms.map(room => ({ label: room.name, value: [room.id] }))
  ]

  const showZoneFilter = ['ZONE', 'DEVICE'].includes(grouping)
  let roomZones = (
    Object.values(zones)
      .filter(zone => facilityRoomIds.includes(zone.room))
      .map(zone => ({ ...zone, fullName: `[${rooms[zone.room]?.name}] ${zone.name}` }))
      .sort(comparator(prop('fullName')))
  )
  if (roomFilter.length) {
    roomZones = roomZones.filter(zone => roomFilter.includes(zone.room))
  }
  const zoneChoices = [
    { label: 'All Zones', value: [] },
    ...roomZones.map(zone => ({ label: zone.fullName, value: [zone.id] }))
  ]

  const getFullDeviceName = device => {
    if (device.deviceName !== device.serialNumber) {
      return `${device.deviceName} (${device.serialNumber})`
    }
    return device.serialNumber
  }

  const showDeviceFilter = grouping === 'DEVICE'
  let orgDevices = (
    Object.values(devices)
      .map(device => ({ ...device, model: models[device.modelKey] }))
      .filter(({ model }) => (model.puck || model.sink || model.gateway))
      .filter(device => facilityFilter.includes(device.facility))
      .map(device => ({ ...device, fullName: getFullDeviceName(device) }))
      .sort(comparator(prop('serialNumber')))
  )
  if (roomFilter.length) {
    orgDevices = orgDevices.filter(device => roomFilter.includes(device.room))
  }
  if (zoneFilter.length) {
    orgDevices = orgDevices.filter(device => zoneFilter.includes(device.zone))
  }
  const deviceChoices = [
    { label: 'All Devices', value: [] },
    ...orgDevices.map(device => ({ label: device.fullName, value: [device.id] }))
  ]

  return (
    <Dialog
      open={Boolean(chart)}
      fullWidth
      maxWidth="xl"
    >
      <DialogContent>
        <Grid container direction="column">
          <Grid item style={{ height: '75px' }}>
            <Grid container spacing={2} justify="flex-end">
              <Grid item style={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">
                  { chart?.label }
                </Typography>
              </Grid>
              {showDeviceFilter && (
                <Grid item style={{ flexBasis: '200px', marginTop: '-14px' }}>
                  <SelectField
                    placeholder="Select Device"
                    name="devices"
                    options={deviceChoices}
                    value={deviceChoices.find(opt => equals(opt.value, deviceFilter))}
                    onChange={({ value }) => doDashboardChartSetParams({ devices: value })}
                  />
                </Grid>
              )}
              {showZoneFilter && (
                <Grid item style={{ flexBasis: '200px', marginTop: '-14px' }}>
                  <SelectField
                    placeholder="Select Zone"
                    name="zones"
                    options={zoneChoices}
                    value={zoneChoices.find(opt => equals(opt.value, zoneFilter))}
                    onChange={({ value }) => doDashboardChartSetParams({ zones: value })}
                  />
                </Grid>
              )}
              {showRoomFilter && (
                <Grid item style={{ flexBasis: '200px', marginTop: '-14px' }}>
                  <SelectField
                    placeholder="Select Room"
                    name="rooms"
                    options={roomChoices}
                    value={roomChoices.find(opt => equals(opt.value, roomFilter))}
                    onChange={({ value }) => doDashboardChartSetParams({ rooms: value })}
                  />
                </Grid>
              )}
              {showFacilityFilter && (
                <Grid item style={{ flexBasis: '200px', marginTop: '-14px' }}>
                  <SelectField
                    placeholder="Select Facility"
                    name="facilities"
                    options={facilityChoices}
                    value={facilityChoices.find(opt => equals(opt.value, facilityFilter))}
                    onChange={({ value }) => doDashboardChartSetParams({ facilities: value })}
                  />
                </Grid>
              )}
              <Grid item style={{ flexBasis: '200px', marginTop: '-14px' }}>
                <SelectField
                  placeholder="Group By"
                  name="grouping"
                  options={groupingChoices}
                  value={groupingChoices.find(opt => opt.value === grouping)}
                  onChange={({ value }) => doDashboardChartSetParams({ grouping: value })}
                />
              </Grid>
              <Grid item style={{ flexBasis: '200px', marginTop: '-30px' }}>
                <DatePicker
                  datetime
                  fullWidth
                  label="From"
                  name="start"
                  value={start}
                  onChange={doDashboardChartSetParams}
                />
              </Grid>
              <Grid item style={{ flexBasis: '200px', marginTop: '-30px' }}>
                <DatePicker
                  datetime
                  fullWidth
                  label="To"
                  name="end"
                  value={end}
                  onChange={doDashboardChartSetParams}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ width: '100%', height: 'calc(100vh - 225px)', overflow: 'hidden' }}>
            { dashboardChart ? (
              <Chart
                chartData={dashboardChart}
                doSetChartParams={doDashboardChartSetParams}
                loading={dashboardChartIsLoading}
                showYAxis
                width={breakpoint}
              />
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
