import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import TextField from './TextField'

const FormikFieldComponent = ({ required, value, validationSchema, onChange, type, ...props }) => (
  <Formik
    enableReinitialize
    initialValues={{ value }}
    validationSchema={validationSchema}
  >
    {formikProps => {
      const {
        errors,
        handleBlur,
      } = formikProps

      return (
        <TextField
          {...props}
          type={type || 'text'}
          required={!!required}
          fullWidth
          error={errors.value}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
        />
      )
    }}
  </Formik>
)

FormikFieldComponent.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  validationSchema: PropTypes.shape({
    value: PropTypes.func
  }),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  required: PropTypes.string
}

FormikFieldComponent.defaultProps = {
  required: null,
  validationSchema: null,
  type: null
}

const addRequired = (schema, required) => {
  if (!required) return schema
  return schema.required(typeof required === 'string' ? required : 'Required')
}

const defaultSchemas = {
  email: required => addRequired(Yup.string().email('Please enter a valid email address'), required),
  number: required => addRequired(Yup.number().typeError('Please enter a valid value'), required),
  text: required => addRequired(Yup.string(), required),
  password: required => addRequired(Yup.string()
    .min(8, 'Password must be at least 8 characters and contain at least one number')
    .matches(/^(?=.*\d).{8,}$/, 'Password must contain at least one number'), required)
}

export default React.memo(props => {
  const { type = 'text', required, validationSchema } = props
  if (validationSchema || !(type in defaultSchemas)) {
    return <FormikFieldComponent {...props} />
  }

  const schema = defaultSchemas[type]
  return (
    <FormikFieldComponent
      {...props}
      validationSchema={Yup.object().shape({ value: schema(required) })}
    />
  )
})
