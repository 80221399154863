import Container from './container'

// TODO - Verify necessity of class, or delete

/**
 * This class currently doesn't extend the functionality of Container. The constructor sets five
 * of the 11 fields expected in Container's constructor object, but produces the same object
 * dictionary if all the arguments are passed through.
 *
 * Facility's methods were presumably expected to use these fields locally, but none of the methods
 * are implemented.
 *
 * Imported by:
 *   parseSvgMap.js
 */
export default class Facility extends Container {
  constructor({ rooms, name, id, width, height, ...args }) {
    super(args)
    this.id = id
    this.name = name
    this.rooms = rooms
    this.width = width
    this.height = height
  }

  updateFacility({ id, state }) {

  }

  updateRoom({ id, state }) {

  }

  updateZone({ id, state }) {

  }
}
