export default theme => ({
  clickableRow: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.aroya.background.two,
    },
    '&:active': {
      backgroundColor: '#fff3',
    },
  },
  nonClickableRow: {
    padding: theme.spacing(1),
    cursor: 'default',
  },
  facilitySection: {
    padding: theme.spacing(2),
  },
  facilityGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'max-content auto auto auto 1fr',
    gridGap: '1rem',
  },
  facilityInfo: {
    gridColumn: 'span 2',
  },
  facilityGridCell: {
    maxHeight: '380px',
    overflow: 'auto',
    borderRadius: '6px',
  },
  deactivatingRow: {
    boxShadow: `inset 0px 0px 0px 2px ${theme.aroya.error.main}`
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  subheaderRow: {
    maxWidth: 'fit-content',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    gap: '1rem',
    flexWrap: 'wrap',
    '@media screen and (max-width: 1262px)': {
      maxWidth: '100%',
      flexWrap: 'wrap'
    },
  },
  licenseChooser: {
    maxWidth: '330px',
    minWidth: '330px'
  },
  metrcRow: {
    gridColumn: 'span 2',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  metrcEntity: {
    textAlign: 'center',
  },
  licenseHeader: {
    marginTop: theme.spacing(2),
  },
  licenseRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  formWrapper: {
    columns: 4,
    columnGap: '1.5rem',
    '& .aroya-portal__field-group': {
      breakInside: 'avoid-column',
    },
    '& > .aroya-portal__feature-flags': {
      paddingBlockStart: '1rem',
    },
    '& .aroya-portal__margin-reset': {
      margin: 0,
    },
    '& .MuiInput-root, & [id*="select-field"] [class*="control"]': {
      minHeight: '3.25rem',
    },
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
  formSelectField: {
    width: '48.5%',
    margin: `${theme.spacing(2)}px 0 0 0`,
  },
  formAltSelectField: {
    width: '48.5%',
  },
  formTextField: {
    width: '48.5%',
    margin: 0,
  },
  licenseField: {
    width: '42.5%',
    margin: 0,
  },
  iconBtn: {
    width: '50px',
    height: '50px',
  },
  toggles: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    breakInside: 'avoid-column',
    paddingBlockStart: '1rem',
    '& > *': {
      flex: '1 0 10rem'
    },
  },
  appField: {
    height: '56px',
    margin: `${theme.spacing(2)}px 0 0 0`,
    background: 'rgba(255, 255, 255, 0.02)',
    backgroundColor: 'rgba(255,255,255,0.02)',
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    borderBottom: '1px solid #82c6ff',
  },
  appTitle: {
    fontSize: theme.spacing(1.4),
    paddingLeft: theme.spacing(2.3),
    paddingTop: theme.spacing(0.3),
  },
  appName: {
    color: '#ffffff',
    fontSize: 14,
    paddingLeft: theme.spacing(2.3),
    marginBottom: theme.spacing(0.9),
  },
  cardData: {
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    border: '1px solid black',
    borderRadius: '1ch',
    backgroundColor: theme.aroya.background.paper,
  },
  boldLabel: {
    color: theme.aroya.contrast.strong,
  },
  circleRed: {
    fill: theme.aroya.error.main
  },
  circleGreen: {
    fill: 'green'
  },
  deviceName: {
    color: theme.aroya.contrast.strong
  },
  deviceData: {
    color: theme.aroya.contrast.muted,
  }
})
