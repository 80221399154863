import React, { useState } from 'react'
import { useConnect } from 'redux-bundler-hook'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  Button,
  CircularProgress,
  IconButton,
  TableBody,
  TableRow,
  Typography,
} from '@material-ui/core'

import {
  ExpandMore as ExpandMoreIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons/'

import ListTable from '~/UI/Shared/ListTable'
import ListTableCell from '~/UI/Shared/ListTableCell'
import { BatteryIcon, SignalIcon } from '~/Device/utils'
import LoggerForm from './form'

export const LoggerList = props => {
  const {
    doLoggerFetch,
    doFacilityFetch,
    doLoggerSave,
    loggerListIsLoading,
    isGuestStaff,
    facilities,
    rooms,
    zones,
    me,
  } = useConnect(
    'doLoggerFetch',
    'doFacilityFetch',
    'doLoggerSave',
    'selectLoggerListIsLoading',
    'selectIsGuestStaff',
    'selectFacilities',
    'selectRooms',
    'selectZones',
    'selectMe',
  )

  const { loggerList } = props
  const [loggerPortsToggle, setLoggerPortsToggle] = useState({ logger: null, isOpen: false })
  const [currentLogger, setCurrentLogger] = useState({})
  const [loggerFormOpen, setLoggerFormOpen] = useState(false)

  const renderToggleButton = loggerId => {
    if (loggerPortsToggle.logger?.id) {
      return loggerPortsToggle.logger?.id === loggerId && loggerPortsToggle.isOpen
        ? <ExpandMoreIcon />
        : <KeyboardArrowRightIcon />
    }
    return <KeyboardArrowRightIcon />
  }

  const getLoggerEntity = logger => {
    if (logger?.payloadType !== 'entity') {
      const logEntity = doLoggerFetch(logger.id)
      return logEntity
    }
    return logger
  }

  const handleConnectionToggle = async (e, logger) => {
    const loggerEntity = await getLoggerEntity(logger)
    if (loggerPortsToggle.isOpen) {
      setLoggerPortsToggle({
        logger: loggerEntity,
        isOpen: loggerPortsToggle.logger?.id !== loggerEntity.id
      })
    } else {
      setLoggerPortsToggle({
        logger: loggerEntity,
        isOpen: true
      })
    }
  }

  const handleConfigureClicked = async logger => {
    const loggerEntity = await getLoggerEntity(logger)
    doFacilityFetch(loggerEntity.facility)
    setCurrentLogger(loggerEntity)
    setLoggerFormOpen(true)
  }

  const renderLocation = logger => {
    if (!logger.room) {
      return (
        <Typography variant={logger.assigned ? 'subtitle2' : 'body2'} noWrap>
          {logger.assigned
            ? facilities?.[logger.facility]?.name
            : 'Not Assigned'}
        </Typography>
      )
    }
    return (
      <>
        <Typography variant="subtitle2" noWrap>
          {rooms?.[logger.room]?.name}
        </Typography>
        <Typography variant="body2" noWrap>
          {zones?.[logger.zone]?.name}
        </Typography>
      </>
    )
  }

  return (
    <>
      <LoggerForm
        open={loggerFormOpen}
        instance={currentLogger}
        onClose={() => setLoggerFormOpen(false)}
        onSave={doLoggerSave}
      />
      <ListTable>
        <TableBody>
          <TableRow>
            <ListTableCell>Ports</ListTableCell>
            <ListTableCell style={{ width: '180px' }}>Name</ListTableCell>
            <ListTableCell style={{ width: '180px' }}>Model/Serial #</ListTableCell>
            <ListTableCell>Devices</ListTableCell>
            <ListTableCell style={{ maxWidth: '180px' }}>Assigned</ListTableCell>
            <ListTableCell>Status</ListTableCell>
            <ListTableCell align="center">Battery</ListTableCell>
            <ListTableCell align="center">Signal</ListTableCell>
            {!isGuestStaff && (
              <ListTableCell>Edit</ListTableCell>
            )}
          </TableRow>
          {loggerList.map(logger => {
            const loggerAndPortsOpen = loggerPortsToggle.isOpen
              && loggerPortsToggle.logger?.id === logger.id

            const activeStatus = logger.ports
              ?.filter(port => port.device?.online)
              ?.includes(true)

            return (
              <React.Fragment key={logger.id}>
                <TableRow key={logger.id}>
                  <ListTableCell>
                    <IconButton
                      size="small"
                      onClick={e => handleConnectionToggle(e, logger)}
                    >
                      {renderToggleButton(logger.id)}
                    </IconButton>
                    {(loggerPortsToggle.logger?.id === logger.id && loggerListIsLoading) && (
                      <CircularProgress size={20} />
                    )}
                  </ListTableCell>

                  <ListTableCell style={{ paddingTop: '0', paddingBottom: '0' }}>
                    <Typography variant="subtitle1" noWrap>
                      {logger.serialNumber}
                    </Typography>
                    {logger.name && logger.name !== logger.serialNumber ? (
                      <Typography variant="body1" noWrap>
                        {logger.name}
                      </Typography>
                    ) : null}
                  </ListTableCell>

                  <ListTableCell>
                    <Typography variant="body1" noWrap>
                      {logger.model}
                    </Typography>
                  </ListTableCell>
                  <ListTableCell>
                    <Typography variant={logger.numberOfDevices ? 'subtitle2' : 'body2'} noWrap>
                      {logger.numberOfDevices} devices connected
                    </Typography>
                  </ListTableCell>
                  <ListTableCell style={{ paddingTop: '0', paddingBottom: '0' }}>
                    {renderLocation(logger)}
                  </ListTableCell>
                  <ListTableCell>
                    <Typography variant={activeStatus ? 'subtitle2' : 'body2'} noWrap>
                      {activeStatus ? 'Active' : 'Offline'}
                    </Typography>
                  </ListTableCell>
                  <ListTableCell>
                    <BatteryIcon device={logger} />
                  </ListTableCell>
                  <ListTableCell>
                    <SignalIcon device={logger} />
                  </ListTableCell>
                  {!isGuestStaff && (
                    <ListTableCell>
                      <Button
                        color="primary"
                        onClick={() => handleConfigureClicked(logger)}
                      >
                        Configure
                      </Button>
                    </ListTableCell>
                  )}
                </TableRow>
                {loggerAndPortsOpen && logger.ports?.length ? logger.ports.map(port => {
                  const assignedModel = me.models?.find(model => (
                    model.id === port.device?.model || model.id === port.assignedModel
                  ))?.name

                  return (
                    <TableRow key={port.id}>
                      <ListTableCell />
                      <ListTableCell>
                        <Typography variant="subtitle2" noWrap>
                          {port.device?.modelName ?? assignedModel ?? '-'}
                        </Typography>
                      </ListTableCell>
                      <ListTableCell style={{ paddingTop: '0', paddingBottom: '0' }}>
                        <Typography variant="subtitle2" noWrap>
                          {port.device?.modelName ?? assignedModel ?? '-'}
                        </Typography>
                        <Typography variant="body2" noWrap>
                          {port.device?.serialNumber}
                        </Typography>
                      </ListTableCell>
                      <ListTableCell>
                        <Typography variant="subtitle2" noWrap>
                          Port {port.number} {port.depth && `(${port.depth}")` }
                        </Typography>
                      </ListTableCell>
                      <ListTableCell />
                      <ListTableCell colSpan={4}>
                        <Typography variant={port.device?.lastCommunication ? 'subtitle2' : 'body2'} noWrap>
                          {port.device?.lastCommunication
                            ? moment(port.device?.lastCommunication).fromNow()
                            : 'Never'}
                        </Typography>
                      </ListTableCell>
                    </TableRow>
                  )
                }) : null}
              </React.Fragment>
            )
          })}
        </TableBody>
      </ListTable>
    </>
  )
}

LoggerList.propTypes = {
  loggerList: PropTypes.arrayOf(PropTypes.object),
}

LoggerList.defaultProps = {
  loggerList: []
}
