import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { DateTimePicker, DatePicker } from '@material-ui/pickers'
import { datePropType, stylePropType } from '~/Lib/PropTypes'
import { getDateTime } from '~/Lib/Utils'

import TextField from '~/UI/Shared/Form/TextField'

const noop = () => {}

export const DatePickerComponent = ({
  name,
  value,
  onChange: propsOnChange,
  clearable,
  dense,
  error: propsError,
  helperText: propsHelperText,
  fullWidth,
  datetime,
  ...props
}) => {
  const onChange = useCallback(
    date => propsOnChange(name ? { [name]: date } : date),
    [propsOnChange]
  )

  const Picker = datetime ? DateTimePicker : DatePicker

  // eslint-disable-next-line react/no-unstable-nested-components
  const TextFieldComponent = useMemo(() => ({
    onChange: tfOnChange = noop,
    error,
    helperText,
    ...tfProps
  }) => (
    <TextField
      {...tfProps}
      dense={dense}
      error={(error && helperText) || propsError}
      helperText={helperText || propsHelperText}
      fullWidth={fullWidth}
      onChange={tfOnChange}
    />
  ), [dense, fullWidth, propsError, propsHelperText])

  return (
    <Picker
      data-testid="date-picker"
      autoOk
      clearable={Boolean(clearable && value)}
      format={datetime ? 'f' : 'D'}
      TextFieldComponent={TextFieldComponent}
      placeholder="Press enter to activate calendar"
      orientation="portrait"
      {...props}
      name={name}
      value={value ? getDateTime(value) : null}
      onChange={onChange}
    />
  )
}

DatePickerComponent.propTypes = {
  clearable: PropTypes.bool,
  datetime: PropTypes.bool,
  dense: PropTypes.bool,
  fullWidth: PropTypes.bool,
  error: PropTypes.node,
  helperText: PropTypes.node,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, datePropType]),
  onChange: PropTypes.func.isRequired,
  style: stylePropType,
}

DatePickerComponent.defaultProps = {
  clearable: true,
  datetime: false,
  dense: false,
  fullWidth: false,
  error: undefined,
  helperText: undefined,
  name: null,
  value: '',
  style: {},
}

export const FormikDatePicker = React.memo(props => {
  const { field, form, alwaysShowError, ...passthru } = props
  const onChange = useCallback(
    ({ [field.name]: value }) => {
      form.setFieldValue(field.name, value)
      form.setFieldTouched(field.name)
    },
    [form.setFieldValue]
  )

  const { error, touched } = form.getFieldMeta(field.name)

  return (
    <DatePickerComponent
      {...passthru}
      {...field}
      error={form.dirty && (touched || alwaysShowError) && error}
      onChange={onChange}
    />
  )
})

export default React.memo(DatePickerComponent)
