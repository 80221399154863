import Container from './container'

export default class Floorplan extends Container {
  constructor({ facility = `facility-${f}`, rooms = {}, zones = {}, directory = {}, ...args }) {
    super(args)
    this.facility = facility
    this.rooms = rooms
    this.zones = zones
    this.directory = directory
  }
}
