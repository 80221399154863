import React from 'react'
import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useConnect } from 'redux-bundler-hook'

import { formatDateTime } from '~/Lib/Utils'
import styles from './styles'

const useStyles = makeStyles(styles)

const FacilityMetrcRow = ({ facility, syncInfo }) => {
  const { currentOrganizationCultivars } = useConnect('selectCurrentOrganizationCultivars')
  const classes = useStyles()

  const metrcRow = [
    {
      type: 'Plants',
      description: 'Last Plant Sync',
      sync: syncInfo?.lastPlantSync,
    },
    {
      type: 'Plant Batches',
      description: 'Last Plant Batch Sync',
      sync: syncInfo?.lastBatchSync,
    },
    {
      type: 'Harvest Batches',
      description: 'Last Harvest Batch Sync',
      sync: syncInfo?.lastHarvestSync,
    },
    {
      type: 'Packages',
      description: 'Last Package Sync',
      sync: syncInfo?.lastPackageSync,
    },
  ]

  return (
    <Paper className={classes.metrcRow}>
      <Grid
        container
        justify="space-between"
        wrap="nowrap"
        alignItems="center"
      >
        {metrcRow.map(entity => {
          const param = entity.type.toLowerCase().replace(' ', '-')
          return (
            <Grid item key={entity.type} className={classes.metrcEntity}>
              <Button
                color="primary"
                component="a"
                disabled={!currentOrganizationCultivars}
                href={`/facilities/${facility.id}/metrc/${param}`}
              >
                {entity.type}
              </Button>
              {entity.sync && (
                <>
                  <Typography color="textSecondary">{entity.description}</Typography>
                  {formatDateTime(entity.sync)}
                </>
              )}
            </Grid>
          )
        })}
      </Grid>
    </Paper>
  )
}

export default FacilityMetrcRow
