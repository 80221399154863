import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'
import { createSelector } from 'redux-bundler'
import reduceReducers from 'reduce-reducers'
import ms from 'milliseconds'

const PLANT_BATCH_LIST_SET_FILTERS = 'PLANT_BATCH_LIST_SET_FILTERS'
const PLANT_BATCH_LIST_SET_SEARCH = 'PLANT_BATCH_LIST_SET_SEARCH'
const PLANT_BATCH_LIST_SET_SORT = 'PLANT_BATCH_LIST_SET_SORT'
const PLANT_BATCH_LIST_SET_PAGINATION = 'PLANT_BATCH_LIST_SET_PAGINATION'
const PLANT_BATCH_LIST_SET_CURRENT_BATCH = 'PLANT_BATCH_LIST_SET_CURRENT_BATCH'

const additionalState = {
  sort: 'label',
  search: '',
  pagination: {
    currentPage: 1,
    next: 2,
    count: 0,
    pageSize: 25,
    numPages: 0,
  },
  filters: {
    finished: null,
    plantBatchExistsIn: null,
    plantType: null,
    plantedDate: null,
    cultivarName: null,
    difference: null,
    hideFinished: 'HIDE',
  },
  currentBatch: {},
}

const plantBatchListBundle = createAsyncResourceBundle({
  name: 'plantBatchList',
  actionBaseType: 'PLANT_BATCH_LIST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: async ({ apiFetch, getState, store }) => {
    const facility = store.selectCurrentFacilityId()
    const license = store.selectCurrentFacilityLicense()?.id
    const { plantBatchList: { filters, sort, search } } = getState()

    /* eslint-disable babel/camelcase */
    const response = await apiFetch('/plant_batches/', {
      facility,
      search,
      sort,
      license,
      difference: filters.difference,
      cultivar_name: filters.cultivarName,
      finished: filters.finished,
      plant_type: filters.plantType,
      planted_date: filters.plantedDate,
      hide_finished: filters.hideFinished,
      exists_in: filters.plantBatchExistsIn,
    })
    /* eslint-enable babel/camelcase */
    const numPages = Math.ceil(response.length / additionalState.pagination.pageSize)
    const next = response.length > additionalState.pagination.pageSize ? 2 : null
    store.doPlantBatchListClearCurrentBatch()
    store.doPlantBatchListSetPagination({
      currentPage: 1,
      count: response.length,
      numPages: numPages || 1,
      next,
    })
    return response
  }
})

export default {
  ...plantBatchListBundle,
  reducer: reduceReducers(plantBatchListBundle.reducer, (state, action) => {
    switch (action.type) {
      case PLANT_BATCH_LIST_SET_SEARCH:
        return { ...state, search: action.payload }
      case PLANT_BATCH_LIST_SET_SORT:
        return { ...state, sort: action.payload }
      case PLANT_BATCH_LIST_SET_FILTERS:
        return { ...state, filters: { ...state.filters, ...action.payload } }
      case PLANT_BATCH_LIST_SET_PAGINATION:
        return { ...state, pagination: { ...state.pagination, ...action.payload } }
      case PLANT_BATCH_LIST_SET_CURRENT_BATCH:
        return { ...state, currentBatch: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),

  doPlantBatchListSetFilters: filters => ({ dispatch, store }) => {
    dispatch({ type: PLANT_BATCH_LIST_SET_FILTERS, payload: filters })
    store.doMarkPlantBatchListAsOutdated()
  },
  doPlantBatchListSetSearch: search => ({ dispatch, store }) => {
    if (search === store.selectPlantBatchListSearch()) return
    dispatch({ type: PLANT_BATCH_LIST_SET_SEARCH, payload: search })
    store.doMarkPlantBatchListAsOutdated()
  },
  doPlantBatchListSetSort: sort => ({ dispatch, store }) => {
    if (sort === store.selectPlantBatchListSort()) return
    dispatch({ type: PLANT_BATCH_LIST_SET_SORT, payload: sort })
    store.doMarkPlantBatchListAsOutdated()
  },
  doPlantBatchListSetPagination: newPagination => ({ store, dispatch }) => {
    const { pagination } = store.selectPlantBatchListRaw()
    const payload = { ...pagination, ...newPagination }
    dispatch({ type: PLANT_BATCH_LIST_SET_PAGINATION, payload })
  },
  doPlantBatchListUpdateBatch: payload => async ({ store, apiFetch }) => {
    try {
      const result = await apiFetch(
        `/plant_batches/${payload.id}/`,
        { ...payload },
        { method: 'PUT' },
      )
      store.doMarkPlantBatchListAsOutdated()
      store.doAddSnackbarMessage(`Successfully updated ${payload.label}`)
      return result
    } catch (error) {
      return error
    }
  },
  doPlantBatchListFetchBatch: id => async ({ store, apiFetch }) => {
    try {
      const result = await apiFetch(`/plant_batches/${id}/`)
      store.doPlantBatchListSetCurrentBatch(result)
      return result
    } catch (error) {
      return error
    }
  },
  doPlantBatchListSetCurrentBatch: payload => async ({ store, dispatch }) => {
    if (payload.id === store.selectPlantBatchListCurrentBatch()?.id) return
    dispatch({ type: PLANT_BATCH_LIST_SET_CURRENT_BATCH, payload })
  },
  doPlantBatchListClearCurrentBatch: () => async ({ dispatch }) => {
    dispatch({ type: PLANT_BATCH_LIST_SET_CURRENT_BATCH, payload: {} })
  },
  selectPlantBatchListFilters: createSelector(
    'selectPlantBatchListRaw',
    ({ filters }) => filters
  ),
  selectPlantBatchListSort: createSelector(
    'selectPlantBatchListRaw',
    ({ sort }) => sort
  ),
  selectPlantBatchListSearch: createSelector(
    'selectPlantBatchListRaw',
    ({ search }) => search
  ),
  selectPlantBatchListCurrentBatch: createSelector(
    'selectPlantBatchListRaw',
    ({ currentBatch }) => currentBatch
  ),
  selectCurrentPlantBatchList: createSelector(
    'selectPlantBatchListRaw',
    ({ data, pagination }) => {
      if (data?.length <= additionalState.pagination.pageSize) return data
      const { pageSize, currentPage } = pagination
      return data?.slice(((pageSize * currentPage) - pageSize), (pageSize * currentPage))
    }
  ),
  selectPlantBatchListPagination: createSelector(
    'selectPlantBatchListRaw',
    ({ pagination }) => pagination
  ),
  reactPlantBatchListFetch: createSelector(
    'selectAuth',
    'selectPlantBatchListShouldUpdate',
    'selectRouteInfo',
    ({ authenticated }, shouldUpdate, { url }) => {
      const params = ['facilities', 'metrc', 'plant-batches']
      const urlHasParams = params.every(param => url.includes(param))
      if (authenticated && shouldUpdate && urlHasParams) {
        return { actionCreator: 'doFetchPlantBatchList' }
      }
      return undefined
    }
  ),
}
