import createEntityBundle from '~/Lib/createEntityBundle'
import { Device as schema } from '~/Store/Schemas'
import { parseApiErrors } from '~/Lib/Utils'

export default createEntityBundle({
  name: 'devices',
  apiConfig: { schema },
  customActions: [{
    action: 'reset',
    returnRaw: true,
    async: true,
    handler: ({ payload, apiFetch }) => apiFetch('/reset_gateway/', payload, { method: 'POST' }),
    snackbar: ({ dispatch, status, payload, error }) => {
      if (status === 'succeed') {
        dispatch({
          actionCreator: 'doAddSnackbarMessage',
          args: [`Reset gateway ${payload?.gateway_sn} successfully started`]
        })
      }

      if (error.error) {
        const errorMessage = parseApiErrors(error) || 'An unknown error occurred.'
        dispatch({
          actionCreator: 'doAddSnackbarMessage',
          args: [`Unable to reset gateway ${payload?.gateway_sn}: ${errorMessage}`]
        })
      } else {
        dispatch({
          actionCreator: 'doAddSnackbarMessage',
          args: [`Reset gateway ${payload?.gateway_sn} started, this could up to 5 min.`]
        })
      }
    }
  }],
})
