import { stringify } from 'svgson'

export default function astToSvg({ floorplan, facility, rooms, zones }, areaAssociationsDict = {}, theme = {}, admin, debug) {
  facility['facility-0'].svgson.children[0].attributes.fill = theme.facility.floorColor

  floorplan['floorplan-0'].svgson.children[0].children = [
    { ...facility['facility-0'].svgson },

    [...Object.keys(rooms).map(room => {
      const uuid = rooms[room].uuid
      const roomChildren = rooms[room].svgson.children
      const text = roomChildren
        .filter(child => child.name === 'svg')[0].children
        .filter(child => child.children[0])[0].children
        .filter(child => child.name === 'text')[0]
      const roomName = areaAssociationsDict?.rooms?.[uuid]?.name

      Object.assign(rooms[room].svgson.attributes, {
        stroke: theme.room?.wallColor,
        'stroke-width': theme.room?.wallWidth
      })

      text.attributes.fill = theme.textColor
      if (roomName) {
        text.children[0].value = roomName
      }

      if (!uuid) {
        if (!admin && !debug) {
          text.children[0].value = ''
        }
        rooms[room].svgson.attributes.class = 'no-room-association'
        rooms[room].svgson.attributes.fill = theme.room.noAssociationFillColor
      } else if (uuid) {
        rooms[room].svgson.attributes.class = 'room-associated'
        rooms[room].svgson.attributes.fill = theme.room.fillColor
      }

      return rooms[room].svgson
    })],

    [...Object.keys(zones).map(zone => {
      const uuid = zones[zone].uuid

      const zoneChildren = zones[zone].svgson.children

      const text = zoneChildren
        .filter(child => child.name === 'svg')[0].children
        .filter(child => child.children[0])[0].children
        .filter(child => child.name === 'text')[0]
      const zoneName = areaAssociationsDict?.zones?.[uuid]?.name

      Object.assign(zones[zone].svgson.attributes, {
        stroke: theme.zone?.wallColor,
        'stroke-width': theme.zone?.wallWidth
      })

      text.attributes.fill = theme.textColor

      if (zoneName) {
        text.children[0].value = zoneName
      }

      if (!uuid) {
        if (!admin && !debug) {
          text.children[0].value = ''
        }
        zones[zone].svgson.attributes.class = 'no-zone-association'
        zones[zone].svgson.attributes.fill = theme.zone.noAssociationFillColor
      } else if (uuid) {
        zones[zone].svgson.attributes.class = 'zone-associated'
        zones[zone].svgson.attributes.fill = theme.zone.fillColor
      }

      return zones[zone].svgson
    })]
  ]

  return stringify(floorplan['floorplan-0'].svgson)
}
