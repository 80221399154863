import React from 'react'

import { SvgIcon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const small = 'M3 16H6V16.4444L3 19.5556V20H6'
const medium = 'M9 11H13V11.6667L9 16.3333V17H13'
const large = 'M16 6H21V6.88889L16 13.1111V14H21'

const displayName = 'Icon/Sleep'
const useStyles = makeStyles({
  root: {
    fill: 'none',
    '& > path': {
      fill: 'none',
      stroke: 'currentColor',
      strokeWidth: 2,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }
  }
}, { name: displayName })

export default props => (
  <SvgIcon {...props} classes={useStyles(props)} viewBox="0 0 24 24">
    <path d={small} />
    <path d={medium} />
    <path d={large} />
  </SvgIcon>
)
