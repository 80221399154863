import { TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const ListTableCell = withStyles(theme => ({
  body: {
    color: theme.meter.text.inactive,
    background: theme.meter.background.four,
    fontSize: 14,
    border: 'none',
    padding: '15px',
    boxShadow: [
      '0 2px 0 0 rgba(0, 0, 0, 0.2)',
      '0 1px 0 0 rgba(0, 0, 0, 0.12)',
      '0 4px 0 0 rgba(0, 0, 0, 0.14)'
    ].join(),
    '&:first-child': {
      borderRadius: '6px 0px 0px 6px',
    },
    '&:last-child': {
      borderRadius: '0px 6px 6px 0px',
    },
  },
}))(TableCell)

export default ListTableCell
