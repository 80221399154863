import { ascend, prop, sortWith } from 'ramda'
import { createSelector } from 'redux-bundler'

import createEntityBundle from '~/Lib/createEntityBundle'
import { compileTemplate, EMPTY_OBJECT } from '~/Lib/Utils'
import { DataType as schema } from '~/Store/Schemas'

const name = 'dataTypes'

const bundle = createEntityBundle({
  name,
  apiConfig: { schema, prepareData: data => data },
})

export const manualDataTypeSort = sortWith([ascend(dt => {
  switch (dt.category) {
    case 'MANUAL':
      return 1
    case 'OTHER':
      return 2
    case 'SOIL':
      return 3
    case 'ENVIRONMENT':
      return 4
    default:
      return 5
  }
}), ascend(prop('sequence')), ascend(prop('name'))])

export const dataTypeSort = sortWith([ascend(dt => {
  switch (dt.category) {
    case 'SOIL':
      return 1
    case 'ENVIRONMENT':
      return 2
    case 'OTHER':
      return 3
    case 'MANUAL':
      return 4
    default:
      return 5
  }
}), ascend(prop('sequence')), ascend(prop('name'))])

export default {
  ...bundle,
  selectAllDataTypes: createSelector(
    'selectDataTypesEntityRoot',
    ({ byId = EMPTY_OBJECT }) => Object.entries(byId).reduce((acc, [key, dataType]) => {
      // eslint-disable-next-line no-shadow
      const { name, template: templateString, ...rest } = dataType
      const template = compileTemplate(templateString)
      return {
        ...acc,
        [key]: {
          ...rest,
          name,
          nameText: name.replace(/<\/?\w+>/g, ' ').trim(),
          template: value => template({ value }),
        },
      }
    }, EMPTY_OBJECT)
  ),
  selectModelsByDataType: createSelector(
    'selectModels',
    models => Object.values(models).reduce(
      (acc, { dataTypes, key }) => ({
        ...acc,
        ...dataTypes.reduce(
          (dtAcc, dtKey) => ({
            ...dtAcc,
            [dtKey]: {
              ...dtAcc[dtKey],
              [key]: 1,
            },
          }),
          acc
        ),
      }),
      EMPTY_OBJECT
    )
  ),
}
