import { createSelector } from 'redux-bundler'

import qs from 'query-string'

import cache from '~/IO/Cache'
import { AUTH_URL, clearTokens, setTokens } from '~/Lib/Auth'
import { getAsyncActionIdentifiers } from '~/Lib/createEntityBundle'
import createLogger from '~/Lib/Logging'
import { defer, parseApiErrors } from '~/Lib/Utils'

const logger = createLogger('Store/auth')

const DEFAULT_NEXT = '/'
const { location } = window

export const loginActions = getAsyncActionIdentifiers('login')
export const logoutActions = getAsyncActionIdentifiers('logout')
const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE'

const SET_URL_HISTORY = 'SET_URL_HISTORY'

export const defaultState = {
  authenticated: false,
  loginProcessing: false,
  loginFailed: false,
  logoutProcessing: false,
  loginMessage: '',
  urlHistory: [],
}

export default {
  name: 'auth',
  reducer: (state = defaultState, action = null) => {
    const {
      types: { start: loginStart, succeed: loginSucceed, fail: loginFail },
    } = loginActions
    const {
      types: { start: logoutStart, succeed: logoutSucceed, fail: logoutFail },
    } = logoutActions

    if (!action || !action.type) return state
    switch (action.type) {
      case loginStart:
        return { ...state, loginProcessing: true, loginFailed: false }
      case loginFail:
        return {
          ...state,
          loginProcessing: false,
          loginFailed: true,
          loginMessage: action?.error ?? 'Unable to login',
        }
      case loginSucceed:
        return {
          ...state,
          ...defaultState,
          authenticated: action?.payload?.authenticated ?? false,
        }
      case logoutStart:
        return { ...state, logoutProcessing: true }
      case logoutFail:
        return { ...state, logoutProcessing: false }
      case logoutSucceed:
      case CLEAR_AUTH_STATE:
        return { ...defaultState }
      case SET_URL_HISTORY:
        return { ...state, urlHistory: [...state.urlHistory, action.payload].slice(-2) }
      default:
        return state
    }
  },
  selectAuth: state => state.auth,
  selectLastUrl: state => state.auth.urlHistory.slice(-2, -1).pop() || '',
  doLogin: credentials => async ({ apiFetch, dispatch }) => {
    const { types } = loginActions

    dispatch({ type: types.start })
    try {
      const auth = await apiFetch(AUTH_URL, credentials, {
        method: 'POST',
        allowedCodes: [400],
        credentials: 'include'
      })
      if ('nonFieldErrors' in auth) {
        dispatch({ type: types.fail, error: parseApiErrors(auth.nonFieldErrors) })
      } else {
        setTokens(auth)
        cache.setUserToken(auth.user)
        dispatch({ type: types.succeed, payload: { authenticated: !!auth.user } })
      }
    } catch (error) {
      dispatch({ type: types.fail, error: parseApiErrors(error) })
    }
  },
  doLogout: (reset = false) => async ({ dispatch }) => {
    const { types } = logoutActions
    dispatch({ type: types.start })
    defer(() => {
      try {
        clearTokens()
        dispatch({ type: types.succeed })
        cache.clear()
        // eslint-disable-next-line no-restricted-globals
        window.location.href = `/login${reset === true ? '?reset=1' : ''}`
      } catch (error) {
        logger.error(error)
        dispatch({ type: types.fail, error })
      }
    }, defer.priorities.low)
  },
  doAuthReset: () => ({ type: CLEAR_AUTH_STATE }),
  doSetUrlHistory: payload => async ({ dispatch }) => {
    dispatch({ type: SET_URL_HISTORY, payload })
  },
  doGoBack: defaultUrl => ({ store }) => {
    const lastUrl = store.selectLastUrl()
    store.doUpdateUrl(lastUrl || defaultUrl)
  },
  selectIsLoggingOut: createSelector('selectAuth', auth => !!auth.logoutProcessing),
  reactChangedUrl: createSelector(
    'selectRouteInfo',
    'selectAuth',
    ({ url }, { urlHistory }) => {
      if (urlHistory.slice(-1).pop() !== url) {
        return { actionCreator: 'doSetUrlHistory', args: [url] }
      }
      return undefined
    }
  ),
  reactLoginSuccess: createSelector(
    'selectRouteInfo',
    'selectAuth',
    ({ url }, { authenticated }) => {
      if (url === '/login' && authenticated) {
        let { next = DEFAULT_NEXT } = qs.parse(location.search)
        if (next === '/login') next = DEFAULT_NEXT
        return {
          actionCreator: 'doUpdateUrl',
          args: [next],
        }
      }
      return undefined
    }
  ),
}
