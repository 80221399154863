import React from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

const SnoozeDialogComponent = props => {
  const { device, facility, onClose } = props

  const obj = facility || device

  const {
    doSnoozeDevice,
    doSnoozeFacility,
  } = useConnect(
    'doSnoozeDevice',
    'doSnoozeFacility',
  )

  const doSnooze = facility ? doSnoozeFacility : doSnoozeDevice
  const handleSnooze = duration => async () => {
    await doSnooze({ device, facility, duration })
    onClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...props}
    >
      <DialogTitle>
        Mute notifications from { facility ? facility.name : device?.serialNumber }?
      </DialogTitle>
      <DialogContent>
        {obj?.snoozedUntil ? (
          `Notifications are currently muted until ${obj?.snoozedUntil}`
        ) : (
          'Notifications are not currently muted'
        )}
      </DialogContent>
      <DialogActions>
        { obj?.snoozedUntil && (
          <Button color="primary" onClick={handleSnooze(-1)}>
            Unmute
          </Button>
        )}
        <Button color="primary" onClick={handleSnooze(36)}>
          Mute for 36h
        </Button>
        <Button color="primary" onClick={handleSnooze(0)}>
          Mute Indefinitely
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SnoozeDialogComponent
