import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import MuiSelectField from '~/UI/Shared/Form/SelectField'

const MetrcLicense = ({
  facility,
  markOutdated = null,
}) => {
  const {
    currentFacilityLicense,
    doSetCurrentFacilityLicense,
  } = useConnect(
    'selectCurrentFacilityLicense',
    'doSetCurrentFacilityLicense',
  )

  const facilityLicenses = facility.licenses
  const options = facilityLicenses?.map(
    license => ({
      label: license?.number,
      value: license.id
    })
  )
  const value = options.find(
    opt => opt.value === currentFacilityLicense?.id
  ) || options[0]

  const handleChange = option => {
    const license = facilityLicenses?.find(lic => lic.id === option.value)
    doSetCurrentFacilityLicense(license)
    if (markOutdated) markOutdated()
  }

  return (
    <div style={{ marginRight: '10px' }}>
      <MuiSelectField
        fullWidth
        placeholder="License"
        color="primary"
        options={options}
        value={value}
        onChange={handleChange}
      />
    </div>
  )
}

export default MetrcLicense
