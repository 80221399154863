import Container from './container'

export default class Zone extends Container {
  constructor({ facility, room, ...args }) {
    super(args)
    this.facility = facility
    this.room = room
  }

  updateZone({ id, state }) {

  }
}
