export default theme => ({
  treeContainer: {
    maxHeight: '85vh',
    overflowX: 'hidden',
    overflowY: 'scroll',
    padding: '0 !important'
  },
  otapList: {
    margin: '-15px 0 0 10px'
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '5px'
  },
  orgItem: {
    padding: '5px 0'
  },
  facItem: {
    paddingLeft: '5px'
  },
  devItem: {
    paddingLeft: '10px'
  },
  filterNav: {
    marginLeft: '17%',
    marginBottom: theme.spacing(1)
  },
  actionButton: {
    margin: `0 ${theme.spacing(1)}px`
  },
  retryButton: {
    marginLeft: theme.spacing(3)
  },
})
