import ms from 'milliseconds'
import { getConfiguredCache } from 'money-clip'

import config from '~/App/config'
import { getTokens } from '~/Lib/Auth'
import createLogger from '~/Lib/Logging'

const logger = createLogger('IO/Cache')

const fauxCache = {
  clear() {},
  getAll: () => Promise.resolve(undefined),
  set() {},
  logger,
}

let cache = fauxCache

const setUserToken = userToken => {
  logger.info('configuring cache with user token:', userToken)
  const options = {
    version: `${userToken.split(':')[0]}|${process.env.npm_package_version
      || 0}`,
    maxAge: ms.hours(config.CACHE_MAX_AGE_HOURS || 12),
  }
  cache = getConfiguredCache(options)
}

const { user: userToken } = getTokens()

if (userToken) setUserToken(userToken)

export default {
  clear: () => cache.clear(),
  getAll: async (...args) => cache.getAll(...args),
  set: (...args) => cache.set(...args),
  logger,
  setUserToken,
}
