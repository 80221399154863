import { createSelector } from 'redux-bundler'
import reduceReducers from 'reduce-reducers'
import createEntityBundle from '~/Lib/createEntityBundle'
import createListBundle from '~/Lib/createListBundle'
import { User as schema } from '~/Store/Schemas'
import { parseApiErrors } from '~/Lib/Utils'

const RESET_PASSWORD_START = 'RESET_PASSWORD_START'
const RESET_PASSWORD_SUCCEED = 'RESET_PASSWORD_SUCCEED'
const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
const USER_LIST_SET_PAGE = 'USER_LIST_SET_PAGE'
const USER_LIST_SET_SEARCH = 'USER_LIST_SET_SEARCH'
const USER_LIST_SET_FILTERS = 'USER_LIST_SET_FILTERS'
const USER_LIST_UPDATE = 'USER_LIST_UPDATE'
const MEMBERSHIP_FETCH_STARTED = 'MEMBERSHIP_FETCH_STARTED'
const MEMBERSHIP_FETCH_SUCCEED = 'MEMBERSHIP_FETCH_SUCCEED'
const MEMBERSHIP_FETCH_FAILED = 'MEMBERSHIP_FETCH_FAILED'

const initialState = {
  page: 1,
  search: '',
  sort: '',
  filters: {
    isSolusUser: null,
  },
}

export const users = createEntityBundle({
  name: 'users',
  apiConfig: { schema },
})

const initialUserList = createListBundle({
  entityName: 'user',
  schema,
  urlTest: url => url.startsWith('/users'),
  fetchHandler: ({ apiFetch, getState }) => {
    const { userList } = getState()
    const { page, filters, search, sort } = userList
    /* eslint-disable babel/camelcase */
    const payload = {
      page: page ?? 1,
      sort: sort ?? 'last_name',
      search,
      is_solus_user: filters.isSolusUser,
    }
    /* eslint-enable babel/camelcase */
    return apiFetch('/users/', payload)
  }
})

export const userList = {
  ...initialUserList,
  reducer: reduceReducers(initialUserList.reducer, (state, action) => {
    switch (action.type) {
      case USER_LIST_SET_PAGE:
        return { ...state, ...action.payload }
      case USER_LIST_SET_SEARCH:
        return { ...state, search: action.payload }
      case USER_LIST_UPDATE:
        return { ...state, data: action.payload }
      case MEMBERSHIP_FETCH_STARTED:
        return { ...state, isLoading: true }
      case MEMBERSHIP_FETCH_SUCCEED:
        return { ...state, memberships: action.payload, isLoading: false }
      case MEMBERSHIP_FETCH_FAILED:
        return { ...state, isLoading: false }
      case USER_LIST_SET_FILTERS:
        return { ...state, filters: { ...state.filters, ...action.payload } }
      default:
        if (!Object.keys(initialState).every(key => key in state)) {
          return { ...initialState, ...state }
        }
        return state
    }
  }),
  selectCurrentUserList: createSelector(
    'selectUsers',
    'selectUserListRaw',
    (_users, { data }) => data?.results?.map(id => _users[id])
  ),
  selectUserListFilters: createSelector(
    'selectUserListRaw',
    ({ filters }) => filters
  ),
  selectUserListPagination: createSelector(
    'selectUserList',
    ({ page, next, previous, numPages, pageSize, count }) => (
      { page, next, previous, numPages, pageSize, count }
    )
  ),
  selectUserListSort: createSelector(
    'selectUserListRaw',
    ({ sort }) => sort
  ),
  selectUserListSearch: createSelector(
    'selectUserListRaw',
    ({ search }) => search
  ),
  selectMemberships: createSelector(
    'selectUserListRaw',
    ({ memberships }) => memberships
  ),
  doUserListSetFilters: filters => ({ dispatch, store }) => {
    store.doUserListSetPage(1)
    dispatch({ type: USER_LIST_SET_FILTERS, payload: filters })
  },
  doFetchUserMemberships: user => async ({ apiFetch, dispatch, store }) => {
    dispatch({ type: MEMBERSHIP_FETCH_STARTED })
    let response
    try {
      response = await apiFetch('/memberships/', { user, pagination: 0 })
      const payload = { ...store.selectMemberships(), [user]: response }
      dispatch({ type: MEMBERSHIP_FETCH_SUCCEED, payload })
      return response
    } catch (error) {
      dispatch({ type: MEMBERSHIP_FETCH_FAILED })
    }
    return response
  },
  doUserSave: user => async ({ apiFetch, dispatch, store }) => {
    dispatch({ type: 'CREATE_USER_STARTED' })
    let response
    try {
      response = await apiFetch('/users/', user, { method: 'POST' })
      store.doResetPassword(user, `Create password email sent to ${user.email}`)
      store.doMarkUserListAsOutdated()
      dispatch({ type: 'CREATE_USER_SUCCEED', payload: response })
      return response
    } catch (error) {
      const errorMsg = parseApiErrors(error.response)
      store.doAddSnackbarMessage(errorMsg)
      dispatch({ type: 'CREATE_USER_FAILED', payload: error })
    }
    return response
  },
  doUserUpdate: user => async ({ apiFetch, dispatch, store }) => {
    dispatch({ type: 'UPDATE_USER_STARTED' })
    let response
    try {
      response = await apiFetch(`/users/${user.id}/`, user, { method: 'PUT' })
      dispatch({ type: 'UPDATE_USER_SUCCEED', payload: response })
      store.doAddSnackbarMessage(`Successfully updated ${user.email}`)
      store.doMarkUserListAsOutdated()
      store.doMarkSolusUsersListAsOutdated()
      return response
    } catch (error) {
      const errorMsg = parseApiErrors(error.response)
      store.doAddSnackbarMessage(errorMsg)
      dispatch({ type: 'UPDATE_USER_FAILED', payload: error })
    }
    return response
  },
  doUserListSetSearch: searchTerms => ({ store, dispatch }) => {
    dispatch({ type: USER_LIST_SET_SEARCH, payload: searchTerms })
    store.doUserListSetPage(1)
  },
  doUserListSetPage: page => ({ store, dispatch }) => {
    dispatch({ type: USER_LIST_SET_PAGE, payload: page })
    store.doMarkUserListAsOutdated()
  },
  doResetPassword: (payload, message) => async ({ apiFetch, store, dispatch }) => {
    const { email } = payload
    let response
    dispatch({ type: RESET_PASSWORD_START })
    try {
      response = await apiFetch('/forgot/', payload, { method: 'POST' })
      if (response.success) {
        dispatch({ type: RESET_PASSWORD_SUCCEED })
        store.doAddSnackbarMessage(message ?? `Successfully reset password for ${email}`)
      } else {
        dispatch({ type: RESET_PASSWORD_FAIL, payload: response[0] })
        store.doAddSnackbarMessage(`No account was found for ${email}`)
      }
    } catch (error) {
      dispatch({ type: RESET_PASSWORD_FAIL, payload: response[0] })
      store.doAddSnackbarMessage(`${response[0]}`)
    }
  }
}
