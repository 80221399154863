import React from 'react'

import PropTypes from 'prop-types'

import FileDrop from 'react-file-drop'

import DropZoneStyles from '../styles/dropZone'

class FileDropZone extends React.Component {
  handleDrop = (files, event) => {
    this.props.onDropFile({ files, event })
    // console.log(files, event)
  }

  render() {
    return (
      <div id="react-file-drop">
        <DropZoneStyles width={this.props.width} height={this.props.height} theme={this.props.theme} />
        <FileDrop onDrop={this.handleDrop}>
          <div className="drop-help">
            <span className="material-icons business-icon" aria-hidden="true">
              business
            </span>
            <p className="drop-text">
              Drop your floorplan here
            </p>
          </div>
        </FileDrop>
      </div>
    )
  }
}

FileDropZone.propTypes = {
  onDropFile: PropTypes.func.isRequired,
}

export default FileDropZone
