import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import { DateTime } from 'luxon'
import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import { DEFAULT_TIMEFRAME, TIMEFRAME_PRESETS } from '~/Device/constants'
import { updateUnlessNullorUndefined } from '~/Device/utils'

export const DEVICE_CHART_PARAMS_UPDATED = 'DEVICE_CHART_PARAMS_UPDATED'
const DEVICE_CHART_SET_SHOW_AXES = 'DEVICE_CHART_SET_SHOW_AXES'

const additionalState = {
  params: {
    start: DateTime.utc().minus(TIMEFRAME_PRESETS[DEFAULT_TIMEFRAME]).toISO(),
    end: null,
  },
  showAxes: true,
}

const deviceChartBundle = createAsyncResourceBundle({
  name: 'deviceChart',
  actionBaseType: 'DEVICE_CHART',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const deviceId = store.selectCurrentDeviceId()
    const { start, end } = store.selectQueryObject()

    return apiFetch(`/devices/${deviceId}/chart/`, {
      start,
      end,
    })
  }
})

export default {
  ...deviceChartBundle,
  reducer: reduceReducers(deviceChartBundle.reducer, (state, action) => {
    switch (action.type) {
      case DEVICE_CHART_PARAMS_UPDATED:
        return { ...state, params: { ...state.params, ...action.payload } }
      case DEVICE_CHART_SET_SHOW_AXES:
        return { ...state, showAxes: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doDeviceChartSetShowAxes: show => ({ dispatch }) => {
    dispatch({ type: DEVICE_CHART_SET_SHOW_AXES, payload: show })
  },
  doDeviceChartSetParams: params => ({ store }) => {
    store.doDeviceEventsSetPage(1)
    // start or end undefined means leave it unchanged, null means clear it
    let { start, end } = params
    let payload = store.selectQueryObject()

    if (start instanceof String) {
      start = DateTime.fromISO(start)
    }
    if (start instanceof DateTime) {
      start = start.toUTC().toISO()
    }
    if (end instanceof String) {
      end = DateTime.fromISO(end)
    }
    if (end instanceof DateTime) {
      end = end.toUTC().toISO()
    }

    if (end !== undefined) payload = updateUnlessNullorUndefined(payload, 'end', end)
    if (start !== undefined) payload = updateUnlessNullorUndefined(payload, 'start', start)

    store.doUpdateQuery(payload)
    store.doMarkDeviceChartAsOutdated()
    store.doMarkDeviceEventsAsOutdated()
  },
  reactDeviceChartFetch: createSelector(
    'selectAuth',
    'selectRouteInfo',
    'selectDeviceChartShouldUpdate',
    'selectCurrentDeviceId',
    ({ authenticated }, { url }, shouldUpdate, deviceId) => {
      if (authenticated && url.startsWith('/devices') && shouldUpdate && deviceId) {
        return { actionCreator: 'doFetchDeviceChart' }
      }
      return undefined
    }
  ),
  reactDeviceIdOutdated: createSelector(
    'selectAuth',
    'selectDeviceChartIsLoading',
    'selectCurrentDeviceId',
    'selectDeviceChart',
    ({ authenticated }, isLoading, deviceId, chartData) => {
      const [deviceChart] = chartData ?? []
      if (!authenticated || isLoading || !deviceId || !deviceChart) return undefined
      if (deviceChart.device.id !== deviceId) {
        return { actionCreator: 'doMarkDeviceChartAsOutdated' }
      }
      return undefined
    }
  ),
  selectDeviceChartDuration: createSelector(
    'selectQueryObject',
    ({ start, end }) => {
      if (end) return null
      const startDT = DateTime.fromISO(start)
      return Math.floor(DateTime.utc().diff(startDT).as('hours'))
    }
  ),
  selectDeviceChartStartDate: createSelector(
    'selectQueryObject',
    ({ start }) => start
  ),
  selectDeviceChartEndDate: createSelector(
    'selectQueryObject',
    ({ end }) => end
  ),
  selectDeviceChartShowAxes: createSelector(
    'selectDeviceChartRaw',
    ({ showAxes }) => showAxes
  ),
  persistActions: [...deviceChartBundle.persistActions, DEVICE_CHART_PARAMS_UPDATED, DEVICE_CHART_SET_SHOW_AXES],
}
