export const LoggerStyles = () => ({
  root: {
    display: 'grid',
    height: '100%',
    gridTemplateAreas: [
      '"loggerTitle  filters"',
      '"page page"',
      '"loggerList loggerList"',
    ].join('\n'),
    gridTemplateColumns: '1fr 25vw',
    gridTemplateRows: '75px 25px 1fr',
    gridColumnGap: '10px',
  },
  loggerTitle: {
    gridArea: 'loggerTitle',
  },
  filters: {
    gridArea: 'filters',
    display: 'flex'
  },
  page: {
    gridArea: 'page',
    alignSelf: 'center',
    justifySelf: 'flex-end'
  },
  loggerList: {
    gridArea: 'loggerList',
  },
  smallField: {
    width: '49%',
    margin: '0',
  },
  largeSelectField: {
    width: '100%',
    margin: '15px 0 0',
  },
  facilitySelect: {
    width: '100%',
    margin: '2px 0 0',
  },
  smallSelectField: {
    width: '49%',
    margin: '15px 0 0',
  },
  portSelectField: {
    width: '72%',
    margin: '15px 0 0',
  },
  depthSelectField: {
    width: '25%',
    margin: '15px 0 0',
  },
  fieldContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  soilHeading: {
    paddingRight: '30px'
  },
  formSectionWithPorts: {
    width: '50%',
    margin: '-1rem 1rem 1rem',
  },
  formSectionWithoutPorts: {
    width: '100%',
    margin: '-1rem 1rem 1rem',
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})
