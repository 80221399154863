import { compose, concat, identity, mergeDeepWith, omit, uniqBy } from 'ramda'
import { EMPTY_OBJECT } from '~/Lib/Utils'
import {
  ENTITIES_CLEARED,
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
} from './constants'

export const defaultInitialState = EMPTY_OBJECT

const concatAndUniq = compose(
  uniqBy(o => o?.id ?? o),
  concat
)
const concatAndUniqIfArray = (left, right) => ([left, right].every(Array.isArray) ? concatAndUniq(left, right) : right)
export const mergeEntities = mergeDeepWith(concatAndUniqIfArray)

export const dirtyCleaner = (state = EMPTY_OBJECT, action = null) => {
  if (!action || !action.payload || !state.dirty) return state
  const { id } = action.payload
  const dirty = omit([id], state.dirty)
  return {
    ...state,
    dirty,
  }
}
export const dirtyUpdater = (state = EMPTY_OBJECT, action = null) => {
  if (!action || !action.payload) return state
  const { id, ...payload } = action.payload
  const dirty = state.dirty || EMPTY_OBJECT
  const record = dirty[id] || EMPTY_OBJECT
  return {
    ...state,
    dirty: {
      ...dirty,
      [id]: {
        ...record,
        ...payload,
        lastUpdateAt: Date.now(),
        id,
      },
    },
  }
}

export const receiveHandler = (state, action) => {
  if (!action || action.type !== ENTITIES_RECEIVED || !action.payload) {
    return state
  }
  const received = Object.entries(action.payload)
  if (!received.length) return state

  const replace = action?.meta?.replace ?? true
  const fullReplace = action?.meta?.fullReplace ?? false

  return {
    ...state,
    ...received.reduce((acc, [name, newRecords]) => {
      const { byId = EMPTY_OBJECT } = state[name] || EMPTY_OBJECT
      return {
        ...acc,
        [name]: {
          ...state[name],
          byId: {
            ...(fullReplace ? EMPTY_OBJECT : byId),
            ...Object.entries(newRecords).reduce((output, [id, newRecord]) => {
              const oldRecord = byId[id]
              /* eslint-disable no-param-reassign */
              if (!oldRecord || (replace && newRecord.payloadType !== 'summary')) {
                output[id] = newRecord
              } else {
                let finalNew = newRecord
                if (newRecord.payloadType === 'summary' && oldRecord.payloadType === 'entity') {
                  finalNew = omit(['payloadType'], newRecord)
                }
                output[id] = mergeEntities(oldRecord, finalNew)
              }
              /* eslint-enable no-param-reassign */
              return output
            }, {}),
          },
        },
      }
    }, EMPTY_OBJECT),
  }
}

export const removeHandler = (state, action) => {
  if (!action || action.type !== ENTITIES_REMOVED || !action.payload) {
    return state
  }

  const remove = Object.entries(action.payload)
  if (!remove.length) return state
  return {
    ...state,
    ...remove.reduce((acc, [name, toRemove]) => {
      if (!Array.isArray(toRemove)) {
        return acc
      }
      const { byId: oldById = EMPTY_OBJECT } = state[name] || EMPTY_OBJECT
      return {
        ...acc,
        [name]: {
          ...state[name],
          byId: omit(toRemove, oldById),
        },
      }
    }, EMPTY_OBJECT),
  }
}

const defaultEntityReducerOpts = Object.freeze({
  initialState: defaultInitialState,
  customHandler: identity,
})
export const entityReducerFactory = (opts = defaultEntityReducerOpts) => {
  const { initialState = defaultInitialState, customHandler = identity } = opts || EMPTY_OBJECT

  return (state = initialState, action = null) => {
    if (!action || !action.type) return state
    switch (action.type) {
      case ENTITIES_CLEARED:
        return { ...initialState }
      case ENTITIES_RECEIVED:
        return receiveHandler(state, action)
      case ENTITIES_REMOVED:
        return removeHandler(state, action)
      default:
        return customHandler(state, action)
    }
  }
}

export const defaultAsyncHandlersFactory = (types, actionName) => ({
  [types.start]: (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
    if (action.type !== types.start) return state
    return {
      ...state,
      current: action.payload,
      inflight: {
        ...state.inflight,
        [action.payload]: actionName,
      },
    }
  },
  [types.succeed]: (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
    if (action.type !== types.succeed) return state
    const isNew = action?.meta?.isNew ?? false
    const cleanupId = isNew ? action?.meta?.cid : action.payload
    const inflight = omit([cleanupId], state?.inflight ?? EMPTY_OBJECT)
    const dirty = omit([cleanupId], state?.dirty ?? EMPTY_OBJECT)
    return {
      ...state,
      current: action.payload,
      inflight,
      dirty,
    }
  },
  [types.fail]: (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
    if (action.type !== types.fail || !action.payload) return state

    const oldInflight = state?.inflight ?? EMPTY_OBJECT
    const inflight = omit([action.payload], oldInflight)
    const record = state?.dirty?.[action.payload]
    const error = action?.error?.isIOError
      ? action?.error?.response
      : action.error
    const status = action?.error?.status ?? 0
    const requestPayload = action?.meta?.requestPayload ?? null

    return {
      ...state,
      current: action.payload,
      inflight,
      dirty: {
        ...state.dirty,
        [action.payload]: {
          ...record,
          lastError: {
            error,
            requestPayload,
            status,
            ts: Date.now(),
            type: actionName,
          },
        },
      },
    }
  },
})

const defaultEntityState = {
  dirty: EMPTY_OBJECT,
  inflight: EMPTY_OBJECT,
}
export const configBasedReducerFactory = (...args) => {
  const [
    config = EMPTY_OBJECT,
    defaultState = defaultEntityState,
    customHandler = identity,
    readOnly = false,
  ] = args
  const rootReducer = (
    state = readOnly ? { inflight: defaultState.inflight } : defaultState,
    action = EMPTY_OBJECT
  ) => {
    const reducer = config[action.type]
    return reducer ? reducer(state, action) : customHandler(state, action)
  }
  rootReducer.config = config
  rootReducer.defaultState = defaultState
  return rootReducer
}
