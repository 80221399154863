import React, { useRef } from 'react'

import memoize from 'memoize-one'
import PropTypes from 'prop-types'

import {
  Badge,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Tooltip as MUITooltip,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  BusinessRounded,
  ChevronLeft,
  ChevronRight,
  PowerSettingsNew,
  SettingsInputAntenna,
  Person,
  ShowChart,
  Speed,
  SystemUpdate,
} from '@material-ui/icons'

import { useBreakpoint, useIsBreakpointDown } from '~/Lib/Utils'
import Sensor from '~/UI/Icons/Sensor'
import SharedIcon from '~/UI/Shared/Icon'

const displayName = 'Nav'
// eslint-disable-next-line react/prop-types
const Tooltip = ({ anchorRef, children, drawerOpen, label }) => (drawerOpen ? children : (
  <MUITooltip
    title={label}
    label={label}
    placement="right"
    PopperProps={{
      anchorEl: () => anchorRef && anchorRef.current,
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: { offset: '0, -16px' },
        },
      },
    }}
  >
    {children}
  </MUITooltip>
))
Tooltip.propTypes = {
  anchorRef: PropTypes.shape({
    current: (props, name, component, path) => {
      const prop = props[name]
      if (prop == null || prop?.nodeType === 1) {
        return undefined
      }
      return new Error(`Tooltip received invalid prop ${path}: ${prop}`)
    },
  }).isRequired,
}

export const styles = theme => ({
  navMain: {
    flexGrow: 1,
  },
  typography: {
    textTransform: 'uppercase',
    padding: '0.5rem 1rem 0rem 1rem',
  },
  menuItem: {
    padding: theme.spacing(1.375, 3),
  },
  navFooter: {
    position: 'fixed',
    bottom: 8,
    left: 0,
    overflow: 'hidden',
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '[role=navigation][data-state=closed] &': {
      width: 72,
    },
    [theme.breakpoints.down('sm')]: {
      '[role=navigation][data-state=closed] &': {
        width: 0,
      },
    },
    '@media (max-height: 720px)': {
      position: 'sticky'
    },
  }
})

const listItemTypographyProps = {
  variant: 'subtitle1',
}

const urlIsCurrent = (url, currentURL) => currentURL.indexOf(url.replace(/\?.*$/, '')) === 0

/* eslint-disable react/prop-types */
const getLinkItem = memoize(
  ({ classes, currentURL, drawerOpen, onLinkClick }) => ({
    avatar,
    label,
    Icon,
    url,
    badge,
    onClick,
  }) => {
    const anchorRef = useRef(null)
    let avatarContent = null
    if (avatar) {
      avatarContent = typeof badge === 'number'
        ? (
          <Badge color="secondary" badgeContent={badge}>
            <ListItemAvatar ref={anchorRef}>{avatar}</ListItemAvatar>
          </Badge>
        )
        : <ListItemAvatar ref={anchorRef}>{avatar}</ListItemAvatar>
    }

    return (
      <Tooltip label={label} drawerOpen={drawerOpen} anchorRef={anchorRef}>
        <ListItem
          onClick={onClick ?? onLinkClick}
          button
          component={url ? 'a' : 'div'}
          href={url}
          selected={url ? urlIsCurrent(url, currentURL) : false}
          className={classes.menuItem}
        >
          {Icon ? (
            <ListItemIcon ref={anchorRef}>
              {typeof badge === 'number' ? (
                <Badge color="secondary" badgeContent={badge}>
                  <SharedIcon icon={Icon} />
                </Badge>
              ) : (
                <SharedIcon icon={Icon} />
              )}
            </ListItemIcon>
          ) : null}
          {avatar ? avatarContent : null}
          <ListItemText
            primary={label}
            primaryTypographyProps={listItemTypographyProps}
          />
        </ListItem>
      </Tooltip>
    )
  }
)
/* eslint-enable react/prop-types */

export class NavComponent extends React.PureComponent {
  static displayName = displayName

  static propTypes = {
    theme: PropTypes.shape({ direction: PropTypes.oneOf(['ltr', 'rtl']) })
      .isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    currentURL: PropTypes.string.isRequired,
    drawerOpen: PropTypes.bool.isRequired,
    drawerToggle: PropTypes.func.isRequired,
    doLogout: PropTypes.func.isRequired,
  }

  logoutRef = React.createRef()

  onLinkClick = event => {
    const { currentTarget } = event
    const target = currentTarget.href
      ? currentTarget
      : currentTarget.closest('a[href]')

    if (!target || !target.href) return

    const { drawerOpen, drawerToggle } = this.props
    // eslint-disable-next-line no-restricted-globals
    if (target.href !== location.href && drawerOpen) {
      drawerToggle()
    }
  }

  render() {
    const { classes, theme, currentURL, drawerOpen, drawerToggle } = this.props
    const { onLinkClick } = this

    const LinkItem = getLinkItem({
      classes,
      currentURL,
      drawerOpen,
      onLinkClick,
    })

    return (
      <>
        <nav
          className={classes.navMain}
          data-testid="aroya-main-nav"
        >
          <List>
            {drawerOpen ? (
              <ListItem
                button
                onClick={drawerToggle}
                className={classes.menuItem}
              >
                <ListItemIcon>
                  <SharedIcon
                    icon={theme.direction === 'rtl' ? ChevronRight : ChevronLeft}
                  />
                </ListItemIcon>
              </ListItem>
            ) : null}
            <LinkItem url="/system/chart" label="System" Icon={ShowChart} />
            <LinkItem url="/dashboard" label="Dashboard" Icon={Speed} />
            <LinkItem url="/users" label="Users" Icon={Person} />
            <LinkItem url="/organizations" label="Organizations" Icon={BusinessRounded} />
            <LinkItem url="/devices" label="Devices" Icon={Sensor} />
            <LinkItem url="/loggers" label="Loggers" Icon={SettingsInputAntenna} />
            <LinkItem url="/firmware" label="Firmware Updates" Icon={SystemUpdate} />
          </List>
        </nav>
        <div className={classes.navFooter}>
          <List dense>
            <Tooltip
              label="Logout"
              drawerOpen={drawerOpen}
              anchorRef={this.logoutRef}
            >
              <ListItem
                button
                onClick={this.props.doLogout}
                className={classes.menuItem}
              >
                <ListItemIcon ref={this.logoutRef}>
                  <SharedIcon icon={PowerSettingsNew} />
                </ListItemIcon>
                <ListItemText
                  primary="Logout"
                  primaryTypographyProps={listItemTypographyProps}
                />
              </ListItem>
            </Tooltip>
          </List>
        </div>
      </>
    )
  }
}

const useStyles = makeStyles(styles, { name: displayName })

export default React.memo(props => {
  const theme = useTheme()
  const classes = useStyles(props)
  const breakpoint = useBreakpoint()
  const isSmDown = useIsBreakpointDown('sm', breakpoint)
  return (
    <NavComponent
      {...props}
      classes={classes}
      theme={theme}
      breakpoint={breakpoint}
      isSmDown={isSmDown}
    />
  )
})
