import React from 'react'

import { useConnect } from 'redux-bundler-hook'

import Fade from '@material-ui/core/Fade'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'

import { getDateTime } from '~/Lib/Utils'

import Cursor from './Cursor'
import Graph from './Graph'
import styles from './styles'
import useChartState from './useChartState'
import { getYAxes } from './utils'

const useStyles = makeStyles(styles, 'DeviceChart')

const ChartComponent = props => {
  const {
    doSetChartParams,
    loading: chartIsLoading,
    chartData,
    interactive,
    graphCount,
  } = props

  const {
    chartWidth,
    chartHeight,
    containerBbox,
    containerRef,
    graphProps,
    haveGraphs,
    responseIsEmpty,
    xDomain,
    xOffset,
    xScale,
    yScale,
    xTicks,
    transitioning,
  } = useChartState(props)

  const loading = !!(chartIsLoading || transitioning)
  const chartReady = !loading && haveGraphs && !responseIsEmpty
  const chartAvailable = !loading && haveGraphs && !responseIsEmpty && chartHeight
  const yAxes = getYAxes(props)

  const classes = useStyles(props)
  const theme = useTheme()

  const setFromAndTo = x => {
    if (!Array.isArray(x)) return
    const [from, to] = x
    const fromDT = from && getDateTime(from)
    let toDT = to && getDateTime(to)
    if (toDT.diff(fromDT).as('minutes') < 60) {
      toDT = fromDT.plus({ hours: 1 })
    }
    doSetChartParams({
      start: from ? fromDT.toUTC().toISO() : undefined,
      end: to ? toDT.toUTC().toISO() : undefined,
    })
  }

  return (
    <div
      ref={containerRef}
      className={classNames({
        [classes.root]: true,
      })}
      data-loading={loading}
    >
      <div className={classNames(classes.loader, classes.content)}>
        <LinearProgress style={{ width: '50%' }} />
      </div>
      {!loading && responseIsEmpty ? (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Typography variant="h5">
            No data
          </Typography>
        </div>
      ) : null}
      {chartAvailable ? (
        <Fade in={chartReady}>
          <div className={classNames(classes.mainGraph, classes.content)}>
            <Graph
              {...graphProps}
              chartData={chartData}
              classes={classes}
              theme={theme}
              chartWidth={chartWidth}
              chartHeight={chartHeight}
              graphs={chartData.graphs}
              interactive={interactive}
              loading={loading}
              xDomain={xDomain}
              xOffset={xOffset}
              xScale={xScale}
              xTicks={xTicks}
              yAxes={yAxes}
              yScale={yScale}
              setFromAndTo={setFromAndTo}
            />
          </div>
        </Fade>
      ) : null}
      {chartAvailable && interactive ? (
        <Cursor
          {...containerBbox}
          {...graphProps}
          chartData={chartData}
          chartRef={containerRef}
          classes={classes}
          data={chartData.data}
          dataInterval={chartData.dataInterval}
          graphs={chartData.graphs}
          graphCount={graphCount}
          height={chartHeight}
          xOffset={xOffset}
          xScale={xScale}
        />
      ) : null}
    </div>
  )
}

ChartComponent.defaultProps = {
  activeNotification: null,
  activeTargetRange: null,
  interactive: true,
  loading: false,
  locale: 'en-US',
  selectedDataTypes: [],
}

const Connected = props => {
  const {
    allDataTypes,
    dimensions
  } = useConnect(
    'selectAllDataTypes',
    'selectDimensions',
  )

  return (
    <ChartComponent
      dataTypes={allDataTypes}
      dimensions={dimensions}
      {...props}
    />
  )
}

Connected.displayName = `Connected(${ChartComponent.displayName})`

export default Connected
