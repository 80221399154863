import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'

import { ENTITIES_RECEIVED } from '~/Lib/createEntityBundle'
import { Device } from '~/Store/Schemas'

const dashboardBundle = createAsyncResourceBundle({
  name: 'dashboard',
  actionBaseType: 'DASHBOARD',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store, dispatch }) => {
    const { objType, id } = store.selectRouteParams()
    return apiFetch(`/dashboard/${objType}/${id}/`).then(response => {
      const { entities, result } = normalize(response, { devices: [Device] })
      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      return result
    })
  }
})

export default {
  ...dashboardBundle,
  reactDashboardFetch: createSelector(
    'selectAuth',
    'selectRouteInfo',
    'selectDashboardShouldUpdate',
    ({ authenticated }, { url }, shouldUpdate) => {
      if (authenticated && url.startsWith('/dashboard/') && shouldUpdate) {
        return { actionCreator: 'doFetchDashboard' }
      }
      return undefined
    }
  ),
}
