import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useConnect } from 'redux-bundler-hook'
import { endsWith, prop, sortBy } from 'ramda'
import { Field, useFormikContext } from 'formik'
import { makeStyles } from '@material-ui/styles'
import {
  InputAdornment,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

import { FormDialog, NumberField, SelectField, TextField } from '~/UI/Shared/Form/FormDialog'
import { FormikDatePicker as DatePicker } from '~/UI/Shared/Form/DatePicker'
import { getType } from '~/Lib/Utils/validation'
import ListTable from '~/UI/Shared/ListTable'
import styles from './styles'

const useStyles = makeStyles(styles)

export const EntityDialogContent = props => {
  const { listMappings, entityType, currentEntity } = props
  const excludedFields = ['isinaroya', 'isinmetrc', 'aroya', 'metrc', 'label', 'name']

  const headerFields = Object.values(listMappings[entityType].columns)
    .filter(value => !excludedFields.includes(value.toLowerCase()))

  const bodyFields = Object.keys(listMappings[entityType].columns)
    .filter(value => !excludedFields.includes(value.toLowerCase()))

  const renderEntityAttribute = (attr, type) => {
    let color = 'initial'
    let field = currentEntity?.[attr]
    const diffAttr = currentEntity?.diffs?.[attr]
    if (diffAttr) {
      field = diffAttr[type]
      color = 'secondary'
    }

    if (field === undefined || field === null) {
      field = '-'
    }

    return (
      <Typography variant="body1" color={color} noWrap>
        {field}
      </Typography>
    )
  }

  const entityNotFound = type => (
    <TableCell colSpan={bodyFields.length}>
      <Typography variant="body1" color="secondary" align="center" noWrap>
        Entity not found in {type}
      </Typography>
    </TableCell>
  )

  return (
    <ListTable>
      <TableHead>
        <TableRow>
          <TableCell />
          {headerFields.map(attr => (
            <TableCell key={attr}>
              <Typography variant="subtitle2" color="textPrimary">
                {attr}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography variant="subtitle2" color="textPrimary">
              AROYA
            </Typography>
          </TableCell>
          {currentEntity?.isInAroya ? (
            bodyFields.map(attr => (
              <TableCell key={attr}>
                {renderEntityAttribute(attr, 'aroya')}
              </TableCell>
            ))
          ) : entityNotFound('aroya')}
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="subtitle2" color="textPrimary">
              Metrc
            </Typography>
          </TableCell>
          {currentEntity?.isInMetrc ? (
            bodyFields.map(attr => (
              <TableCell key={attr}>
                {renderEntityAttribute(attr, 'metrc')}
              </TableCell>
            ))
          ) : entityNotFound('metrc')}
        </TableRow>
      </TableBody>
    </ListTable>
  )
}

export const EditForm = props => {
  const classes = useStyles()
  const { me, isOpen, entityType, entityId, onClose } = props

  const {
    rooms,
    organizations,
    currentOrganizationId,
    currentFacilityId,
    plantListCurrentPlant,
    packageListCurrentPackage,
    plantBatchListCurrentBatch,
    harvestBatchListCurrentBatch,
    doPlantListFetchPlant,
    doPlantListUpdatePlant,
    doPlantListDeletePlant,
    doPlantListClearCurrentPlant,
    doPlantBatchListFetchBatch,
    doPlantBatchListUpdateBatch,
    doPlantBatchListClearCurrentBatch,
    doHarvestBatchListFetchBatch,
    doHarvestBatchListUpdateBatch,
    doHarvestBatchListClearCurrentBatch,
    doPackageListFetchPackage,
    doPackageListUpdatePackage,
    doPackageListClearCurrentPackage
  } = useConnect(
    'selectRooms',
    'selectOrganizations',
    'selectCurrentOrganizationId',
    'selectCurrentFacilityId',
    'selectPackageListCurrentPackage',
    'selectPlantListCurrentPlant',
    'selectPlantBatchListCurrentBatch',
    'selectHarvestBatchListCurrentBatch',
    'doPlantListFetchPlant',
    'doPlantListUpdatePlant',
    'doPlantListDeletePlant',
    'doPlantListClearCurrentPlant',
    'doPlantBatchListFetchBatch',
    'doPlantBatchListUpdateBatch',
    'doPlantBatchListClearCurrentBatch',
    'doHarvestBatchListFetchBatch',
    'doHarvestBatchListUpdateBatch',
    'doHarvestBatchListClearCurrentBatch',
    'doPackageListFetchPackage',
    'doPackageListUpdatePackage',
    'doPackageListClearCurrentPackage',
  )

  const entityMappings = {
    PLANTS: {
      fetchEntity: doPlantListFetchPlant,
      updateEntity: doPlantListUpdatePlant,
      deleteEntity: doPlantListDeletePlant,
      currentEntity: plantListCurrentPlant,
      clearEntity: doPlantListClearCurrentPlant,
    },
    PLANT_BATCHES: {
      fetchEntity: doPlantBatchListFetchBatch,
      updateEntity: doPlantBatchListUpdateBatch,
      deleteEntity: doPlantListDeletePlant,
      currentEntity: plantBatchListCurrentBatch,
      clearEntity: doPlantBatchListClearCurrentBatch,
    },
    HARVEST_BATCHES: {
      fetchEntity: doHarvestBatchListFetchBatch,
      updateEntity: doHarvestBatchListUpdateBatch,
      deleteEntity: doPlantListDeletePlant,
      currentEntity: harvestBatchListCurrentBatch,
      clearEntity: doHarvestBatchListClearCurrentBatch,
    },
    PACKAGES: {
      fetchEntity: doPackageListFetchPackage,
      updateEntity: doPackageListUpdatePackage,
      deleteEntity: doPlantListDeletePlant,
      currentEntity: packageListCurrentPackage,
      clearEntity: doPackageListClearCurrentPackage,
    }
  }

  const fetchEntity = entityMappings[entityType]?.fetchEntity
  const updateEntity = entityMappings[entityType]?.updateEntity
  const deleteEntity = entityMappings[entityType]?.deleteEntity
  const currentEntity = entityMappings[entityType]?.currentEntity
  const clearEntity = entityMappings[entityType]?.clearEntity

  useEffect(() => {
    if (entityId && isOpen) fetchEntity(entityId)
    return () => clearEntity()
  }, [entityId])

  const toTitleCase = str => {
    let res = str.replace(/([A-Z])/g, ' $1').replace('_', ' ')
    if (endsWith('hes', res)) res = res.slice(0, res.length - 2)
    if (endsWith('s', res)) res = res.slice(0, res.length - 1)
    return (res.charAt(0).toUpperCase() + res.slice(1))
  }

  const getSelectFieldOptions = (arr, hasID = false) => (hasID
    ? arr?.map(entity => ({ label: entity.name, value: entity.id }))
    : arr?.map(attr => ({ label: toTitleCase(attr.toLowerCase()), value: attr })))

  const addInputAdornment = text => ({
    endAdornment: (
      <InputAdornment position="end" style={{ marginTop: '23px' }}>
        <Typography variant="body2">{text}</Typography>
      </InputAdornment>
    )
  })

  const facilityRooms = Object.values(rooms).filter(room => room.facility === currentFacilityId)

  const orgCultivarsOptions = getSelectFieldOptions(organizations[currentOrganizationId].cultivars, true)
  const roomOptions = getSelectFieldOptions(sortBy(prop('name'), facilityRooms), true)
  const growthPhaseOptions = getSelectFieldOptions(me.growthPhases)
  const plantStateOptions = getSelectFieldOptions(me.plantStates)
  const plantTypeOptions = getSelectFieldOptions(me.plantTypes)

  const cultivarIsNullable = ['HARVEST_BATCHES', 'PACKAGES'].some(el => entityType.includes(el))
  if (cultivarIsNullable) orgCultivarsOptions.unshift({ label: 'No Cultivar', value: null })
  roomOptions.unshift({ label: 'No Room', value: null })

  const DateFormatter = () => {
    const { values, dirty, setFieldValue } = useFormikContext()
    useEffect(() => {
      if (dirty) {
        Object.entries(values).forEach(([attr, val]) => {
          if (getType(val) === 'DateTime') {
            setFieldValue(attr, val.toFormat('yyyy-LL-dd'))
          }
        })
      }
    }, [values])
    return null
  }

  const EntityComponents = {
    PLANTS: (
      <>
        <Field component={TextField} label="Label" name="label" />
        <Field component={SelectField} label="Cultivar" name="cultivar" options={orgCultivarsOptions} />
        <Field component={SelectField} label="Room" name="room" options={roomOptions} />
        <Field component={SelectField} label="Growth Phase" name="growthPhase" options={growthPhaseOptions} />
        <Field component={SelectField} label="State" name="state" options={plantStateOptions} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Field component={DatePicker} label="Planted" name="plantedDate" className={classes.oneThirdField} />
          <Field component={DatePicker} label="Harvest" name="harvestedDate" className={classes.oneThirdField} />
          <Field component={DatePicker} label="Destroyed" name="destroyedDate" className={classes.oneThirdField} />
        </div>
      </>
    ),
    PLANT_BATCHES: (
      <>
        <Field component={TextField} label="Label" name="label" />
        <Field component={SelectField} label="Cultivar" name="cultivar" options={orgCultivarsOptions} />
        <Field component={SelectField} label="Plant Type" name="plantType" options={plantTypeOptions} />
        <Field component={SelectField} label="Room" name="room" options={roomOptions} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Field component={DatePicker} label="Planted" name="plantedDate" className={classes.oneThirdField} />
          <Field component={NumberField} label="Initial Count" name="initialCount" className={classes.oneThirdField} />
          <Field component={NumberField} label="Untracked Count" name="untrackedCount" className={classes.oneThirdField} />
        </div>
      </>
    ),
    HARVEST_BATCHES: (
      <>
        <Field component={TextField} label="Name" name="name" />
        <Field component={SelectField} label="Cultivar" name="cultivar" options={orgCultivarsOptions} />
        <Field component={SelectField} label="Room" name="room" options={roomOptions} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Field
            component={NumberField}
            label="Current Weight"
            name="currentWeight"
            className={classes.oneThirdField}
            InputProps={addInputAdornment(currentEntity?.weightUnit)}
          />
          <Field component={DatePicker} label="Harvest Date" name="harvestDate" className={classes.oneThirdField} />
          <Field component={DatePicker} label="Finished Date" name="finishedDate" className={classes.oneThirdField} />
        </div>
      </>
    ),
    PACKAGES: (
      <>
        <Field component={TextField} label="Label" name="label" />
        <Field component={SelectField} label="Cultivar" name="cultivar" options={orgCultivarsOptions} />
        <Field component={SelectField} label="Room" name="room" options={roomOptions} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Field
            component={NumberField}
            label="Quantity"
            name="currentQuantity"
            className={classes.oneThirdField}
            InputProps={addInputAdornment(currentEntity?.weightUnit)}
          />
          <Field component={DatePicker} label="Finished Date" name="finishedDate" className={classes.oneThirdField} />
          <Field component={DatePicker} label="Packaged Date" name="packagedDate" className={classes.oneThirdField} />
        </div>
      </>
    )
  }

  const validationSchemas = {
    PLANT: yup.object().shape({
      label: yup.string().required('Please enter a label'),
      cultivar: yup.number().required('Please select a cultivar'),
      growthPhase: yup.number().required('Please select a cultivar'),
      state: yup.number().required('Please select a cultivar'),
      room: yup.number().nullable(),
      destroyedDate: yup.string().nullable(),
      plantedDate: yup.string().required('Please select the planted date'),
      harvestedDate: yup.string().nullable(),
    }),
    PLANT_BATCHES: yup.object().shape({
      label: yup.string().required('Please enter a label'),
      cultivar: yup.number().required('Please select a cultivar'),
      plantType: yup.string().required('Please select a plant type'),
      plantedDate: yup.string().required('Please enter the planted date').nullable(),
      initialCount: yup.number().required('Please enter an initial count'),
      untrackedCount: yup.number().required('Please enter an untracked count'),
    }),
    HARVEST_BATCHES: yup.object().shape({
      name: yup.string().required('Please enter a name'),
      cultivar: yup.number().nullable(),
      room: yup.number().nullable(),
      currentWeight: yup.number().required('Please enter the current weight'),
      harvestDate: yup.string().required('Please enter the harvest date').nullable(),
      finishedDate: yup.string().nullable(),
    }),
    PACKAGES: yup.object().shape({
      label: yup.string().required('Please enter a label'),
      cultivar: yup.number().nullable(),
      room: yup.number().nullable(),
      currentQuantity: yup.number().required('Please enter the current quantity'),
      finishedDate: yup.string().nullable(),
      packagedDate: yup.string().nullable(),
    }),
  }

  return (
    <FormDialog
      open={isOpen && !!currentEntity?.id}
      instance={currentEntity}
      formatInstance={entity => `${entity.label ?? entity.name}`}
      initialValues={currentEntity}
      label={toTitleCase(entityType.toLowerCase())}
      onSave={updateEntity}
      onDelete={deleteEntity}
      validationSchema={validationSchemas[entityType]}
      onClose={onClose}
      {...props}
    >
      <DateFormatter />
      {EntityComponents[entityType]}
    </FormDialog>
  )
}
