import React, { useCallback, useEffect, useRef } from 'react'

import { useTheme } from '@material-ui/core/styles'
import {
  Curve,
  Line as VLine,
  Point,
  LineSegment,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
} from 'victory'

import classNames from 'classnames'
import * as ms from 'milliseconds'

import createLogger from '~/Lib/Logging'
import {
  defer,
  EMPTY_OBJECT,
  formattedNumber,
  useBreakpoint,
  usePrevious,
} from '~/Lib/Utils'
import appTheme from '~/UI/Theme/mui'

import { getXLabel, getXLabelStyle } from '../labels'
import {
  getBounds,
  getDenormalized,
  getLineData,
  getPaddingForChart,
  GRAPH_X_AXIS_CLASSNAME,
  setChartNotLoading,
} from '../utils'
import {
  getDomain,
  getLineStyles,
  getTheme,
  NullComponent,
} from './utils'

const displayName = 'Graph'
const logger = createLogger(displayName)

const leavingTargetNodes = ['rect', 'svg']
const enteringTargetNodes = ['button', 'div', 'main', 'nav', 'window']

const Line = ({ standalone, stringMap, ...props }) => <VLine {...props} />

const enoughSelected = (x, pixels) => {
  const [start, end] = x
  const selectedTime = end.getTime() - start.getTime()
  if (selectedTime < ms.hours(2) && pixels < 20) {
    return false
  }
  if (selectedTime < ms.hours(1)) {
    return false
  }
  return true
}

export const GraphComponent = props => {
  const {
    classes,
    theme,
    chartWidth,
    chartHeight,
    chartData = EMPTY_OBJECT,
    graphs,
    dataTypes,
    interactive,
    loading,
    locale,
    mainGraphZone,
    xOffset,
    xScale,
    xTicks,
    yAxes,
    setFromAndTo,
    showYAxis,
  } = props
  const { data = EMPTY_OBJECT } = chartData
  const prevLoading = usePrevious(loading)
  const prevMainGraph = usePrevious(mainGraphZone)
  const brush = useRef(null)

  useEffect(() => {
    const doneLoading = prevLoading === true && !loading
    const changedMainGraph = prevMainGraph !== mainGraphZone
    // If neither of the above are true, we have nothing to do.
    if (!doneLoading && !changedMainGraph) return
    defer(setChartNotLoading, defer.priorities.low)
  }, [mainGraphZone, loading])

  const onDomainChange = useCallback((_, { currentDomain, x1, x2 }) => {
    if (!currentDomain.x.every(x => x instanceof Date)) {
      return
    }
    brush.current = { times: currentDomain.x, pixels: Math.abs(x1 - x2) }
  }, [])

  const onDomainChangeEnd = useCallback((_, { currentDomain, x1, x2 }) => {
    if (!currentDomain.x.every(x => x instanceof Date)) {
      return
    }

    if (!enoughSelected(currentDomain.x, Math.abs(x1 - x2))) {
      return
    }
    setFromAndTo(currentDomain.x)
    brush.current = null
  }, [setFromAndTo])

  const onMouseOut = useCallback(({ target, relatedTarget }) => {
    const targetName = target?.nodeName?.toLowerCase?.()
    if (!brush.current || !leavingTargetNodes.includes(targetName)) return
    const relatedTargetName = relatedTarget?.nodeName?.toLowerCase?.()
    if (!enteringTargetNodes.includes(relatedTargetName)) {
      return
    }

    if (brush.current && enoughSelected(brush.current.times, brush.current.pixels)) {
      setFromAndTo(brush.current.times)
      brush.current = null
    }
  }, [setFromAndTo])

  const bounds = getBounds(props)
  const padding = getPaddingForChart(props)

  const ContainerComponent = interactive ? VictoryBrushContainer : VictoryContainer

  return chartWidth && chartHeight ? (
    <VictoryChart
      domain={getDomain(props)}
      width={chartWidth}
      height={chartHeight}
      padding={padding}
      scale={{ x: 'time' }}
      theme={getTheme(theme)}
      containerComponent={(
        <ContainerComponent
          containerId="room-dashboard-chart-selection-container"
          height={chartHeight}
          responsive={false}
          allowDrag={false}
          brushDimension="x"
          brushDomain={{ x: [0, 0] }}
          brushStyle={{
            fill: theme.meter.blue.light,
            fillOpacity: 0.1,
          }}
          defaultBrushArea="none"
          events={{ onMouseOut }}
          onBrushDomainChange={onDomainChange}
          onBrushDomainChangeEnd={onDomainChangeEnd}
        />
        )}
    >
      {graphs?.map(({ id, type, unit }) => {
        const { [id]: lineData } = data
        const { [unit]: dataTypeBounds } = bounds
        const { [id]: style } = getLineStyles({ graphs })
        const Visualization = type === 'scatter' ? VictoryScatter : VictoryLine
        const DataComponent = type === 'scatter'
          ? <Point className={id} size={4} />
          : <Curve className={id} />

        return (
          <Visualization
            key={id}
            name={id}
            data={getLineData(lineData, dataTypeBounds)}
            dataComponent={DataComponent}
            interpolation="linear"
            style={style}
            x="_x"
            y="_y"
            y0="_y0"
          />
        )
      })}
      {showYAxis && yAxes?.length ? (
        yAxes.map(({ key, unit }, i) => {
          const { [key]: dataType } = dataTypes
          const { [unit]: unitBounds } = bounds
          const offsetLabel = (xOffset - i * 50) - 25

          return [(
            <VictoryLabel
              key={key}
              className={`room-graph-y-axis-label-${key}`}
              x={offsetLabel}
              y={6}
              text={dataType?.units}
              style={{ ...appTheme.typography.body2, fill: dataType.color, textAnchor: 'middle' }}
            />
          ), (
            <VictoryAxis
              className={`room-graph-y-axis-${key}`}
              dependentAxis
              key={key}
              axisComponent={
                <LineSegment className={`room-graph-y-axis-line-${key}`} />
                }
              gridComponent={<NullComponent />}
              tickLabelComponent={
                <VictoryLabel className={`room-graph-y-axis-tick-${key}`} />
                }
              offsetX={xOffset - i * 50}
              style={{
                axis: {
                  stroke: dataType.color,
                },
                tickLabels: {
                  fill: dataType.color,
                },
                ticks: {
                  stroke: dataType.color,
                }
              }}
                // Use normalized tickValues (0 - 1)
              tickValues={[0, 0.25, 0.5, 0.75, 1]}
                // Re-scale ticks by multiplying by correct maxima
              tickFormat={tick => (unitBounds
                ? formattedNumber(
                  getDenormalized(tick, unitBounds),
                  dataType.precision
                )
                : null)}
            />
          )]
        }).flat()
      ) : (
        <VictoryAxis
          data-testid="room-graph-placeholder-y-axis"
          dependentAxis
          gridComponent={<NullComponent />}
          tickComponent={<NullComponent />}
          tickLabelComponent={<NullComponent />}
        />
      )}
      <Line
        x1={padding.left}
        x2={chartWidth - (padding.right || 0)}
        y1={chartHeight - padding.bottom}
        y2={chartHeight - padding.bottom}
        className={classNames(classes.xAxis, GRAPH_X_AXIS_CLASSNAME)}
      />
      {xTicks.ticks.map((x, i) => {
        const left = xScale(x)
        if (left + 45 > chartWidth || left < padding.left) return null

        return (
          <VictoryLabel
            key={+x}
            className={`room-graph-x-axis-tick-${x.toISOString()}`}
            scale={{ x: 'time' }}
            x={left}
            y={chartHeight - padding.bottom + 24}
            text={getXLabel(x, i, locale, xTicks)}
            style={getXLabelStyle(x, i, props, xTicks)}
          />
        )
      })}
    </VictoryChart>
  ) : null
}

GraphComponent.defaultProps = {
  chartHeight: null,
  chartWidth: null,
}

GraphComponent.displayName = displayName

const Memoized = React.memo(GraphComponent)

const WithTheme = props => {
  const theme = useTheme()
  const width = useBreakpoint()

  return (
    <Memoized
      {...props}
      theme={theme}
      width={width}
    />
  )
}
WithTheme.displayName = `WithTheme(${displayName})`

export default React.memo(WithTheme)
