import { singularize } from 'inflection'
import { identity } from 'ramda'

import { EMPTY_ARRAY } from '~/Lib/Utils'

import { actionFactory, asyncActionFactory } from './actions'
import {
  configBasedReducerFactory,
  defaultAsyncHandlersFactory,
  dirtyCleaner,
  dirtyUpdater,
} from './reducers'
import * as selectors from './selectors'

export const defaultApiActionsFactory = (name, readOnly) => [
  {
    name,
    action: 'fetch',
    prepareData: payload => {
      if (typeof payload === 'number' || typeof payload === 'string') return { id: payload }
      return {
        id: payload?.id,
        params: payload?.params,
      }
    },
    handler: ({ apiFetch, payload }) => apiFetch(`/${name}/${payload.id}/`, payload.params),
  },
].concat(
  readOnly
    ? EMPTY_ARRAY
    : [
      {
        name,
        action: 'save',
        handler: ({ apiFetch, payload }) => apiFetch(
          payload.id ? `/${name}/${payload.id}/` : `/${name}/`,
          payload,
          { method: payload.id ? 'PUT' : 'POST' }
        ),
      },
      {
        name,
        action: 'delete',
        prepareData: identity,
        handler: ({ apiFetch, payload: { id } }) => apiFetch(`/${name}/${id}/`, null, { method: 'DELETE' }),
        remove: true,
      },
    ]
)

export const addUpdateAction = (name, reducerConfig, bundle) => {
  const updateAction = actionFactory('update', name)
  Object.assign(bundle, { [updateAction.actionName]: updateAction })
  Object.assign(reducerConfig, { [updateAction.type]: dirtyUpdater })
}
export const addClearDirtyAction = (name, reducerConfig, bundle) => {
  const clearAction = actionFactory('clear_dirty', name)
  Object.assign(bundle, { [clearAction.actionName]: clearAction })
  Object.assign(reducerConfig, { [clearAction.type]: dirtyCleaner })
}
export const addSetCurrentAction = (name, reducerConfig, bundle) => {
  const setCurrentAction = actionFactory('set_current', name)
  Object.assign(bundle, { [setCurrentAction.actionName]: setCurrentAction })
  Object.assign(reducerConfig, {
    [setCurrentAction.type]: (state, action) => {
      // logger.info(`${setCurrentAction.actionName} reducer:`, { state, action })
      if (action.type !== setCurrentAction.type) return state
      // logger.info('updating current to', action.payload)
      return {
        ...state,
        current: action.payload,
      }
    },
  })
}
export const addAsyncAction = (config, reducerConfig, bundle) => {
  const actionCreator = asyncActionFactory(config)

  Object.assign(bundle, { [actionCreator.actionName]: actionCreator })
  Object.assign(
    reducerConfig,
    defaultAsyncHandlersFactory(actionCreator.types, config.action)
  )
}
export const addCustomActions = (
  customActions,
  { name },
  reducerConfig,
  bundle
) => {
  customActions.forEach(({ async = false, ...config }) => {
    if (async) {
      addAsyncAction(
        config.name ? config : { ...config, name },
        reducerConfig,
        bundle
      )
      return
    }
    const { actionName, type, creator, reducer } = config
    Object.assign(bundle, { [actionName]: creator })
    Object.assign(reducerConfig, { [type]: reducer })
  })
}

// name should be the plural name
export default bundleConfig => {
  const {
    name,
    customActions,
    idAttribute = 'id',
    apiConfig,
    defaultState,
    parents,
    customReducer = identity,
    readOnly = false,
    ...bundleProps
  } = bundleConfig
  const singularName = singularize(name)
  const reducerConfig = {}
  const bundle = { name }
  if (!readOnly) {
    addUpdateAction(singularName, reducerConfig, bundle)
    addClearDirtyAction(singularName, reducerConfig, bundle)
  }
  addSetCurrentAction(singularName, reducerConfig, bundle)
  defaultApiActionsFactory(name, readOnly).forEach(({ action, ...config }) => {
    const {
      snackbar: defaultSnackbar,
      [action]: customConfig,
      ...apiConfigRest
    } = apiConfig
    const { snackbar: customSnackbar } = config
    const snackbar = customSnackbar || defaultSnackbar
    addAsyncAction(
      {
        ...apiConfigRest,
        ...config,
        ...customConfig,
        singularName,
        action,
        parents,
        snackbar:
          action.indexOf('fetch') === 0 && typeof snackbar !== 'function'
            ? false
            : snackbar,
      },
      reducerConfig,
      bundle
    )
  })
  if (Array.isArray(customActions)) {
    addCustomActions(customActions, bundleConfig, reducerConfig, bundle)
  }
  bundle.reducer = configBasedReducerFactory(
    reducerConfig,
    defaultState,
    customReducer,
    readOnly
  )

  Object.assign(
    bundle,
    selectors.entitySelectorsFactory({
      name,
      singularName,
      idAttribute,
      readOnly,
    }),
    bundleProps
  )

  return bundle
}
