import React, { useRef } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import { Field, FieldArray, useFormikContext } from 'formik'
import * as yup from 'yup'

import { states } from '~/Lib/States'
import { timezones } from '~/Lib/Timezones'
import { getFacilityAttrOptions } from '~/Organization/filters'
import {
  FormDialog,
  SelectField,
  TextField,
  ToggleField,
} from '~/UI/Shared/Form/FormDialog'
import TextSelect from '~/UI/Shared/Form/TextField'
import Icon from '~/UI/Shared/Icon'

import styles from './Detail/styles'

const useStyles = makeStyles(styles)

const validationSchema = yup.object().shape({
  organization: yup.number().required('Please select an organization'),
  name: yup.string().max(50).required('Please enter a facility name'),
  location: yup.string().max(100).required('Please enter a location'),
  timezone: yup.string().required('Please select a timezone'),
  totalSpace: yup.number().min(0).integer().required('Please enter a number'),
  state: yup.string().required('Please select a state'),
  licenses: yup.array().of(
    yup.object().shape({
      apiKey: yup.string().required('api key required'),
      number: yup.string().required('license no. required'),
      billingPerPlant: yup.boolean(),
    })
  ),
  allowInternalMemberships: yup.boolean(),
  outdoor: yup.boolean(),
  continuous: yup.boolean(),
  app: yup.string().required('App type required'),
  tier: yup.string().nullable(),
  status: yup.string().required('Status type required'),
  supportCategory: yup.string().required('Support category required'),
})

const MetrcLicenseForm = () => {
  const classes = useStyles()
  const { values: { licenses }, setFieldValue } = useFormikContext()

  return (
    <FieldArray
      name="licenses"
      render={options => (
        <div className="aroya-portal__field-group">
          <Grid container className={classes.licenseHeader}>
            <Grid item style={{ flexGrow: 1 }}>
              <Typography variant="h6" color="primary" align="left">
                Licenses
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={() => (
                  options.push({
                    apiKey: '',
                    number: ''
                  })
                )}
              >
                Add License
              </Button>
            </Grid>
          </Grid>
          {licenses?.map((license, idx) => (
            <div
              key={license.id ?? idx}
              className={classes.licenseRow}
            >
              <Field
                component={TextField}
                label="API Key"
                name={`licenses[${idx}].apiKey`}
                onChange={e => setFieldValue(
                  `licenses[${idx}].apiKey`,
                  e.target.value
                )}
                className={classes.licenseField}
              />
              <Field
                component={TextField}
                label="License No."
                name={`licenses[${idx}].number`}
                onChange={e => setFieldValue(
                  `licenses[${idx}].number`,
                  e.target.value
                )}
                className={classes.licenseField}
              />
              <Field
                component={ToggleField}
                label={(
                  <>
                    Billing Per Plant
                  </>
                )}
                name={`licenses[${idx}].billingPerPlant`}
                className={classes.licenseField}
              />
              <IconButton
                className={classes.iconBtn}
                onClick={() => options.remove(idx)}
              >
                <Icon
                  icon={DeleteIcon}
                  color="secondary"
                />
              </IconButton>
            </div>
          ))}
        </div>
      )}
    />
  )
}

const FacilityForm = props => {
  const { currentOrganization, instance, ...passthru } = props
  const classes = useStyles()
  const sectionRef = useRef(null)

  const {
    me,
    currentOrganizationList
  } = useConnect(
    'selectMe',
    'selectCurrentOrganizationList'
  )

  const organizationChoices = currentOrganizationList ? currentOrganizationList.map(
    org => ({ label: org.name, value: org.id })
  ) : []

  const stateChoices = [
    { label: '---', value: '' },
    ...states,
  ]

  const appOptions = getFacilityAttrOptions('appTypes', me).filter(({ value }) => value !== 'all')
  const tierOptions = getFacilityAttrOptions('facilityTiers', me).filter(({ value }) => value !== 'all')
  const supportOptions = getFacilityAttrOptions('supportCategories', me).filter(({ value }) => value !== 'all')
  const statusOptions = getFacilityAttrOptions('statusTypes', me).filter(({ value }) => value !== 'all')

  const initialValues = {
    organization: currentOrganization.id ?? organizationChoices[0]?.value,
    name: '',
    location: '',
    timezone: timezones[0].value,
    totalSpace: 0,
    state: stateChoices[0].value,
    allowInternalMemberships: true,
    outdoor: false,
    continuous: false,
    licenses: [],
    app: appOptions[0].value,
    tier: tierOptions[0].value,
    status: statusOptions[0].value,
    supportCategory: supportOptions[0].value,
    flags: { ...me.flags.reduce((obj, { key }) => ({ ...obj, [key]: false }), {}) },
  }

  const isEdit = Boolean(instance?.id && Boolean(props.open))

  if (isEdit) {
    Object.keys(initialValues).forEach(field => {
      initialValues[field] = instance[field] ?? ''
    })
  }

  return (
    <FormDialog
      fullScreen
      softDeletes
      formatInstance={obj => obj.name}
      initialValues={initialValues}
      instance={instance}
      label="Facility"
      validationSchema={validationSchema}
      {...passthru}
    >
      <section ref={sectionRef} className={classes.formWrapper} data-testid="facility-form">
        <Field
          component={SelectField}
          label="Organization"
          menuPortalTarget={sectionRef.current}
          name="organization"
          options={organizationChoices}
          className="aroya-portal__margin-reset"
        />

        <Field
          component={TextField}
          label="Name"
          name="name"
        />

        <Field
          component={SelectField}
          label="Timezone"
          menuPortalTarget={sectionRef.current}
          name="timezone"
          options={timezones}
        />

        <Field
          component={TextField}
          label="Location"
          name="location"
        />

        <Field
          component={SelectField}
          label="State/Province"
          menuPortalTarget={sectionRef.current}
          name="state"
          options={stateChoices}
        />

        <Field
          component={TextField}
          label="Total Space"
          name="totalSpace"
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">ft<sup>2</sup></InputAdornment>
            )
          }}
        />
        {isEdit ? (
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="flex-start"
            className={classes.appField}
          >
            <Typography color="textSecondary" className={classes.appTitle}> App </Typography>
            <Typography className={classes.appName}>{initialValues.app}</Typography>
          </Grid>
        ) : (
          <Field
            component={SelectField}
            label="App"
            name="app"
            options={appOptions}
          />
        )}

        <Field name="tier">
          {({ field, form }) => (
            <TextSelect
              {...field}
              fullWidth
              select
              label="Tier"
              onChange={({ [field.name]: value }) => form.setFieldValue(field.name, value)}
            >
              {tierOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </TextSelect>
          )}
        </Field>

        <Field name="status">
          {({ field, form }) => (
            <TextSelect
              {...field}
              fullWidth
              select
              label="Status of the facility"
              onChange={({ [field.name]: value }) => form.setFieldValue(field.name, value)}
            >
              {statusOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </TextSelect>
          )}
        </Field>

        <Field name="supportCategory">
          {({ field, form }) => (
            <TextSelect
              {...field}
              fullWidth
              select
              helperText="Who will provide support to this facility?"
              label="Support Category"
              onChange={({ [field.name]: value }) => form.setFieldValue(field.name, value)}
            >
              {supportOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </TextSelect>
          )}
        </Field>

        <MetrcLicenseForm />

        <div className={`${classes.toggles} aroya-portal__field-group`}>
          <Typography variant="h6" align="left" color="primary">Facility attributes</Typography>
          <Field className="mar-t-xs" component={ToggleField} label="Outdoor Facility" name="outdoor" />
          <Field className="mar-t-xs" component={ToggleField} label="Continuous Harvesting" name="continuous" />
          <Field className="mar-t-xs" component={ToggleField} label="Allow Internal Memberships" name="allowInternalMemberships" />
        </div>
        <section data-testid="feature-flags" className="aroya-portal__feature-flags">
          <Typography variant="h6" align="left" color="primary">Feature Flags</Typography>
          {me.flags.map(flag => (
            <div key={flag.key} style={{ breakInside: 'avoid-column' }}>
              <Field
                className="mar-t-xs"
                component={ToggleField}
                label={(
                  <>
                    {flag.key}
                    <Typography component="span" display="block" variant="body2">
                      {flag.description}
                    </Typography>
                  </>
                )}
                name={`flags.${flag.key}`}
              />
            </div>
          ))}
        </section>
      </section>
    </FormDialog>
  )
}

FacilityForm.defaultProps = {
  currentOrganization: {},
  instance: {},
}

export default FacilityForm
