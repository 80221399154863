import memoizeOne from 'memoize-one'

import {
  EMPTY_ARRAY,
  formattedDate,
  getDateTime,
  memoize,
} from '~/Lib/Utils'

const isDays = ({ splitter }) => !!splitter?.days

const isFirstLabelForDay = (x, i, ticks) => {
  const { splitter: { minutes, hours } } = ticks

  const isFirst = i === 0
  const isMidnight = x.getHours() === 0
  const isMinutes = !!minutes

  if (
    !isDays(ticks)
    && (isFirst
      || (!isMinutes && (isMidnight || x.getHours() - hours < 0))
      || (isMinutes && isMidnight && x.getMinutes() - minutes < 0))
  ) {
    return true
  }
  return false
}

export const getXLabel = (x, i, locale, ticks) => {
  if (isFirstLabelForDay(x, i, ticks) || isDays(ticks)) {
    return formattedDate(x, locale, {
      month: 'short',
      day: 'numeric',
    })
  }
  return formattedDate(
    x,
    locale,
    x.getMinutes() !== 0
      ? {
        hour: 'numeric',
        minute: 'numeric',
      }
      : {
        hour: 'numeric',
      }
  )
    .replace(' ', '')
    .toLowerCase()
}

export const getXLabelStyle = memoize((x, i, { theme }, ticks) => {
  const defaultStyle = {
    ...theme.typography.body2,
    fontSize: 11,
    fill: theme.meter.white,
    textAnchor: i === 0 ? 'start' : 'middle',
  }

  if (!isDays(ticks) && isFirstLabelForDay(x, i, ticks)) {
    return {
      ...defaultStyle,
      fontFamily: theme.meter.typography.boldFontFamily,
      fontWeight: 700,
    }
  }
  return defaultStyle
})

const X_TICK_STEP = 6
const BREAKPOINT_TO_TICK_COUNT = {
  xs: 4,
  sm: 6,
  md: 10,
  lg: 14,
  xl: 18,
}

const emptyTicks = Object.freeze({
  step: 0,
  ticks: EMPTY_ARRAY,
})
const toJSDate = ({ start }) => start.toJSDate()
export const getXTicks = memoizeOne(
  ({ chartData, width, breakpoint = width }) => {
    if (!chartData || !('range' in chartData)) return emptyTicks
    const {
      range: { start: startTS, end: endTS },
    } = chartData
    const startDT = getDateTime(startTS)
    const endDT = getDateTime(endTS)
    const diffHours = Math.floor(endDT.diff(startDT).as('hours'))
    const labelCount = BREAKPOINT_TO_TICK_COUNT[breakpoint]
    let step = diffHours / labelCount
    let splitter

    if (step < X_TICK_STEP) {
      if (step >= 1) {
        splitter = {
          // ensure that we'll land on midnight for any days after the start
          // so that we display a date label
          hours: step > 2 ? Math.round(step / 2) * 2 : Math.ceil(step),
        }
      } else {
        step *= 60
        const minutes = Math.ceil(step / 30) * 30 || 30

        splitter = { minutes }
        let start = startDT
        const startPlusSplitter = startDT.plus(splitter)
        if (!startDT.hasSame(startPlusSplitter, 'day')) {
          start = startPlusSplitter.startOf('day')
        }
        const ticks = [
          start.toJSDate(),
          ...start.plus({
            minutes: start.minute > minutes
              ? (minutes - (start.minute % minutes)) + minutes
              : minutes - start.minute
          })
            .until(endDT)
            .splitBy(splitter)
            .map(toJSDate),
        ]
        return { step, splitter, ticks }
      }
    }
    if (step > X_TICK_STEP && step % X_TICK_STEP !== 0) {
      step = Math.round(step / X_TICK_STEP) * X_TICK_STEP
    }
    if (step > 24) {
      step = Math.round(step / 24)
      splitter = { days: step }
    }

    splitter = splitter || { hours: step }

    let ticks
    if (step === 1 || startDT.hour % 2 === 0) {
      ticks = [
        startDT.toJSDate(),
        ...startDT
          .plus(splitter)
          .startOf('hour')
          .until(endDT)
          .splitBy(splitter)
          .map(toJSDate),
      ]
    }

    if (splitter.days) {
      ticks = [
        startDT.toJSDate(),
        ...startDT
          .plus(splitter)
          .startOf('day')
          .until(endDT)
          .splitBy(splitter)
          .map(toJSDate),
      ]
    }

    if (!ticks) {
      ticks = [
        startDT.toJSDate(),
        ...startDT
          .set({ hour: Math.ceil((startDT.hour + step) / 2) * 2 })
          .startOf('hour')
          .until(endDT)
          .splitBy(splitter)
          .map(toJSDate),
      ]
    }

    return {
      step,
      splitter,
      ticks,
    }
  }
)
