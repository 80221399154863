import React, { useState } from 'react'
import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  Grid,
  List,
  ListItem,
  Paper,
  Popover,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import SingleSelect from '~/UI/Shared/Form/SingleSelect'
import Icon from '~/UI/Shared/Icon'

export const sortByLabel = (a, b) => {
  if (a.label.toLowerCase() < b.label.toLowerCase()) return -1
  if (a.label.toLowerCase() > b.label.toLowerCase()) return 1
  return 0
}

const MetrcEntitiesFilterComponent = ({ currentEntity }) => {
  const {
    currentOrganizationCultivars,
    allMetrcLocations,
    plantListFilters,
    plantBatchListFilters,
    harvestBatchListFilters,
    packageListFilters,
    plantListIsLoading,
    plantBatchListIsLoading,
    harvestBatchListIsLoading,
    packageListIsLoading,
    doPlantListSetFilters,
    doPlantBatchListSetFilters,
    doHarvestBatchListSetFilters,
    doPackageListSetFilters,
  } = useConnect(
    'selectCurrentOrganizationCultivars',
    'selectAllMetrcLocations',
    'selectPlantListFilters',
    'selectPlantBatchListFilters',
    'selectHarvestBatchListFilters',
    'selectPackageListFilters',
    'selectPlantListIsLoading',
    'selectPlantBatchListIsLoading',
    'selectHarvestBatchListIsLoading',
    'selectPackageListIsLoading',
    'doPlantListSetFilters',
    'doPlantBatchListSetFilters',
    'doHarvestBatchListSetFilters',
    'doPackageListSetFilters',
  )

  const [anchorEl, setAnchorEl] = useState(null)
  const filterPopoverOpen = Boolean(anchorEl)

  const dateFilter = [
    { label: 'Any Date', value: null },
    { label: 'Today', value: 'TODAY' },
    { label: 'Past 7 Days', value: 'WEEK' },
    { label: 'This Month', value: 'MONTH' },
    { label: 'This Year', value: 'YEAR' },
    { label: 'No Date', value: 'NO_DATE' },
    { label: 'Has Date', value: 'HAS_DATE' },
  ].sort(sortByLabel)

  const cultivarOptions = [
    { label: 'All', value: null },
    ...(Array.isArray(currentOrganizationCultivars)
      ? currentOrganizationCultivars.map(cultivar => ({ label: cultivar.name, value: cultivar.name })).sort(sortByLabel)
      : [])
  ]

  const hideFinished = [
    { label: 'Hide', value: 'HIDE' },
    { label: 'Show', value: 'SHOW' },
  ].sort(sortByLabel)

  const entityExistsIn = [
    { label: 'Either', value: null },
    { label: 'Aroya Only', value: 'AROYA' },
    { label: 'Metrc Only', value: 'METRC' },
  ]

  const locationOptions = [
    { label: 'All', value: null },
    ...(Array.isArray(allMetrcLocations)
      ? allMetrcLocations.map(loc => ({ label: loc.name, value: loc.id }))
      : [])
  ].sort(sortByLabel)

  const plantOptions = {
    state: [
      { label: 'All', value: null },
      { label: 'Tracked', value: 'TRACKED' },
      { label: 'Harvested', value: 'HARVESTED' },
      { label: 'Destroyed', value: 'DESTROYED' },
    ].sort(sortByLabel),
    growthPhase: [
      { label: 'All', value: null },
      { label: 'Young', value: 'YOUNG' },
      { label: 'Veg', value: 'VEG' },
      { label: 'Flower', value: 'FLOWER' },
    ].sort(sortByLabel),
  }

  const plantBatchOptions = {
    finished: [
      { label: 'All', value: null },
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
    ].sort(sortByLabel),
    plantType: [
      { label: 'All', value: null },
      { label: 'Seed', value: 'SEED' },
      { label: 'Clone', value: 'CLONE' },
    ].sort(sortByLabel),
  }

  const diffOptions = [
    { label: 'All', value: null },
    { label: 'Has Diffs', value: true },
    { label: 'No Diffs', value: false },
  ].sort(sortByLabel)

  const disabled = {
    PLANTS: plantListIsLoading,
    PLANT_BATCHES: plantBatchListIsLoading,
    HARVEST_BATCHES: harvestBatchListIsLoading,
    PACKAGES: packageListIsLoading,
  }

  const filters = {
    PLANTS: [
      {
        label: 'Hide Finished',
        options: hideFinished,
        onChange: hide => doPlantListSetFilters({ hideFinished: hide }),
        value: plantListFilters.hideFinished,
      },
      {
        label: 'State',
        options: plantOptions.state,
        onChange: plantState => doPlantListSetFilters({ plantState }),
        value: plantListFilters.plantState,
      },
      {
        label: 'Plant Exists In',
        options: entityExistsIn,
        onChange: plantExistsIn => doPlantListSetFilters({ plantExistsIn }),
        value: plantListFilters.plantExistsIn,
      },
      {
        label: 'Growth Phase',
        options: plantOptions.growthPhase,
        onChange: phase => doPlantListSetFilters({ phase }),
        value: plantListFilters.phase,
      },
      {
        label: 'Planted Date',
        options: dateFilter,
        onChange: plantedDate => doPlantListSetFilters({ plantedDate }),
        value: plantListFilters.plantedDate,
      },
      {
        label: 'Harvested Date',
        options: dateFilter,
        onChange: harvestedDate => doPlantListSetFilters({ harvestedDate }),
        value: plantListFilters.harvestedDate,
      },
      {
        label: 'Destroyed Date',
        options: dateFilter,
        onChange: dateDestroyed => doPlantListSetFilters({ dateDestroyed }),
        value: plantListFilters.dateDestroyed,
      },
      {
        label: 'Cultivar',
        options: cultivarOptions,
        onChange: cultivarName => doPlantListSetFilters({ cultivarName }),
        value: plantListFilters.cultivarName,
      },
      {
        label: 'Locations',
        options: locationOptions,
        onChange: roomName => doPlantListSetFilters({ roomName }),
        value: plantListFilters.roomName,
      },
      {
        label: 'Differences',
        options: diffOptions,
        onChange: difference => doPlantListSetFilters({ difference }),
        value: plantListFilters.difference,
      },
    ],
    PLANT_BATCHES: [
      {
        label: 'Hide Finished',
        options: hideFinished,
        onChange: hide => doPlantBatchListSetFilters({ hideFinished: hide }),
        value: plantBatchListFilters.hideFinished,
      },
      {
        label: 'Finished',
        options: plantBatchOptions.finished,
        onChange: finished => doPlantBatchListSetFilters({ finished }),
        value: plantBatchListFilters.finished,
      },
      {
        label: 'Plant Batch Exists In',
        options: entityExistsIn,
        onChange: plantBatchExistsIn => doPlantBatchListSetFilters({ plantBatchExistsIn }),
        value: plantBatchListFilters.plantBatchExistsIn,
      },
      {
        label: 'Plant Type',
        options: plantBatchOptions.plantType,
        onChange: plantType => doPlantBatchListSetFilters({ plantType }),
        value: plantBatchListFilters.plantType,
      },
      {
        label: 'Planted Date',
        options: dateFilter,
        onChange: plantedDate => doPlantBatchListSetFilters({ plantedDate }),
        value: plantBatchListFilters.plantedDate,
      },
      {
        label: 'Cultivar',
        options: cultivarOptions,
        onChange: cultivarName => doPlantBatchListSetFilters({ cultivarName }),
        value: plantBatchListFilters.cultivarName,
      },
      {
        label: 'Differences',
        options: diffOptions,
        onChange: difference => doPlantBatchListSetFilters({ difference }),
        value: plantBatchListFilters.difference,
      },
    ],
    HARVEST_BATCHES: [
      {
        label: 'Hide Finished',
        options: hideFinished,
        onChange: hide => doHarvestBatchListSetFilters({ hideFinished: hide }),
        value: harvestBatchListFilters.hideFinished,
      },
      {
        label: 'Finished Date',
        options: dateFilter,
        onChange: finishedDate => doHarvestBatchListSetFilters({ finishedDate }),
        value: harvestBatchListFilters.finishedDate,
      },
      {
        label: 'Harvest Batch Exists In',
        options: entityExistsIn,
        onChange: harvestBatchExistsIn => doHarvestBatchListSetFilters({ harvestBatchExistsIn }),
        value: harvestBatchListFilters.harvestBatchExistsIn,
      },
      {
        label: 'Harvested Date',
        options: dateFilter,
        onChange: dateHarvested => doHarvestBatchListSetFilters({ dateHarvested }),
        value: harvestBatchListFilters.dateHarvested,
      },
      {
        label: 'Cultivar',
        options: cultivarOptions,
        onChange: cultivarName => doHarvestBatchListSetFilters({ cultivarName }),
        value: harvestBatchListFilters.cultivarName,
      },
      {
        label: 'Locations',
        options: locationOptions,
        onChange: roomName => doHarvestBatchListSetFilters({ roomName }),
        value: harvestBatchListFilters.roomName,
      },
      {
        label: 'Differences',
        options: diffOptions,
        onChange: difference => doHarvestBatchListSetFilters({ difference }),
        value: harvestBatchListFilters.difference,
      },
    ],
    PACKAGES: [
      {
        label: 'Hide Finished',
        options: hideFinished,
        onChange: hide => doPackageListSetFilters({ hideFinished: hide }),
        value: packageListFilters.hideFinished,
      },
      {
        label: 'Package Exists In',
        options: entityExistsIn,
        onChange: packageExistsIn => doPackageListSetFilters({ packageExistsIn }),
        value: packageListFilters.packageExistsIn,
      },
      {
        label: 'Finished Date',
        options: dateFilter,
        onChange: finishedDate => doPackageListSetFilters({ finishedDate }),
        value: packageListFilters.finishedDate,
      },
      {
        label: 'Packaged Date',
        options: dateFilter,
        onChange: packagedDate => doPackageListSetFilters({ packagedDate }),
        value: packageListFilters.packagedDate,
      },
      {
        label: 'Cultivar',
        options: cultivarOptions,
        onChange: cultivarName => doPackageListSetFilters({ cultivarName }),
        value: packageListFilters.cultivarName,
      },
      {
        label: 'Locations',
        options: locationOptions,
        onChange: roomName => doPackageListSetFilters({ roomName }),
        value: packageListFilters.roomName,
      },
      {
        label: 'Differences',
        options: diffOptions,
        onChange: difference => doPackageListSetFilters({ difference }),
        value: packageListFilters.difference,
      },
    ],
  }

  return (
    <>
      <Button
        size="small"
        color="primary"
        onClick={event => setAnchorEl(event.target)}
      >
        Filter by
        <Icon icon={ExpandMore} />
      </Button>
      <Popover
        open={filterPopoverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <Paper style={{ minWidth: '350px', padding: '8px' }}>
          <List>
            {filters[currentEntity].map(({ label, options, onChange, value }) => (
              <ListItem key={label}>
                <Grid container spacing={2} wrap="nowrap" alignItems="center" justify="space-between">
                  <Typography variant="body1"> {label} </Typography>
                  <SingleSelect
                    color="primary"
                    options={options}
                    value={value}
                    onChange={onChange}
                    disabled={disabled[currentEntity]}
                  />
                </Grid>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popover>
    </>
  )
}

export default MetrcEntitiesFilterComponent
