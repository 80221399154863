import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { uniqueId } from '~/Lib/Utils'
import { meter, secondaryText } from '~/UI/Theme/mui'

const displayName = 'Aroya/SelectField'

// react-select custom styles
const customStyles = theme => ({
  menu: base => ({
    ...base,
    backgroundColor: meter.background.four,
    zIndex: theme.zIndex.modal,
  }),
  option: (base, { isFocused, isSelected, isActive }) => {
    let backgroundColor = meter.background.four

    if (isFocused) {
      backgroundColor = meter.select.focused
    } else if (isSelected || isActive) {
      backgroundColor = meter.select.active
    }

    return {
      ...base,
      ...theme.typography.body1,
      color: '#fff',
      backgroundColor,
      ':active': {
        backgroundColor: isSelected
          ? meter.select.focused
          : meter.select.active,
      },
    }
  },
  input: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled ? meter.text.disabled : meter.white,
    // caretColor: meter.white,
  }),
  clearIndicator: (base, { isFocused }) => ({
    ...base,
    color: meter.white,
    ':hover': {
      ...base[':hover'],
      color: isFocused ? theme.palette.primary.main : meter.grey,
    },
  }),
  control: (base, { isFocused, hasValue }) => ({
    ...base,
    background: 'rgba(255, 255, 255, 0.02)',
    border: 'none',
    borderBottom: `1px solid ${
      hasValue || isFocused
        ? theme.palette.primary.light
        : 'rgba(255, 255, 255, 0.7)'
    }`,
    boxShadow: 'none',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    padding: 0,
    paddingTop: '1rem',
    minHeight: 56,
    width: '100%',
    '&:hover': {
      borderBottomColor: `${
        isFocused ? theme.palette.primary.light : meter.white
      }`,
      boxShadow: 'none',
    },
  }),
  dropdownIndicator: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled ? meter.text.disabled : theme.palette.primary.main,
    borderleft: 'none',
    '&:hover': {
      color: isDisabled ? meter.text.disabled : theme.palette.primary.main,
    },
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  singleValue: (base, { isDisabled }) => ({
    ...base,
    ...theme.typography.body1,
    transform: 'translate(8px, -50%)',
    color: isDisabled ? meter.text.disabled : meter.white,
    caretColor: isDisabled ? meter.text.disabled : meter.white,
  }),
  multiValue: base => ({
    ...base,
    ...theme.typography.body1,
    backgroundColor: meter.background.four,
  }),
  multiValueLabel: base => ({
    ...base,
    ...theme.typography.body1,
    color: meter.white,
  }),
  placeholder: base => ({
    ...base,
    ...theme.typography.body1,
    color: secondaryText,
  }),
})

// SelectField theming
const styles = theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(),
    position: 'relative',
    '&$focused $label': {
      color: theme.palette.primary.main,
    },
    '&$dense': {
      margin: theme.spacing(0.5),
    },
    '&$dense label': {
      left: theme.spacing(),
    },
  },
  dense: {},
  focused: {},
  label: {
    position: 'absolute',
    top: '5px',
    left: theme.spacing(2),
    fontSize: '12px',
    lineHeight: 1,
    color: 'rgba(255, 255, 255, 0.54)',
  },
})

const useStyles = makeStyles(styles, { name: displayName })

export class SelectFieldComponent extends React.PureComponent {
  static displayName = displayName

  static propTypes = {
    className: PropTypes.string,
    dense: PropTypes.bool,
    disableCreateOnBlur: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      }),
      PropTypes.arrayOf(PropTypes.object),
    ]),
    errorText: PropTypes.string,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    disabled: PropTypes.bool,
    selectRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onBlur: PropTypes.func,
    onCreateOption: PropTypes.func,
    onFocus: PropTypes.func,
  }

  static defaultProps = {
    className: '',
    dense: false,
    disableCreateOnBlur: true,
    errorText: '',
    placeholder: undefined,
    value: undefined,
    isLoading: false,
    disabled: false,
    isDisabled: false,
    selectRef: undefined,
    onBlur: undefined,
    onCreateOption: undefined,
    onFocus: undefined,
  }

  state = {
    focused: false,
    inputValue: '',
  }

  constructor(props) {
    super(props)
    this.id = uniqueId('select-field-')
  }

  onFocus = () => {
    if (this.props.onFocus) this.props.onFocus()
    this.setState({ focused: true })
  }

  onBlur = () => {
    const { inputValue } = this.state
    const { disableCreateOnBlur, onBlur, onCreateOption } = this.props
    if (!disableCreateOnBlur && inputValue && onCreateOption) {
      onCreateOption(inputValue)
    }
    if (onBlur) onBlur()
    this.setState({ focused: false })
  }

  render() {
    const {
      classes,
      className,
      dense,
      errorText,
      value,
      placeholder,
      disabled,
      isDisabled,
      isLoading,
      onCreateOption,
      selectRef,
      theme,
      ...props
    } = this.props

    let SelectComponent = Select
    if (onCreateOption) {
      SelectComponent = CreatableSelect
    }
    const hasValue = !(value?.value == null || value == null)
    return (
      <div
        data-testid="select-field-container"
        className={classNames({
          [classes.root]: true,
          [classes.focused]: this.state.focused,
          [classes.dense]: dense,
          [className]: !!className,
        })}
      >
        {placeholder && hasValue ? (
          <label
            data-testid="select-field-label"
            htmlFor={this.id}
            className={classes.label}
          >
            {placeholder}
          </label>
        ) : null}
        <SelectComponent
          id={this.id}
          data-testid="select-field"
          {...props}
          placeholder={placeholder}
          value={value}
          styles={customStyles(theme)}
          isLoading={isLoading}
          isDisabled={isLoading || disabled || isDisabled}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onCreateOption={onCreateOption}
          ref={selectRef}
        />
        {errorText ? (
          <FormHelperText data-testid="select-field-error" error>
            {errorText}
          </FormHelperText>
        ) : null}
      </div>
    )
  }
}

const Wrapped = props => {
  const classes = useStyles(props)
  const theme = useTheme()

  return <SelectFieldComponent {...props} theme={theme} classes={classes} />
}
Wrapped.displayName = `StyledWithTheme(${displayName})`

export default React.memo(Wrapped)
