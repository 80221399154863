import React from 'react'

const DropZoneStyle = ({ width, height, theme }) => (
  <style>
    {`.business-icon {
      color: ${theme.primary};
      font-size: 50px;
    }
    
    .drop-text {
      margin: 0;
    }
    
    .drop-help {
      justify-items: center;
      align-items: center;
    }
  
    .file-drop > .file-drop-target {
      textAlign: center;
      border: 1px solid ${theme.primary};
      width: ${width}px;
      height: ${height}px;
      color: ${theme.primary};
      font-size: 25px;
      text-align: center;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      justify-items: center;
      align-items: center;
    }
    
    .file-drop > .file-drop-target.file-drop-dragging-over-frame {
      border: 1px dashed ${theme.primary};
      background-color: ${theme.activeBackground};
    }
    
    .file-drop > .file-drop-target.file-drop-dragging-over-frame .drop-help {
      
    }`}
  </style>
)

export default React.memo(DropZoneStyle)
