import React, { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { reorder } from '~/Lib/Data'
import RoomForm from '~/Room/form'

import styles from './styles'

const useStyles = makeStyles(styles)

const RoomListRow = ({ onClick, room, rowStyle, isGuestStaff }) => {
  const classes = useStyles()

  return (
    <Paper
      className={isGuestStaff ? classes.nonClickableRow : classes.clickableRow}
      onClick={onClick}
      style={{ ...rowStyle, marginTop: '8px' }}
    >
      <Grid container spacing={2} alignItems="center">
        {!isGuestStaff && (
          <Grid item style={{ cursor: 'grab' }}>
            <DragIndicatorIcon />
          </Grid>
        )}
        <Grid item style={{ flexGrow: 1, marginLeft: isGuestStaff ? '15px' : '0' }}>
          <Typography color="primary">
            {room.name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textSecondary" style={{ minWidth: '150px' }}>
            Total Space: {room.totalSpace} ft<sup>2</sup>
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textSecondary" style={{ minWidth: '100px' }} align="right">
            Zones: {room.zones?.length ?? 0}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

const DraggableRoomListRow = ({ idx, onClick, room, rowStyle, isGuestStaff }) => (
  <Draggable draggableId={`room_${room.id}`} index={idx}>
    {provided => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{ ...provided.draggableProps.style, marginTop: '8px' }}
      >
        <RoomListRow
          onClick={onClick}
          room={room}
          rowStyle={rowStyle}
          isGuestStaff={isGuestStaff}
        />
      </div>
    )}
  </Draggable>
)

const RoomList = () => {
  const [addRoomFormOpen, setAddRoomFormOpen] = useState(false)
  const [editRoomFormOpen, setEditRoomFormOpen] = useState(false)
  const [currentRoom, setCurrentRoom] = useState({})

  const [duplicateMode, setDuplicateMode] = useState(false)
  const [duplicating, setDuplicating] = useState(false)
  const [duplicationError, setDuplicationError] = useState('')

  const {
    isGuestStaff,
    facility,
    rooms,
    zones,
    doDeleteRoom,
    doReorderRooms,
    doFacilityFetch,
    doSaveRoom,
  } = useConnect(
    'selectIsGuestStaff',
    'selectFacility',
    'selectRooms',
    'selectZones',
    'doDeleteRoom',
    'doReorderRooms',
    'doFacilityFetch',
    'doSaveRoom',
  )

  const duplicateModeBoxShadow = { boxShadow: 'inset 0px 0px 0px 2px rgba(255, 255, 255, 0.2)' }

  let roomList = Object.values(rooms)
    ?.filter(room => room.facility === facility?.id)
    .map(room => ({ ...room, zones: room?.zones.map(zoneId => zones[zoneId]) }))
    .sort((a, b) => a.sequence - b.sequence)

  // Saves a new room with the same zone names and space allocation as the passed room.
  const cloneRoom = async room => {
    let roomName = room.name

    const cloneRegex = /\b([0-9]+)$/
    const isClone = roomName.match(cloneRegex)
    if (!isClone) {
      roomName = `${roomName} 1`
    }

    const nameList = roomList.map(r => r.name)
    do {
      roomName = roomName.replace(cloneRegex, (match, p) => `${Number(p) + 1}`)
    } while (nameList.includes(roomName) && roomName !== room.name)

    const clonedZones = room.zones.map(zone => ({ name: zone.name, totalSpace: zone.totalSpace }))

    setDuplicating(true)
    const result = await doSaveRoom({
      name: roomName,
      totalSpace: room.totalSpace,
      zones: clonedZones,
      forPlantBatches: room?.forPlantBatches ?? true,
      forPlants: room?.forPlants ?? true,
      forHarvests: room?.forHarvests ?? true,
      forPackages: room?.forPackages ?? true,
      outdoor: room?.outdoor,
      roomType: room?.roomType ?? 'FLOWER',
      sequence: roomList.length,  // currently needed if name was previously used in deactivated room
    })
    setDuplicating(false)

    if (result.error) {
      setDuplicationError('Error duplicating room.')
    } else if (result) {
      setDuplicationError('')
    }
  }

  const handleRoomClick = room => {
    if (duplicateMode) {
      cloneRoom(room)
    } else {
      setCurrentRoom(room)
      setEditRoomFormOpen(true)
    }
  }

  const handleDragEnd = result => {
    if (!result.destination) return
    if (result.source.index === result.destination.index) return

    // It's important to update `roomList` to prevent the row from flickering positions
    roomList = reorder(roomList, result.source.index, result.destination.index)
    doReorderRooms(roomList.map(room => room.id))
  }

  const onClose = () => {
    if (addRoomFormOpen) setAddRoomFormOpen(false)
    if (editRoomFormOpen) setEditRoomFormOpen(false)
  }

  const saveRoomWithTotalSqft = async values => {
    const totalSpace = values?.zones?.reduce((acc, zone) => (acc + zone.totalSpace), 0)
    const newValues = {
      ...values,
      totalSpace,
      metrcLocations: Object.values(values.metrcLocations)
    }
    await doSaveRoom(newValues)
    doFacilityFetch(facility.id)
    onClose()
  }

  return (
    <>
      <RoomForm
        onClose={onClose}
        onSave={saveRoomWithTotalSqft}
        open={addRoomFormOpen}
      />
      <RoomForm
        instance={currentRoom}
        onClose={onClose}
        onDelete={doDeleteRoom}
        onSave={saveRoomWithTotalSqft}
        open={editRoomFormOpen}
        facility={facility}
      />
      <Grid container spacing={2}>
        <Grid item xs={2} style={{ marginBottom: '6px' }}>
          <Typography variant="subtitle1">
            Rooms
          </Typography>
        </Grid>
        {!isGuestStaff && (
          <Grid item container xs={10} justify="flex-end" alignItems="center">
            {duplicateMode && (
              <>
                {duplicating && <CircularProgress size={30} style={{ marginRight: '1rem' }} />}
                <Typography variant="subtitle2">
                  {duplicationError || 'Click room to duplicate.'}
                </Typography>
              </>
            )}
            <Button
              variant={duplicateMode ? 'contained' : 'outlined'}
              onClick={() => setDuplicateMode(!duplicateMode)}
              style={{ margin: '0 1rem' }}
            >
              {duplicateMode ? 'Cancel' : 'Duplicate'}
            </Button>
            <Button
              color="primary"
              variant={duplicateMode ? 'outlined' : 'contained'}
              onClick={() => {
                setDuplicateMode(false)
                setAddRoomFormOpen(true)
              }}
            >
              Add Room
            </Button>
          </Grid>
        )}
      </Grid>
      {!isGuestStaff ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="roomDroppable">
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {roomList.map((room, idx) => (
                  <DraggableRoomListRow
                    idx={idx}
                    key={room.id}
                    onClick={() => (!isGuestStaff && handleRoomClick(room))}
                    room={room}
                    rowStyle={duplicateMode ? duplicateModeBoxShadow : {}}
                    isGuestStaff={isGuestStaff}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <>
          {roomList.map(room => (
            <RoomListRow
              key={room.id}
              onClick={() => (!isGuestStaff && handleRoomClick(room))}
              room={room}
              rowStyle={duplicateMode ? duplicateModeBoxShadow : {}}
              isGuestStaff={isGuestStaff}
            />
          ))}
        </>
      )}
    </>
  )
}

export default RoomList
