import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

const AlertDialog = ({ title, onClose, onChange }) => (
  <Dialog open fullWidth onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Repudiate (No)
      </Button>
      <Button onClick={onChange} color="secondary" autoFocus>
        Corroborate (Yes)
      </Button>
    </DialogActions>
  </Dialog>
)

export default AlertDialog
