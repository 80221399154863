import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import GroundControl from 'ground-control'

import createLogger from '~/Lib/Logging'
import { noop } from '~/Lib/Utils'
import FormikField from '~/UI/Shared/Form/FormikField'
import MuiTitle from '~/UI/Shared/MuiTitle'

import { MappingCard } from './mappingCard'
import { FloorPlanStyles as styles } from './styles'

const logger = createLogger('Facility/FloorPlan')

// GroundControl Prop
const gcModalProps = {
  width: 510,
  height: 200,
  padding: 10
}

const useStyles = makeStyles(styles)

export const FloorPlan = props => {
  const { facility, floorPlan, onClose, onUpload } = props

  const classes = useStyles()
  const theme = useTheme()

  const [formValues, setFormValues] = React.useState({
    name: '',
    sequence: 0
  })
  const [edit, setEdit] = useState(false)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [associatedZones, setAssociatedZones] = useState([])
  const [svgProps, setSvgProps] = useState({})
  const [groundsAst, setGroundsAst] = useState(null)
  const [areaAssociationsDict, setAreaAssociationsDict] = useState({ zones: {}, rooms: {} })
  const [mappingCard, setMappingCard] = useState(false)
  const [currentRoom, setCurrentRoom] = useState(null)
  const [currentZone, setCurrentZone] = useState(null)
  const [selectedArea, setSelectedArea] = useState(null)
  const [sqftMappings, setSqftMappings] = useState()
  const [uploadDisabled, setUploadDisabled] = useState(false)

  const {
    doCreateNewFloorPlan,
    doUpdateFloorPlan,
  } = useConnect(
    'doCreateNewFloorPlan',
    'doUpdateFloorPlan',
  )

  // If passed an existing floor plan, set state
  useEffect(() => {
    if (floorPlan) {
      setFormValues({
        name: floorPlan.name,
        sequence: floorPlan.sequence
      })
      setGroundsAst(floorPlan.floorplan.groundsAst)
      setEdit(true)
    }
  }, [])

  // Set GroundControl window size on mount
  const refCallback = useCallback(element => {
    if (element) {
      setWidth(element.offsetWidth)
      setHeight(element.offsetHeight)
    }
  }, [])

  const gcRef = useRef()

  // Reset GroundControl map view
  useEffect(() => {
    if (gcRef.current && gcRef.current.viewer) {
      gcRef.current.viewer.doResetMap()
    }
  }, [gcRef.current])

  // Update form state upon input change
  const handleChange = updatedValue => setFormValues({ ...formValues, ...updatedValue })

  // Highlight rooms and zones which are already assigned
  const handleColor = (areaId, facilityArea) => {
    const areas = groundsAst[areaId]
    if (Object.values(areas).some(area => area.uuid === facilityArea.id)) {
      return 'primary'
    }
    return 'inherit'
  }

  // Updates map's zone fill color
  const handleZoneColor = (areaId, zones) => {
    const svg = { ...svgProps }
    Object.keys(svg).forEach(zone => {
      if (zones && zones.includes(zone)) {
        svg[zone].fill = '#094777'
      } else {
        svg[zone].fill = 'auto'
      }
    })
    if (areaId) {
      svg[areaId] = { fill: '#0d63a7' }
    }
    setSvgProps(svg)
  }

  // TODO - This function appears to execute when a FloorPlan is loaded, and twice when the mapping
  // popup is closed via 'ESC' button. It is not triggered when clicking away from the popup.
  const handleClickAway = () => {
    gcRef.current.viewer.doResetMap()
    setMappingCard(false)
    setCurrentRoom(null)
    setCurrentZone(null)
    handleZoneColor()
  }

  const handleSelect = area => {
    logger('select', area)
    const { areaId } = area
    if (area.areaBoundClientRect) {
      setCurrentRoom(areaId)
      setSelectedArea(area)
      setMappingCard(true)
    } else if (areaId?.includes('zone')) {
      if (!currentRoom) {
        setCurrentRoom(groundsAst.zones[areaId].room)
      }
      if (!mappingCard) {
        setMappingCard(true)
      }
      handleZoneColor(areaId, associatedZones)
      setCurrentZone(areaId)
    }
  }

  const handleZone = map => {
    setAssociatedZones(Object.keys(map))
    handleZoneColor(null, Object.keys(map))
  }

  const handleSave = (ast, associations, zoneSqftMappings = null) => {
    if (zoneSqftMappings) setSqftMappings(zoneSqftMappings)
    setAreaAssociationsDict(associations)
    setGroundsAst(ast)
    setAssociatedZones([])
    setMappingCard(false)
  }

  const handleUpload = async () => {
    setUploadDisabled(true)
    let frontendAst = JSON.stringify(groundsAst, (key, val) => (key === 'fill' ? undefined : val))
    frontendAst = JSON.parse(frontendAst)

    const payload = {
      facility: facility.id,
      name: formValues.name,
      sequence: formValues.sequence,
      floorplan: { groundsAst: frontendAst },
      updatedZones: sqftMappings,
    }

    if (edit) {
      payload.id = floorPlan.id
      await doUpdateFloorPlan(payload)
    } else {
      await doCreateNewFloorPlan(payload)
    }
    setUploadDisabled(false)
    onUpload()
  }

  const isValid = () => formValues.name.length && groundsAst

  return (
    <Dialog fullScreen open>
      {mappingCard ? (
        <MappingCard
          areaAssociations={areaAssociationsDict}
          currentRoom={currentRoom}
          currentZone={currentZone}
          rooms={facility.rooms}
          select={selectedArea}
          handleZone={map => handleZone(map)}
          onClose={() => {
            setMappingCard(false)
            gcRef.current.viewer.doResetMap()
          }}
          onSave={handleSave}
        />
      ) : null}
      <div className={classes.root}>
        <div className={classes.fpForm}>
          <FormikField
            autoFocus
            required="Please enter a floorplan name"
            name="name"
            label="Floorplan Name"
            maxLength={25}
            value={formValues.name}
            onChange={handleChange}
            className={classes.fpName}
          />
          <FormikField
            required="Please enter a room sequence"
            type="number"
            name="sequence"
            label="Sequence"
            value={formValues.sequence}
            onChange={handleChange}
            className={classes.fpNumber}
          />
          <Button
            color="secondary"
            variant="outlined"
            className={classes.button}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            disabled={!isValid() || uploadDisabled}
            color="primary"
            variant="outlined"
            className={classes.button}
            onClick={handleUpload}
          >
            {edit ? 'Update' : 'Upload'}
          </Button>
          <div className={classes.fpRoom}>
            <MuiTitle title="Rooms" variant="h4" />
            {facility.rooms.map(room => (
              <React.Fragment key={room.id}>
                <MuiTitle
                  title={room.name}
                  variant="h6"
                  color={groundsAst ? handleColor('rooms', room) : 'inherit'}
                />
                {room.zones.map(zone => (
                  <MuiTitle
                    key={zone.id}
                    title={zone.name}
                    variant="body2"
                    color={groundsAst ? handleColor('zones', zone) : 'inherit'}
                  />
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div ref={refCallback} className={classes.fp}>
          <GroundControl
            key={floorPlan ? floorPlan.id : 'upload'}
            ref={gcRef}
            admin
            associatedRooms={[]}
            associatedZones={[]}
            ast={groundsAst}
            width={width}
            height={height}
            theme={theme.floorPlan}
            modal={gcModalProps}
            svgProps={svgProps}
            areaAssociationsDict={areaAssociationsDict}
            onSelect={handleSelect}
            onMapReset={noop}
            onClickAway={handleClickAway}
            // TODO - onParse is a required callback, currently unused
            onParse={() => {}}
          />
        </div>
      </div>
    </Dialog>
  )
}

FloorPlan.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.number,
    rooms: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  floorPlan: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sequence: PropTypes.number,
    floorplan: PropTypes.object
  }),
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired
}

FloorPlan.defaultProps = {
  floorPlan: null
}
