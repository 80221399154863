import { EMPTY_OBJECT } from './constants'

export const nthLogicStyles = config => config.reduce((acc, { selector, counts, rules }) => ({
  ...acc,
  ...counts.reduce((selectorAcc, count) => {
    const selectorPrefix = `${selector}:first-child:nth-last-child(n+${count})`
    return {
      ...selectorAcc,
      [`${selectorPrefix}, ${selectorPrefix} ~ ${selector}`]: rules
    }
  }, EMPTY_OBJECT)
}), EMPTY_OBJECT)
