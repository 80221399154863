import React from 'react'

import { SvgIcon } from '@material-ui/core'

export const path = `
  M17.2191312,6.62469505 C16.8741216,6.19343311 16.944043,5.56414074 17.375305,
  5.21913119 C17.8065669,4.87412164 18.4358593,4.94404302 18.7808688,
  5.37530495 C20.2596413,7.22377054 21,9.4448467 21,12 C21,14.5551533 20.2596413,
  16.7762295 18.7808688,18.624695 C18.4358593,19.055957 17.8065669,19.1258784 17.375305,
  18.7808688 C16.944043,18.4358593 16.8741216,17.8065669 17.2191312,
  17.375305 C18.4070254,15.8904372 19,14.1115134 19,12 C19,9.88848663 18.4070254,
  8.10956279 17.2191312,6.62469505 Z M6.78083199,6.62469505 C5.59293779,
  8.10956279 4.99996318,9.88848663 4.99996318,12 C4.99996318,14.1115134 5.59293779,
  15.8904372 6.78083199,17.375305 C7.12584153,17.8065669 7.05592016,
  18.4358593 6.62465822,18.7808688 C6.19339629,19.1258784 5.56410392,
  19.055957 5.21909437,18.624695 C3.7403219,16.7762295 2.99996318,14.5551533 2.99996318,
  12 C2.99996318,9.4448467 3.7403219,7.22377054 5.21909437,5.37530495 C5.56410392,
  4.94404302 6.19339629,4.87412164 6.62465822,5.21913119 C7.05592016,
  5.56414074 7.12584153,6.19343311 6.78083199,6.62469505 Z M15.1269391,
  10.447327 C14.830578,9.95338454 15.0708262,9.35275536 15.6635484,
  9.10578414 C16.2562707,8.85881293 16.9770152,9.05902265 17.2733763,
  9.55296508 C17.754373,10.354638 18,11.1734066 18,12 C18,12.8265934 17.754373,
  13.645362 17.2733763,14.4470349 C16.9770152,14.9409773 16.2562707,
  15.1411871 15.6635484,14.8942159 C15.0708262,14.6472446 14.830578,
  14.0466155 15.1269391,13.552673 C15.4458723,13.0211099 15.6002103,
  12.5066426 15.6002103,12 C15.6002103,11.4933574 15.4458723,10.9788901 15.1269391,
  10.447327 Z M8.87306088,10.447327 C8.55412768,10.9788901 8.39978972,11.4933574 8.39978972,
  12 C8.39978972,12.5066426 8.55412768,13.0211099 8.87306088,13.552673 C9.16942201,
  14.0466155 8.92917385,14.6472446 8.33645159,14.8942159 C7.74372933,
  15.1411871 7.02298483,14.9409773 6.7266237,14.4470349 C6.245627,13.645362 6,
  12.8265934 6,12 C6,11.1734066 6.245627,10.354638 6.7266237,9.55296508 C7.02298483,
  9.05902265 7.74372933,8.85881293 8.33645159,9.10578414 C8.92917385,
  9.35275536 9.16942201,9.95338454 8.87306088,10.447327 Z M12,14 C10.8954305,
  14 10,13.1045695 10,12 C10,10.8954305 10.8954305,10 12,10 C13.1045695,10 14,10.8954305 14,
  12 C14,13.1045695 13.1045695,14 12,14 Z
`

export default props => (
  <SvgIcon {...props}>
    <path data-iconid="aroya-icons-sensor" d={path} />
  </SvgIcon>
)
