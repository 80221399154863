/* eslint-disable babel/camelcase */
import React, { useEffect } from 'react'
import { useConnect } from 'redux-bundler-hook'
import { formatDateTime, getDateTime } from '~/Lib/Utils/datetime'
import { makeStyles } from '@material-ui/styles'
import Tree from 'react-d3-tree'
import T from '~/UI/Shared/Typography'

import styles from './styles'

const useStyles = makeStyles(styles)

const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
  classes,
  onNodeMouseOver,
}) => {
  const last_communication = nodeDatum?.attributes?.last_communication ? getDateTime(nodeDatum?.attributes?.last_communication) : null
  const { minutes } = last_communication ? getDateTime('now').diff(last_communication, 'minutes').toObject() : { minutes: 100 }
  onNodeMouseOver()
  return (
    <g>
      {nodeDatum?.attributes ? (
        <circle r={25} className={minutes >= 20 ? classes.circleRed : classes.circleGreen} onClick={toggleNode} />
      ) : (
        <circle r={25} className={classes.circleGreen} onClick={toggleNode} />
      )}
      <foreignObject {...foreignObjectProps}>
        <div className={classes.cardData}>
          <T.H5 className={classes.deviceName}>{nodeDatum.name}</T.H5>
          {nodeDatum?.attributes ? Object.entries(nodeDatum?.attributes).map(([key, value]) => {
            if (last_communication && key === 'last_communication') {
              return (
                <T className={classes.deviceData}>
                  <strong className={classes.boldLabel}>{`${key}:`}</strong>
                  {` ${formatDateTime(last_communication)}`}
                </T>
              )
            }
            if (value != null) return <T className={classes.deviceData}><strong className={classes.boldLabel}>{`${key}:`}</strong>{` ${value}`}</T>
            return null
          }) : null}
        </div>
      </foreignObject>
    </g>
  )
}

const Topology = ({ facility }) => {
  const {
    doFetchFacilityTopology,
    topology
  } = useConnect(
    'doFetchFacilityTopology',
    'selectTopology'
  )
  const classes = useStyles()
  const nodeSize = { x: 250, y: 500 }
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: 20 }

  useEffect(() => {
    doFetchFacilityTopology(facility)
  }, [])

  return (
    <div>
      {topology && (
        <div id="treeWrapper" style={{ background: '#FFF', minHeight: 2000, width: '90vw', height: '55vh' }}>
          <Tree
            data={topology}
            zoom={0.7}
            orientation="vertical"
            separation={{ siblings: 3 }}
            depthFactor={400}
            renderCustomNodeElement={rd3tProps => renderForeignObjectNode({ ...rd3tProps, foreignObjectProps, classes })}
          />
        </div>
      )}
    </div>
  )
}

export default Topology
