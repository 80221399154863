import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { pick } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { createLogger } from '@meter-ui/utils'

import { shape as shapeConfig } from '~/Device/bundle'
import Dialog from '~/Dialog'
import ConfirmationDialog from '~/UI/Shared/ConfirmationDialog'
import FormikField from '~/UI/Shared/Form/FormikField'
import TextField from '~/UI/Shared/Form/TextField'

const displayName = 'Device/Form'
const logger = createLogger(displayName)

const statePicker = pick([
  'id',
  'leased',
  'serialNumber',
  'deviceName',
  'room',
  'zone',
  'retired',
])

const styles = theme => ({
  actions: {
    padding: theme.spacing(0, 1.25, 1.25, 4),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  checkBoxLabel: {
    marginTop: theme.spacing(1.25),
    marginLeft: 0,
  }
})

const useStyles = makeStyles(styles, { name: displayName })

class DeviceFormComponent extends React.PureComponent {
  static displayName = displayName

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    device: shapeConfig.isRequired,
    rooms: PropTypes.objectOf(PropTypes.object).isRequired,
    zones: PropTypes.objectOf(PropTypes.object).isRequired,
    onClickDeprovision: PropTypes.func.isRequired,
    doDeviceSave: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      ...statePicker(props.device),
      attempted: false,
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleRetired = this.toggleRetired.bind(this)
    this.toggleLeased = this.toggleLeased.bind(this)
  }

  componentDidUpdate(prevProps) {
    const oldSaving = prevProps?.device?.inflight === 'save'

    if (oldSaving && oldSaving !== this.saving) {
      const newSuccess = this.props?.device?.lastError?.type !== 'save'
      if (newSuccess !== null) {
        this.props.onClose(newSuccess)
      }
    }
  }

  get saving() {
    return this.props?.device?.inflight === 'save'
  }

  onChange(data) {
    const newState = { ...this.state, ...data }
    if ('room' in data) {
      newState.zone = null
    }
    this.setState(newState)
  }

  onSubmit() {
    this.props.doDeviceSave(this.state)
  }

  toggleRetired() {
    this.setState(prevState => ({
      retired: !prevState.retired
    }))
  }

  toggleLeased() {
    this.setState(prevState => ({
      leased: !prevState.leased
    }))
  }

  render() {
    const { classes, device, rooms, zones, onClickDeprovision } = this.props
    const { deviceName, leased, room, zone, retired } = this.state

    const facilityRooms = Object.values(rooms).filter(
      _room => _room.facility === device.facility
    )
    const selectedRoom = facilityRooms.find(facRoom => facRoom.id === room) ?? null

    return (
      <Dialog
        open
        maxWidth="sm"
        fullWidth
        onClose={() => this.props.onClose(null)}
        content={(
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormikField
                disabled
                type="text"
                name="serialNumber"
                label="Serial Number"
                value={device.serialNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                type="text"
                maxLength={15}
                name="deviceName"
                label="Device Name"
                value={deviceName}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                name="room"
                label="Choose Room"
                value={room || ''}
                onChange={this.onChange}
              >
                <MenuItem value="">None</MenuItem>
                {facilityRooms.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>{name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            {selectedRoom ? (
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  name="zone"
                  label="Choose Zone"
                  value={zone || ''}
                  onChange={this.onChange}
                >
                  <MenuItem value="">None</MenuItem>
                  {Object.values(selectedRoom.zones).map(zoneId => {
                    const { id, name } = zones[zoneId]
                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            ) : null}
          </Grid>
        )}
        actions={(
          <div className={classes.actions}>
            <Button color="secondary" onClick={onClickDeprovision}>
              Deprovision
            </Button>
            <FormControlLabel
              control={(
                <Switch
                  checked={retired}
                  color="primary"
                  value="retired"
                  onChange={this.toggleRetired}
                />
)}
              label="Retired"
            />
            <FormControlLabel
              control={(
                <Switch
                  checked={leased}
                  color="primary"
                  value="leased"
                  onChange={this.toggleLeased}
                />
)}
              label="Leased"
            />
            <div>
              <Button onClick={() => this.props.onClose(null)} color="primary">
                Cancel
              </Button>
              <Button
                onClick={this.onSubmit}
                color="primary"
                disabled={this.saving}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      />
    )
  }
}

const ConnectedAndStyled = props => {
  const classes = useStyles(props)
  const {
    doDeprovisionDevice,
    doMarkDeviceListAsOutdated,
    ...connectedProps
  } = useConnect(
    'selectRooms',
    'selectZones',
    'selectDevice',
    'doDeprovisionDevice',
    'doDeviceSave',
    'doMarkDeviceListAsOutdated',
  )

  const [confirmDeprovision, setConfirmDeprovision] = useState(false)

  const handleDeprovision = async () => {
    const success = await doDeprovisionDevice(props.device.serialNumber)
    if (success) {
      doMarkDeviceListAsOutdated()
      props.onClose(null)
    }
  }

  return confirmDeprovision ? (
    <ConfirmationDialog
      open
      destructive
      title="Deprovision Device"
      content={`Are you sure you want to deprovision ${props.device.deviceName}?`}
      confirmText="Deprovision"
      cancelText="Cancel"
      onClose={() => setConfirmDeprovision(false)}
      onConfirm={handleDeprovision}
    />
  ) : (
    <DeviceFormComponent
      {...props}
      {...connectedProps}
      classes={classes}
      onClickDeprovision={() => setConfirmDeprovision(true)}
    />
  )
}
ConnectedAndStyled.displayName = `ConnectedStyled(${displayName})`

export default React.memo(ConnectedAndStyled)
