import React from 'react'
import PropTypes from 'prop-types'

import { debounce } from '~/Lib/Utils'
import TextField from '~/UI/Shared/Form/TextField'

export class SearchFieldComponent extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onEmpty: PropTypes.func.isRequired,
    value: PropTypes.string,
  }

  static defaultProps = {
    value: '',
  }

  constructor(props) {
    super(props)
    this.sendSearch = debounce(
      searchTerms => this.props.onChange({ searchTerms }),
      400
    )
  }

  state = {
    value: this.props.value,
  }

  componentDidUpdate(prevProps) {
    this.checkValue(prevProps.value)
  }

  onEmpty = () => {
    this.setState({ value: '' })
    this.props.onEmpty()
  }

  onChange = ({ searchTerms }) => {
    this.setState({ value: searchTerms })
    this.sendSearch(searchTerms)
  }

  checkValue(prevValue) {
    if (prevValue === this.state.value && this.props.value !== this.state.value) {
      this.setState({ value: this.props.value })
    }
  }

  render() {
    return (
      <TextField
        data-testid="search-text-field"
        {...this.props}
        name="searchTerms"
        value={this.state.value}
        onEmpty={this.onEmpty}
        onChange={this.onChange}
      />
    )
  }
}

export default SearchFieldComponent
