import React, { Component } from 'react'

import PropTypes from 'prop-types'

import astToSvg from '../utils/astToSvg'
import createFacilityDirectory from '../utils/createFacilityDirectory'
import parseSvgMap from '../utils/parseSvgMap'
import FileDrop from './file-drop'
import Viewer from './viewer'

const showError = msg => console.dir(msg)

class GroundControl extends Component {
  state = {
    floorplan: (this.props.ast ? astToSvg(this.props.ast, this.props.areaAssociationsDict, this.props.theme, this.props.admin, this.props.debug) : null),
    groundsAst: this.props.ast,
    groundsDictionary: (this.props.ast ? createFacilityDirectory(this.props.ast) : null)
  }

  componentDidMount() {
    if (this.props.ast) {
      this.setState({
        floorplan: astToSvg(this.props.ast, this.props.areaAssociationsDict, this.props.theme, this.props.admin, this.props.debug),
        groundsAst: this.props.ast,
        groundsDictionary: createFacilityDirectory(this.props.ast)
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.ast && prevProps.ast !== this.props.ast) {
      this.setState({
        floorplan: astToSvg(this.props.ast, this.props.areaAssociationsDict, this.props.theme, this.props.admin, this.props.debug),
        groundsAst: this.props.ast,
        groundsDictionary: createFacilityDirectory(this.props.ast)
      })
    }
  }

  readUploadedFileAsText = inputFile => {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort()
        reject(new DOMException('Problem parsing input file.'))
      }

      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsText(inputFile)
    })
  }

  doResetMap() {
    this.viewer.doResetMap()
  }

  readFile = async ({ files, event }) => {
    if (files.length > 1) {
      return showError({ files, event, msg: `More then one file is not supported, you provided ${files.length}` })
    }
    if (files[0].type !== 'image/svg+xml') {
      return showError({ files, event, msg: 'Only an svg image is allowed' })
    }

    const svg = await this.readUploadedFileAsText(files[0])

    const groundsAst = parseSvgMap(svg, this.props.theme)
    const groundsDictionary = createFacilityDirectory(groundsAst)
    this.setState({ groundsAst })
    this.setState({ floorplan: astToSvg(groundsAst, this.props.areaAssociationsDict, this.props.theme, this.props.admin, this.props.debug) })
    this.setState({ groundsDictionary })
    this.props.onParse({ groundsAst, groundsDictionary })
    return null
  }

  render() {
    return (
      <div>
        { !this.state.floorplan && this.props.admin ? (
          <FileDrop
            onDropFile={this.readFile}
            onFileError={showError}
            width={this.props.width}
            height={this.props.height}
            theme={this.props.theme}
          />
        ) : (
          <Viewer
            ref={viewer => this.viewer = viewer}
            floorplan={this.state.floorplan}
            groundsAst={this.state.groundsAst}
            svgProps={this.props.svgProps}
            groundsDictionary={this.state.groundsDictionary}
            onParse={this.props.onParse}
            onSelect={this.props.onSelect}
            onMapReset={this.props.onMapReset}
            onClickAway={this.props.onClickAway}
            onActivateArea={this.onActivateArea}
            onDeactivateArea={this.onDeactivateArea}
            doResetMap={this.doResetMap}
            width={this.props.width}
            height={this.props.height}
            warningSVGIcon={this.props.warningSVGIcon}
            modal={this.props.modal}
            admin={this.props.admin}
            debug={this.props.debug}
            associatedRooms={this.props.associatedRooms}
            associatedZones={this.props.associatedZones}
            theme={this.props.theme}
          />
        )}
      </div>
    )
  }
}

GroundControl.propTypes = {
  admin: PropTypes.bool.isRequired,
  areaAssociationsDict: PropTypes.shape().isRequired,
  associatedRooms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  associatedZones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  ast: PropTypes.shape(),
  debug: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  modal: PropTypes.shape().isRequired,
  svgProps: PropTypes.shape().isRequired,
  width: PropTypes.number.isRequired,
  warningSVGIcon: PropTypes.string.isRequired,
  onClickAway: PropTypes.func.isRequired,
  onMapReset: PropTypes.func.isRequired,
  onParse: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

GroundControl.defaultProps = {
  ast: null,
}

export default GroundControl
