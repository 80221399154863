import React from 'react'

import {
  Checkbox,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
  Button,
} from '@material-ui/core'
import moment from 'moment'

import { percentChange, metricCountChange } from './utils'

export const MetricList = props => {
  const {
    chart,
    classes,
    isolateMetric,
    setMetrics,
    filterMetrics,
    availableMetrics,
  } = props

  const showingAllMetrics = availableMetrics.length === chart.length

  return (
    <>
      <Paper className={classes.paperHeader} elevation={1}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" className={classes.header}>
              Metrics
            </Typography>
          </Grid>
          <Grid item>
            {!showingAllMetrics && (
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  setMetrics(chart.map(graph => graph.label))
                }}
              >
                Show All
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.paper} elevation={1}>
        <List>
          {chart.map(graph => {
            const { label, color, data } = graph
            const previousDataPoint = data[data.length - 2]?.y
            const previousDataPointDate = data[data.length - 2]?.x
            const currentDataPoint = data[data.length - 1]?.y
            const percentage = percentChange(previousDataPoint, currentDataPoint)
            const metricCountDiff = metricCountChange(previousDataPoint, currentDataPoint)
            const metricCountMsg = `${metricCountDiff} ${label}`
            return (
              <ListItem className={classes.listItem} key={label}>
                <Checkbox
                  color="primary"
                  edge="start"
                  checked={availableMetrics.includes(label)}
                  onChange={() => filterMetrics(label)}
                />
                <Grid container direction="column" style={{ flexBasis: '650px' }}>
                  <Typography variant="subtitle2" style={{ color }}>
                    {data[data.length - 1]?.y} {label}
                  </Typography>
                  {previousDataPointDate && (
                    <Typography variant="body2">
                      {`${percentage} from ${moment(previousDataPointDate).fromNow()}`}
                    </Typography>
                  )}
                  {metricCountDiff && (
                    <Typography variant="body2">
                      {metricCountMsg}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Button
                    color="primary"
                    style={{ fontSize: '10px' }}
                    onClick={() => isolateMetric(graph.label)}
                  >
                    Show only this
                  </Button>
                </Grid>
              </ListItem>
            )
          })}
        </List>
      </Paper>
    </>
  )
}

export default MetricList
