import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import DataTypeName from '~/UI/Shared/DataTypeName'

const stylePropType = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
)
const labelPropTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  balloonTemplate: PropTypes.func.isRequired,
  bottom: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  style: stylePropType,
}

const LineLabel = ({
  className,
  id,
  balloonTemplate,
  bottom,
  color,
  value,
  style,
  ...rest
}) => (
  <span
    className={className}
    style={{
      color,
      bottom,
      ...style,
    }}
    data-testid={rest['data-testid']}
  >
    <DataTypeName name={balloonTemplate({ value })} />
  </span>
)
LineLabel.propTypes = {
  ...labelPropTypes,
  className: PropTypes.string.isRequired,
}

export const CursorLabel = ({ classes, labels, collisions, style }) => {
  // return null if no labels or collisions
  if (!(labels && labels.length) && !(collisions && collisions.length)) return null
  let collisionsTruncated = false

  const Labels = labels.length
    ? labels
      .filter(
        ({ id }) => !collisions.some(c => c.colliding.find(l => l.id === id))
      )
      .map(label => (
        <LineLabel
          key={label.id}
          data-testid={label.id}
          {...label}
          className={classes.label}
          style={style}
        />
      ))
    : null
  const Collisions = collisions && collisions.length ? collisions.map(({ bottom, colliding, truncated }) => {
    collisionsTruncated = Boolean(truncated)
    return (
      <span
        key={`${bottom}-container`}
        style={{ ...style, bottom }}
        className={classes.label}
      >
        {colliding.map(({ id, balloonTemplate, color, ...data }) => (
          <span key={id} data-testid={id} style={{ color }}>
            <DataTypeName name={balloonTemplate(data)} />
          </span>
        ))}
      </span>
    )
  }) : null
  return (
    <div className={classNames(classes.labels, collisionsTruncated && classes.labelsTruncated)}>
      {Labels}
      {Collisions}
    </div>
  )
}
CursorLabel.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape(labelPropTypes)).isRequired,
  collisions: PropTypes.arrayOf(
    PropTypes.shape({
      bottom: labelPropTypes.bottom,
      colliding: PropTypes.arrayOf(PropTypes.shape(labelPropTypes)).isRequired,
    })
  ).isRequired,
  style: stylePropType.isRequired,
}

export default React.memo(CursorLabel)
