/* eslint-disable import/no-import-module-exports */
import {
  appTimeBundle,
  composeBundlesRaw,
  createCacheBundle,
  createDebugBundle,
  createReactorBundle,
  createSelector,
  createUrlBundle,
} from 'redux-bundler'
import onlineBundle from 'redux-bundler/dist/online-bundle'

import qs from 'query-string'

// Entity bundles
import { dashboard, dashboardChart } from '~/Dashboard/bundle'
import dataType from '~/DataType/bundle'
import {
  deviceChart,
  deviceEvents,
  deviceList,
  devices,
  provisioning,
} from '~/Device/bundle'
import dialog from '~/Dialog/bundle'
import {
  facilities,
  facilityList,
  harvestHistory,
  historicImport,
} from '~/Facility/bundle'
import {
  otapImageList,
  otapNodeList,
  otapStatus,
  otapStatusList,
} from '~/Firmware/bundle'
import getApiFetch from '~/IO/Api'
import cache from '~/IO/Cache'
import { loggerList, loggers } from '~/Logger/bundle'
import harvestBatchList from '~/Metrc/HarvestBatch/bundle'
import packageList from '~/Metrc/Package/bundle'
import plantList from '~/Metrc/Plant/bundle'
import plantBatchList from '~/Metrc/PlantBatch/bundle'
import nodeList from '~/Node/bundle'
import { organizationList, organizations } from '~/Organization/bundle'
import routes from '~/Routes/bundle'
import systemChart from '~/System/bundle'
import { userList, users } from '~/Users/bundle'
import { solus, solusList } from '~/Users/solus/bundle'

import auth from './bundles/auth'
import dimensions from './bundles/dimensions'
import entities from './bundles/entities'
import me from './bundles/me'
import snackbar from './bundles/snackbar'

const cacheBundle = createCacheBundle({
  cacheFn: cache.set,
  logger: cache.logger,
})

const extraArgs = {
  name: 'extraArgs',
  getExtraArgs: store => ({
    apiFetch: getApiFetch(store),
  }),
  doUpdateQueryAdvanced: (payload, opts) => ({ store }) => {
    const search = qs.stringify(payload)
    const oldSearch = store.selectUrlObject().search
    if (search !== oldSearch) {
      store.doUpdateQuery(search, opts)
    }
  },
  selectQueryAdvanced: createSelector(
    'selectUrlObject',
    ({ search }) => qs.parse(search)
  ),
}

const isIOPattern = /sav(e|ing)|creat(e|ing)|delet(e|ing)|destroy|fetch/i
const isStartPattern = /start/i
const isReadPattern = /fetch/i
const io = {
  name: 'io',
  reducer: (state = { inflight: { read: 0, write: 0 } }, action = {}) => {
    if (!action.type || !isIOPattern.test(action.type)) return state
    const {
      inflight: { read, write },
    } = state
    const isStart = isStartPattern.test(action.type)
    const isRead = isReadPattern.test(action.type)
    const newState = {
      inflight: {
        read: isRead ? Math.max(0, isStart ? read + 1 : read - 1) : read,
        write: isRead ? write : Math.max(0, isStart ? write + 1 : write - 1),
      },
    }
    return newState
  },
  selectInflight: state => state.io && state.io.inflight,
}

const MAINTENANCE_MODE_ENABLED = 'MAINTENANCE_MODE_ENABLED'
const MAINTENANCE_MODE_DISABLED = 'MAINTENANCE_MODE_DISABLED'

const maintenanceMode = {
  name: 'maintenanceMode',
  reducer: (state = false, action = {}) => {
    switch (action.type) {
      case MAINTENANCE_MODE_ENABLED:
        return true
      case MAINTENANCE_MODE_DISABLED:
        return false
      default:
        return state
    }
  },
  doMaintenanceModeEnable: () => ({ dispatch }) => dispatch({ type: MAINTENANCE_MODE_ENABLED }),
  doMaintenanceModeDisable: () => ({ dispatch }) => dispatch({ type: MAINTENANCE_MODE_DISABLED }),
  selectMaintenanceModeEnabled: state => state.maintenanceMode,
}

const noLogActions = {
  APP_IDLE: 1,
  SET_URL_HISTORY: 1,
  DIMENSIONS_UPDATE: 1,
}

export default composeBundlesRaw(
  // redux-bundler built-ins
  appTimeBundle,
  onlineBundle,
  createDebugBundle({
    logSelectors: false,
    logState: false,
    actionFilter: ({ type }) => !(type in noLogActions)
  }),
  createReactorBundle(),
  createUrlBundle(),
  // Entity bundles
  dataType,
  devices,
  dialog,
  facilities,
  loggers,
  me,
  organizations,
  solus,
  users,
  // List bundles
  dashboard,
  dashboardChart,
  deviceChart,
  deviceEvents,
  deviceList,
  facilityList,
  loggerList,
  nodeList,
  organizationList,
  otapImageList,
  otapNodeList,
  otapStatus,
  otapStatusList,
  solusList,
  packageList,
  plantList,
  plantBatchList,
  harvestBatchList,
  systemChart,
  userList,
  // System bundles
  auth,
  entities,
  routes,
  dimensions,
  cacheBundle,
  extraArgs,
  snackbar,
  io,
  maintenanceMode,
  historicImport,
  harvestHistory,
  provisioning,
)
// eslint-disable-next-line no-restricted-globals
if (module && module.hot) module.hot.accept(() => location.reload())
