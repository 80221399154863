const getZoneCoOrds = zone => {
  const { zoneId, coOrds } = zone
  const sides = coOrds.reduce((acc, coOrd, i) => {
    if (i < 4) {
      const [x, y] = coOrd.replace(/^[A-Za-z]/, '').split(',')
      return { ...acc, [`side${i + 1}`]: { x: parseFloat(x), y: parseFloat(y) } }
    }
    return { ...acc }
  }, {})
  return { zoneId, sides }
}

const cmsToFeet = cms => cms * 0.0328084

const getSqftForZones = zones => {
  // The amount the plants canopy overhanags the benches
  // in cms (added for more accurate sqft measurements)
  const CANOPY_OVERHANG_WIDTH = 25
  const CANOPY_OVERHANG_LENGTH = 30

  return zones.map(zone => {
    const { side1, side3 } = zone.sides
    const x = Math.abs(side1.x - side3.x)
    const y = Math.abs(side1.y - side3.y)

    let length; let
      width

    if (x > y) {
      length = cmsToFeet(x + CANOPY_OVERHANG_LENGTH)
      width = cmsToFeet(y + CANOPY_OVERHANG_WIDTH)
    } else {
      length = cmsToFeet(x + CANOPY_OVERHANG_WIDTH)
      width = cmsToFeet(y + CANOPY_OVERHANG_LENGTH)
    }
    const area = Math.round(length * width)
    return { zoneId: zone.zoneId, sqft: area }
  })
}

export const calculateSqftFromAST = ast => {
  const zones = Object.values(ast.zones).map(zone => {
    const zoneId = zone.uuid
    const pathObj = zone?.svgson?.children?.find(child => child.name === 'path')
    const coOrds = pathObj?.attributes?.d.split(' ')
    return getZoneCoOrds({ zoneId, coOrds })
  })

  const zoneSqft = getSqftForZones(zones).filter(zone => !!zone.zoneId)
  return zoneSqft
}
