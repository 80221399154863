export default theme => ({
  facilityRow: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.meter.background.two,
    },
  },
  deactiveFacilityRow: {
    cursor: 'default',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  tableFooter: {
    marginTop: theme.spacing(2)
  },
  filters: {
    paddingRight: '1rem',
    [theme.breakpoints.down(1914)]: {
      paddingBottom: '1rem',
    },
    [theme.breakpoints.down(1658)]: {
      paddingBottom: '2.2rem',
    },
    [theme.breakpoints.down(1280)]: {
      paddingBottom: '1rem',
    },
  },
  selectField: {
    [theme.breakpoints.down(1658)]: {
      marginBottom: '-0.7rem !important',
    },
    [theme.breakpoints.down(1280)]: {
      marginBottom: '0.2rem !important',
    },
  },
  searchField: {
    paddingBottom: '1.2rem !important',
    paddingRight: '0.7rem !important',
    [theme.breakpoints.down(1914)]: {
      paddingBottom: '0px !important',
      paddingRight: '4px !important',
    },
  },
  emptyTable: {
    textAlign: 'center'
  }
})
