import React from 'react'
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryLabel,
  VictoryGroup,
  VictoryTooltip,
} from 'victory'

import { useTheme } from '@material-ui/core/styles'
import {
  getTheme,
  getBarChartData,
  setLabelXPosition,
  setLabelYPosition
} from '../utils'

const Chart = props => {
  const {
    chart,
    availableMetrics,
    chartWidth,
    chartHeight,
  } = props

  const theme = useTheme()
  const getLabels = charts => charts.slice(0).reverse().map(graph => graph.label)

  return (
    <VictoryChart
      width={chartWidth}
      height={chartHeight}
      theme={getTheme(theme)}
      padding={{ top: 30, bottom: 50, left: 30, right: 40 }}
      domainPadding={{ x: 12 }}
    >
      <VictoryAxis
        tickCount={availableMetrics.length}
        tickLabelComponent={(
          <VictoryLabel
            dy={() => setLabelYPosition(availableMetrics.length)}
            dx={() => setLabelXPosition(availableMetrics.length)}
            angle={-30}
            textAnchor="end"
          />
        )}
      />
      <VictoryAxis
        dependentAxis
        orientation="right"
        offsetX={40}
        style={{
          ticks: { stroke: '#fff', size: 3 }
        }}
      />
      <VictoryGroup>
        {chart.filter(metric => availableMetrics
          .includes(metric.label)).slice(0).reverse().map(graph => {
          const color = graph.color
          const barData = getBarChartData(graph)
          return (
            <VictoryBar
              key={graph.label}
              barWidth={
                Math.floor((chartWidth / getLabels(chart).length / 2)) / barData.length
              }
              style={{ data: { fill: color } }}
              data={barData}
              labelComponent={(
                <VictoryTooltip
                  theme={getTheme(theme)}
                />
              )}
              events={[{
                target: 'data',
                eventHandlers: {
                  onMouseOver: () => [
                    { target: 'data', mutation: () => ({ style: { fill: 'white', width: 30 } }) },
                    { target: 'labels', mutation: () => ({ active: true }) }
                  ],
                  onMouseOut: () => [
                    { target: 'data', mutation: () => {} },
                    { target: 'labels', mutation: () => ({ active: false }) }
                  ]
                }
              }]}
            />
          )
        })}
      </VictoryGroup>
    </VictoryChart>
  )
}
export default Chart
