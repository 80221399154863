import React from 'react'

import classNames from 'clsx'
import memoizeOne from 'memoize-one'
import PropTypes from 'prop-types'
import { omit } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import addLogger from '~/Lib/Logging'
import { atLeastOneRequired } from '~/Lib/PropTypes'
import CloseButton from '~/UI/Shared/CloseButton'

const omitProps = [
  'content',
  'actions',
  'title',
  'closeButton',
  'classes',
  'dialog',
  'doOpenDialog',
  'doCloseDialog',
  'dense',
  'isMobile',
  'splitActions',
]

const getMuiProps = memoizeOne(omit(omitProps))
const getActionsClasses = memoizeOne(({
  actions: root,
  actionsSpacing: spacing,
}) => ({ root, spacing }))
const getContentClasses = memoizeOne((classes, { actions, dense }) => ({
  root: classNames({
    [classes.content]: true,
    scroller: true,
    [classes.dense]: dense,
    [classes.noActions]: !actions
  }),
}))

const getTitleClasses = memoizeOne((classes, { dense }) => ({
  root: classNames({
    [classes.title]: true,
    [classes.dense]: dense
  })
}))

const styles = theme => ({
  root: {
    overflow: 'hidden'
  },
  actions: {},
  actionsSpacing: {},
  content: {
    padding: theme.spacing(1, 5.125, 1, 3),
    marginRight: -17,
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&$noActions': {
      paddingBottom: theme.spacing(2.5),
    }
  },
  noActions: {},
  title: {
    '& h2': {
      textAlign: 'left',
    }
  },
  dense: {
    padding: '8px 4px',
  },
  actionsSplit: {
    justifyContent: 'space-between',
  }
})

const displayName = 'Dialog'

const useStyles = makeStyles(styles, { name: displayName })

export class DialogComponent extends React.PureComponent {
  static displayName = displayName

  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    actions: PropTypes.node,
    dense: PropTypes.bool,
    doOpenDialog: PropTypes.func.isRequired,
    doCloseDialog: PropTypes.func.isRequired,
    ...atLeastOneRequired({
      content: PropTypes.node,
      title: PropTypes.node,
    }),
  }

  static defaultProps = {
    actions: null,
    dense: false,
  }

  componentWillUnmount() {
    this.props.doCloseDialog()
  }

  get dialogProps() {
    const muiProps = getMuiProps(this.props)
    const onEntering = (...args) => {
      this.props.doOpenDialog()
      if (muiProps.onEntering) muiProps.onEntering(...args)
    }
    const onExiting = (...args) => {
      this.props.doCloseDialog()
      if (muiProps.onExiting) muiProps.onExiting(...args)
    }
    return { ...muiProps, onEntering, onExiting }
  }

  render() {
    const {
      classes,
      content,
      actions,
      title,
      closeButton,
      isMobile,
      splitActions,
      onClose,
    } = this.props

    return (
      <Dialog
        classes={{ paper: classes.root }}
        data-testid="dialog"
        fullScreen={!!isMobile}
        {...this.dialogProps}
        container={() => document.getElementById('modalRoot')}
      >
        {title ? (
          <DialogTitle
            classes={getTitleClasses(classes, this.props)}
            data-testid="dialog-title"
            disableTypography={typeof title !== 'string' && title?.type !== Symbol.for('react.fragment')}
          >
            {title}
          </DialogTitle>
        ) : null}
        <DialogContent
          classes={getContentClasses(classes, this.props)}
          data-testid="dialog-content"
        >
          {content}
        </DialogContent>
        {actions ? (
          <DialogActions
            data-testid="dialog-actions"
            classes={getActionsClasses(classes)}
            className={splitActions ? classes.actionsSplit : undefined}
          >
            {actions}
          </DialogActions>
        ) : null}
        {(isMobile || closeButton) && onClose ? <CloseButton onClose={onClose} /> : null}
      </Dialog>
    )
  }
}

addLogger(DialogComponent)

const Wrapped = props => {
  const classes = useStyles(props)
  const connectedProps = useConnect(
    'doOpenDialog',
    'doCloseDialog',
    // 'selectDialog',
    'selectIsMobile'
  )

  return <DialogComponent {...connectedProps} {...props} classes={classes} />
}

Wrapped.displayName = `ConnectedAndStyled(${displayName})`

export default React.memo(Wrapped)
