import memoizeOne from 'memoize-one'
import PropTypes from 'prop-types'
import {
  both,
  equals,
  identity,
  map,
  nthArg,
  pipe,
  prop,
  uniqBy,
} from 'ramda'

import { scaleLinear, scaleTime } from 'd3-scale'
import { createSelector } from 'reselect'

import { DEFAULT_TIMEFRAME, TIMEFRAME_PRESETS } from '~/Device/constants'
import createLogger from '~/Lib/Logging'
import {
  createShallowEqualsSelector,
  defer,
  downsampleToMax,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  getDateTime,
  getId,
  memoize,
} from '~/Lib/Utils'

export const CHART_ID = 'room-dashboard-chart-container'

export const GRAPH_MAX_POINTS = 500
export const SIDEBAR_WIDTH = 360
export const GRAPH_X_AXIS_CLASSNAME = 'room-graph-x-axis-line'

export const CHART_PADDING = {
  top: 24,
  right: 0,
  bottom: 36,
}
export const Y_AXIS_WIDTH = 51

const logger = createLogger('Chart#utils')

export const dataTypeKeyShape = PropTypes.shape({
  dataTypeKey: PropTypes.string
})

const get2ndArg = nthArg(1)
const roomProp = prop('room')

export const setChartNotLoading = (id = CHART_ID) => {
  const chartContainer = document.getElementById(id)
  if (chartContainer && 'loading' in chartContainer.dataset) {
    delete chartContainer.dataset.loading
  }
}

export const setChartLoading = (id = CHART_ID) => {
  const chartContainer = document.getElementById(id)
  if (chartContainer) {
    chartContainer.dataset.loading = true
    defer()
  }
}

const dateFormatter = date => (date ? getDateTime(date).toUTC().toISO() : date)

export const selectFrom = createShallowEqualsSelector(
  prop('manualFrom'),
  prop('chartTimeframe'),
  prop('selectedHarvest'),
  (manualFrom, tf = DEFAULT_TIMEFRAME, selectedHarvest = null) => {
    let from
    if (manualFrom) {
      from = getDateTime(manualFrom)
    } else if (tf === 'selectedHarvest' && selectedHarvest) {
      from = getDateTime(selectedHarvest.startDate)
    } else {
      const timeframe = TIMEFRAME_PRESETS[tf] || TIMEFRAME_PRESETS[DEFAULT_TIMEFRAME]
      from = getDateTime('now').minus(timeframe).startOf(timeframe.days ? 'day' : 'hour')
    }
    return from
  }
)

export const selectTo = createShallowEqualsSelector(
  prop('manualTo'),
  prop('chartTimeframe'),
  prop('selectedHarvest'),
  (manualTo, tf, selectedHarvest) => {
    let to
    if (manualTo) {
      to = getDateTime(manualTo)
    } else if (tf === 'selectedHarvest' && selectedHarvest) {
      to = getDateTime(selectedHarvest.endDate || selectedHarvest.harvestDate || 'now')
    }
    return to
  }
)

const selectChartFrom = createSelector(
  selectFrom,
  dateFormatter,
)
const selectChartTo = createSelector(
  selectTo,
  both(identity, dateFormatter),
)

export const selectChartParams = createSelector(
  roomProp,
  selectChartFrom,
  selectChartTo,
  pipe(
    roomProp,
    prop('zones'),
    map(getId)
  ),
  prop('individualSensors'),
  (room, start, end, zones, individualSensors) => (room
    ? {
      start,
      end,
      zones,
      grouping: individualSensors ? 'DEVICE' : undefined,
    }
    : undefined)
)
// Params: props: Object, context: Object
export const selectChartShouldUpdate = createSelector(
  prop('inflight'),
  prop('params'),
  pipe(
    get2ndArg,
    selectChartParams
  ),
  (inflight, prevParams, nextParams) => {
    const equal = equals(prevParams, nextParams)
    return !inflight && !equal
  }
)

const getGraphs = memoizeOne(({ chartData }) => chartData?.graphs ?? EMPTY_ARRAY)
const uniqueByUnit = uniqBy(prop('unit'))
export const getYAxes = createSelector(
  getGraphs,
  pipe(uniqueByUnit, map(({ dataType: key, unit }) => ({ key, unit })))
)
export const getXOffset = createShallowEqualsSelector(
  getGraphs,
  prop('dataTypes'),
  prop('graphCount'),
  prop('showYAxis'),
  (
    chartGraphs,
    dataTypes,
    graphCount,
    showYAxis,
  ) => {
    if (graphCount) return graphCount * 50

    let offset = 0
    const graphs = uniqueByUnit(chartGraphs ?? EMPTY_ARRAY)
    const axisCount = graphs.length
    if (showYAxis && axisCount) {
      offset = (axisCount || 1) * 50
    }

    return offset
  }
)

const breakpointPadding = {
  xs: {
    top: 8,
    right: 0
  },
  sm: {
    right: 0,
  }
}
export const getPaddingForChart = memoizeOne(props => {
  const defaultLeft = getXOffset(props)

  return {
    ...CHART_PADDING,
    left: defaultLeft,
    ...breakpointPadding[props.width || props.breakpoint]
  }
})

export const getXDomain = memoizeOne(chartData => {
  if (chartData?.range == null) return [0, 0]
  const {
    range: { start, end },
  } = chartData
  return [new Date(start), new Date(end)]
})

const xScaleFake = () => 0
export const getXScale = memoizeOne((props, bbox) => {
  if (!('range' in props.chartData) || !bbox.width) return xScaleFake
  const { left, right } = getPaddingForChart(props)

  let { width } = bbox
  width -= (right || 0)
  if (props.individualGraphs || props.journalOpen) {
    width -= SIDEBAR_WIDTH
  }

  return scaleTime(getXDomain(props.chartData), [left, width])
})

export const getYScale = memoizeOne((props, bbox, customHeight) => {
  if (!bbox.width) return xScaleFake
  const { top, bottom } = getPaddingForChart(props)
  const { height: bboxHeight } = bbox
  const height = (customHeight || bboxHeight) - bottom
  return scaleLinear([height, top])
})

export const boundsPadder = graphBounds => Object.entries(graphBounds).reduce((acc, [key, bounds], index) => {
  const { min: originalMin, max: originalMax } = bounds
  const isEven = index % 2 === 0
  let padding = (originalMax - originalMin) * 0.12
  let offset = 0
  if ((originalMax - originalMin) < (originalMax / 10) || originalMax === originalMin) {
    padding = originalMax / 5
    offset = (index + 1) * (originalMax / 10)
  }

  padding = Math.abs(padding)
  offset = Math.abs(offset)

  const clamp = ({ min, max }) => ({
    min: originalMin >= 0 ? Math.max(0, min) : min,
    max: originalMax < 0 ? Math.min(0, max) : max,
  })

  return {
    ...acc,
    [key]: clamp({
      min: isEven ? originalMin - padding : (originalMin - padding / 2) - offset,
      max: isEven ? (originalMax + padding / 2) + offset : originalMax + padding,
    })
  }
}, EMPTY_OBJECT)

export const getBounds = memoizeOne(props => {
  const {
    chartData: { unitBounds },
  } = props
  const graphBounds = unitBounds ?? EMPTY_OBJECT

  if (graphBounds === EMPTY_OBJECT) {
    return graphBounds
  }

  return boundsPadder(graphBounds)
})

export const getNormalized = (value, { min, max }) => {
  if (value == null) {
    return undefined
  }
  if (max - min) {
    return (value - min) / (max - min)
  }
  return Number.isNaN(value) ? null : value
}

export const getDenormalized = (normalized, { min, max }) => {
  if (max - min) {
    return (normalized * (max - min)) + min
  }
  return normalized
}

export const getLineData = memoize(
  (data, bounds, maxPoints = GRAPH_MAX_POINTS) => {
    let lineData = data
    if (lineData.length > maxPoints * 1.25) {
      lineData = downsampleToMax(lineData, maxPoints)
      if (lineData.length < data.length) {
        logger(`reducing data points original: ${
          data.length
        }, resampled:${
          lineData.length
        }, maxPoints=${
          maxPoints
        }`)
      }
    }
    const result = lineData.map(({ x, y, _y = getNormalized(y, bounds) }) => ({
      _x: new Date(x),
      _y,
      _y0: _y === null ? _y : bounds.min,
    }))

    return result
  }
)

const preferredDatatypes = {
  high: ['pore_ec', 'soil_moist'],
  low: ['vpd', 'rel_hum', 'air_temp'],
}
export const getDefaultDataTypes = memoizeOne(roomDataTypes => {
  if (preferredDatatypes.high.some(type => roomDataTypes.includes(type))) {
    return preferredDatatypes.high
  }
  if (preferredDatatypes.low.some(type => roomDataTypes.includes(type))) {
    return preferredDatatypes.low
  }
  return roomDataTypes.length > 3 ? roomDataTypes.slice(0, 2) : roomDataTypes
})
