import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

const OTAP_STATUS_ID_UPDATED = 'OTAP_STATUS_ID_UPDATED'

const additionalState = {
  requestId: undefined
}

const otapStatusBundle = createAsyncResourceBundle({
  name: 'otapStatus',
  actionBaseType: 'OTAP_STATUS',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const requestId = store.selectOtapStatusId()
    return apiFetch(`/firmware/status/${requestId}/`)
  }
})

export default {
  ...otapStatusBundle,
  reducer: reduceReducers(otapStatusBundle.reducer, (state, action) => {
    switch (action.type) {
      case OTAP_STATUS_ID_UPDATED:
        return { ...state, requestId: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doOtapStatusSetRequest: requestId => ({ dispatch, store }) => {
    if (requestId !== store.selectOtapStatusId()) {
      store.doClearOtapStatus()
    }
    dispatch({ type: OTAP_STATUS_ID_UPDATED, payload: requestId })
    store.doMarkOtapStatusAsOutdated()
  },
  reactOtapStatusFetch: createSelector(
    'selectAuth',
    'selectOtapStatusShouldUpdate',
    'selectOtapStatusId',
    'selectRouteInfo',
    ({ authenticated }, shouldUpdate, requestId, { url }) => {
      if (authenticated && shouldUpdate && requestId && url.startsWith('/firmware/logs/')) {
        return { actionCreator: 'doFetchOtapStatus' }
      }
      return undefined
    }
  ),
  selectOtapStatusId: createSelector(
    'selectOtapStatusRaw',
    ({ requestId }) => requestId
  ),
}
