export const DeviceChartStyles = () => ({
  chartRoot: {
    display: 'grid',
    height: '100%',
    gridTemplateAreas: [
      '"mainChart"',
      '"brushChart"',
      '"chartActions"',
    ].join('\n'),
    gridTemplateRows: '1fr 100px 75px',
  },
  mainChart: {
    display: 'flex',
    gridArea: 'mainChart',
  },
  brushChart: {
    gridArea: 'brushChart',
  },
  chartActions: {
    alignSelf: 'flex-start',
    gridArea: 'chartActions',
    display: 'grid',
    height: '100%',
    gridTemplateAreas: [
      '". duration interval"',
    ].join('\n'),
    gridTemplateColumns: '1fr 10vw 10vw',
    gridColumnGap: '10px'
  },
  interval: {
    gridArea: 'interval',
  },
  duration: {
    gridArea: 'duration',
  },
})
