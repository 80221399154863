import React, { Fragment, useEffect, useState } from 'react'
import { Field, FieldArray, useFormikContext } from 'formik'
import { useConnect } from 'redux-bundler-hook'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'clsx'
import * as yup from 'yup'

import { timezones } from '~/Lib/Timezones'
import { EMPTY_OBJECT } from '~/Lib/Utils'
import { FormDialog, SelectField, TextField } from '~/UI/Shared/Form/FormDialog'
import { LoggerStyles as styles } from './styles'

const useStyles = makeStyles(styles)

const LoggerPortsForm = ({ classes, models, facilities, defaultDepths }) => {
  const { values: { facility, ports } } = useFormikContext()

  const modelOptions = models
    .filter(m => m.decanumber)
    .map(m => ({
      label: m.name,
      value: m.id,
    }))

  const depthOptions = defaultDepths.map(d => ({ label: `${d}"`, value: d }))
  depthOptions.unshift({ key: null, label: 'None' })

  const isOutdoor = facilities[facility]?.outdoor
  const outdoorLayout = isOutdoor
    ? classes.portSelectField
    : classes.largeSelectField

  return (
    <FieldArray name="ports">
      <div className={classes.fieldContainer}>
        <Typography variant="subtitle1" color="primary">
          Configure Ports
        </Typography>
        {isOutdoor && (
          <Typography
            variant="subtitle1"
            color="primary"
            className={classes.soilHeading}
          >
            Soil Depth
          </Typography>
        )}
        {ports.map((port, idx) => {
          const handlePortName = i => (
            port?.device?.id
              ? `ports[${i}].device.model`
              : `ports[${i}].assignedModel`
          )

          const handlePortLabel = i => (
            port?.device?.id
              ? port.device.serialNumber
              : `Port ${i + 1}`
          )

          return (
            <Fragment key={port.number}>
              <Field
                className={outdoorLayout}
                component={SelectField}
                label={handlePortLabel(idx)}
                name={handlePortName(idx)}
                options={modelOptions}
                disabled={port?.device?.id >= 0}
              />
              {isOutdoor && (
                <Field
                  key={`ports_${facility}`}
                  className={classes.depthSelectField}
                  component={SelectField}
                  name={`ports[${idx}].depth`}
                  label="Depth"
                  options={depthOptions}
                />
              )}
            </Fragment>
          )
        })}
      </div>
    </FieldArray>
  )
}

const AssignLoggerForm = ({
  scopeToFacility,
  classes,
  facilities,
  rooms,
  zones
}) => {
  const { dirty, setFieldValue, values } = useFormikContext()

  useEffect(() => {
    if (dirty) {
      setFieldValue('room', '')
      setFieldValue('zone', '')
    }
  }, [values.facility])

  const getFacilityOptions = () => (
    Object.values(facilities).map(fac => ({ label: fac.name, value: fac.id }))
  )

  const getRoomOptions = () => (
    Object.values(rooms).filter(_room => _room.facility === values.facility)
      .map(r => ({ label: r.name, value: r.id }))
  )

  const getZoneOptions = () => (
    Object.values(zones).filter(zone => zone.room === values.room)
      .map(z => ({ label: z.name, value: z.id }))
  )

  const handleFacilityWide = () => {
    if (!values.assigned) {
      setFieldValue('room', '')
      setFieldValue('zone', '')
    }
    setFieldValue('assigned', !values.assigned)
  }

  return (
    <div style={{ marginTop: '40px' }}>
      <div className={classes.fieldContainer}>
        <Typography variant="subtitle1" color="primary">
          Assign Logger
        </Typography>
        <FormControlLabel
          control={(
            <Checkbox
              name="assigned"
              checked={values.assigned}
              onChange={handleFacilityWide}
              color="primary"
            />
        )}
          label={(
            <Typography variant="subtitle2">Assign as a facility wide device</Typography>
        )}
        />
        <Field
          className={classes.facilitySelect}
          disabled={scopeToFacility}
          component={SelectField}
          label="Facility"
          name="facility"
          options={getFacilityOptions()}
        />
        <Field
          className={classes.smallSelectField}
          component={SelectField}
          key={`rooms_${values.facility}_${values.assigned}`}
          label="Room"
          name="room"
          options={getRoomOptions()}
          disabled={values.assigned}
        />
        <Field
          className={classes.smallSelectField}
          component={SelectField}
          key={`zones_${values.facility}_${values.assigned}`}
          label="Zone"
          name="zone"
          options={getZoneOptions()}
          disabled={getZoneOptions().length === 0 || values.assigned}
        />
      </div>
    </div>
  )
}

const ConfigureLoggerForm = ({
  classes,
  modelChoices,
  setShowPorts
}) => {
  const { values, setFieldValue, initialValues } = useFormikContext()

  useEffect(() => {
    const isEm50G = values?.model === 'Em50G'
    setShowPorts(!isEm50G)
    setFieldValue('ports', isEm50G ? [] : initialValues?.ports)
  }, [values.model])

  return (
    <>
      <Typography variant="subtitle1" color="primary">
        Configure Logger
      </Typography>
      <div className={classes.fieldContainer}>
        <Field
          className={classes.smallField}
          component={TextField}
          label="Name"
          name="name"
        />
        <Field
          className={classes.smallField}
          component={TextField}
          label="Password"
          name="password"
        />
        <Field
          component={TextField}
          label="Serial Number"
          name="serialNumber"
          className={classes.smallField}
        />
        <Field
          component={TextField}
          label="MRID"
          name="mrid"
          type="number"
          className={classes.smallField}
        />
        <Field
          component={SelectField}
          label="Timezone"
          name="timezone"
          options={timezones}
          className={classes.smallSelectField}
        />
        <Field
          component={SelectField}
          label="Model"
          name="model"
          options={modelChoices}
          className={classes.smallSelectField}
        />
      </div>
    </>
  )
}

const LoggerForm = props => {
  const classes = useStyles()
  const { instance, scopeToFacility } = props
  const [showPorts, setShowPorts] = useState(instance?.model !== 'Em50G')

  const {
    me,
    rooms,
    zones,
    facilities,
    currentFacilityId,
  } = useConnect(
    'selectMe',
    'selectRooms',
    'selectZones',
    'selectFacilities',
    'selectCurrentFacilityId',
  )

  const modelChoices = [
    { label: 'ZL6', value: 'ZL6' },
    { label: 'Em50G', value: 'Em50G' },
  ]

  const initialValues = {
    name: '',
    room: '',
    zone: '',
    assigned: false,
    facility: '',
    password: '',
    ports: [],
    serialNumber: '',
    timezone: timezones[0].value,
    model: modelChoices[0].value,
    mrid: 0,
  }

  const assignEntityValues = (entities, attr) => {
    const entity = Object.values(entities).find(ent => ent.id === instance[attr])
    initialValues[attr] = entity && { label: entity.name, value: entity.id }
  }

  if (instance.id) {
    Object.keys(initialValues).forEach(field => {
      if (field.includes('facility')) assignEntityValues(facilities, 'facility')
      if (field.includes('room')) assignEntityValues(rooms, 'room')
      initialValues[field] = instance[field] ?? ''
    })
  } else {
    initialValues.facility = currentFacilityId
  }

  initialValues.ports = instance?.ports?.length
    ? instance.ports
    : [...Array(6).keys()].map(() => EMPTY_OBJECT)

  const validationSchema = yup.object().shape({
    name: yup.string(),
    password: yup.string().required(),
    serialNumber: yup.string().required(),
    mrid: yup.number().required('Please enter a number'),
    timezone: yup.string().required('Please select a timezone'),
    model: yup.string().required('Please select a model'),
    assigned: yup.boolean(),
    room: yup.number().when('assigned', {
      is: true,
      then: yup.number(),
      otherwise: yup.number().required('Please select a room'),
    }),
    zone: yup.number(),
    facility: yup.number(),
  })

  return (
    <FormDialog
      maxFormWidth={showPorts ? 'lg' : 'sm'}
      formatInstance={obj => obj.serialNumber}
      initialValues={initialValues}
      label={instance.name ?? instance.serialNumber ?? 'Logger'}
      softDeletes
      validationSchema={validationSchema}
      {...props}
    >

      <div className={classes.form}>
        <div
          className={classNames({
            [classes.formSectionWithPorts]: showPorts,
            [classes.formSectionWithoutPorts]: !showPorts,
          })}
        >
          <ConfigureLoggerForm
            classes={classes}
            modelChoices={modelChoices}
            setShowPorts={setShowPorts}
          />
          <AssignLoggerForm
            scopeToFacility={scopeToFacility}
            facilities={facilities}
            classes={classes}
            rooms={rooms}
            zones={zones}
          />
        </div>

        {showPorts && (
          <div className={classes.formSectionWithPorts}>
            <LoggerPortsForm
              classes={classes}
              models={me.models}
              facilities={facilities}
              defaultDepths={me.defaultDepths}
            />
          </div>
        )}
      </div>

    </FormDialog>
  )
}

LoggerForm.defaultProps = {
  instance: {},
}

export default LoggerForm
