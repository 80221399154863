import React from 'react'

import { INITIAL_VALUE, ReactSVGPanZoom, TOOL_NONE } from 'react-svg-pan-zoom'
import { ReactSvgPanZoomLoader, SvgLoaderSelectElement } from 'react-svg-pan-zoom-loader'
import { applyToPoint, fromObject, inverse } from 'transformation-matrix'

import ViewerStyles from '../styles/viewer'

const getSVGPoint = (value, viewerX, viewerY) => {
  const matrix = fromObject(value)
  const inverseMatrix = inverse(matrix)
  return applyToPoint(inverseMatrix, { x: viewerX, y: viewerY })
}

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

class Viewer extends React.PureComponent {
  state = {
    tool: TOOL_NONE,
    value: INITIAL_VALUE,
    showZones: false,
    intentionallyShowZones: false,
    svgProxies: null,
    shortcutsAreBound: false,
    minZoomLevel: null,
    activeRooms: [],
    activeZones: [],
    deactiveRooms: [],
    deactiveZones: [],
  }

  Viewer = null

  componentDidMount() {
    this.Viewer.fitToViewer('center')
  }

  componentDidUpdate(prevProps) {
    const svgPropsChanged = prevProps.svgProps !== this.props.svgProps
    const dictNoProxies = this.props.groundsDictionary && this.state.svgProxies === null
    const dictChanged = prevProps.groundsDictionary && prevProps.groundsDictionary !== this.props.groundsDictionary

    if (dictNoProxies || dictChanged) {
      this.createSVGProxies()
    } else if (svgPropsChanged) {
      this.updateSVGProxies()
    }
  }

  componentWillUnmount() {
    this.unbindShortcutKeys()
  }

  onKeyup = () => {
    this.changeTool('none')
  }

  onKeydown = e => {
    if (e.keyCode === 32 || e.key === 'Spacebar' || e.key === ' ') {
      this.changeTool('pan')
    }

    if (e.key === '+' || e.key === '-') {
      this.changeTool('zoom-in')
    }

    if (e.key === '-') {
      this.changeTool('zoom-out')
    }

    if (e.key === '0') {
      this.Viewer.fitToViewer('center')
    }

    if (e.key === 'Escape') {
      this.doResetMap()
      this.props.onClickAway()
    }
  }

  onZoom(e) {
    const scaleFactor = e.a

    if (scaleFactor > 1.5 || this.state.intentionallyShowZones) {
      this.showZones(true)
    } else {
      this.showZones(false)
      this.setState({
        intentionallyShowZones: false
      })
    }
  }

  changeValue(nextValue) {
    this.setState(({ minZoomLevel }) => ({
      value: nextValue,
      minZoomLevel: minZoomLevel || nextValue.a,
    }))
  }

  doActivateArea(key) {
    let keyType

    if (key && key.includes('-')) {
      keyType = `deactive${capitalize(key.split('-').shift())}s`
      document.querySelector(`#${key}`)?.classList?.remove?.('deactive')
      document.querySelector(`#${key}`)?.classList?.add?.('active')
    }

    this.setState(prevState => ({
      [keyType]: [...new Set(prevState[keyType]).add(key)]
    }), () => {
      this.props.onActivateRoom?.(key, this.state[keyType])
    })
  }

  doDeactivateArea(key) {
    let keyType

    if (key && key.includes('-')) {
      keyType = `deactive${capitalize(key.split('-').shift())}s`
      document.querySelector(`#${key}`)?.classList?.remove?.('active')
      document.querySelector(`#${key}`)?.classList?.add?.('deactive')
    }

    this.setState(prevState => ({
      [keyType]: [...new Set(prevState[keyType]).add(key)]
    }), () => {
      this.props.onDeactivateRoom?.(key, this.state[keyType])
    })
  }

  activateRoomsAndZones(areaId) {
    const activeZones = this.props.groundsDictionary[areaId].zones
    const deactiveZones = Object.keys(this.props.groundsAst.zones).filter(key => !activeZones.includes(key))
    const deactiveRooms = Object.keys(this.props.groundsAst.rooms).filter(key => key !== areaId)

    window.requestAnimationFrame(() => {
      activeZones.forEach(key => {
        this.doActivateArea(key)
      })

      const deactiveRoomsAndZones = [...deactiveZones, ...deactiveRooms]
      deactiveRoomsAndZones.forEach(key => {
        this.doDeactivateArea(key)
      })

      document.querySelector(`#${areaId}`)?.classList?.remove?.('deactive')
      document.querySelector(`#${areaId}`)?.classList?.add?.('active')
    })

    this.setState({
      activeRooms: [areaId],
      deactiveRooms,
      activeZones,
      deactiveZones
    })
  }

  doResetAllRoomsAndZones() {
    window.requestAnimationFrame(() => {
      this.setState({
        activeRooms: [],
        activeZones: [],
        deactiveRooms: [],
        deactiveZones: []
      })

      const roomAndZoneKeys = [...Object.keys(this.props.groundsAst.rooms), ...Object.keys(this.props.groundsAst.zones)]

      roomAndZoneKeys.forEach(areaId => {
        const el = document.querySelector(`#${areaId}`)
        if (!el) return
        el.classList.remove('deactive')
        el.classList.remove('active')
      })
    })
  }

  zoomAnimation = (areaId, lastScale = undefined, increment = undefined) => {
    const currentValue = this.Viewer.getValue()
    const svgWidth = this.Viewer.ViewerDOM.clientWidth
    const svgHeight = this.Viewer.ViewerDOM.clientHeight
    const roomRect = document.getElementById(areaId).getBoundingClientRect()

    const svgRect = this.Viewer.ViewerDOM.getBoundingClientRect()
    const MODAL_WIDTH = this.props.modal.width
    const MODAL_HEIGHT = this.props.modal.height
    const PAD = this.props.modal.padding
    const targetWidth = svgWidth - (PAD * 3)
    const targetHeight = svgHeight - MODAL_HEIGHT
    const targetZoomWidth = (targetWidth * currentValue.a) / (roomRect.width + MODAL_WIDTH)
    const targetZoomHeight = (targetHeight * currentValue.a) / roomRect.height
    const finalTargetZoom = Math.min(targetZoomWidth, targetZoomHeight)

    const targetCenterX = (roomRect.x - svgRect.x) + ((roomRect.width + MODAL_WIDTH) / 2)
    const targetCenterY = (roomRect.y - svgRect.y) + (roomRect.height / 2)

    const areaOverallCenterXY = getSVGPoint(currentValue, targetCenterX, targetCenterY)

    let targetScaleFactor = this.state.minZoomLevel
    let incr = increment
    if (lastScale) {
      incr = (finalTargetZoom - currentValue.a) / 5
      targetScaleFactor = lastScale + (currentValue.a >= finalTargetZoom ? 0 : incr)
    }

    this.Viewer.setPointOnViewerCenter(areaOverallCenterXY.x, areaOverallCenterXY.y, targetScaleFactor)

    requestAnimationFrame(() => {
      if (Number(targetScaleFactor).toFixed(1) < Number(finalTargetZoom).toFixed(1)) {
        this.zoomAnimation(areaId, targetScaleFactor, incr)
        return
      }

      const newRoomRect = document.getElementById(areaId).getBoundingClientRect()
      this.setState({
        intentionallyShowZones: true
      }, () => {
        this.showZones(true)
        this.props.onSelect({
          areaId,
          ast: this.props.groundsAst,
          groundsDictionary: this.props.groundsDictionary,
          areaBoundClientRect: newRoomRect,
        })
      })

      this.activateRoomsAndZones(areaId)
    })
  }

  handleClick = event => {
    const {
      admin,
      groundsAst: ast,
      groundsDictionary,
      onClickAway,
      onSelect,
    } = this.props
    const { activeRooms, activeZones } = this.state

    const { attributes, classList } = event.originalEvent.target.closest('g')

    const activeRoom = activeRooms[0]
    const areaId = attributes?.areaid?.value
    const hasClickedRoom = areaId?.includes('room')
    const isActive = classList?.contains('active')

    if (!activeRoom && hasClickedRoom) {
      // Map is zoomed out; user has clicked a room
      if (ast.rooms[areaId]?.uuid || admin) {
        this.doResetMap()
        this.zoomAnimation(areaId)
      }
    } else if (activeRoom && isActive) {
      // Map is zoomed in; user has clicked within the selected room
      onSelect({ areaId, ast, activeZones, groundsDictionary })
    } else {
      // Any other clicked area is considered "clicking away"
      onClickAway()
    }
  }

  showZones(bool) {
    const zones = document.querySelectorAll('[id*="zone"]')
    const rooms = document.querySelectorAll('[id*="room"]')

    if (bool) {
      this.setState({
        showZones: true
      })

      zones.forEach(zone => {
        zone.classList.remove('hidden')
      })

      rooms.forEach(room => {
        const roomSvgWrapper = document.querySelector(`#${room.id}-svg-wrapper`)

        if (roomSvgWrapper) {
          roomSvgWrapper.style.display = 'none'
        }
      })
    } else {
      this.setState({ showZones: false })
      zones.forEach(zone => {
        zone.classList.add('hidden')
      })

      rooms.forEach(room => {
        const roomSvgWrapper = document.querySelector(`#${room.id}-svg-wrapper`)

        if (roomSvgWrapper) {
          roomSvgWrapper.style.display = 'block'
        }
      })
    }
  }

  updateSVGProxies() {
    if (this.props.groundsDictionary) {
      const svgProxies = Object.keys(this.props.svgProps).map(id => {
        const { [id]: passedProps } = this.props.svgProps || {}
        return (
          <SvgLoaderSelectElement
            key={id}
            selector={`#${id}`}
            {...passedProps}
          />
        )
      })
      this.setState({ svgProxies })
    }
  }

  createSVGProxies() {
    if (this.props.groundsDictionary) {
      this.updateSVGProxies()
      setTimeout(() => this.showZones(false), 50)
    }
  }

  doResetMap() {
    this.setState({
      intentionallyShowZones: false,
      showZones: false,
    }, () => {
      this.Viewer.fitToViewer('center')
    })
    this.doResetAllRoomsAndZones()
    this.props.onMapReset()
  }

  changeTool(nextTool) {
    this.setState({ tool: nextTool })
  }

  bindShortcutKeys() {
    if (!this.state.shortcutsAreBound) {
      this.setState({ shortcutsAreBound: true }, () => {
        document.addEventListener('keydown', this.onKeydown)
        document.addEventListener('keyup', this.onKeyup)
      })
    }
  }

  unbindShortcutKeys() {
    if (this.state.shortcutsAreBound) {
      this.setState({ shortcutsAreBound: false }, () => {
        document.removeEventListener('keydown', this.onKeydown)
        document.removeEventListener('keyup', this.onKeyup)
      })
    }
  }

  render() {
    const simpleProps = {
      customMiniature: () => null,
      customToolbar: () => null,
      onChangeTool: () => null,
      detectWheel: false,
      tool: TOOL_NONE,
    }

    if (this.props.admin) {
      // const allZones = Object.keys(this.props.groundsAst.zones)
      // const allRooms = Object.keys(this.props.groundsAst.rooms)
      const associatedRooms = this.props.associatedRooms
      const associatedZones = this.props.associatedZones
      // const availableRooms = allRooms.filter(room => !associatedRooms.includes(room))
      // const availableZones = allZones.filter(zone => !associatedZones.includes(zone))

      const associatedRoomsAndZones = [...associatedRooms, ...associatedZones]

      associatedRoomsAndZones.forEach(key => {
        this.doDeactivateArea(key)
      })

      this.setState({
        deactiveRooms: associatedRooms,
        deactiveZones: associatedZones
      })
    }

    return (
      <div
        onMouseOver={event => this.bindShortcutKeys(event)}
        onFocus={event => this.bindShortcutKeys(event)}
        onMouseLeave={event => this.unbindShortcutKeys(event)}
      >
        <ViewerStyles theme={this.props.theme} />
        <ReactSvgPanZoomLoader
          svgXML={this.props.floorplan}
          proxy={(this.state.svgProxies ? this.state.svgProxies : <></>)}
          render={content => (
            <ReactSVGPanZoom
              width={this.props.width}
              height={this.props.height}
              ref={Viewer => this.Viewer = Viewer}
              tool={this.state.tool}
              onChangeTool={tool => this.changeTool(tool)}
              value={this.state.value}
              onChangeValue={value => this.changeValue(value)}
              groundsAst={this.props.groundsAst}
              preventPanOutside={false}
              detectAutoPan={false}
              scaleFactorOnWheel={1.2}
              SVGBackground="rgba(0,0,0,0)"
              background="rgba(0,0,0,0)"
              miniatureProps={{ background: 'rgba(0,0,0, 0.1)', position: 'right', width: 160, height: 120 }}
              onZoom={e => { this.onZoom(e) }}
              onClick={event => {
                this.handleClick(event)
              }}
              {...simpleProps}
            >
              <svg
                width={this.props.groundsAst.floorplan['floorplan-0'].width}
                height={this.props.groundsAst.floorplan['floorplan-0'].height}
                id="ground-control-svg"
              >
                {content}
              </svg>
            </ReactSVGPanZoom>
          )}
        />
      </div>
    )
  }
}

export default Viewer
