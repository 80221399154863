import React from 'react'
import { Field } from 'formik'
import * as yup from 'yup'

import { Typography } from '@material-ui/core'
import { FormDialog, TextField, ToggleField } from '~/UI/Shared/Form/FormDialog'

const OrganizationForm = props => {
  const { instance, featureFlags } = props

  const initialValues = {
    name: '',
    clientId: '',
    networkAddress: '',
    networkChannel: '',
    flags: { ...featureFlags.reduce((obj, { key }) => ({ ...obj, [key]: false }), {}) }
  }

  if (instance.id) {
    initialValues.name = instance.name ?? ''
    initialValues.clientId = instance.clientId ?? ''
    initialValues.networkAddress = instance.networkAddress ?? ''
    initialValues.networkChannel = instance.networkChannel ?? ''
    if (instance?.flags) {
      Object.keys(instance.flags).forEach(flag => {
        initialValues.flags[flag] = true
      })
    }
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    clientId: yup.number(),
    networkAddress: yup.number(),
    networkChannel: yup.number(),
  })

  return (
    <FormDialog
      fullScreen
      softDeletes
      formatInstance={obj => obj.name}
      initialValues={initialValues}
      label="Organization"
      maxFormWidth="xl"
      validationSchema={validationSchema}
      {...props}
    >
      <section data-testid="org-form" style={{ columns: 4, columnGap: '1.5rem' }}>
        <Field component={TextField} label="Name" name="name" />
        <Field component={TextField} label="Client ID" name="clientId" type="number" />
        <Field component={TextField} label="Network Address" name="networkAddress" type="number" />
        <Field component={TextField} label="Network Channel" name="networkChannel" type="number" />
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBlockStart: '1rem' }}>
          <Typography variant="h6" align="left" color="primary">Feature Flags</Typography>
          {featureFlags.map(flag => (
            <div key={flag.key} style={{ breakInside: 'avoid-column', width: '100%' }}>
              <Field className="mar-t-xs" component={ToggleField} label={flag.key} name={`flags.${flag.key}`} />
              <Typography variant="body2"> {flag.description} </Typography>
            </div>
          ))}
        </div>
      </section>
    </FormDialog>
  )
}

OrganizationForm.defaultProps = {
  instance: {},
}

export default OrganizationForm
