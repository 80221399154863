import React from 'react'

import { Tooltip } from '@material-ui/core'

const TooltipComponent = props => (
  <Tooltip
    {...props}
    PopperProps={{
      modifiers: {
        hide: { enabled: false },
        preventOverflow: { enabled: false },
        offset: {
          enabled: true,
          fn: data => ({ ...data, styles: { zIndex: 1 } }),
        },
      },
    }}
  />
)

export default React.memo(TooltipComponent)
