import React from 'react'
import {
  CircularProgress,
  Grid,
} from '@material-ui/core'

export const arrayDifference = (arr1, arr2) => (
  arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)))
)

export const formatDate = date => {
  const year = date.getFullYear().toString().substr(-2)
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hr = date.getHours()
  let min = date.getMinutes()

  if (month < 10) { month = `0${month}` }
  if (day < 10) { day = `0${day}` }
  if (hr < 10) { hr = `0${hr}` }
  if (min < 10) { min = `0${min}` }

  return `${month}/${day}/${year} ${hr}:${min}`
}

const Loading = () => (
  <Grid item style={{ flexGrow: 1 }} className="text-center">
    <CircularProgress className="text-center mar-t-xl" />
  </Grid>
)

export default Loading
