import PropTypes from 'prop-types'
import { compose, evolve, pick, trim } from 'ramda'

import { validIdOrNil } from '~/Lib/Data'

const shapeConfig = {
  id: PropTypes.number,
  deviceName: PropTypes.string,
  serialNumber: PropTypes.string,
  room: PropTypes.number,
  zone: PropTypes.number,
  reassign: PropTypes.bool,
}
export const shape = PropTypes.shape(shapeConfig)

const transforms = {
  id: Number,
  deviceName: trim,
  serialNumber: trim,
  room: validIdOrNil,
  zone: validIdOrNil,
}
const transformer = evolve(transforms)
export const cleaner = compose(
  transformer,
  pick(Object.keys(shapeConfig))
)

export const prepareData = raw => {
  const cleanData = cleaner(raw)
  if (cleanData.deviceName.length === 0) {
    cleanData.deviceName = cleanData.serialNumber
  }
  return cleanData
}
