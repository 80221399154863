import React, { useState } from 'react'
import { useConnect } from 'redux-bundler-hook'
import moment from 'moment'

import {
  CircularProgress,
  Grid,
  IconButton,
  TableHead,
  TableFooter,
  TableBody,
  TableRow,
  TablePagination,
  Typography,
  Tooltip,
} from '@material-ui/core'

import {
  Check as CheckIcon,
  Clear as ClearIcon,
  ExpandMore as ExpandMoreIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  PhonelinkRing as PhonelinkRingIcon
} from '@material-ui/icons/'

import ListTable from '~/UI/Shared/ListTable'
import ListTableCell from '~/UI/Shared/ListTableCell'
import UserForm from '~/Users/form'
import { formatPhoneNumber } from '~/Lib/Data'
import { formatDateTime } from '~/Lib/Utils/datetime'
import MembershipForm from '~/Membership/form'
import { BlankCell, HeaderCell, MembershipCell, TransparentRow } from '~/Users/utils'

const UserList = props => {
  const {
    facility,
    facilities,
    userListPagination,
    isGuestStaff,
    memberships,
    userListRaw,
    userListIsLoading,
    doUserUpdate,
    doFetchUserMemberships,
    doSaveMembership,
    doDeleteMembership,
    doFacilityFetch,
    doUserListSetPage,
  } = useConnect(
    'selectFacility',
    'selectFacilities',
    'selectUserListPagination',
    'selectIsGuestStaff',
    'selectMemberships',
    'selectUserListRaw',
    'selectUserListIsLoading',
    'doUserUpdate',
    'doFetchUserMemberships',
    'doSaveMembership',
    'doDeleteMembership',
    'doFacilityFetch',
    'doUserListSetPage',
  )

  const {
    currentUserList,
    saveCreatedUser,
    createUserFormOpen,
    setCreateUserFormOpen,
  } = props

  const { isOutdated, isLoading } = userListRaw
  const [currentUser, setCurrentUser] = useState({})
  const [currentMembership, setCurrentMembership] = useState({})
  const [membershipDrawOpen, setMembershipDrawOpen] = useState({ user: null, isOpen: false })
  const [membershipFormOpen, setMembershipFormOpen] = useState(false)
  const [editUserFormFormOpen, setEditUserFormFormOpen] = useState(false)

  const openForm = (form, mship = null) => {
    if (!isGuestStaff) {
      if (form === 'user') setEditUserFormFormOpen(true)
      if (form === 'membership') {
        setMembershipFormOpen(true)
        doFacilityFetch(mship.facility)
        setCurrentMembership(mship)
      }
    }
  }

  const onChangePage = (event, page) => {
    doUserListSetPage(
      page === userListPagination.page
        ? userListPagination.page + 1
        : userListPagination.page - 1
    )
  }

  const handleMembershipToggle = (e, user) => {
    if (!memberships?.[user] || isOutdated) {
      doFetchUserMemberships(user)
    }
    e.stopPropagation()
    if (membershipDrawOpen.isOpen) {
      setMembershipDrawOpen({ user, isOpen: membershipDrawOpen.user !== user })
    } else {
      setMembershipDrawOpen({ user, isOpen: true })
    }
  }

  const renderToggleButton = user => {
    if (membershipDrawOpen.user) {
      return membershipDrawOpen.user === user && membershipDrawOpen.isOpen ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />
    }
    return <KeyboardArrowRightIcon />
  }

  const renderMemberships = userMemberships => (userMemberships?.length ? (
    <>
      <TransparentRow>
        <BlankCell colSpan={1} />
        <HeaderCell colSpan={1}>ID</HeaderCell>
        <HeaderCell colSpan={2}>Facility</HeaderCell>
        <HeaderCell colSpan={1}>Created</HeaderCell>
        <HeaderCell colSpan={3}>Active</HeaderCell>
        <HeaderCell colSpan={1}>Hidden</HeaderCell>
      </TransparentRow>
      {userMemberships.map(mship => (
        <TransparentRow
          key={mship.id}
          className={isGuestStaff ? 'default' : 'pointer'}
          onClick={() => openForm('membership', mship)}
        >
          <BlankCell colSpan={1} />
          <MembershipCell
            colSpan={1}
            style={{
              borderRadius: '6px 0px 0px 6px',
              width: '80px'
            }}
          >
            <Typography gutterBottom>
              {facilities[mship?.facility]?.id}
            </Typography>
          </MembershipCell>

          <MembershipCell colSpan={2}>
            <Typography gutterBottom>
              {facilities[mship?.facility]?.name}
            </Typography>
          </MembershipCell>

          <MembershipCell colSpan={1}>
            <Typography gutterBottom>
              {moment(mship.createdOn).fromNow()}
            </Typography>
          </MembershipCell>

          <MembershipCell colSpan={3}>
            <Typography gutterBottom>
              {mship.active ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
            </Typography>
          </MembershipCell>

          <MembershipCell colSpan={1}>
            <Typography gutterBottom>
              {mship.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </Typography>
          </MembershipCell>
        </TransparentRow>
      ))}
    </>
  ) : null)

  return (
    <Grid item style={{ flexGrow: 1 }}>
      {editUserFormFormOpen ? (
        <UserForm
          instance={currentUser}
          open={editUserFormFormOpen}
          onClose={() => setEditUserFormFormOpen(false)}
          onSave={doUserUpdate}
        />
      ) : null}
      {createUserFormOpen ? (
        <UserForm
          open={createUserFormOpen}
          onClose={() => setCreateUserFormOpen(false)}
          onSave={saveCreatedUser}
        />
      ) : null}
      <MembershipForm
        instance={currentMembership}
        onClose={() => setMembershipFormOpen(false)}
        onDelete={doDeleteMembership}
        onSave={doSaveMembership}
        open={membershipFormOpen}
        facilityTitle={facility?.name}
      />
      <ListTable>
        <TableHead>
          <TableRow>
            <ListTableCell>Memberships</ListTableCell>
            <ListTableCell>ID</ListTableCell>
            <ListTableCell>Name</ListTableCell>
            <ListTableCell>Phone</ListTableCell>
            <ListTableCell>Email</ListTableCell>
            <ListTableCell>Active</ListTableCell>
            <ListTableCell>Staff</ListTableCell>
            <ListTableCell>Guest Staff</ListTableCell>
            <ListTableCell>Solus Profile</ListTableCell>
            <ListTableCell>Last Seen</ListTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentUserList.map(user => {
            const lastSeen = user.lastSeen ? moment(user.lastSeen).fromNow() : 'Never'
            const lastSeenToolTip = user.lastSeen ? formatDateTime(user.lastSeen) : 'User has not logged in'
            const activeStatus = user.isActive ? <CheckIcon color="primary" /> : <ClearIcon color="error" />
            const staffStatus = user.isStaff ? <CheckIcon color="primary" /> : <ClearIcon color="error" />
            const guestStaffStatus = user.isGuestStaff ? <CheckIcon color="primary" /> : <ClearIcon color="error" />
            const userAndDrawOpen = membershipDrawOpen.isOpen && membershipDrawOpen.user === user.id
            const userMemberships = memberships?.[user.id]?.filter(mship => mship.user.id === user.id)

            return (
              <React.Fragment key={user.id}>
                <TableRow
                  className={isGuestStaff ? 'default' : 'pointer'}
                  onClick={() => {
                    setCurrentUser(user)
                    openForm('user')
                  }}
                >
                  <ListTableCell style={{ width: '80px' }}>
                    <IconButton
                      size="small"
                      onClick={e => handleMembershipToggle(e, user.id)}
                    >
                      {renderToggleButton(user.id)}
                    </IconButton>
                    {(membershipDrawOpen.user === user.id && isLoading) && (
                      <CircularProgress size={20} style={{ margin: '0 0 -5px 30px' }} />
                    )}
                  </ListTableCell>
                  <ListTableCell style={{ width: '50px' }}>
                    <Typography variant="body1" className="text-normal" noWrap>
                      {user.id}
                    </Typography>
                  </ListTableCell>
                  <ListTableCell style={{ width: '300px' }}>
                    <Typography variant="subtitle1" className="text-normal" noWrap>
                      {user.fullName}
                    </Typography>
                  </ListTableCell>
                  <ListTableCell>
                    <Typography variant="subtitle1" className="text-normal">
                      {formatPhoneNumber(user.phoneNumber)}
                    </Typography>
                  </ListTableCell>
                  <ListTableCell style={{ width: '400px' }}>
                    <Typography variant="subtitle1" className="text-normal" noWrap>
                      {user.email}
                    </Typography>
                  </ListTableCell>
                  <ListTableCell>
                    {activeStatus}
                  </ListTableCell>
                  <ListTableCell>
                    {staffStatus}
                  </ListTableCell>
                  <ListTableCell>
                    {guestStaffStatus}
                  </ListTableCell>
                  <ListTableCell>
                    {user.isSolusUser && <PhonelinkRingIcon color="primary" />}
                  </ListTableCell>
                  <ListTableCell>
                    <Tooltip title={lastSeenToolTip} placement="top-start">
                      <Typography
                        variant="subtitle1"
                        className="text-normal"
                        color="inherit"
                      >
                        {lastSeen}
                      </Typography>
                    </Tooltip>
                  </ListTableCell>
                </TableRow>
                {userAndDrawOpen && renderMemberships(userMemberships)}
              </React.Fragment>
            )
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={12}
              nextIconButtonProps={{ disabled: !userListPagination.next || userListIsLoading }}
              backIconButtonProps={{ disabled: !userListPagination.previous || userListIsLoading }}
              classes={{ spacer: 'hidden' }}
              count={userListPagination.count}
              rowsPerPage={userListPagination.pageSize}
              rowsPerPageOptions={[]}
              page={userListPagination.page - 1}
              onChangePage={onChangePage}
              labelDisplayedRows={() => `Page ${userListPagination.page} of ${userListPagination.numPages}`}
            />
          </TableRow>
        </TableFooter>
      </ListTable>
    </Grid>
  )
}

export default UserList
