import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import { updateUnlessNullorUndefined } from '~/Device/utils'
import { DEVICE_CHART_PARAMS_UPDATED } from './chart'
import { DEVICE_ID_UPDATED } from './list'

const DEVICE_EVENTS_SET_PAGE = 'DEVICE_EVENTS_SET_PAGE'
const DEVICE_EVENTS_SET_FILTER = 'DEVICE_EVENTS_SET_FILTER'
const DEVICE_EVENTS_KEYS_FETCH_STARTED = 'DEVICE_EVENTS_KEYS_FETCH_STARTED'
const DEVICE_EVENTS_KEYS_FETCH_FINISHED = 'DEVICE_EVENTS_KEYS_FETCH_FINISHED'
const DEVICE_EVENTS_KEYS_FETCH_FAILED = 'DEVICE_EVENTS_KEYS_FETCH_FAILED'
const DEVICE_IRRIGATION_ERRORS_FETCH_STARTED = 'DEVICE_IRRIGATION_ERRORS_FETCH_STARTED'
const DEVICE_IRRIGATION_ERRORS_FETCH_FINISHED = 'DEVICE_IRRIGATION_ERRORS_FETCH_FINISHED'
const DEVICE_IRRIGATION_ERRORS_FETCH_FAILED = 'DEVICE_IRRIGATION_ERRORS_FETCH_FAILED'

const additionalState = {
  currentPage: 1,
  filter: undefined,
  keyChoices: [],
}

const deviceEventsBundle = createAsyncResourceBundle({
  name: 'deviceEvents',
  actionBaseType: 'DEVICE_EVENTS',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const deviceId = store.selectCurrentDeviceId()
    const page = store.selectDeviceEventsPage()
    const filterValue = store.selectDeviceEventsFilter()
    const start = store.selectDeviceChartStartDate()
    const end = store.selectDeviceChartEndDate()

    const filter = {}

    if (filterValue === 'warningsOnly') {
      filter.warnings = true
    } else if (filterValue) {
      filter.key = [filterValue]
    }

    return apiFetch(`/devices/${deviceId}/events/`, {
      start,
      end,
      page,
      ...filter,
    })
  }
})

export default {
  ...deviceEventsBundle,
  reducer: reduceReducers(
    deviceEventsBundle.reducer,
    (state, action) => {
      switch (action.type) {
        case DEVICE_CHART_PARAMS_UPDATED:
          return { ...state, currentPage: 1 }
        case DEVICE_EVENTS_KEYS_FETCH_FINISHED:
          return { ...state, keyChoices: action.payload }
        case DEVICE_IRRIGATION_ERRORS_FETCH_FINISHED:
          return { ...state, irrigationErrors: action.payload }
        case DEVICE_EVENTS_SET_FILTER:
          return { ...state, currentPage: 1, filter: action.payload }
        case DEVICE_EVENTS_SET_PAGE:
          return { ...state, currentPage: action.payload }
        case DEVICE_ID_UPDATED:
          return { ...state, currentPage: 1, keyChoices: [] }
        default:
          if (!Object.keys(additionalState).every(key => key in state)) {
            return { ...additionalState, ...state }
          }
          return state
      }
    },
  ),
  doDeviceEventsSetFilter: filter => ({ store, dispatch }) => {
    const queryObj = store.selectQueryObject()
    const payload = updateUnlessNullorUndefined(queryObj, 'packetFilter', filter)
    dispatch({ type: DEVICE_EVENTS_SET_FILTER, payload: filter })
    store.doUpdateQuery(payload)
    store.doMarkDeviceEventsAsOutdated()
  },
  doDeviceEventsSetPage: page => ({ dispatch, store }) => {
    dispatch({ type: DEVICE_EVENTS_SET_PAGE, payload: page })
    store.doMarkDeviceEventsAsOutdated()
  },
  doFetchDeviceEventsKeys: deviceId => async ({ apiFetch, dispatch }) => {
    dispatch({ type: DEVICE_EVENTS_KEYS_FETCH_STARTED })
    try {
      const response = await apiFetch(`/devices/${deviceId}/event_keys/`)
      dispatch({ type: DEVICE_EVENTS_KEYS_FETCH_FINISHED, payload: response })
    } catch (error) {
      dispatch({ type: DEVICE_EVENTS_KEYS_FETCH_FAILED, payload: error })
    }
  },
  doFetchDeviceIrrigationErrors: (deviceId, page) => async ({ apiFetch, dispatch }) => {
    dispatch({ type: DEVICE_IRRIGATION_ERRORS_FETCH_STARTED })
    try {
      const response = await apiFetch(`/irrigation_debug_logs/?device=${deviceId}&page=${page}`)
      dispatch({ type: DEVICE_IRRIGATION_ERRORS_FETCH_FINISHED, payload: response })
    } catch (error) {
      dispatch({ type: DEVICE_IRRIGATION_ERRORS_FETCH_FAILED, payload: error })
    }
  },
  doDeviceCommandPost: ({ networkAddress, nodeAddress, command }) => async ({ apiFetch, store }) => {
    try {
      const success = await apiFetch('/h431_controls/', { networkAddress, nodeAddress, command }, { method: 'POST' })

      if (success && networkAddress) {
        store.doAddSnackbarMessage(success.response)
      } else if (success && networkAddress === 0) {
        store.doAddSnackbarMessage('Send Failed: this organization has no network address')
      } else {
        store.doAddSnackbarMessage('Failure')
      }
    } catch (error) {
      store.doAddSnackbarMessage('Failure')
    }
  },
  reactDeviceEventsFetch: createSelector(
    'selectAuth',
    'selectRouteInfo',
    'selectDeviceEventsShouldUpdate',
    'selectCurrentDeviceId',
    ({ authenticated }, { url }, shouldUpdate, deviceId) => {
      if (authenticated && url.startsWith('/devices') && shouldUpdate && deviceId) {
        return { actionCreator: 'doFetchDeviceEvents' }
      }
      return undefined
    }
  ),
  // reactDeviceEventsSyncWithChart: createSelector(
  //   'selectAuth',
  //   'selectDeviceEventsShouldUpdate',
  //   'selectDeviceChartShouldUpdate',
  //   ({ authenticated }, eventsShouldUpdate, chartShouldUpdate) => {
  //     console.log('chartShouldUpdate....', chartShouldUpdate)
  //     console.log('eventsShouldUpdate...', eventsShouldUpdate)
  //     console.log('!eventsShouldUpdate..', !eventsShouldUpdate)
  //     if (authenticated && !eventsShouldUpdate && chartShouldUpdate) {
  //       console.log('..............We are marking the events outdated...............')
  //       return { actionCreator: 'doMarkDeviceEventsAsOutdated' }
  //     }
  //     return undefined
  //   }
  // ),
  selectDeviceEventsKeyChoices: createSelector(
    'selectDeviceEventsRaw',
    ({ keyChoices }) => keyChoices
  ),
  selectIrrigationErrors: createSelector(
    'selectDeviceEventsRaw',
    ({ irrigationErrors }) => irrigationErrors
  ),
  selectDeviceEventsFilter: createSelector(
    'selectQueryObject',
    ({ packetFilter }) => packetFilter
  ),
  selectDeviceEventsPage: createSelector(
    'selectDeviceEventsRaw',
    ({ currentPage }) => currentPage
  ),
}
