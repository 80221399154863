import { createSelector } from 'redux-bundler'

import { normalize } from 'normalizr'
import { groupBy, pipe, prop } from 'ramda'
import reduceReducers from 'reduce-reducers'

import createEntityBundle, { doEntitiesReceived, ENTITIES_RECEIVED } from '~/Lib/createEntityBundle'
import createListBundle from '~/Lib/createListBundle'
import { parseApiErrors } from '~/Lib/Utils'
import { sortNestedArrayBy } from '~/Lib/Data'
import { Facility as schema, Room } from '~/Store/Schemas'

const FACILITY_DATA_EXPORT_FAILED = 'FACILITY_DATA_EXPORT_FAILED'
const SET_CURRENT_FACILITY_LICENSE = 'SET_CURRENT_FACILITY_LICENSE'
const TOPOLOGY_FETCH_SUCCEED = 'TOPOLOGY_FETCH_SUCCEED'
const FAC_LIST_SET_TIER_FILTER = 'FAC_LIST_SET_TIER_FILTER'
const FAC_LIST_SET_STATUS_FILTER = 'FAC_LIST_SET_STATUS_FILTER'
const FAC_LIST_SET_SEARCH = 'FAC_LIST_SET_SEARCH'

export const facilities = createEntityBundle({
  name: 'facilities',
  apiConfig: { schema },
})

const initialFacilityList = createListBundle({
  entityName: 'facility',
  schema,
  fetchHandler: ({ apiFetch }) => (
    apiFetch('/facilities/').then(response => ({ results: response }))
  )
})

const additionalState = {
  currentLicense: null,
  topology: null,
  tierFilter: ['NONE', 1, 2, 3, 4],
  statusFilter: 'ACTIVE',
  search: '',
}

const initialHistoricImportState = {
  errorType: null,
  isLoading: false,
  lastSuccess: null,
}

export const historicImport = {
  name: 'historicImport',
  reducer: (state = initialHistoricImportState, action = {}) => {
    switch (action.type) {
      case 'DO_HISTORIC_IMPORT_STARTED':
        return { ...state, isLoading: true }
      case 'DO_HISTORIC_IMPORT_FINISHED':
        return { ...state, isLoading: false, errorType: null, lastSuccess: Date.now() }
      case 'DO_HISTORIC_IMPORT_FAILED':
        return { ...state, isLoading: false, errorType: action.payload, lastSuccess: null }
      default:
        return state
    }
  },
  doFetchHistoricImport: params => async ({ apiFetch, dispatch }) => {
    dispatch({ type: 'DO_HISTORIC_IMPORT_STARTED' })
    try {
      const response = await apiFetch('/metrc_data_import/', params, { method: 'POST' })
      dispatch({ type: 'DO_HISTORIC_IMPORT_FINISHED', payload: response })
    } catch (error) {
      dispatch({ type: 'DO_HISTORIC_IMPORT_FAILED', payload: error })
    }
  },
  selectHistoricImport: state => state.historicImport,
}

const initialHarvestHistoryState = {
  errorType: null,
  isLoading: false,
  data: [],
}

export const harvestHistory = {
  name: 'harvestHistory',
  reducer: (state = initialHarvestHistoryState, action = {}) => {
    switch (action.type) {
      case 'DO_HARVEST_HISTORY_STARTED':
        return { ...state, isLoading: true }
      case 'DO_HARVEST_HISTORY_STARTED_FINISHED':
        return { ...state, isLoading: false, errorType: null, data: action.payload }
      case 'DO_HARVEST_HISTORY_STARTED_FAILED':
        return { ...state, isLoading: false, errorType: action.payload }
      default:
        return state
    }
  },
  doFetchHarvestHistory: params => async ({ apiFetch, dispatch }) => {
    dispatch({ type: 'DO_HARVEST_HISTORY_STARTED' })
    try {
      const response = await apiFetch('/harvested_count/', params, { method: 'GET' })
      dispatch({ type: 'DO_HARVEST_HISTORY_STARTED_FINISHED', payload: response })
    } catch (error) {
      dispatch({ type: 'DO_HARVEST_HISTORY_STARTED_FAILED', payload: error })
    }
  },
  selectHarvestHistory: state => state.harvestHistory.data,
}

export const facilityList = {
  ...initialFacilityList,
  reducer: reduceReducers(initialFacilityList.reducer, (state, action) => {
    switch (action.type) {
      case FAC_LIST_SET_TIER_FILTER:
        return { ...state, tierFilter: action.payload }
      case FAC_LIST_SET_STATUS_FILTER:
        return { ...state, statusFilter: action.payload }
      case FAC_LIST_SET_SEARCH:
        return { ...state, search: action.payload }
      case SET_CURRENT_FACILITY_LICENSE:
        return { ...state, currentLicense: action.payload }
      case TOPOLOGY_FETCH_SUCCEED:
        return { ...state, topology: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doDeleteMembership: payload => async ({ apiFetch, store }) => {
    try {
      const result = await apiFetch(`/memberships/${payload.id}/`, {}, { method: 'DELETE' })
      const facility = store.selectCurrentFacilityId()
      store.doAddSnackbarMessage(`Successfully removed ${payload.user.firstName} ${payload.user.lastName} from facility`)
      store.doFacilityFetch(facility)
      return result
    } catch (error) {
      return error
    }
  },
  doDeleteRoom: payload => async ({ apiFetch, store }) => {
    try {
      const result = await apiFetch(`/rooms/${payload.id}/`, {}, { method: 'DELETE' })
      const facility = store.selectCurrentFacilityId()
      store.doAddSnackbarMessage(`Successfully deactivated ${payload.name}`)
      store.doFacilityFetch(facility)
      return result
    } catch (error) {
      const errorMsg = parseApiErrors(error.response)
      store.doAddSnackbarMessage(errorMsg)
      return error
    }
  },
  doSaveMembership: payload => async ({ apiFetch, store }) => {
    try {
      let result
      const facility = store.selectCurrentFacilityId()

      if (payload.id) {
        result = await apiFetch(`/memberships/${payload.id}/`, { facility, ...payload }, { method: 'PUT' })
        store.doAddSnackbarMessage(`Successfully updated membership for ${payload.user.firstName} ${payload.user.lastName}`)
      } else {
        result = await apiFetch('/memberships/', { facility, ...payload }, { method: 'POST' })
        store.doAddSnackbarMessage(`Successfully added ${payload.user.firstName} ${payload.user.lastName} to facility`)
      }
      store.doFacilityFetch(facility)
      return result
    } catch (error) {
      return error
    }
  },
  doSaveRoom: payload => async ({ apiFetch, dispatch, store }) => {
    try {
      let response
      const facility = store.selectCurrentFacilityId()

      if (payload.id) {
        response = await apiFetch(`/rooms/${payload.id}/`, { facility, ...payload }, { method: 'PUT' })
        store.doAddSnackbarMessage(`Successfully updated ${payload.name}`)
      } else {
        response = await apiFetch('/rooms/', { facility, ...payload }, { method: 'POST' })
        store.doAddSnackbarMessage(`Successfully created ${payload.name}`)
        store.doFacilityFetch(facility)
      }
      const { entities, result } = normalize(response, Room)
      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      return result
    } catch (error) {
      return error
    }
  },
  doCreateNewFloorPlan: payload => async ({ apiFetch, store }) => {
    try {
      const result = await apiFetch('/floorplans/', payload, { method: 'POST' })
      store.doAddSnackbarMessage('Successfully created floor plan')
      store.doFacilityFetch(payload.facility)
      return result
    } catch (error) {
      const errorMsg = parseApiErrors(error.response)
      store.doAddSnackbarMessage(errorMsg)
      return error
    }
  },
  doDeactivateFloorPlan: payload => async ({ apiFetch, store }) => {
    try {
      const result = await apiFetch(`/floorplans/${payload.id}/deactivate/`, { id: payload.id }, { method: 'PUT' })
      store.doAddSnackbarMessage('Successfully deactivated floor plan')
      store.doFacilityFetch(payload.facility)
      return result
    } catch (error) {
      return error
    }
  },
  doUpdateFloorPlan: payload => async ({ apiFetch, store }) => {
    try {
      const result = await apiFetch(`/floorplans/${payload.id}/`, payload, { method: 'PUT' })
      store.doAddSnackbarMessage('Successfully updated floor plan')
      store.doFacilityFetch(payload.facility)
      return result
    } catch (error) {
      const errorMsg = parseApiErrors(error.response)
      store.doAddSnackbarMessage(errorMsg)
      return error
    }
  },
  doReorderFloorPlans: payload => async ({ apiFetch, dispatch, store }) => {
    try {
      const facility = store.selectCurrentFacilityId()
      const response = await apiFetch('/floorplans/sequence/', { facility, sequence: payload }, { method: 'PUT' })
      store.doAddSnackbarMessage('Successfully updated floor plan sequence')

      const { entities, result } = normalize(response, schema)
      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      return result
    } catch (error) {
      return error
    }
  },
  doReorderRooms: payload => async ({ apiFetch, dispatch, store }) => {
    try {
      const facility = store.selectCurrentFacilityId()
      const response = await apiFetch('/rooms/sequence/', { facility, sequence: payload }, { method: 'PUT' })
      store.doAddSnackbarMessage('Successfully updated room sequence')

      const { entities, result } = normalize(response, schema)
      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      return result
    } catch (error) {
      return error
    }
  },
  doSnoozeFacility: payload => async ({ apiFetch, dispatch, store }) => {
    try {
      const { facility, duration } = payload
      const response = await apiFetch(`/facilities/${facility.id}/snooze/`, { duration }, { method: 'POST' })
      store.doAddSnackbarMessage(`Successfully ${duration < 0 ? 'unmuted' : 'muted'} notifications for ${facility.name}`)

      const { entities, result } = normalize(response, schema)
      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      return result
    } catch (error) {
      return error
    }
  },
  doFetchFacilityMap: () => async ({ apiFetch, dispatch }) => {
    try {
      const response = await apiFetch('/map/')
      const { entities, result } = normalize(response, [schema])
      dispatch(doEntitiesReceived(entities, { replace: false }))
      return result
    } catch (error) {
      return error
    }
  },
  doFetchFacilityTopology: facility => async ({ apiFetch, dispatch }) => {
    try {
      const response = await apiFetch(`/network_topology?facility_id=${facility.id}`)
      dispatch({ type: TOPOLOGY_FETCH_SUCCEED, payload: response })
      return response
    } catch (error) {
      return error
    }
  },
  doFacilityExportData: facilityId => async ({ dispatch, apiFetch, store }) => {
    const user = store.selectMe()
    try {
      const success = await apiFetch('/export/facility/', { facilityId }, { method: 'POST' })
      if (success) {
        store.doAddSnackbarMessage(`An email will be sent to ${user.email} shortly.`)
      } else {
        store.doAddSnackbarMessage('Failed to export device data.')
      }
    } catch (error) {
      store.doAddSnackbarMessage('Failed to export device data.')
      dispatch({ type: FACILITY_DATA_EXPORT_FAILED, payload: error })
    }
  },
  doSetCurrentFacilityLicense: licenseNo => ({ dispatch }) => {
    dispatch({ type: SET_CURRENT_FACILITY_LICENSE, payload: licenseNo })
  },
  doFacilityListSetTierFilter: filter => ({ store, dispatch }) => {
    if (filter === store.selectOrganizationListTierFilter()) return
    dispatch({ type: FAC_LIST_SET_TIER_FILTER, payload: filter })
  },
  doFacilityListSetStatusFilter: filter => ({ store, dispatch }) => {
    if (filter === store.selectOrganizationListStatusFilter()) return
    dispatch({ type: FAC_LIST_SET_STATUS_FILTER, payload: filter })
  },
  doFacilitySetSearch: search => ({ dispatch }) => {
    dispatch({ type: FAC_LIST_SET_SEARCH, payload: search })
  },
  selectAvailableMetrcLocations: createSelector(
    'selectFacility',
    'selectRooms',
    (facility, rooms) => {
      const assignedMetrcLocations = Object.values(rooms)
        .filter(room => room?.metrcLocations?.length)
        .reduce((acc, room) => ([...acc, ...room.metrcLocations]), [])

      return (facility?.metrcLocations ?? []).filter(
        metrcLocation => !assignedMetrcLocations.includes(metrcLocation?.id)
      )
    }
  ),
  selectAllMetrcLocations: createSelector(
    'selectFacility',
    ({ metrcLocations }) => metrcLocations
  ),
  selectTopology: createSelector(
    'selectFacilityListRaw',
    ({ topology }) => topology
  ),
  selectCurrentFacilityRooms: createSelector(
    'selectFacility',
    'selectRooms',
    ({ rooms }, allRooms) => (
      rooms
        ? Object.values(rooms).map(id => allRooms[id])
        : []
    )
  ),
  selectCurrentFacilityLicense: createSelector(
    'selectFacilityListRaw',
    ({ currentLicense }) => currentLicense
  ),
  selectFacilitiesByOrg: createSelector(
    'selectFacilities',
    pipe(Object.values, groupBy(prop('organization')))
  ),
  selectFilteredFacilities: createSelector(
    'selectFacilityListRaw',
    'selectFacilities',
    ({ search, tierFilter, statusFilter }, _facilities) => {
      const searchRegex = new RegExp(search, 'i')
      let facs = Object.values(_facilities)
      if (tierFilter) {
        facs = facs.filter(fac => (
          tierFilter.includes(fac.tier)
          || (tierFilter.includes('NONE') && fac.tier == null)
        ))
      }
      if (statusFilter && statusFilter !== 'all') {
        facs = facs.filter(fac => fac.status === statusFilter)
      }

      if (search) {
        facs = facs.filter(fac => (
          searchRegex.test(fac.name)
        ))
      }

      return sortNestedArrayBy('name', facs)
    }
  ),
  selectFacilityListTierFilter: createSelector(
    'selectFacilityListRaw',
    ({ tierFilter }) => tierFilter
  ),
  selectFacilityListStatusFilter: createSelector(
    'selectFacilityListRaw',
    ({ statusFilter }) => statusFilter
  ),
  selectFacilityListSearch: createSelector(
    'selectFacilityListRaw',
    ({ search }) => search
  ),
}
