import React, { useEffect } from 'react'
import { useConnect } from 'redux-bundler-hook'

import Grid from '@material-ui/core/Grid'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { formatDateTime } from '~/Lib/Utils'
import ListTable from '~/UI/Shared/ListTable'
import ListTableCell from '~/UI/Shared/ListTableCell'
import Loading from '~/UI/Shared/utils'
import MuiTitle from '~/UI/Shared/MuiTitle'

const OtapStatusListComponent = () => {
  const {
    otapStatusList,
    doMarkOtapStatusListAsOutdated,
    doUpdateUrl,
  } = useConnect(
    'selectOtapStatusList',
    'doMarkOtapStatusListAsOutdated',
    'doUpdateUrl',
  )

  useEffect(() => {
    doMarkOtapStatusListAsOutdated()
  }, [])

  if (!otapStatusList) {
    return <Loading />
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <MuiTitle title="Firmware Update History" />
        </Grid>
      </Grid>
      <ListTable>
        <TableHead>
          <TableRow>
            <ListTableCell>Started</ListTableCell>
            <ListTableCell>Last Updated</ListTableCell>
            <ListTableCell>Target Version</ListTableCell>
            <ListTableCell>Gateway</ListTableCell>
            <ListTableCell>Facility</ListTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {otapStatusList?.map(status => (
            <TableRow
              style={{ cursor: 'pointer' }}
              hover
              key={status.requestId}
              onClick={() => doUpdateUrl(`/firmware/logs/${status.requestId}`)}
            >
              <ListTableCell style={{ minWidth: '200px' }}>
                {formatDateTime(status.createdOn)}
              </ListTableCell>
              <ListTableCell style={{ minWidth: '200px' }}>
                {formatDateTime(status.modifiedOn)}
              </ListTableCell>
              <ListTableCell style={{ minWidth: '120px' }}>
                {status.targetVersion}
              </ListTableCell>
              <ListTableCell>
                {status.gateways.join(', ')}
              </ListTableCell>
              <ListTableCell>
                {status.facilities.join(', ')}
              </ListTableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
    </>
  )
}

export default OtapStatusListComponent
