import { identity } from 'ramda'

import {
  camelize,
  humanize,
  singularize,
  underscore,
} from 'inflection'
import { normalize } from 'normalizr'

import { defer, noop, uniqueId } from '~/Lib/Utils'

import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  getActionIdentifiers,
  getAsyncActionIdentifiers,
} from './constants'

// const logger = createLogger('createEntityBundle', 'actions')

export const doEntitiesReceived = (payload, meta) => ({
  type: ENTITIES_RECEIVED,
  payload,
  meta,
})

export const doEntitiesRemoved = (payload, meta) => ({
  type: ENTITIES_REMOVED,
  payload,
  meta,
})

export const getDispatchPayloadFactory = name => payload => {
  if (typeof payload === 'number' || typeof payload === 'string') return payload
  return payload?.id ?? uniqueId(`${name}_`)
}
const idOrValue = o => (o ? o.id || o.cid || o.valueOf() : o)
export const removeEntitiesPayloadFactory = name => payload => ({
  [name]: Array.isArray(payload)
    ? payload.map(idOrValue)
    : Array.of(idOrValue(payload)),
})

const createSnackbarActionFactory = config => {
  const { action, singularName, snackbar } = config
  if (snackbar === false) return noop
  if (typeof snackbar === 'function') return snackbar
  if (snackbar.indexOf(`do${camelize(singularName)}`) === 0) {
    return ({ dispatch, status, payload, response }) => {
      dispatch({
        actionCreator: snackbar,
        args: [{ ...config, status, payload, response }],
      })
    }
  }
  return ({ dispatch, status, payload, response, error }) => {
    const snackbarString = payload?.[snackbar] ?? response?.[snackbar]
    const identifier = snackbarString ? ` "${snackbarString}"` : ''
    const statusSuffixed = `${status}${status.match(/e$/) ? 'd' : 'ed'}`

    let snackMessage = null

    if (error && Array.isArray(error.response) && error.response.length === 1) {
      snackMessage = error?.response?.[0]
    }

    if (error?.response?.nonFieldErrors) {
      snackMessage = error.response.nonFieldErrors?.[0]
    }

    if (!snackMessage) {
      snackMessage = [
        `${humanize(action)} ${statusSuffixed} for`,
        `${humanize(underscore(singularName), true)}${identifier}`,
      ].join(' ')
    }

    dispatch({
      actionCreator: 'doAddSnackbarMessage',
      args: [snackMessage],
    })
  }
}

const getMeta = ({ status, defaultMeta, paramsMeta, ...rest }) => ({
  ...(defaultMeta?.[status] ?? defaultMeta),
  ...(paramsMeta?.[status] ?? paramsMeta),
  ...rest,
})

export const asyncActionFactory = config => {
  /*
    action: String
    name: String
    handler: Function
      signature: async ({
        payload,
        apiFetch,
        getState,
        store,
      }) => {
      …doStuff throwing IOError on erros
    },
    schema: Entity (normalizr schema.Entity),
    remove: Boolean (is action removing the entity?),
    snackbar: [
      String (key of entity's "name", default: name),
      String (name of action creator),
      Function ()
      false to disable]
  */
  const {
    action,
    name,
    idAttribute = 'id',
    singularName = singularize(name),
    handler,
    parents,
    prepareData = identity,
    schema,
    defaultMeta = {},
    remove = false,
    returnRaw = false,
    snackbar = 'name',
  } = config

  const identifiers = getAsyncActionIdentifiers(action, singularName)
  const { types } = identifiers
  const getRemovePayload = removeEntitiesPayloadFactory(name)
  const getDispatchPayload = getDispatchPayloadFactory(name)
  const createSnackbarAction = createSnackbarActionFactory({
    action,
    name,
    singularName,
    snackbar,
  })
  const creator = (rawPayload, paramsMeta) => async ({
    dispatch,
    ...handlerArgs
  }) => {
    const payload = prepareData(rawPayload)
    const dispatchPayload = getDispatchPayload(payload)

    dispatch({
      type: types.start,
      payload: dispatchPayload,
      meta: getMeta({ defaultMeta, status: 'start', paramsMeta }),
    })

    try {
      const response = await handler({ ...handlerArgs, dispatch, payload })
      if (remove) {
        dispatch({
          type: types.succeed,
          payload: dispatchPayload,
          meta: getMeta({ defaultMeta, status: 'succeed', paramsMeta }),
        })
        createSnackbarAction({
          ...handlerArgs,
          status: 'succeed',
          dispatch,
          payload,
          response,
        })
        defer(() => dispatch(doEntitiesRemoved(getRemovePayload(payload), getMeta({
          defaultMeta,
          status: 'entities',
          paramsMeta
        }))), defer.priorities.low)

        return true
      }
      const { entities, result } = normalize(response, schema)
      const isNew = !payload.id
      const currentId = response[idAttribute]
      const meta = getMeta({ defaultMeta, status: 'entities', paramsMeta })
      if (isNew) {
        const newEntity = entities?.[name]?.[result]
        dispatch({
          actionCreator: `do${camelize(singularName)}SetCurrent`,
          args: [currentId],
        })
        if (parents) {
          meta.replace = false
          Object.entries(parents).forEach(
            ([parentEntity, parentIdAttribute]) => {
              const parentId = newEntity?.[parentIdAttribute]
              entities[parentEntity] = {
                [parentId]: { id: parentId, [name]: [newEntity?.id] },
              }
            }
          )
        }
      }
      dispatch({
        type: ENTITIES_RECEIVED,
        payload: entities,
        meta,
      })
      dispatch({
        type: types.succeed,
        payload: currentId,
        meta: getMeta({
          isNew,
          cid: isNew && dispatchPayload,
          defaultMeta,
          status: 'succeed',
          paramsMeta,
        }),
      })

      createSnackbarAction({
        ...handlerArgs,
        status: 'succeed',
        dispatch,
        payload,
        response,
      })
      return returnRaw || Array.isArray(result)
        ? response
        : entities[name][result]
    } catch (error) {
      dispatch({
        type: types.fail,
        payload: dispatchPayload,
        error,
        meta: getMeta({
          defaultMeta,
          status: 'fail',
          paramsMeta,
          requestPayload: payload,
        }),
      })
      dispatch({
        actionCreator: `do${camelize(singularName)}SetCurrent`,
        args: [dispatchPayload],
      })
      createSnackbarAction({
        ...handlerArgs,
        status: 'fail',
        dispatch,
        payload,
        error,
      })
      if (localStorage.throwApiErrors === 'true') throw error
      return false
    }
  }

  return Object.assign(creator, identifiers, { createSnackbarAction, handler })
}

export const actionFactory = (...args) => {
  const identifiers = getActionIdentifiers(...args)
  const { type } = identifiers
  const creator = payload => ({ type, payload })
  return Object.assign(creator, identifiers)
}

export const createCustomAction = ({ actionType, actionName, reducerKey, loadingKey = 'loading' }) => {
  const actionIdentifiers = getAsyncActionIdentifiers(actionType, actionName)
  const actionReducer = (state, action) => {
    switch (action.type) {
      case actionIdentifiers.types.start:
        return {
          ...state,
          [reducerKey]: {
            ...state[reducerKey],
            [loadingKey]: true,
          },
        }
      case actionIdentifiers.types.succeed:
        return {
          ...state,
          [reducerKey]: {
            ...state[reducerKey],
            [loadingKey]: false,
            data: action.payload,
          },
        }
      case actionIdentifiers.types.fail:
        return {
          ...state,
          [reducerKey]: {
            ...state[reducerKey],
            [loadingKey]: false,
            error: action.error,
          },
        }
      default:
        return state
    }
  }
  const defaultState = { [reducerKey]: { [loadingKey]: false, data: [] } }
  return { actionIdentifiers, actionReducer, defaultState }
}
