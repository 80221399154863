import React, { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import LoggerForm from '~/Logger/form'

import styles from './styles'

const useStyles = makeStyles(styles)

const LoggerList = () => {
  const classes = useStyles()
  const [addLoggerFormOpen, setAddLoggerFormOpen] = useState(false)
  const [editLoggerFormOpen, setEditLoggerFormOpen] = useState(false)
  const [currentLogger, setCurrentLogger] = useState({})

  const {
    isGuestStaff,
    facility,
    doLoggerDelete,
    doLoggerFetch,
    doFacilityFetch,
    doLoggerSave,
  } = useConnect(
    'selectIsGuestStaff',
    'selectFacility',
    'doLoggerDelete',
    'doLoggerFetch',
    'doFacilityFetch',
    'doLoggerSave',
  )

  const handleLoggerClick = async logger => {
    if (isGuestStaff) return

    let loggerEntity = logger
    if (logger.payloadType !== 'entity') {
      loggerEntity = await doLoggerFetch(logger.id)
    }
    setCurrentLogger(loggerEntity)
    setEditLoggerFormOpen(true)
  }

  return (
    <>
      <LoggerForm
        scopeToFacility
        onClose={() => setAddLoggerFormOpen(false)}
        onSave={doLoggerSave}
        open={addLoggerFormOpen}
      />
      <LoggerForm
        scopeToFacility
        instance={currentLogger}
        onClose={() => setEditLoggerFormOpen(false)}
        onDelete={async instance => {
          await doLoggerDelete(instance)
          doFacilityFetch(facility.id)
        }}
        onSave={doLoggerSave}
        open={editLoggerFormOpen}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            Loggers
          </Typography>
        </Grid>
        {!isGuestStaff && (
        <Grid item container xs={6} justify="flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={() => { setAddLoggerFormOpen(true) }}
          >
            Add Logger
          </Button>
        </Grid>
        )}
      </Grid>
      <Grid container spacing={1} direction="column" style={{ marginTop: '8px' }}>
        {facility?.loggers?.map(logger => (
          <Grid item key={logger.id}>
            <Paper
              className={isGuestStaff ? classes.nonClickableRow : classes.clickableRow}
              onClick={() => handleLoggerClick(logger)}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  {logger.name && (
                    <Typography
                      noWrap
                      variant="body2"
                      color="inherit"
                      style={{ maxWidth: '150px' }}
                    >
                      {logger.name}
                    </Typography>
                  )}
                  <Typography color="primary" style={{ minWidth: '150px' }}>
                    {logger.serialNumber}
                  </Typography>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <Typography color="textSecondary">
                    Timezone: {logger.timezone}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography color="textSecondary">
                    Password: {logger.password}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default LoggerList
