import theme, { meter } from '~/UI/Theme/mui'

export default () => ({
  paper: {
    height: '100%',
    maxHeight: '65vh',
    overflowY: 'auto',
    borderRadius: '0px 0px 5px 5px'

  },
  paperHeader: {
    height: '100%',
    maxHeight: '8vh',
    padding: '5% 20px 0 20px',
    borderRadius: '5px 5px 0px 0px'
  },
  listItem: {
    borderTop: '1px solid rgba(255, 255, 255, 0.05)',
    '&:hover': {
      backgroundColor: meter.background.two,
    },
    minHeight: '70px',
  },
  buttonRow: {
    flexGrow: 1,
  },
  paddingRight: {
    paddingRight: '75px',
  },
  button: {
    padding: '5px 10px',
    margin: '10px'
  },
  selected: {
    color: meter.blue.light,
    textDecoration: 'underline',
  },
})

const defaultStyles = {
  padding: '0',
  margin: '0',
}

export const toolTipStyles = {
  container: {
    ...defaultStyles,
    fontFamily: meter.typography.defaultFontFamily,
    backgroundColor: meter.background.two,
    color: meter.white,
    width: '100%',
    height: '100%',
    padding: '5px 30px 10px 5px',
    maxWidth: '500px',
    maxHeight: '800px',
  },
  body1: {
    ...theme.typography.body1,
    ...defaultStyles,
    margin: '0 0 -5px 0',
  },
  body2: {
    ...theme.typography.body2,
    ...defaultStyles,
    color: theme.palette.primary.main,
  },
  header: {
    ...theme.typography.subtitle1,
    ...defaultStyles,
    textAlign: 'left',
    margin: '-3px 0 -7px 0',
    background: 'none',
    border: '0',
    outline: 'none',
    cursor: 'pointer',
  },
  clusterHeader: {
    ...theme.typography.subtitle1,
    ...defaultStyles,
    textAlign: 'left',
    background: 'none',
    border: '0',
    outline: 'none',
    paddingLeft: '15px'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    height: '10px',
    width: '10px',
    backgroundColor: 'rgb(173, 244, 135)',
    boxShadow: '0 0 3px 3px rgba(173, 244, 135, 0.05)',
    margin: '5px 5px 0 5px',
    borderRadius: '50%',
  },
  inactive: {
    height: '10px',
    width: '10px',
    backgroundColor: 'rgb(219, 31, 64)',
    boxShadow: '0 0 3px 3px rgba(219, 31, 64, 0.05)',
    margin: '5px 5px 0 5px',
    borderRadius: '50%',
  }
}

export const mapStyles = [
  {
    featureType: 'all',
    elementType: 'geometry',
    stylers: [
      { color: '#202c3e' }
    ]
  },
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      { gamma: 0.01 },
      { lightness: 20 },
      { weight: '1.39' },
      { color: '#ffffff' }
    ]
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      { weight: '0.96' },
      { saturation: '9' },
      { visibility: 'on' },
      { color: '#000000' }
    ]
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [
      { visibility: 'off' }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      { lightness: 30 },
      { saturation: '9' },
      { color: '#29446b' }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      { saturation: 20 }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      { lightness: 20 },
      { saturation: -20 }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      { lightness: 10 },
      { saturation: -30 }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      { color: '#193a55' }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      { saturation: 25 },
      { lightness: 25 },
      { weight: '0.01' }
    ]
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      { color: '#131F3F' }
    ]
  }
]
