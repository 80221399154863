import React, { useState, useEffect, Fragment } from 'react'
import { useConnect } from 'redux-bundler-hook'
import { uniq } from 'ramda'

import {
  Collapse,
  Checkbox,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import styles from './styles'

const useStyles = makeStyles(styles)

const TreeView = props => {
  const classes = useStyles()
  const {
    facilities,
    organizations,
    otapNodeListRaw,
    otapNodesForFacility,
    otapNodeListSelectedNodes,
    otapNodeListSelectedFacility,
    otapNodeListDeviceTypeFilter,
    doOtapNodeListSetSelectedNodes,
    doOtapNodeListSetSelectedFacility,
    doOtapNodeListFetchNodesForFacility,
    doMarkOtapNodeListAsOutdated,
  } = useConnect(
    'selectFacilities',
    'selectOrganizations',
    'selectOtapNodeListRaw',
    'selectOtapNodesForFacility',
    'selectOtapNodeListSelectedNodes',
    'selectOtapNodeListSelectedFacility',
    'selectOtapNodeListDeviceTypeFilter',
    'doOtapNodeListSetSelectedNodes',
    'doOtapNodeListSetSelectedFacility',
    'doOtapNodeListFetchNodesForFacility',
    'doMarkOtapNodeListAsOutdated',
  )

  useEffect(() => {
    doMarkOtapNodeListAsOutdated()
  }, [otapNodesForFacility])

  const [organizationOpen, setOrganizationOpen] = useState({ orgId: null, isOpen: false })
  const [facilityOpen, setFacilityOpen] = useState({ facId: null, isOpen: false })
  const { toggleNodeSelected } = props
  const { isLoading, isOutdated } = otapNodeListRaw

  const sortByName = (a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    return 0
  }
  const getActiveOrgs = () => Object.values(organizations).filter(org => org.active).sort(sortByName)

  const getFacilitiesFromOrg = orgId => (
    Object.values(facilities)
      .filter(fac => fac.organization === orgId)
      .filter(fac => fac.active)
      .sort(sortByName)
  )

  const handleOrgClicked = org => {
    setFacilityOpen({ facId: null, isOpen: false })
    const hasActiveFacilities = Object.values(facilities).filter(fac => fac.organization === org.id).some(fac => fac.active)
    if (!getFacilitiesFromOrg(org.id).length || !hasActiveFacilities) return
    if (organizationOpen.isOpen) {
      setOrganizationOpen({ orgId: org.id, isOpen: organizationOpen.orgId !== org.id })
    } else {
      setOrganizationOpen({ orgId: org.id, isOpen: true })
    }
  }

  const handleFacClicked = fac => {
    if (facilityOpen.isOpen && facilityOpen.facId === fac.id) {
      setFacilityOpen({ facId: fac.id, isOpen: false })
      return
    }
    if (facilityOpen.isOpen) {
      setFacilityOpen({ facId: fac.id, isOpen: facilityOpen.facId !== fac.id })
    } else {
      setFacilityOpen({ facId: fac.id, isOpen: true })
    }
    if (!otapNodesForFacility?.[fac.id] || isOutdated) {
      doOtapNodeListFetchNodesForFacility(fac.id)
    }
  }

  const orgIsOpen = org => organizationOpen.isOpen
      && organizationOpen.orgId === org.id
      && getFacilitiesFromOrg(org.id).length

  const facIsOpen = fac => facilityOpen.isOpen
      && facilityOpen.facId === fac.id

  const willShowFacilityCheckbox = facId => {
    const currentFac = facilityOpen.isOpen && facilityOpen.facId === facId
    const currentFacHasNodes = otapNodesForFacility?.[facId]?.length
    if (currentFac && currentFacHasNodes) return true
    return false
  }

  const getTextColor = (stateObj, isTitleText, entity = null) => {
    const activeColor = isTitleText ? 'initial' : 'primary'
    if (entity?.id && entity.id === facilityOpen.facId) return activeColor
    return stateObj.isOpen ? 'inherit' : activeColor
  }

  const mapFacilityNodeIds = facId => otapNodesForFacility?.[facId]?.map(node => node.nodeAddress)

  const toggleSelectedFacility = facId => {
    if (!otapNodeListSelectedFacility) {
      doOtapNodeListSetSelectedFacility(facId)
    }
    if (otapNodeListSelectedFacility === facId) {
      doOtapNodeListSetSelectedFacility(null)
      doOtapNodeListSetSelectedNodes([])
    }
    if (otapNodeListSelectedFacility !== facId) {
      doOtapNodeListSetSelectedFacility(facId)
      setFacilityOpen({ facId, isOpen: true })
      doOtapNodeListSetSelectedNodes(uniq([...mapFacilityNodeIds(facId)]))
    }
  }

  return (
    <List style={{ width: '100%' }}>
      {getActiveOrgs().map(org => (
        <Fragment key={org.id}>
          <ListItem
            button
            onClick={() => handleOrgClicked(org)}
            className={classes.listItem}
          >
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className={classes.orgItem}
              wrap="nowrap"
            >
              <Grid item direction="column">
                <Typography
                  variant="body2"
                  color={getTextColor(organizationOpen, false)}
                >
                  ORGANIZATION
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={getTextColor(organizationOpen, true)}
                >
                  {org.name}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>

          <Collapse in={!!orgIsOpen(org)} timeout="auto" unmountOnExit>
            <List style={{ width: '100%' }}>

              {getFacilitiesFromOrg(org.id)?.map(fac => (
                <Fragment key={fac.id}>
                  <ListItem
                    button
                    onClick={e => {
                      e.stopPropagation()
                      handleFacClicked(fac)
                    }}
                    className={classes.listItem}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      className={classes.facItem}
                      wrap="nowrap"
                    >
                      <Grid item direction="column">
                        <Typography
                          variant="body2"
                          color={getTextColor(facilityOpen, false, fac)}
                        >
                          FACILITY
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={getTextColor(facilityOpen, true, fac)}
                        >
                          {fac.name}
                        </Typography>
                      </Grid>
                      {willShowFacilityCheckbox(fac.id) && (
                        <Checkbox
                          checked={otapNodeListSelectedFacility === fac.id}
                          onClick={e => {
                            e.stopPropagation()
                            toggleSelectedFacility(fac.id)
                          }}
                        />
                      )}
                    </Grid>
                  </ListItem>
                  <Collapse in={!!facIsOpen(fac)} timeout="auto" unmountOnExit>

                    {!otapNodesForFacility?.[fac.id]?.length && !isLoading && (
                      <Typography
                        variant="body2"
                        color="primary"
                        align="center"
                      >
                        No {otapNodeListDeviceTypeFilter.toLowerCase()}s to display
                      </Typography>
                    )}

                    <List style={{ width: '100%' }}>
                      {isLoading && <LinearProgress style={{ width: '90%', margin: 'auto' }} />}
                      {otapNodesForFacility?.[fac.id]?.map(dev => (
                        <ListItem
                          button
                          key={dev.id}
                          className={classes.listItem}
                        >
                          <Grid
                            container
                            alignItems="center"
                            justify="space-between"
                            className={classes.devItem}
                            wrap="nowrap"
                          >
                            <Grid item direction="column">
                              <Typography
                                variant="body2"
                                color="primary"
                              >
                                {dev.modelName?.toUpperCase()}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color="initial"
                              >
                                {dev.serialNumber}
                              </Typography>
                            </Grid>
                            <Checkbox
                              checked={otapNodeListSelectedNodes?.includes(dev.nodeAddress)}
                              onClick={() => toggleNodeSelected(dev)}
                            />
                          </Grid>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </Fragment>
              ))}
            </List>
          </Collapse>
        </Fragment>
      ))}
    </List>
  )
}

export default TreeView
