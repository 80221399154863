import React, { useState } from 'react'
import { getIn, Formik, Form } from 'formik'
import { underscore, humanize } from 'inflection'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'

import MuiSelectField from '~/UI/Shared/Form/SelectField'

const styles = () => ({
  noMargin: {
    margin: '0'
  },
  topMargin: {
    margin: '1rem 0 0',
  },
  facilityTitle: {
    margin: '1rem 0 0',
  }
})

const useStyles = makeStyles(styles)
const useDialogStyles = makeStyles({
  paper: {
    maxHeight: '90vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    padding: '1rem 1.5rem',
    '& > form': {
      display: 'contents',
    },
    '& .MuiDialogContent-root': {
      flex: '1 1 100%',
      overflow: 'hidden auto',
    },
    '&.MuiDialog-paperFullScreen': {
      maxHeight: '100vh',
    },
    '& .MuiFormControl-root': {
      margin: 0,
    },
    '& .MuiFormControlLabel-root': {
      marginTop: '1rem',
    }
  },
  actions: {
    flex: '0 0 auto',
  },
  title: {
    flex: '0 0 auto',
  },
})

const formatError = err => humanize(underscore(err))
const getContainer = () => document.getElementById('modalRoot')

const formatErrors = ({ field, form }) => {
  const error = getIn(form.errors, field.name)
  if (!error) return [false, '']
  const errorText = Array.isArray(error) ? error.map(formatError) : formatError(error)
  return [true, errorText]
}

export const TextField = formikProps => {
  const { field, form, ...props } = formikProps
  const [hasError, errorText] = formatErrors(formikProps)
  const { touched } = form.getFieldMeta(field.name)
  const classes = useStyles()
  const showError = form.dirty && touched && hasError

  return (
    <MuiTextField
      className={classes.noMargin}
      error={showError}
      fullWidth
      helperText={showError && errorText}
      {...field}
      {...props}
    />
  )
}

export const NumberField = formikProps => (
  <TextField
    type="number"
    {...formikProps}
  />
)

export const SelectField = formikProps => {
  const { field: { onChange, value, ...field }, form, label, options, ...props } = formikProps
  const [hasError, errorText] = formatErrors(formikProps)
  const classes = useStyles()

  return (
    <MuiSelectField
      fullWidth
      className={classes.topMargin}
      errorText={hasError ? errorText : ''}
      placeholder={label}
      {...field}
      {...props}
      options={options}
      onBlur={() => {}}
      onChange={opt => form.setFieldValue(field.name, opt.value, false)}
      value={options.filter(org => org.value === value)[0]}
    />
  )
}

export const ToggleField = ({ field, form, ...props }) => (
  <FormControlLabel
    {...props}
    control={(
      <Switch color="primary" checked={field.value} {...field} />
    )}
  />
)

export const FormDialog = props => {
  const {
    children,
    formatInstance,
    initialValues,
    instance,
    label,
    onClose,
    onDelete,
    onSave,
    open,
    softDeletes,
    validationSchema,
    customAction,
    facilityTitle,
    maxFormWidth,
    ...passthru
  } = props

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  // replace empty string values with nulls
  const cleanData = obj => {
    if (obj === '') return null
    if (obj === null) return null
    if (Array.isArray(obj)) {
      return obj.map(item => cleanData(item))
    }
    if (typeof obj === 'object') {
      return Object.fromEntries(
        Object.entries(obj).map(([k, v]) => [k, cleanData(v)])
      )
    }
    return obj
  }

  const onSubmit = async (values, { setErrors }) => {
    const result = await onSave({
      id: instance?.id,
      ...cleanData(values),
    })
    if (result?.error) {
      setErrors(result.error.response)
    } else if (result) {
      onClose()
    }
  }

  const handleDelete = obj => async () => {
    setDeleteConfirmationOpen(false)
    await onDelete(obj)
    onClose()
  }

  return (
    <>
      {instance?.id && onDelete && (
        <Dialog
          open={deleteConfirmationOpen}
          container={getContainer}
          onClose={() => setDeleteConfirmationOpen(false)}
        >
          <DialogContent>
            <DialogContentText>
              Are you sure you want to { softDeletes ? 'deactivate' : 'remove' } { formatInstance(instance) }?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => setDeleteConfirmationOpen(false)}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={handleDelete(instance)}
            >
              { softDeletes ? 'Deactivate' : 'Remove' }
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog
        fullWidth
        classes={dialogClasses}
        container={getContainer}
        maxWidth={maxFormWidth ?? 'sm'}
        {...passthru}
        open={open}
        onClose={onClose}
      >
        {facilityTitle && (
          <Typography align="center" variant="h5" className={classes.facilityTitle}>
            {facilityTitle}
          </Typography>
        )}
        <DialogTitle>
          { instance?.id ? `Edit ${label}` : `Add ${label}` }
        </DialogTitle>
        <Formik
          enableReinitialize
          validateOnBlur
          validateOnChange
          initialErrors={instance?.lastError?.error}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ dirty, errors, isValid }) => (
            <Form>
              <DialogContent>
                { errors.nonFieldErrors?.length ? (
                  <div className="alert alert-danger">
                    {errors.nonFieldErrors.map(error => (
                      <div key={error}>{ error }</div>
                    ))}
                  </div>
                ) : null}
                { children }
              </DialogContent>
              <DialogActions>
                <div style={{ flexGrow: 1 }}>
                  {instance?.id && customAction && (customAction)}
                  {instance?.id && onDelete && (
                    <Button
                      color="secondary"
                      onClick={() => setDeleteConfirmationOpen(true)}
                    >
                      { softDeletes ? 'Deactivate' : 'Remove' }
                    </Button>
                  )}
                </div>
                <Button color="secondary" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={!dirty || !isValid}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}
