import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import { render } from 'react-dom'
import { Provider as ReduxProvider } from 'redux-bundler-react'
import { ReduxBundlerProvider } from 'redux-bundler-hook'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'
import * as Sentry from '@sentry/browser'

import { defaultState as authDefaultState } from '~/Store/bundles/auth'
import { getTokens } from '~/Lib/Auth'
import cache from '~/IO/Cache'
import config from '~/App/config'
import getStore from '~/Store'
import App from '~/App'
import createLogger from '~/Lib/Logging'
// shim requestIdleCallback for Edge and Safari
import '~/Lib/rIC-shim'

import '~/UI/Theme/main.css'
import muiTheme from '~/UI/Theme/mui'

const logger = createLogger('main')

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    release: `v${process.env.npm_package_version}`,
    environment: config.ENVIRONMENT,
  })
}

cache.getAll().then(initialData => {
  if (initialData) {
    logger.info('bootstrapping store with initial data:', initialData)
  }
  const store = getStore({
    ...initialData,
    auth: {
      ...authDefaultState,
      authenticated: !!getTokens().user,
    },
  })

  const Root = () => (
    <MuiThemeProvider theme={muiTheme}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <ReduxBundlerProvider store={store}>
          <ReduxProvider store={store}>
            <App />
          </ReduxProvider>
        </ReduxBundlerProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )

  render(<Root />, document.getElementById('root'))
  // eslint-disable-next-line no-restricted-globals
  if (module && module.hot) module.hot.accept(() => location.reload())
})
