import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'
import { camelize, pluralize } from 'inflection'
import ms from 'milliseconds'
import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'
import { doEntitiesReceived } from '~/Lib/createEntityBundle'
import { defer, EMPTY_ARRAY, EMPTY_OBJECT } from '~/Lib/Utils'

export const defaultInitialState = {
  data: { results: EMPTY_ARRAY },
  search: '',
  filter: 'ALL',
  sort: null,
  page: 1,
}
export const defaultListActions = ['set_filter', 'set_page', 'set_search', 'set_sort']
export const createListActionTypes = (actionBaseType, actions) => (
  actions.map(action => `${actionBaseType}_${action.toUpperCase()}`)
)
export const createActionTypesMap = actionTypes => (
  actionTypes
    .reduce((acc, action) => {
      if (!action.includes('_SET_')) return acc
      return {
        ...acc,
        [action]: camelize(action.split('_SET_').pop().toLowerCase(), true),
      }
    }, EMPTY_OBJECT)
)
const defaultOptions = {
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(10),
}
const defaultGetMeta = () => EMPTY_OBJECT
const defaultSearchTest = (payload = '') => payload.length >= 3 || payload.length === 0
/**
 * @callback fetchHandler
 * @param {{ apiFetch: function, dispatch: function, listState: Object }} kwargs
 */
/**
 *
 * @param {Object} config
 * @param {string} config.entityName
 * @param {Object} config.schema
 * @param {fetchHandler} config.fetchHandler
 * @param {string} [config.name=`${config.entityName}List`]
 * @param {string[]} [config.actions]
 * @return {{ name: string, reducer: function }} - asyncResourceBundle
 */
export default config => {
  const {
    actions = defaultListActions,
    entityName,
    name = `${entityName}List`,
    initialState = defaultInitialState,
    fetchHandler,
    schema,
    searchTest = defaultSearchTest,
    authorizationSelector = 'selectAuth',
    authorizationTest = ({ authenticated }) => authenticated,
    urlTest = Boolean,
    getEntitiesReceivedMeta = defaultGetMeta,
    ...options
  } = config

  const rbNameFragment = camelize(name)
  const actionBaseType = `${entityName.toUpperCase()}_LIST`
  const actionTypes = createListActionTypes(actionBaseType, actions)
  const actionTypesMap = createActionTypesMap(actionTypes, rbNameFragment)
  const initialBundle = createAsyncResourceBundle({
    ...defaultOptions,
    ...options,
    actionBaseType,
    name,
    getPromise: async kwargs => {
      const { dispatch, getState } = kwargs
      const { [name]: listState } = getState()
      const response = await fetchHandler({ ...kwargs, listState })
      const isFlat = Array.isArray(response)
      const { entities, result: results } = normalize(
        isFlat ? response : response.results,
        [schema]
      )
      dispatch(doEntitiesReceived(entities, getEntitiesReceivedMeta(listState)))
      return isFlat ? { results } : { ...response, results }
    },
  })

  return {
    ...initialBundle,
    reducer: reduceReducers(initialBundle.reducer, (state, action) => {
      if (
        action.type === `${actionBaseType}_CLEARED`
        || (
          (!action.type || !action.type.startsWith(actionBaseType))
          && Object.keys(initialState).some(key => state[key] == null && initialState[key] != null)
        )
      ) {
        return { ...state, ...initialState }
      }
      if (action.type in actionTypesMap) {
        const key = actionTypesMap[action.type]
        return {
          ...state,
          page: initialState.page,
          [key]: action.payload,
        }
      }
      return state
    }),
    [`do${rbNameFragment}Clear`]: () => ({
      type: 'BATCH_ACTIONS',
      actions: [{ type: `${actionBaseType}_CLEARED` }, { type: `${actionBaseType}_OUTDATED` }]
    }),
    ...Object.entries(actionTypesMap).reduce((acc, [type, key]) => ({
      ...acc,
      [`do${rbNameFragment}Set${camelize(key)}`]: type.endsWith('SET_SEARCH')
        ? payload => ({ dispatch, store }) => {
          dispatch({ type, payload })
          if (!searchTest(payload)) {
            return
          }
          defer(() => {
            if (store[`select${rbNameFragment}IsLoading`]()) return
            dispatch({ actionCreator: `doMark${rbNameFragment}AsOutdated`, args: EMPTY_ARRAY })
          })
        }
        : payload => ({ dispatch, store }) => {
          dispatch({ type, payload })
          defer(() => {
            if (store[`select${rbNameFragment}IsLoading`]()) return
            dispatch({ actionCreator: `doMark${rbNameFragment}AsOutdated`, args: EMPTY_ARRAY })
          })
        },
    }), EMPTY_OBJECT),
    [`react${rbNameFragment}Fetch`]: createSelector(
      authorizationSelector,
      `select${rbNameFragment}ShouldUpdate`,
      'selectRouteInfo',
      (authorizationData, shouldUpdate, { url }) => {
        if (authorizationTest(authorizationData) && shouldUpdate && urlTest(url)) {
          return { actionCreator: `doFetch${rbNameFragment}` }
        }
        return undefined
      }
    ),
    [`selectCurrent${rbNameFragment}`]: createSelector(
      `select${pluralize(camelize(entityName))}`,
      `select${rbNameFragment}Raw`,
      (entities, listRoot) => {
        const { data } = listRoot ?? EMPTY_OBJECT
        if (!data || !data.results?.length) return EMPTY_ARRAY
        return data.results.map(id => entities[id]).filter(Boolean)
      }
    ),
    [`select${rbNameFragment}ShouldUpdate`]: createSelector(
      'selectIsOnline',
      `select${rbNameFragment}IsLoading`,
      `select${rbNameFragment}FailedPermanently`,
      `select${rbNameFragment}IsWaitingToRetry`,
      `select${rbNameFragment}`,
      `select${rbNameFragment}IsStale`,
      (
        isOnline,
        isLoading,
        failedPermanently,
        isWaitingToRetry,
        data,
        isStale,
      ) => {
        if (!isOnline || isLoading || failedPermanently || isWaitingToRetry) {
          return false
        }
        if (data === initialState.data) {
          return true
        }
        return isStale
      }
    ),
  }
}
