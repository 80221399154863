import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import { DateTime } from 'luxon'
import color from 'color'
import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

const SYSTEM_CHART_SET_START = 'SYSTEM_CHART_SET_START'
const SYSTEM_CHART_SET_METRICS = 'SYSTEM_CHART_SET_METRICS'
const SYSTEM_CHART_SET_BAR_CHART = 'SYSTEM_CHART_SET_BAR_CHART'

const additionalState = {
  availableMetrics: [],
  params: {
    startMonth: 3,
  }
}

const systemChartBundle = createAsyncResourceBundle({
  name: 'systemChart',
  actionBaseType: 'SYSTEM_CHART',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: async ({ apiFetch, store }) => {
    const startMonth = store.selectSystemChartStartMonth()
    const start = DateTime.utc().minus({ months: startMonth }).toISO()
    const response = await apiFetch('/stats/', { start })
    const coloredMetrics = await store.doSystemChartAssignColors(response)

    if (startMonth === 3) { store.doSystemChartSetBarChart(coloredMetrics) }
    const metricTypes = coloredMetrics.map(graph => graph.label)
    store.doSystemChartSetAvailableMetrics(metricTypes)
    return coloredMetrics
  }
})

export default {
  ...systemChartBundle,
  reducer: reduceReducers(systemChartBundle.reducer, (state, action) => {
    switch (action.type) {
      case SYSTEM_CHART_SET_START:
        return { ...state, params: { ...state.params, startMonth: action.payload } }
      case SYSTEM_CHART_SET_BAR_CHART:
        return { ...state, ...action.payload }
      case SYSTEM_CHART_SET_METRICS:
        return { ...state, availableMetrics: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doSystemChartSetStartDate: start => async ({ store, dispatch }) => {
    dispatch({ type: SYSTEM_CHART_SET_START, payload: start })
    store.doMarkSystemChartAsOutdated()
  },
  doSystemChartSetAvailableMetrics: metrics => async ({ dispatch }) => {
    dispatch({ type: SYSTEM_CHART_SET_METRICS, payload: metrics })
  },
  doSystemChartIsolateMetric: metric => async ({ dispatch }) => {
    dispatch({ type: SYSTEM_CHART_SET_METRICS, payload: [metric] })
  },
  doSystemChartSetBarChart: result => async ({ dispatch }) => {
    const dataset = { barChartData: result }
    dispatch({ type: SYSTEM_CHART_SET_BAR_CHART, payload: dataset })
  },
  doSystemChartAssignColors: response => async () => {
    const blue = color('rgb(67, 253, 253)')
    const coloredMetrics = response.map((metric, index) => {
      if (metric.color === '0') {
        const deviceColor = blue.darken(index / 35).rgb().string()
        return { ...metric, color: deviceColor }
      }
      return metric
    })
    return coloredMetrics
  },
  doSystemChartFilterMetrics: metricType => async ({ dispatch, store }) => {
    const availMetrics = store.selectSystemChartAvailableMetrics()
    let metrics
    if (availMetrics.includes(metricType)) {
      metrics = availMetrics.filter(metric => metric !== metricType)
    } else {
      metrics = [...availMetrics, metricType]
    }
    dispatch({ type: SYSTEM_CHART_SET_METRICS, payload: metrics })
  },
  reactSystemChartFetch: createSelector(
    'selectAuth',
    'selectRouteInfo',
    'selectSystemChartShouldUpdate',
    ({ authenticated }, { url }, shouldUpdate) => {
      if (authenticated && url.startsWith('/system') && shouldUpdate) {
        return { actionCreator: 'doFetchSystemChart' }
      }
      return undefined
    }
  ),
  selectSystemChartAvailableMetrics: createSelector(
    'selectSystemChartRaw',
    ({ availableMetrics }) => availableMetrics
  ),
  selectSystemChartBarGraph: createSelector(
    'selectSystemChartRaw',
    ({ barChartData }) => barChartData
  ),
  selectSystemChartStartMonth: createSelector(
    'selectSystemChartRaw',
    ({ params }) => params.startMonth
  ),
}
