import React, { useState, useEffect } from 'react'
import { useConnect } from 'redux-bundler-hook'
import {
  useLoadScript,
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api'
import config from '~/App/config'
import { mapStyles } from '../styles'
import { ClusterToolTip, MarkerToolTip } from './tooltips'

const CustomerSiteMap = () => {
  const {
    widths,
    filteredFacilities: facilities,
    organizations,
    deactivatedOrganizationsIds,
    doFetchFacilityMap,
  } = useConnect(
    'selectWidths',
    'selectFilteredFacilities',
    'selectOrganizations',
    'selectDeactivatedOrganizationsIds',
    'doFetchFacilityMap',
  )

  useEffect(() => {
    doFetchFacilityMap()
  }, [])

  const [mapRef, setMapRef] = useState(null)
  const [selectedFacility, setSelectedFacility] = useState(null)
  const [selectedCluster, setSelectedCluster] = useState(null)
  const [markerMap, setMarkerMap] = useState({})
  const [center, setCenter] = useState({ lat: 38.1, lng: -93 })
  const [infoOpenFromClick, setInfoOpenFromClick] = useState(false)
  const zoomLevel = widths.window < 1350 ? 4.4 : 4.7

  const getMonitoredSpace = facility => {
    const facilitySpaceGiven = (facility.totalSpace && facility.monitoredSpace)
    const facilitySpaceNotZero = (facility.totalSpace !== 0 && facility.monitoredSpace !== 0)

    if (facilitySpaceGiven && facilitySpaceNotZero) {
      const total = facility.totalSpace
      const monitored = facility.monitoredSpace
      const percentage = Math.round((facility.monitoredSpace * 100) / facility.totalSpace)
      return `Monitored: ${monitored}ft\u00b2/${total}ft\u00b2 (${percentage}%)`
    }
    return 'Nothing to display'
  }

  const facilitiesWithGeolocation = Object.values(facilities).filter(
    fac => fac.latitude && fac.longitude && !deactivatedOrganizationsIds.includes(fac.organization)
  )

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY || ''
  })

  const loadHandler = map => {
    setMapRef(map)
  }

  const markerLoadHandler = (marker, facility) => {
    setMarkerMap(prevState => ({ ...prevState, [facility.id]: marker }))
  }

  const markerClickHandler = facility => {
    mapRef.zoom = 6
    setCenter({ lat: facility.latitude, lng: facility.longitude })
    if (selectedFacility) setSelectedFacility(null)
    setSelectedFacility(facility)
    setInfoOpenFromClick(true)
  }

  const markerOverHandler = facility => {
    if (!infoOpenFromClick) {
      setSelectedFacility(facility)
    }
  }

  const markerOutHandler = () => {
    if (!infoOpenFromClick) {
      setSelectedFacility(null)
    }
  }

  const onCloseHandler = () => {
    setSelectedFacility(null)
    setInfoOpenFromClick(false)
  }

  const clusterOverHandler = cluster => {
    setSelectedFacility(null)
    setInfoOpenFromClick(false)
    setSelectedCluster(cluster)
  }

  const clusterOutHandler = () => {
    setSelectedCluster(null)
  }

  const getFacilitesFromCluster = cluster => {
    const uniqueFacilities = []

    cluster.markers?.forEach(marker => {
      const latitude = marker.position.lat()
      const longitude = marker.position.lng()
      facilitiesWithGeolocation.forEach(facility => {
        if (facility.latitude === latitude && facility.longitude === longitude) {
          if (!uniqueFacilities.some(uniqueFac => uniqueFac.id === facility.id)) {
            uniqueFacilities.push(facility)
          }
        }
      })
    })
    return uniqueFacilities
  }

  const renderMap = () => (
    <GoogleMap
      onLoad={loadHandler}
      center={center}
      options={{ styles: mapStyles }}
      zoom={zoomLevel}
      mapContainerStyle={{
        width: '100vw',
        height: widths.window < 1350 ? '73vh' : '78vh'
      }}
    >
      <MarkerClusterer
        onMouseOver={clusterOverHandler}
        onFocus={clusterOverHandler}
        onMouseOut={clusterOutHandler}
        onBlur={clusterOutHandler}
        onClick={clusterOutHandler}
      >
        {clusterer => facilitiesWithGeolocation.map(fac => (
          <Marker
            key={fac.id}
            clusterer={clusterer}
            position={{ lat: fac.latitude, lng: fac.longitude }}
            onLoad={marker => markerLoadHandler(marker, fac)}
            onClick={() => markerClickHandler(fac)}
            onMouseOver={() => markerOverHandler(fac)}
            onFocus={() => markerOverHandler(fac)}
            onMouseOut={markerOutHandler}
            onBlur={markerOutHandler}
          />
        ))}
      </MarkerClusterer>
      {selectedFacility && (
        <InfoWindow anchor={markerMap[selectedFacility.id]} onCloseClick={onCloseHandler}>
          <MarkerToolTip
            facility={selectedFacility}
            organizations={organizations}
            monitoredSpace={getMonitoredSpace(selectedFacility)}
          />
        </InfoWindow>
      )}
      {selectedCluster && (
        <InfoWindow position={{ lat: selectedCluster.center.lat(), lng: selectedCluster.center.lng() }}>
          <ClusterToolTip
            organizations={organizations}
            facilities={getFacilitesFromCluster(selectedCluster)}
          />
        </InfoWindow>
      )}
    </GoogleMap>
  )

  return (isLoaded) ? renderMap() : null
}

export default React.memo(CustomerSiteMap)
