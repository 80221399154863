import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

export const DataDialog = props => {
  const { data, duration, unit, startTime } = props
  const [packets, setPackets] = useState([])

  useEffect(() => {
    const packetList = []
    data.forEach(x => {
      const date = new Date(`${x.beat} UTC`)
      const isAfterStart = date >= startTime
      const isBeforeEnd = date <= moment(startTime).add(duration.value, unit.value).toDate()
      if (isAfterStart && isBeforeEnd) {
        packetList.push(x)
      }
    })
    setPackets(packetList)
  }, [data])

  return (
    <Dialog open onClose={() => props.onClose()}>
      <DialogTitle>
        Packets recorded:
      </DialogTitle>
      <DialogContent>
        {packets.map(packet => (
          <div key={packet.beat} style={{ borderBottomStyle: 'groove', borderBottomColor: '#338edc' }}>
            <pre>
              {JSON.stringify(packet, null, 4)}
            </pre>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="outlined" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DataDialog.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  duration: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  }).isRequired,
  unit: PropTypes.objectOf(PropTypes.string).isRequired,
  startTime: PropTypes.instanceOf(Date).isRequired,
  onClose: PropTypes.func.isRequired
}
