import { useEffect, useRef, useState } from 'react'

import memoizeOne from 'memoize-one'
import { pick, prop, uniq } from 'ramda'

import createLogger from '~/Lib/Logging'
import {
  defer,
  deferUntil,
  EMPTY_OBJECT,
  usePrevious,
} from '~/Lib/Utils'
import { shallowEquals } from '~/Lib/Utils/equality'

import { getXTicks } from './labels'
import {
  getXDomain,
  getXOffset,
  getXScale,
  getYScale,
} from './utils'

export const ANNOTATIONS_HEIGHT = 36
export const ANNOTATION_WIDTH = 36
const logger = createLogger('Chart#useChartState')

const bboxKeys = ['width', 'height', 'top', 'left']
const bboxPicker = pick(bboxKeys)
const getWidth = prop('width')

const getHaveGraphs = memoizeOne(
  ({ chartData }) => chartData.graphs
    && chartData.graphs.some(
      graph => chartData.data[graph.id] && chartData.data[graph.id].length
    )
)

const getResponseIsEmpty = memoizeOne(
  ({ chartData }) => !!chartData.data && !Object.keys(chartData.data).length
)

const getDataTypes = memoizeOne(({
  activeNotification,
  activeTargetRange,
  selectedDataTypes,
}) => ({
  activeDataTypes: uniq([
    ...selectedDataTypes,
    activeNotification?.dataTypeKey,
    activeTargetRange?.dataTypeKey
  ].filter(Boolean)),
  selectedDataTypes
}))

const GRAPH_PROPS_KEYS = [
  'activeNotification',
  'activeTargetRange',
  'classes',
  'dataTypes',
  'locale',
  'highlightTimeframe',
  'individualGraphs',
  'mainGraphZone',
  'showYAxis',
  'showRoomAvg',
  'setFromAndTo',
  'graphCount',
]
const getGraphProps = memoizeOne(({ width: breakpoint, ...props }) => ({
  ...pick(GRAPH_PROPS_KEYS, props),
  breakpoint,
  ...getDataTypes(props),
}))

const isSameBbox = (prev, curr) => shallowEquals(prev, curr)

export default props => {
  const [containerBbox, setContainerBbox] = useState(EMPTY_OBJECT)
  const [transitioning, setTransitioning] = useState(false)
  const containerRef = useRef(null)
  const sidebarRef = useRef(null)
  const { growlog, individualGraphs, journalOpen, loading } = props
  const prevIndividualGraphs = usePrevious(individualGraphs)
  const prevJournalOpen = usePrevious(journalOpen)

  const { width: chartWidth, height } = containerBbox
  const chartHeight = growlog ? (height || 200) : height

  const graphProps = getGraphProps(props)
  const haveGraphs = getHaveGraphs(props)
  const responseIsEmpty = getResponseIsEmpty(props)
  const xDomain = getXDomain(props.chartData)
  const xOffset = getXOffset(props)
  const xTicks = getXTicks(props)
  const xScale = getXScale(props, containerBbox, xOffset)
  const yScale = getYScale(props, containerBbox, chartHeight)

  useEffect(() => {
    defer(() => setContainerBbox(oldBbox => {
      const bbox = containerRef.current.getBoundingClientRect()
      const newBbox = bboxPicker(bbox)
      if (isSameBbox(oldBbox, newBbox)) {
        return oldBbox
      }
      return newBbox
    }), defer.priorities.low)
  }, [props.dimensions.width, haveGraphs, loading])
  const transitionTimer = useRef(Infinity)
  useEffect(() => {
    if (
      (typeof prevIndividualGraphs !== 'boolean' && typeof prevJournalOpen !== 'boolean')
      || !sidebarRef.current
    ) {
      if (transitioning) setTransitioning(false)
      return
    }
    if (individualGraphs !== prevIndividualGraphs || journalOpen !== prevJournalOpen) {
      const originalWidth = getWidth(
        sidebarRef.current.getBoundingClientRect()
      )
      let prevWidth = originalWidth
      setTransitioning(true)
      transitionTimer.current = Date.now()
      deferUntil(
        () => setTransitioning(false),
        () => {
          const currWidth = getWidth(
            sidebarRef.current.getBoundingClientRect()
          )
          if (currWidth === originalWidth) {
            return false
          }
          if (currWidth !== prevWidth) {
            prevWidth = currWidth
            return false
          }

          if (Date.now() - transitionTimer.current >= 500) {
            transitionTimer.current = Infinity
            return true
          }
          return false
        },
        defer.priorities.high,
        500
      )
      return
    }
    setTransitioning(false)
  }, [individualGraphs, journalOpen])

  return {
    chartWidth,
    chartHeight,
    containerBbox,
    containerRef,
    graphProps,
    haveGraphs,
    responseIsEmpty,
    xDomain,
    xOffset,
    xScale,
    yScale,
    xTicks,
    transitioning,
  }
}
