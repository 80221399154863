import React, { useState, useEffect } from 'react'
import { isEmpty } from 'ramda'

import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  Table,
  TableFooter,
  TableRow,
  TablePagination,
} from '@material-ui/core'

import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import FacilityForm from '~/Facility/form'
import Icon from '~/UI/Shared/Icon'
import MuiTitle from '~/UI/Shared/MuiTitle'
import Loading from '~/UI/Shared/utils'
import Tooltip from '~/UI/Shared/Tooltip'

import OrganizationForm from './form'
import OrganizationFilters from './filters'
import styles from './styles'

const useStyles = makeStyles(styles)

const OrganizationList = () => {
  const classes = useStyles()

  const [facilityFormOpen, setFacilityFormOpen] = useState(false)
  const [orgFormOpen, setOrgFormOpen] = useState(false)

  const {
    currentOrganizationList,
    facility: currentFacility,
    isGuestStaff,
    lastUsedClientId,
    me,
    organization,
    organizationListIsLoading,
    organizationListPagination,
    doFacilitySave,
    doFacilitySetCurrent,
    doFetchLastUsedClientId,
    doMarkOrganizationListAsOutdated,
    doOrganizationDelete,
    doOrganizationSave,
    doOrganizationSetCurrent,
    doOrganizationFetch,
    doOrganizationListSetPage,
    doUpdateUrl,
  } = useConnect(
    'selectCurrentOrganizationList',
    'selectFacility',
    'selectIsGuestStaff',
    'selectLastUsedClientId',
    'selectMe',
    'selectOrganization',
    'selectOrganizationListIsLoading',
    'selectOrganizationListPagination',
    'doFacilitySave',
    'doFacilitySetCurrent',
    'doFetchLastUsedClientId',
    'doMarkOrganizationListAsOutdated',
    'doOrganizationDelete',
    'doOrganizationSave',
    'doOrganizationSetCurrent',
    'doOrganizationSetSearch',
    'doOrganizationFetch',
    'doOrganizationListSetPage',
    'doUpdateUrl',
  )

  useEffect(() => {
    doFetchLastUsedClientId()
  }, [])

  if (organizationListIsLoading && !currentOrganizationList?.length) {
    return <Loading />
  }

  const handleSaveOrganization = async org => {
    const response = await doOrganizationSave(org)
    doMarkOrganizationListAsOutdated()
    doFetchLastUsedClientId()
    return response
  }

  const handleSaveFacility = async fac => {
    const response = await doFacilitySave(fac)
    doMarkOrganizationListAsOutdated()
    return response
  }

  const handleDeleteOrganization = async org => {
    const response = await doOrganizationDelete(org)
    doMarkOrganizationListAsOutdated()
    return response
  }

  const renderInactiveToolTip = entity => {
    const title = `${entity} has been deactivated`
    return (
      <Tooltip title={title} placement="top-start">
        <Grid item style={{ flexBasis: '35px', textAlign: 'center' }}>
          <Icon icon={InfoOutlinedIcon} color="error" style={{ margin: '0 10px -5px', fontSize: '18px' }} />
        </Grid>
      </Tooltip>
    )
  }

  const onChangePage = (event, page) => {
    doOrganizationListSetPage(
      page === organizationListPagination.page
        ? organizationListPagination.page + 1
        : organizationListPagination.page - 1
    )
  }

  useEffect(() => {
    doOrganizationListSetPage(1)
  }, [])

  return (
    <>
      <FacilityForm
        instance={currentFacility}
        currentOrganization={organization}
        onClose={() => setFacilityFormOpen(false)}
        onSave={handleSaveFacility}
        open={facilityFormOpen}
      />
      <OrganizationForm
        instance={organization}
        featureFlags={me.flags}
        onClose={() => setOrgFormOpen(false)}
        onDelete={handleDeleteOrganization}
        onSave={handleSaveOrganization}
        open={orgFormOpen}
      />
      <Grid container spacing={2} alignItems="center">
        <Grid container spacing={2} justify="space-between" alignItems="center" style={{ paddingRight: '0.8rem' }}>
          <Grid item style={{ flexGrow: 1 }}>
            <MuiTitle title="Organizations" />
          </Grid>
          {lastUsedClientId && (
            <Typography>Last used client ID: <strong>{lastUsedClientId}</strong></Typography>
          )}
          {!isGuestStaff && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  doOrganizationSetCurrent(null)
                  setOrgFormOpen(true)
                }}
              >
                Add Organization
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item style={{ width: '100%' }}>
            <OrganizationFilters />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isEmpty(currentOrganizationList)
            ? (
              <div className={classes.emptyTable}>
                There are no organizations that fit your filters
              </div>
            )
            : currentOrganizationList.map((org, idx) => (
              <React.Fragment key={org.id}>
                {idx > 0 && (<Divider style={{ marginTop: '16px', marginBottom: '8px' }} />)}
                <Grid container spacing={2} alignItems="center">
                  <Grid item style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle1" className="text-normal">
                      {org.name}
                    </Typography>
                    {!org?.active && renderInactiveToolTip(org?.name)}
                  </Grid>
                  <Grid item style={{ flexBasis: '140px' }}>
                    <Typography variant="body1" className="text-normal">
                      Org ID: {org.id}
                    </Typography>
                  </Grid>
                  <Grid item style={{ flexBasis: '140px' }}>
                    <Typography variant="body1" className="text-normal">
                      Client ID: {org.clientId}
                    </Typography>
                  </Grid>
                  <Grid item style={{ flexBasis: '280px' }}>
                    <Typography variant="body1" className="text-normal">
                      Network Address: {org.networkAddress}
                    </Typography>
                  </Grid>
                  <Grid item style={{ flexBasis: '280px' }}>
                    <Typography variant="body1" align="left" className="text-normal">
                      Network Channel: {org.networkChannel}
                    </Typography>
                  </Grid>
                  {!isGuestStaff && (
                    <>
                      <Grid item>
                        <Button
                          color="primary"
                          onClick={() => {
                            doFacilitySetCurrent(null)
                            doOrganizationSetCurrent(org.id)
                            setFacilityFormOpen(true)
                          }}
                          disabled={!org.active}
                        >
                          Add Facility
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          onClick={async () => {
                            const orgEntity = await doOrganizationFetch(org.id)
                            doOrganizationSetCurrent(orgEntity.id)
                            setOrgFormOpen(true)
                          }}
                          disabled={!org.active}
                        >
                          Edit Organization
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
                {org.facilities?.map(facility => (
                  <Paper
                    key={facility.id}
                    className={
                      facility.active ? classes.facilityRow : classes.deactiveFacilityRow
                    }
                    onClick={() => {
                      if (facility.active) {
                        doUpdateUrl(`/facilities/${facility.id}`)
                      }
                      doOrganizationFetch(org.id)
                      doOrganizationSetCurrent(org.id)
                    }}
                  >
                    <Grid container spacing={2} justify="space-between" wrap="nowrap">
                      <Grid item style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle1" align="left" className="text-normal">
                          {facility.name}
                        </Typography>
                        {!facility.active && renderInactiveToolTip(facility.name)}
                      </Grid>
                      <Grid item style={{ flexBasis: '150px' }}>
                        <Typography variant="body1">
                          ID: {facility.id}
                        </Typography>
                      </Grid>
                      <Grid item style={{ flexBasis: '300px' }}>
                        <Typography variant="body1">
                          Location: {facility.location}
                        </Typography>
                      </Grid>
                      <Grid item style={{ flexBasis: '300px' }}>
                        <Typography variant="body1">
                          Total Space: {facility.totalSpace} ft<sup>2</sup>
                        </Typography>
                      </Grid>
                      <Grid item style={{ flexBasis: '150px' }}>
                        <Typography variant="body1">
                          Loggers: {facility.loggerCount}
                        </Typography>
                      </Grid>
                      <Grid item style={{ flexBasis: '150px' }}>
                        <Typography variant="body1">
                          Devices: {facility.deviceCount}
                        </Typography>
                      </Grid>
                      <Grid item style={{ flexBasis: '150px' }}>
                        <Typography variant="body1">
                          Rooms: {facility.roomCount}
                        </Typography>
                      </Grid>
                      <Grid item style={{ flexBasis: '150px' }}>
                        <Typography variant="body1">
                          Members: {facility.membershipCount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
              </React.Fragment>
            ))}
        </Grid>
        {currentOrganizationList?.length ? (
          <Table className={classes.tableFooter}>
            <TableFooter>
              <TableRow>
                <TablePagination
                  nextIconButtonProps={{ disabled: !organizationListPagination.next }}
                  classes={{ spacer: 'hidden' }}
                  count={organizationListPagination.count}
                  rowsPerPage={organizationListPagination.pageSize}
                  rowsPerPageOptions={[]}
                  page={organizationListPagination.page - 1}
                  onChangePage={onChangePage}
                  labelDisplayedRows={() => (
                    `Page ${organizationListPagination.page} of ${organizationListPagination.numPages}`
                  )}
                />
              </TableRow>
            </TableFooter>
          </Table>
        ) : null}
      </Grid>
    </>
  )
}

export default OrganizationList
