import { createRouteBundle, createSelector } from 'redux-bundler'

import Dashboard from '~/Dashboard'
import { Users } from '~/Users'
import DeviceManagement from '~/Device'
import { FacilityDetailComponent as FacilityDetail } from '~/Facility/Detail'
import { MetrcFacilityComponent as MetrcFacility } from '~/Metrc'
import OtapNodeList from '~/Firmware'
import OtapStatusList from '~/Firmware/Status'
import OtapStatusDetail from '~/Firmware/Status/detail'
import { LoggerComponent as Logger } from '~/Logger'
import NodeManagement from '~/Node'
import OrganizationList from '~/Organization'
import SystemStatistic from '~/System'
import Home from '~/Routes/Home'
import Login from '~/Routes/Login'
import NotFound from '~/Routes/NotFound'

export default {
  ...createRouteBundle({
    '/': Home,
    '/users': Users,
    '/dashboard': Dashboard,
    '/dashboard/:objType/:id': Dashboard,
    '/devices': DeviceManagement,
    '/devices/:id': DeviceManagement,
    '/nodes': NodeManagement,
    '/firmware': OtapNodeList,
    '/firmware/logs': OtapStatusList,
    '/firmware/logs/:id': OtapStatusDetail,
    '/loggers': Logger,
    '/facilities/:id': FacilityDetail,
    '/facilities/:id/metrc/:entity': MetrcFacility,
    '/organizations': OrganizationList,
    '/system/:tab': SystemStatistic,
    '/login': { component: Login, viewMode: 'fullscreen', publicAccess: true },
    '*': {
      component: NotFound,
      publicAccess: true,
      title: 'Unable to locate resource',
    },
  }),
  reactUpdateAnalytics: createSelector(
    'selectRouteInfo',
    () => undefined
  )
}
