import { createLogger } from '@meter-ui/utils'
import { isEmpty } from 'ramda'
import reduceReducers from 'reduce-reducers'
import { createSelector } from 'redux-bundler'
import createEntityBundle from '~/Lib/createEntityBundle'
import createListBundle from '~/Lib/createListBundle'
import { sortNestedArrayBy } from '~/Lib/Data'

import { Organization as schema } from '~/Store/Schemas'

const logger = createLogger('Organization/bundle')

const ORG_LIST_SET_ORG_FILTER = 'ORG_LIST_SET_ORG_FILTER'
const ORG_LIST_SET_FAC_FILTER = 'ORG_LIST_SET_FAC_FILTER'
const ORG_LIST_SET_APP_FILTER = 'ORG_LIST_SET_APP_FILTER'
const ORG_LIST_SET_TIER_FILTER = 'ORG_LIST_SET_TIER_FILTER'
const ORG_LIST_SET_SUPPORT_FILTER = 'ORG_LIST_SET_SUPPORT_FILTER'
const ORG_LIST_SET_STATUS_FILTER = 'ORG_LIST_SET_STATUS_FILTER'
const ORG_LIST_SET_CURRENT_ORG = 'ORG_LIST_SET_CURRENT_ORG'
const ORG_LIST_SET_PAGINATION = 'ORG_LIST_SET_PAGINATION'
const ORG_LIST_SET_ORG_COUNT = 'ORG_LIST_SET_ORG_COUNT'
const ORG_LIST_SET_SEARCH = 'ORG_LIST_SET_SEARCH'

const SET_LAST_USED_CLIENT_ID = 'SET_LAST_USED_CLIENT_ID'

const activeFilter = { label: 'Active', value: 'active' }

const additionalState = {
  orgFilter: activeFilter,
  facFilter: activeFilter,
  appFilter: { label: 'Aroya', value: 'AROYA' },
  tierFilter: { label: 'All', value: 'all' },
  supportFilter: { label: 'Internal', value: 'INTERNAL' },
  statusFilter: { label: 'Active', value: 'ACTIVE' },
  currentOrg: null,
  page: 1,
  next: 2,
  previous: null,
  numPages: 0,
  pageSize: 25,
  count: 0,
  lastUsedClientId: null,
}

export const organizations = createEntityBundle({
  name: 'organizations',
  apiConfig: { schema },
})

export const initialOrganizationList = createListBundle({
  entityName: 'organization',
  schema,
  fetchHandler: ({ apiFetch }) => apiFetch('/organizations/')
})

export const organizationList = {
  ...initialOrganizationList,
  reducer: reduceReducers(initialOrganizationList.reducer, (state, action) => {
    switch (action.type) {
      case ORG_LIST_SET_ORG_FILTER:
        return { ...state, orgFilter: action.payload }
      case ORG_LIST_SET_FAC_FILTER:
        return { ...state, facFilter: action.payload }
      case ORG_LIST_SET_APP_FILTER:
        return { ...state, appFilter: action.payload }
      case ORG_LIST_SET_TIER_FILTER:
        return { ...state, tierFilter: action.payload }
      case ORG_LIST_SET_SUPPORT_FILTER:
        return { ...state, supportFilter: action.payload }
      case ORG_LIST_SET_STATUS_FILTER:
        return { ...state, statusFilter: action.payload }
      case ORG_LIST_SET_CURRENT_ORG:
        return { ...state, currentOrg: action.payload }
      case ORG_LIST_SET_PAGINATION:
        return { ...state, ...action.payload }
      case ORG_LIST_SET_ORG_COUNT:
        return { ...state, count: action.payload }
      case ORG_LIST_SET_SEARCH:
        return { ...state, search: action.payload }
      case SET_LAST_USED_CLIENT_ID:
        return { ...state, lastUsedClientId: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  selectOrganizationListOrgFilter: createSelector(
    'selectOrganizationListRaw',
    ({ orgFilter }) => orgFilter
  ),
  selectOrganizationListFacFilter: createSelector(
    'selectOrganizationListRaw',
    ({ facFilter }) => facFilter
  ),
  selectOrganizationListAppFilter: createSelector(
    'selectOrganizationListRaw',
    ({ appFilter }) => appFilter
  ),
  selectOrganizationListTierFilter: createSelector(
    'selectOrganizationListRaw',
    ({ tierFilter }) => tierFilter
  ),
  selectOrganizationListSupportFilter: createSelector(
    'selectOrganizationListRaw',
    ({ supportFilter }) => supportFilter
  ),
  selectOrganizationListStatusFilter: createSelector(
    'selectOrganizationListRaw',
    ({ statusFilter }) => statusFilter
  ),
  selectCurrentOrganizationCultivars: createSelector(
    'selectOrganizations',
    'selectCurrentOrganizationId',
    (orgs, id) => orgs?.[id]?.cultivars
  ),
  selectCurrentOrganizationId: createSelector(
    'selectOrganizationListRaw',
    ({ currentOrg }) => currentOrg
  ),
  selectOrganizationListPagination: createSelector(
    'selectOrganizationListRaw',
    ({ page, next, previous, numPages, pageSize, count }) => (
      { page, next, previous, numPages, pageSize, count }
    )
  ),
  selectOrganizationListSearch: createSelector(
    'selectOrganizationListRaw',
    ({ search }) => search
  ),
  selectDeactivatedOrganizationsIds: createSelector(
    'selectOrganizations',
    orgs => Object.values(orgs)?.filter(org => !org.active).map(org => org.id)
  ),
  selectFilteredOrganizationList: createSelector(
    'selectOrganizationListRaw',
    'selectOrganizations',
    'selectFacilities',
    'selectFacilitiesByOrg',
    ({ search, orgFilter, facFilter, appFilter, tierFilter, supportFilter, statusFilter }, _organizations, facilities, facilitiesByOrg) => {
      const searchRegex = new RegExp(search, 'i')
      let orgs = Object.values(_organizations)
      let facs = Object.values(facilities)

      if (orgFilter.value === 'active') orgs = orgs.filter(org => org.active)
      if (orgFilter.value === 'deactive') orgs = orgs.filter(org => !org.active)
      if (facFilter.value === 'active') facs = facs.filter(fac => fac.active)
      if (facFilter.value === 'deactive') facs = facs.filter(fac => !fac.active)
      if (facFilter.value === 'outdoor') facs = facs.filter(fac => fac.outdoor)
      if (appFilter.value) facs = facs.filter(fac => fac.app === appFilter.value)
      if (tierFilter.value === '') facs = facs.filter(fac => !fac.tier)
      if (tierFilter.value && tierFilter.value !== 'all') facs = facs.filter(fac => fac.tier === tierFilter.value)
      if (supportFilter.value) facs = facs.filter(fac => fac.supportCategory === supportFilter.value)
      if (statusFilter.value && statusFilter.value !== 'all') facs = facs.filter(fac => fac.status === statusFilter.value)

      facs = sortNestedArrayBy('name', facs)

      const matchingFacilities = (fac, org) => (
        (searchRegex.test(fac.name ?? '')
          || searchRegex.test(fac.id)
          || searchRegex.test(fac.crmFacilityId))
        && fac.organization === org.id
      )

      if (search) {
        orgs = orgs.filter(org => (
          searchRegex.test(org.name)
          || searchRegex.test(org.clientId)
          || searchRegex.test(org.networkAddress)
          || searchRegex.test(org.networkChannel)
          || !(org.id in facilitiesByOrg)
          || facilitiesByOrg[org.id].find(fac => matchingFacilities(fac, org))
        ))
      }

      const orgList = orgs.map(org => {
        const scopedFacilities = !org.name?.toLowerCase().includes(search.toLowerCase())
          ? facs.filter(fac => matchingFacilities(fac, org))
          : facs.filter(fac => fac.organization === org.id)
        return ({ ...org, facilities: scopedFacilities })
      })

      const isNotDefault = (facFilter.value !== additionalState.facFilter.value
        || tierFilter.value !== additionalState.tierFilter.value
        || statusFilter.value !== additionalState.statusFilter.value
        || supportFilter.value !== additionalState.supportFilter.value)

      if (isNotDefault) {
        return sortNestedArrayBy('name', orgList.filter(({ facilities: orgFacilities, id: orgId }) => !isEmpty(orgFacilities) || !(orgId in facilitiesByOrg)))
      }

      return sortNestedArrayBy('name', orgList)
      // return sortNestedArrayBy('name', orgList)
    }
  ),
  selectCurrentOrganizationList: createSelector(
    'selectOrganizationListRaw',
    'selectFilteredOrganizationList',
    ({ page, pageSize }, orgList) => (
      orgList.slice(((pageSize * page) - pageSize), (pageSize * page))
    )
  ),
  selectFullOrganizationList: createSelector(
    'selectOrganizations',
    'selectFacilities',
    (_organizations, facilities) => {
      const orgs = sortNestedArrayBy('name', Object.values(_organizations))
      return orgs.map(org => ({
        ...org,
        facilities: sortNestedArrayBy('name', Object.values(facilities))
          .filter(fac => fac.organization === org.id)
      }))
    }
  ),
  doOrganizationListSetPage: page => ({ store, dispatch }) => {
    const { pageSize } = store.selectOrganizationListRaw()
    const currOrgCount = store.selectFilteredOrganizationList()?.length
    const pages = Math.ceil(currOrgCount / pageSize)

    const pagination = {
      page,
      numPages: pages,
      next: page === pages ? null : page + 1,
      previous: page === 1 ? null : page - 1,
      count: currOrgCount,
    }
    dispatch({ type: ORG_LIST_SET_PAGINATION, payload: pagination })
  },
  doOrganizationListSetOrgFilter: filter => ({ store, dispatch }) => {
    if (filter === store.selectOrganizationListOrgFilter()) return
    dispatch({ type: ORG_LIST_SET_ORG_FILTER, payload: filter })
    store.doOrganizationListSetPage(1)
  },
  doOrganizationListSetFacFilter: filter => ({ store, dispatch }) => {
    if (filter === store.selectOrganizationListFacFilter()) return
    dispatch({ type: ORG_LIST_SET_FAC_FILTER, payload: filter })
    store.doOrganizationListSetPage(1)
  },
  doOrganizationListSetAppFilter: filter => ({ store, dispatch }) => {
    if (filter === store.selectOrganizationListAppFilter()) return
    dispatch({ type: ORG_LIST_SET_APP_FILTER, payload: filter })
    store.doOrganizationListSetPage(1)
  },
  doOrganizationListSetTierFilter: filter => ({ store, dispatch }) => {
    if (filter === store.selectOrganizationListTierFilter()) return
    dispatch({ type: ORG_LIST_SET_TIER_FILTER, payload: filter })
    store.doOrganizationListSetPage(1)
  },
  doOrganizationListSetSupportFilter: filter => ({ store, dispatch }) => {
    if (filter === store.selectOrganizationListSupportFilter()) return
    dispatch({ type: ORG_LIST_SET_SUPPORT_FILTER, payload: filter })
    store.doOrganizationListSetPage(1)
  },
  doOrganizationListSetStatusFilter: filter => ({ store, dispatch }) => {
    if (filter === store.selectOrganizationListStatusFilter()) return
    dispatch({ type: ORG_LIST_SET_STATUS_FILTER, payload: filter })
    store.doOrganizationListSetPage(1)
  },
  doOrganizationSetCurrent: id => ({ store, dispatch }) => {
    if (id === store.selectCurrentOrganizationId()) return
    dispatch({ type: ORG_LIST_SET_CURRENT_ORG, payload: id })
  },
  doOrganizationSetSearch: search => ({ store, dispatch }) => {
    dispatch({ type: ORG_LIST_SET_SEARCH, payload: search })
    store.doOrganizationListSetPage(1)
  },
  doFetchLastUsedClientId: () => async ({ apiFetch, dispatch }) => {
    try {
      const result = await apiFetch('/organizations/last_used_client_id/')
      dispatch({ type: SET_LAST_USED_CLIENT_ID, payload: result.clientId_Max })
    } catch (error) {
      console.error(error)
    }
  },
  selectLastUsedClientId: createSelector(
    'selectOrganizationListRaw',
    ({ lastUsedClientId }) => lastUsedClientId,
  ),
}
