import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import { ENTITIES_RECEIVED } from '~/Lib/createEntityBundle'
import { Device } from '~/Store/Schemas'

const OTAP_NODE_LIST_SET_DEVICE_TYPE = 'OTAP_NODE_LIST_SET_DEVICE_TYPE'
const OTAP_NODE_LIST_SET_FACILITY = 'OTAP_NODE_LIST_SET_FACILITY'
const OTAP_NODE_LIST_SET_IMAGE = 'OTAP_NODE_LIST_SET_IMAGE'
const OTAP_NODE_LIST_SET_PAGE = 'OTAP_NODE_LIST_SET_PAGE'
const OTAP_NODE_LIST_SET_SELECTED_NODES = 'OTAP_NODE_LIST_SET_SELECTED_NODES'
const OTAP_NODE_LIST_SET_SEARCH = 'OTAP_NODE_LIST_SET_SEARCH'
const OTAP_NODE_LIST_SET_ONLINE = 'OTAP_NODE_LIST_SET_ONLINE'
const OTAP_NODE_LIST_SET_VERSION = 'OTAP_NODE_LIST_SET_VERSION'
const OTAP_NODE_LIST_FETCH_FACILITY_NODES_STARTED = 'OTAP_NODE_LIST_FETCH_FACILITY_NODES_STARTED'
const OTAP_NODE_LIST_FETCH_FACILITY_NODES_SUCCEEDED = 'OTAP_NODE_LIST_FETCH_FACILITY_NODES_SUCCEEDED'
const OTAP_NODE_LIST_FETCH_FACILITY_NODES_FAILED = 'OTAP_NODE_LIST_FETCH_FACILITY_NODES_FAILED'
const OTAP_NODE_LIST_SET_SELECTED_FACILITY = 'OTAP_NODE_LIST_SET_SELECTED_FACILITY'
const OTAP_NODE_LIST_CLEAR_FACILITY_NODES = 'OTAP_NODE_LIST_CLEAR_FACILITY_NODES'

const additionalState = {
  currentPage: 1,
  deviceTypeFilter: 'puck',
  facilityFilter: undefined,
  selectedImage: undefined,
  selectedNodes: [],
  searchTerms: '',
  onlineFilter: null,
  retiredFilter: null,
  wirepasFilter: null,
  versionFilter: null
}

const otapNodeListBundle = createAsyncResourceBundle({
  name: 'otapNodeList',
  actionBaseType: 'OTAP_NODE_LIST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, getState, dispatch }) => {
    const { otapNodeList } = getState()
    const {
      currentPage,
      deviceTypeFilter,
      facilityFilter,
      searchTerms,
      onlineFilter,
      retiredFilter,
      wirepasFilter,
      versionFilter,
      versionNumber,
    } = otapNodeList

    /* eslint-disable babel/camelcase */
    return apiFetch('/devices/', {
      nodes: true,
      facility_id: facilityFilter,
      model__puck: deviceTypeFilter === 'puck',
      model__sink: deviceTypeFilter === 'sink',
      page: currentPage,
      search: searchTerms,
      online: onlineFilter,
      retired: retiredFilter,
      wirepas: wirepasFilter,
      version_filter: versionFilter,
      version_number: versionNumber,
    }).then(payload => {
      const { entities } = normalize(payload.results, [Device])
      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      return payload
    })
    /* eslint-enable babel/camelcase */
  }
})

export default {
  ...otapNodeListBundle,
  reducer: reduceReducers(otapNodeListBundle.reducer, (state, action) => {
    switch (action.type) {
      case OTAP_NODE_LIST_SET_DEVICE_TYPE:
        return {
          ...state,
          currentPage: 1,
          data: null,
          deviceTypeFilter: action.payload,
          selectedImage: undefined,
          selectedNodes: [],
          selectedFacility: [],
          facilityNodes: {},
        }
      case OTAP_NODE_LIST_SET_FACILITY:
        return { ...state, currentPage: 1, data: null, facilityFilter: action.payload }
      case OTAP_NODE_LIST_SET_IMAGE:
        return { ...state, selectedImage: action.payload }
      case OTAP_NODE_LIST_SET_PAGE:
        return { ...state, currentPage: action.payload }
      case OTAP_NODE_LIST_SET_SELECTED_NODES:
        return { ...state, selectedNodes: action.payload }
      case OTAP_NODE_LIST_SET_SEARCH:
        return { ...state, searchTerms: action.payload }
      case OTAP_NODE_LIST_SET_ONLINE:
        return { ...state, onlineFilter: action.payload }
      case OTAP_NODE_LIST_SET_VERSION:
        return { ...state, ...action.payload }
      case OTAP_NODE_LIST_FETCH_FACILITY_NODES_SUCCEEDED:
        return { ...state, facilityNodes: action.payload, isLoading: false }
      case OTAP_NODE_LIST_CLEAR_FACILITY_NODES:
        return { ...state, facilityNodes: action.payload }
      case OTAP_NODE_LIST_FETCH_FACILITY_NODES_STARTED:
        return { ...state, isLoading: true }
      case OTAP_NODE_LIST_SET_SELECTED_FACILITY:
        return { ...state, selectedFacility: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doOtapNodeListSetSearch: search => ({ dispatch, store }) => {
    if (search === store.selectOtapNodeListSearch()) return
    dispatch({ type: OTAP_NODE_LIST_SET_SEARCH, payload: search })
    store.doMarkOtapNodeListAsOutdated()
  },
  doOtapNodeListSetOnlineFilter: online => ({ dispatch, store }) => {
    if (online === store.selectOtapNodeListOnlineFilter()) return
    dispatch({ type: OTAP_NODE_LIST_SET_ONLINE, payload: online })
    store.doMarkOtapNodeListAsOutdated()
  },
  doOtapNodeListSetVersionFilter: filter => ({ dispatch, store }) => {
    if (filter === store.selectOtapNodeListVersionFilter()) return
    let payload = { versionFilter: null, versionNumber: null }
    if (filter) {
      payload = { versionFilter: filter, versionNumber: store.selectMe()?.latestFwVersion }
    }
    dispatch({ type: OTAP_NODE_LIST_SET_VERSION, payload })
    store.doMarkOtapNodeListAsOutdated()
  },
  doOtapNodeListSetDeviceType: filter => ({ dispatch, store }) => {
    store.doOtapNodeListClearFacilityNodes()
    if (filter === store.selectOtapNodeListDeviceTypeFilter() || !filter) return

    dispatch({ type: OTAP_NODE_LIST_SET_DEVICE_TYPE, payload: filter })
    store.doMarkOtapNodeListAsOutdated()
    store.doClearOtapImageList()
  },
  doOtapNodeListSetFacilityFilter: filter => ({ dispatch, store }) => {
    if (filter === store.selectOtapNodeListFacilityFilter()) return

    dispatch({ type: OTAP_NODE_LIST_SET_FACILITY, payload: filter })
    store.doMarkOtapNodeListAsOutdated()
  },
  doOtapNodeListSetPage: page => ({ dispatch, store }) => {
    dispatch({ type: OTAP_NODE_LIST_SET_PAGE, payload: page })
    store.doMarkOtapNodeListAsOutdated()
  },
  doOtapNodeListSetSelectedNodes: nodes => ({ dispatch }) => {
    dispatch({ type: OTAP_NODE_LIST_SET_SELECTED_NODES, payload: nodes })
  },
  doOtapNodeListSetImage: image => ({ dispatch }) => {
    dispatch({ type: OTAP_NODE_LIST_SET_IMAGE, payload: image })
  },
  doOtapNodeListSetSelectedFacility: facs => ({ dispatch }) => {
    dispatch({ type: OTAP_NODE_LIST_SET_SELECTED_FACILITY, payload: facs })
  },
  doOtapNodeListClearFacilityNodes: () => async ({ dispatch }) => {
    dispatch({ type: OTAP_NODE_LIST_CLEAR_FACILITY_NODES, payload: {} })
  },
  doOtapNodeListFetchNodesForFacility: facId => async ({ apiFetch, dispatch, store }) => {
    dispatch({ type: OTAP_NODE_LIST_FETCH_FACILITY_NODES_STARTED })
    let response = null
    const deviceTypeFilter = store.selectOtapNodeListDeviceTypeFilter()
    /* eslint-disable babel/camelcase */
    const payload = {
      nodes: true,
      pagination: 0,
      facility_id: facId,
      model__puck: deviceTypeFilter === 'puck',
      model__sink: deviceTypeFilter === 'sink',
    }
    /* eslint-enable babel/camelcase */
    try {
      response = await apiFetch('/devices/', payload)
      const existingNodes = store.selectOtapNodesForFacility()
      const formattedResponse = { ...existingNodes, [facId]: response }
      dispatch({ type: OTAP_NODE_LIST_FETCH_FACILITY_NODES_SUCCEEDED, payload: formattedResponse })
    } catch (error) {
      dispatch({ type: OTAP_NODE_LIST_FETCH_FACILITY_NODES_FAILED, payload: error })
    }
    return response
  },
  doOtapSubmitUpdate: () => async ({ apiFetch, store }) => {
    try {
      const payload = {
        imagePath: store.selectOtapNodeListSelectedImagePath(),
        nodeAddresses: store.selectOtapNodeListSelectedNodes(),
      }

      const response = await apiFetch('/firmware/', payload, { method: 'POST' })

      if (response.success) {
        store.doAddSnackbarMessage('Successfully submitted update request')
        store.doUpdateUrl(`/firmware/logs/${response.requestId}`)
      } else {
        store.doAddSnackbarMessage(`Error processing update request: ${response.message}`)
      }
      return response
    } catch (error) {
      return error
    }
  },
  doOtapSubmitBulkUpdates: () => async ({ apiFetch, store }) => {
    try {
      const payload = {
        imagePath: store.selectOtapNodeListSelectedImagePath(),
      }
      const response = await apiFetch('/firmware/bulk', payload, { method: 'POST' })

      if (response.success) {
        store.doAddSnackbarMessage('Successfully submitted update request to all nodes.')
        store.doUpdateUrl('/firmware/logs')
      } else {
        store.doAddSnackbarMessage(`Error processing update request: ${response.message}`)
      }
      return response
    } catch (error) {
      return error
    }
  },
  reactOtapNodeListFetch: createSelector(
    'selectAuth',
    'selectOtapNodeListShouldUpdate',
    'selectRouteInfo',
    ({ authenticated }, shouldUpdate, { url }) => {
      if (authenticated && shouldUpdate && url === '/firmware') {
        return { actionCreator: 'doFetchOtapNodeList' }
      }
      return undefined
    }
  ),
  selectOtapNodeListSearch: createSelector(
    'selectOtapNodeListRaw',
    ({ searchTerms }) => searchTerms
  ),
  selectOtapNodeListOnlineFilter: createSelector(
    'selectOtapNodeListRaw',
    ({ onlineFilter }) => onlineFilter
  ),
  selectOtapNodeListVersionFilter: createSelector(
    'selectOtapNodeListRaw',
    ({ versionFilter }) => versionFilter?.split('-')[0]
  ),
  selectOtapNodeListLatestVersion: createSelector(
    'selectMe',
    me => me?.latestFwVersion
  ),
  selectOtapNodeListDeviceTypeFilter: createSelector(
    'selectOtapNodeListRaw',
    ({ deviceTypeFilter }) => deviceTypeFilter
  ),
  selectOtapNodeListFacilityFilter: createSelector(
    'selectOtapNodeListRaw',
    ({ facilityFilter }) => facilityFilter
  ),
  selectOtapNodeListSelectedImage: createSelector(
    'selectOtapNodeListRaw',
    ({ selectedImage }) => selectedImage
  ),
  selectOtapNodeListSelectedImagePath: createSelector(
    'selectOtapNodeListSelectedImage',
    image => image?.value
  ),
  selectOtapNodeListSelectedNodes: createSelector(
    'selectOtapNodeListRaw',
    ({ selectedNodes }) => selectedNodes
  ),
  selectOtapNodeListSelectedFacility: createSelector(
    'selectOtapNodeListRaw',
    ({ selectedFacility }) => selectedFacility
  ),
  selectOtapNodesForFacility: createSelector(
    'selectOtapNodeListRaw',
    ({ facilityNodes }) => facilityNodes
  ),
}
