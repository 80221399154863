import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'

import { ENTITIES_RECEIVED } from '~/Lib/createEntityBundle'
import { User } from '~/Store/Schemas'

const meBundle = createAsyncResourceBundle({
  name: 'me',
  retryAfter: ms.seconds(10),
  persist: true,
  getPromise: ({ dispatch, apiFetch }) => apiFetch('/me/').then(payload => {
    const { entities, result } = normalize(payload, User)
    dispatch({ type: ENTITIES_RECEIVED, payload: entities })
    return { ...payload, results: result }
  }),
})

export default {
  ...meBundle,
  reactMeFetch: createSelector(
    'selectAuth',
    'selectMeShouldUpdate',
    ({ authenticated }, shouldUpdate) => {
      if (authenticated && shouldUpdate) {
        return { actionCreator: 'doFetchMe' }
      }
      return undefined
    }
  ),
  selectIsGuestStaff: createSelector(
    'selectMe',
    me => {
      if (me !== null) {
        return me.isGuestStaff
      }
      return undefined
    }
  ),
}
