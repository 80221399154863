/* eslint-disable no-loop-func */
import React, { useEffect } from 'react'

import { useConnect } from 'redux-bundler-hook'
import { EMPTY_ARRAY } from '~/Lib/Utils'

import T from '~/UI/Shared/Typography'

const searchDeviceInTopology = ({ children, device }) => {
  if (!children.length) return false
  let newChildren = []
  let currentChildren = children
  let deviceTopology = false

  while (!deviceTopology && currentChildren.length) {
    currentChildren.forEach(({ attributes, ...child }) => {
      if (attributes.serial_number === device.serialNumber) {
        deviceTopology = child
      }
      newChildren = child?.children ? [...newChildren, ...child.children] : newChildren
    })

    if (!deviceTopology) {
      currentChildren = newChildren
      newChildren = []
    }
  }
  return deviceTopology
}

const RoutingData = ({ deviceTopology }) => {
  const children = deviceTopology?.children ? deviceTopology?.children.length : 0
  return (
    <T>{`No. devices routing: ${children}`}</T>
  )
}

const Connected = ({ device }) => {
  const {
    facilities,
    topology,
    doFetchFacilityTopology,
  } = useConnect(
    'selectFacilities',
    'selectTopology',
    'doFetchFacilityTopology',
  )
  const { [device.facility]: deviceFacility } = facilities
  const children = topology?.children ?? EMPTY_ARRAY
  const deviceTopology = searchDeviceInTopology({ children, device })

  useEffect(() => {
    doFetchFacilityTopology(deviceFacility)
  }, [deviceFacility])
  return <RoutingData deviceTopology={deviceTopology} />
}

const Memoized = React.memo(Connected)
export default Memoized
