/* eslint-disable no-restricted-syntax */
import getGlobal from '~/Lib/getGlobal'

const container = {}
const storageTypes = ['session', 'local']

class BothError extends TypeError {
  constructor(operation) {
    super(
      [
        'safeStorage() only performs key => value reads, preferring sessionStorage.',
        `"${operation}" is invalid in this context`
      ].join('\n')
    )
  }
}

const safeStorage = which => {
  if (which == null) {
    return new Proxy({
      clear() {
        throw new BothError('clear()')
      },
      getItem(key) {
        let value
        for (const storageType of storageTypes) {
          value = safeStorage(storageType).getItem(key)
          if (value !== null) return value
        }
        return value
      },
      key() {
        throw new BothError('key(index)')
      },
      get length() {
        throw new BothError('length()')
      },
      removeItem() {
        throw new BothError('removeItem(key)')
      },
      setItem() {
        throw new BothError('setItem(key, value)')
      }
    }, {
      deleteProperty(target, key) {
        throw new BothError(`delete safeStorage()['${key}']`)
      },
      get(target, key) {
        return key in target ? target[key] : target.getItem(key)
      },
      has(target, key) {
        return key in target || target.getItem(key) !== null
      },
      set(target, key, value) {
        target.setItem(key, value)
      },
    })
  }

  if (!storageTypes.includes(which)) {
    throw new TypeError([
      `Error: "${which}" is not a valid storage type.`,
      `Please use one of ${JSON.stringify(storageTypes)}.`
    ].join(' '), '~/src/Lib/safeStorage.js', 5)
  }

  const root = getGlobal()
  if (`${which}Storage` in root === false) {
    if (!(which in container)) {
      const CONTAINER = Symbol('CONTAINER')
      let myContainer
      container[which] = new Proxy({
        clear() {
          // eslint-disable-next-line no-multi-assign
          myContainer = container[which][CONTAINER] = {}
        },
        getItem(key) {
          if (key in myContainer) return myContainer[key]
          return null
        },
        key(index) {
          return Object.keys(this[CONTAINER])[index] ?? null
        },
        get length() {
          return Object.keys(myContainer).length
        },
        removeItem(key) {
          delete this[key]
        },
        setItem(key, value) {
          this[key] = value
          return String(value)
        }
      }, {
        deleteProperty(target, key) {
          if (!(key in target[CONTAINER])) return false
          return delete target[CONTAINER][key]
        },
        get(target, key) {
          if (key === CONTAINER) return undefined
          if (key in target) {
            return target[key]
          }
          return target[CONTAINER][key]
        },
        has(target, key) {
          if (key in target && key !== CONTAINER) {
            return true
          }
          return key in target[CONTAINER]
        },
        set(target, key, value) {
          if (key === CONTAINER) {
            target[CONTAINER] = value
          }
          target[CONTAINER][key] = String(value)
          return value
        },
      })
      // eslint-disable-next-line no-multi-assign
      myContainer = container[which][CONTAINER] = {}
    }
    return container[which]
  }
  return root[`${which}Storage`]
}
getGlobal().safeStorage = safeStorage

export default safeStorage
