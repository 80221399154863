import {
  ENTITIES_CLEARED,
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  entityReducerFactory,
  entitySelectorsFactory,
} from '~/Lib/createEntityBundle'

export const names = [
  'models',
  'units',
  'rooms',
  'zones',
]

const selectors = names.reduce(
  (acc, name) => ({ ...acc, ...entitySelectorsFactory({ name }) }),
  {}
)

export const bundle = {
  name: 'entities',
  reducer: entityReducerFactory(),
  persistActions: [ENTITIES_RECEIVED, ENTITIES_REMOVED],
  selectEntities: (state = {}) => state.entities,
  doClearEntities: () => ({ type: ENTITIES_CLEARED }),
  ...selectors,
}

export default bundle
