import React, { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import { CheckCircle, Error as ErrorIcon, Help } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import { memoize } from '~/Lib/Utils'
import Sensor from '~/UI/Icons/Sensor'
import Icon from '~/UI/Shared/Icon'

import styles from './styles'

const useStyles = makeStyles(styles)

const getStatusIcon = memoize(level => {
  let IconComponent = CheckCircle
  let iconProps = { color: 'primary' }

  if (level === 'warning') {
    IconComponent = ErrorIcon
    iconProps = { color: 'error' }
  } else if (level === 'unknown') {
    IconComponent = Help
    iconProps = { style: { color: '#888' } }
  }

  return () => <Icon icon={IconComponent} {...iconProps} />
})

const CheckCard = props => {
  const classes = useStyles()
  const { check, setDeviceInfo } = props

  const StatusIcon = getStatusIcon(check.status)

  const getStatusColorProps = memoize(level => {
    if (level === 'warning') return ({ color: 'error' })
    if (level === 'unknown') return ({ className: classes.badgeColorUnknown })
    return ({ color: 'primary' })
  })

  return (
    <Grid item xs>
      <Paper className={classes.card}>
        <div className="text-center">
          <StatusIcon />
        </div>
        <Typography variant="subtitle1" color="inherit" className="text-center">
          { check.label }
        </Typography>
        {check.items?.map(item => (
          <Button
            key={item.label}
            color="inherit"
            style={{ paddingRight: '20px', width: '100%' }}
            onClick={() => setDeviceInfo(item)}
          >
            <Grid container>
              <Grid item className="text-left" style={{ flexGrow: 1, textAlign: 'left' }}>
                { item.label }
              </Grid>
              <Grid item>
                <Badge
                  badgeContent={item.devices.length}
                  {...getStatusColorProps(item.status)}
                  max={999}
                />
              </Grid>
            </Grid>
          </Button>
        ))}
      </Paper>
    </Grid>
  )
}

const Connected = props => {
  const { checklist } = props

  const [deviceInfo, setDeviceInfo] = useState(null)

  const { devices } = useConnect('selectDevices')

  return (
    <>
      <Dialog
        open={Boolean(deviceInfo)}
        onClose={() => setDeviceInfo(null)}
        fullWidth
        maxWidth="sm"
        scroll="body"
      >
        <DialogTitle>
          { deviceInfo?.title || deviceInfo?.label }
        </DialogTitle>
        <DialogContent>
          { deviceInfo?.helpText && (
            <Typography variant="body2" className="linebreaksbr">{ deviceInfo.helpText }</Typography>
          )}
          <List>
            {deviceInfo?.devices.map(deviceId => devices[deviceId]).map(device => {
              const urlId = device.puck || device.id
              const href = `/devices/${urlId}`
              return (
                <ListItem
                  button
                  key={device.id}
                  component="a"
                  href={href}
                >
                  <ListItemIcon>
                    <Icon icon={Sensor} />
                  </ListItemIcon>
                  <ListItemText>
                    {device.serialNumber}
                  </ListItemText>
                </ListItem>
              )
            })}
          </List>
        </DialogContent>
      </Dialog>
      <Grid container spacing={2} justify="space-between" alignItems="stretch">
        {checklist?.map(check => (
          <CheckCard key={check.label} check={check} setDeviceInfo={setDeviceInfo} />
        ))}
      </Grid>
    </>
  )
}

export default Connected
