import moment from 'moment'
import { merge } from 'ramda'
import { DateTime } from 'luxon'
import { VictoryTheme } from 'victory'
import memoizeOne from 'memoize-one'
import { getDate } from '~/Lib/Utils/datetime'

export const formatDateForChart = dataset => dataset.map(
  datapoint => ({ x: getDate(datapoint.x), y: datapoint.y })
)

export const metricCountChange = (prev, curr) => {
  if (prev === undefined || curr === undefined) return null
  if (curr > prev) return `+${curr - prev}`
  if (prev > curr) return `-${prev - curr} `
  return null
}

const formatPercentage = changeValue => {
  if (changeValue > 0) return `+${changeValue}%`
  return `${changeValue}%`
}

export const percentChange = (prev, curr) => {
  if (prev === undefined || curr === undefined) return null
  let percent
  if (curr !== 0) {
    if (prev !== 0) {
      percent = ((curr - prev) / prev) * 100
    } else {
      percent = curr * 100
    }
  } else {
    percent = -prev * 100
  }
  return formatPercentage(Math.floor(percent))
}

const baseLabelStyles = {
  fontFamily: 'Akkurat',
  fontSize: 8,
  padding: 10,
  fill: '#fff',
  stroke: 'transparent'
}

export const getTheme = memoizeOne(theme => merge(VictoryTheme.greyscale, {
  axis: {
    style: {
      axis: {
        stroke: theme.meter.white,
        strokeWidth: 1,
      },
      grid: {
        stroke: 'none',
      },
      tickLabels: baseLabelStyles,
      ticks: {
        stroke: 'transparent',
        size: 3
      }
    }
  },
  bar: {
    style: {
      data: {
        padding: 8,
        strokeWidth: 0
      },
      labels: baseLabelStyles
    }
  },
  tooltip: {
    style: { ...baseLabelStyles, padding: 0, pointerEvents: 'none' },
    flyoutStyle: {
      stroke: 'none',
      strokeWidth: 1,
      fill: theme.meter.background.four,
      pointerEvents: 'none',
    },
    flyoutPadding: 0,
    cornerRadius: 5,
    pointerLength: 0
  },
}))

export const getBarChartData = graph => {
  const label = graph.label
  const now = graph.data[graph.data.length - 1]?.x ?? moment(DateTime.local().toISODate()).format('YYYY-MM-DD')
  const oneMonthAgo = DateTime.local().minus({ months: 1 }).toISODate()
  const twoMonthsAgo = DateTime.local().minus({ months: 2 }).toISODate()

  const leftBarCount = graph.data.find(dp => dp?.x === twoMonthsAgo)?.y ?? 0
  const leftBarDate = moment(twoMonthsAgo).format('MMM Do YY')
  const leftBarMessage = `${leftBarCount} ${label}\n ${leftBarDate}`

  const middleBarCount = graph.data.find(dp => dp?.x === oneMonthAgo)?.y ?? 0
  const middleBarDate = moment(oneMonthAgo).format('MMM Do YY')
  const middleBarChange = percentChange(leftBarCount, middleBarCount)
  const middleBarChangeMessage = `${middleBarChange} since ${moment(twoMonthsAgo).format('MMM DD')}`
  const middleBarMessage = `${middleBarCount} ${label}\n ${middleBarDate}\n ${middleBarChangeMessage}`

  const rightBarCount = graph.data[graph.data.length - 1]?.y ?? 0
  const rightBarDate = moment(now).format('MMM Do YY')
  const rightBarChange = percentChange(middleBarCount, rightBarCount)
  const rightBarChangeMessage = `${rightBarChange} since ${moment(oneMonthAgo).format('MMM DD')}`
  const rightBarMessage = `${rightBarCount} ${label}\n ${rightBarDate}\n ${rightBarChangeMessage}`

  return ([
    { x: `${label}`, y: leftBarCount, label: leftBarMessage },
    { x: `${label}2`, y: middleBarCount, label: middleBarMessage },
    { x: `${label}3`, y: rightBarCount, label: rightBarMessage },
  ])
}

export const setLabelXPosition = numMetrics => {
  if (numMetrics >= 15) return 20
  if (numMetrics >= 10) return 25
  if (numMetrics >= 7) return 35
  if (numMetrics >= 5) return 50
  if (numMetrics >= 4) return 80
  if (numMetrics >= 3) return 100
  if (numMetrics >= 2) return 160
  return 395
}

export const setLabelYPosition = numMetrics => {
  if (numMetrics >= 15) return 0
  if (numMetrics >= 10) return 5
  if (numMetrics >= 7) return 10
  if (numMetrics >= 5) return 20
  if (numMetrics >= 4) return 35
  if (numMetrics >= 3) return 45
  if (numMetrics >= 2) return 80
  return 215
}
