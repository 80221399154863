import { createSelector } from 'redux-bundler'

import createEntityBundle from '~/Lib/createEntityBundle'
import createListBundle from '~/Lib/createListBundle'
import { Logger as schema } from '~/Store/Schemas'

export const loggers = createEntityBundle({
  name: 'loggers',
  apiConfig: { schema },
})

const loggerListBundle = createListBundle({
  entityName: 'logger',
  schema,
  fetchHandler: ({ apiFetch, getState }) => {
    const { loggerList } = getState()
    const { filter, page } = loggerList
    return apiFetch('/loggers/', {
      page,
      facility: filter === 'ALL' ? null : filter
    })
  }
})

export const loggerList = {
  ...loggerListBundle,
  reactLoggerListFetch: createSelector(
    'selectAuth',
    'selectLoggerListShouldUpdate',
    'selectRouteInfo',
    ({ authenticated }, shouldUpdate, { url }) => {
      if (authenticated && shouldUpdate && url === '/loggers') {
        return { actionCreator: 'doFetchLoggerList' }
      }
      return undefined
    }
  )
}
