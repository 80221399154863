export default theme => ({
  card: {
    padding: theme.spacing(2),
    height: '100%',
  },
  notificationsRoot: {
    overflowX: 'hidden',
  },
  notificationItem: {
    position: 'relative',
    borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
  },
  notificationItemOpen: {
    // '& $notificationItemContent': {
    //   transform: 'translate(-100%)',
    // },
    // '& $notificationItemActions': {
    //   transform: 'translate(0)',
    // },
  },
  notificationItemContent: {
    // transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    paddingRight: theme.spacing(2),
  },
  // notificationItemActions: {
  //   // transition: 'transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  //   transform: 'translate(100%)',
  //   position: 'absolute',
  //   top: 0,
  //   bottom: 0,
  //   left: 0,
  //   right: 0,
  // },
  chartCard: {
    padding: theme.spacing(2),
    height: '100%',
    cursor: 'pointer',
    transition: 'background-color 125ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: theme.meter.background.two,
    },
  },
  badgeColorUnknown: {
    '& span': {
      color: '#fff',
      backgroundColor: '#888',
    },
  },
})
