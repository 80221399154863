/* eslint-disable no-underscore-dangle */
import colorUtil from 'color'

import { EMPTY_OBJECT } from '~/Lib/Utils/constants'
import { shallowEqualsMemoizer } from '~/Lib/Utils/memoizer'
import getGlobal from '~/Lib/getGlobal'
import safeStorage from '~/Lib/safeStorage'

export const inputStates = ['active', 'disabled', 'enabled', 'focused', 'hovered']
export const states = {
  active: 0.16,
  disabled: 0.32,
  enabled: 0.08,
  focused: 0.16,
  hovered: 0.12,
  faded: 0.24,
  inactive: 0.54,
  muted: 0.68,
  strong: 0.9,
}

export const getInputColorStates = shallowEqualsMemoizer((palette, keys = inputStates) => {
  const main = colorUtil(palette?.main ?? palette)

  return keys.reduce((colorStates, key) => (
    key in states
      ? { ...colorStates, [key]: main.alpha(states[key]).string() }
      : colorStates
  ), {})
})

export const getStringableColorWithStates = (main, defaultColorKey = 'main', colorStates = states, spread = EMPTY_OBJECT) => {
  if (!main) return { main }

  const proto = {
    ...spread,
    main,
    ...getInputColorStates(main, Object.keys(colorStates)),
  }

  const valueConfig = {
    value: () => proto[defaultColorKey]
  }
  Object.defineProperties(proto, {
    [Symbol.for('defaultColor')]: {
      value: defaultColorKey,
    },
    length: {
      get() { return main.length }
    },
    toString: valueConfig,
    valueOf: valueConfig
  })

  return proto
}

const white = getStringableColorWithStates('#fff', 'strong')
const black = getStringableColorWithStates('#000', 'strong')
const blue = Object.freeze(getStringableColorWithStates('#338edc', 'main', states, {
  ...getInputColorStates('#338edc'),
  light: '#82c6ff',
  lighter: '#57a8ef',
  darker: '#2376bb',
  dark: '#0d63a7',
}))

export const monochrome = Array.from({ length: 16 }, (_, i) => {
  const value = (15 - i).toString(16)
  return `#${value}${value}${value}`
})

const entityColors = {
  ipm: {
    peach: getStringableColorWithStates('#ffad8a')
  }
}

const itemColors = {
  denim: {
    main: '#21527b',
    light: '#316c9d',
  },
  fig: {
    main: '#573a55',
    light: '#7d607c',
  },
  moss: {
    main: '#354e23',
    light: '#546845',
  },
  royal: {
    main: '#25377a',
    light: '#4857a1',
  },
  stone: {
    dark: '#2b3855',
    main: '#6c5c68',
  },
  tan: {
    main: '#15473a',
    light: '#37645b',
  },
  terracotta: {
    main: '#78522a',
    light: '#826a53',
  }
}

const batch = getStringableColorWithStates('#1ba293', 'main', states, {
  one: '#3aeae3',
  two: '#32d8ce',
  three: '#2bc6ba',
  four: '#23b4a6',
  five: '#1ba293',
  six: '#129180',
  seven: '#09806d',
  eight: '#00705b',
  light: '#a6d2cd',
})

const orange = getStringableColorWithStates('#ff6a13', 'main')

export const shared = {
  batch,
  blue,
  green: getStringableColorWithStates('#89e05d', 'main', {}, {
    four: '#64c133',
    five: '#478924',
  }),
  orange,
  purple: getStringableColorWithStates('#a37b9a', 'main', {}, {
    five: '#7e5475',
  }),
  yellow: getStringableColorWithStates('#ffbf4d', 'main', states),
  states,
  black,
  white,
  monochrome,
  error: getStringableColorWithStates('#db2e4c', 'main', states, {
    notification: '#db2e4c',
    warning: '#a0b10e',
  }),
  info: blue,
  success: batch,
  warning: orange,
}

const chestnut = getStringableColorWithStates('#3a2004')
const eggshell = getStringableColorWithStates('#fffcf6')
const dusty = getStringableColorWithStates('#9d9086')
const pometaBlue = getStringableColorWithStates('#3290B6', 'main', states, {
  contrastText: eggshell.main,
})

export const palettes = {
  AROYA: Object.freeze({
    ...shared,
    ...entityColors,
    type: 'dark',
    get appPrimary() { return this.primary },
    get appSecondary() { return this.secondary },
    primary: {
      ...shared.blue,
      contrastText: shared.white.strong,
    },
    secondary: {
      ...batch,
      dark: batch.seven,
      faded: 'rgba(58, 234, 227, 0.12)',
      contrastText: shared.white.strong,
    },
    background: {
      default: '#071638',
      secondary: '#314153',
      paper: '#1d2836',
      dark: '#06122f',
    },
    contrast: white,
    contrastInverse: black,
    gray: white.inactive,
    plantscore: {
      one: '#db1f40',
      two: '#ce4618',
      three: '#c08813',
      four: '#a0b10e',
      five: '#51a209',
      six: '#0b9205',
      seven: '#028133',
      eight: '#00705b',
    },
    ...Object.entries(itemColors).reduce((colorMap, [name, { main, ...rest }]) => ({
      ...colorMap,
      [name]: getStringableColorWithStates(main, 'main', states, rest)
    }), {}),
    text: {
      disabled: white.disabled,
      inactive: white.inactive,
      primary: white.strong,
      secondary: white.inactive,
    },
  }),
  // TODO: replace with CULTIVATION or PRODUCTION
  get AROYA_CULTIVATION() {
    if ('_AROYA_CULTIVATION' in this === false) {
      const { background, primary: secondary, secondary: primary, ...rest } = this.AROYA
      this._AROYA_CULTIVATION = Object.freeze({
        ...rest,
        primary,
        secondary,
        background: {
          ...background,
          light: rest.white.hovered,
        }
      })
    }
    return this._AROYA_CULTIVATION
  },
  POMETA: Object.freeze({
    ...shared,
    type: 'light',
    get appPrimary() { return this.primary },
    get appSecondary() { return this.secondary },
    primary: pometaBlue,
    secondary: getStringableColorWithStates('#88A867'),
    background: {
      default: eggshell.main,
      paper: '#fffffe',
      dark: '#eeece8',
      secondary: '#eeece8',
      logo: '#169180',
    },
    contrast: chestnut,
    contrastInverse: eggshell,
    monochrome: shared.monochrome.slice().reverse(),
    text: {
      disabled: chestnut.disabled,
      inactive: chestnut.inactive,
      primary: chestnut.main,
      secondary: dusty.main,
    }
  }),
}
if (safeStorage('local').getItem('debug') === 'true') {
  getGlobal().palettes = palettes
  getGlobal().colorUtil = colorUtil
}
