const ADD_SNACKBAR_MESSAGE = 'ADD_SNACKBAR_MESSAGE'
const DISMISS_SNACKBAR_MESSAGE = 'DISMISS_SNACKBAR_MESSAGE'

let counter = 0

export default {
  name: 'snackbar',
  reducer: (state = [], action = {}) => {
    switch (action.type) {
      case ADD_SNACKBAR_MESSAGE:
        counter += 1
        return [
          ...state,
          { key: `snackbar_${counter}`, message: action.payload },
        ]
      case DISMISS_SNACKBAR_MESSAGE:
        return state.filter(({ key }) => key !== action.payload)
      default:
        return state
    }
  },
  selectSnackbarMessages: state => state.snackbar,
  doAddSnackbarMessage: message => ({
    type: ADD_SNACKBAR_MESSAGE,
    payload: message,
  }),
  doDismissSnackbarMessage: key => ({
    type: DISMISS_SNACKBAR_MESSAGE,
    payload: key,
  }),
}
