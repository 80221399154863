import React from 'react'

import { Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import Chart from '~/UI/Shared/Chart'

import styles from './styles'

const useStyles = makeStyles(styles)

export default props => {
  const { data, label, loading, onClick } = props
  const classes = useStyles()

  return (
    <Grid item sm={12} md={6} xl={4} style={{ height: '400px' }}>
      <Paper className={classes.chartCard} onClick={onClick}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div>
            <Typography variant="subtitle1">{ label }</Typography>
          </div>
          <div style={{ flex: '1 1 auto', height: '100%' }}>
            <Chart
              chartData={data}
              interactive={false}
              loading={loading}
              showYAxis
              width="sm"
            />
          </div>
        </div>
      </Paper>
    </Grid>
  )
}
