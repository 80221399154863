import React, { useState } from 'react'
import { useConnect } from 'redux-bundler-hook'

import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import MuiTitle from '~/UI/Shared/MuiTitle'

import SelectField from '~/UI/Shared/Form/SelectField'
import { DataChart as Chart } from './Chart/index'
import { DataLineChart } from './Chart/dataChart'
import { DeviceList } from './list'
import { Packets } from './packets'
import { DeviceStyles as styles } from './styles'

const useStyles = makeStyles(styles)

const sortFields = [
  { label: 'Low to High', value: 'Low to High' },
  { label: 'High to Low', value: 'High to Low' }
]

const sortByFields = [
  { label: 'Address', value: 'Address' },
  { label: 'Battery', value: 'Battery' },
  { label: 'Travel Time', value: 'Travel Time' }
]

const DeviceComponent = () => {
  const classes = useStyles()
  const [sort, setSort] = useState(sortFields[0])
  const [sortBy, setSortBy] = useState(sortByFields[0])
  const [chart, setChart] = useState('Bar')
  const { currentNode } = useConnect('selectCurrentNode')

  return (
    <div className={classes.root}>
      <div className={classes.nodeTitle}>
        <MuiTitle title="Nodes" />
      </div>
      <div className={classes.nodeId}>
        {currentNode ? <MuiTitle title={currentNode.address} color="inherit" variant="h5" /> : null}
      </div>
      {currentNode && currentNode.dataArray ? (
        <div className={classes.graphToggle}>
          <Button variant="outlined" color="primary" onClick={() => setChart('Bar')}>
            Packet Count
          </Button>
          <Button variant="outlined" color="primary" onClick={() => setChart('Line')}>
            PV Data
          </Button>
        </div>
      ) : null}
      <div className={classes.filters}>
        <SelectField
          dense
          value={sortBy}
          options={sortByFields}
          placeholder="Sort By"
          onChange={selection => setSortBy(selection)}
        />
        <SelectField
          dense
          value={sort}
          options={sortFields}
          placeholder="Sort"
          onChange={selection => setSort(selection)}
        />
      </div>
      <div className={classes.nodeList}>
        <DeviceList sort={sort.value} sortBy={sortBy.value} />
      </div>
      <div className={classes.nodeDetails}>
        {currentNode && currentNode.dataArray ? (
          <div className={classes.nodeChart}>
            {chart === 'Bar' ? <Chart currentDevice={currentNode} /> : null}
            {chart === 'Line' ? <DataLineChart currentDevice={currentNode} /> : null}
          </div>
        ) : null}
        <div className={classes.nodePackets}>
          <Packets currentDevice={currentNode} />
        </div>
      </div>
    </div>
  )
}

export default DeviceComponent
