import React, { useState, useEffect, useCallback } from 'react'
import { useConnect } from 'redux-bundler-hook'
import PropTypes from 'prop-types'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import AlertDialog from '~/UI/Shared/Form/AlertDialog'
import DeviceIcon from '~/Device/icon'
import { DeviceListStyles as styles } from './styles'

const useStyles = makeStyles(styles)

export const DeviceList = props => {
  const classes = useStyles(styles)
  const { sort, sortBy } = props
  const [selectedNode, setSelectedNode] = useState(null)
  const [nodes, setNodes] = useState([])
  const {
    currentNode,
    nodeList,
    doSetCurrentNode,
    doDeleteNode,
    doMarkNodeListAsOutdated
  } = useConnect(
    'selectCurrentNode',
    'selectNodeList',
    'doSetCurrentNode',
    'doDeleteNode',
    'doMarkNodeListAsOutdated'
  )

  const handleIcon = useCallback(node => {
    if (node.boot) {
      if (node.boot.role === 'endnode') {
        if (node.data) {
          if (moment(new Date(`${node.data.beat} UTC`)).add(30, 'minutes') < moment(new Date())) {
            return <DeviceIcon noderole="endnode" online={false} />
          }
          return <DeviceIcon noderole="endnode" online />
        }
        return <DeviceIcon noderole="router" />
      }
      if (node.boot.role === 'router') {
        return <DeviceIcon noderole="router" />
      }
      if (node.boot.role === 'sink') {
        return <DeviceIcon noderole="sink" />
      }
    }
    return <DeviceIcon online={node.data} />
  })

  const handleAlert = (e, node) => {
    if (e.currentTarget.classList.contains('deleteIcon')) {
      e.preventDefault()
      e.stopPropagation()
      setSelectedNode(node.address)
    }
  }

  const handleNodeDelete = () => {
    doDeleteNode(selectedNode)
    setSelectedNode(null)
    doMarkNodeListAsOutdated()
  }

  useEffect(() => {
    let sortedNodes
    let direction = 1

    if (nodeList) {
      sortedNodes = [...nodeList]
    } else {
      sortedNodes = [...nodes]
    }

    if (sort === 'High to Low') {
      direction = -1
    }
    if (sortBy === 'Address') {
      sortedNodes.sort((a, b) => {
        if (Number(a.address) > Number(b.address)) return direction
        if (Number(a.address) < Number(b.address)) return -direction
        return 0
      })
      setNodes(sortedNodes)
    } else if (sortBy === 'Battery') {
      sortedNodes.sort((a, b) => {
        if (a.status && b.status) {
          if (a.status.batteryLevel > b.status.batteryLevel) return direction
          if (a.status.batteryLevel < b.status.batteryLevel) return -direction
        }
        return 0
      })
      setNodes(sortedNodes)
    } else if (sortBy === 'Travel Time') {
      const dataNodes = sortedNodes.filter(node => node.data)
      dataNodes.sort((a, b) => {
        if (a.data.travelTimeS > b.data.travelTimeS) return direction
        if (a.data.travelTimeS < b.data.travelTimeS) return -direction
        return 0
      })
      setNodes([...dataNodes, ...sortedNodes.filter(node => !node.data)])
    }
  }, [sort, sortBy, nodeList])

  return (
    <List className={classes.listRoot}>
      {selectedNode ? (
        <AlertDialog
          title={
            `Delete node ${selectedNode}?`
          }
          onChange={() => handleNodeDelete()}
          onClose={() => setSelectedNode(null)}
        />
      ) : null}
      {nodes.map(node => (
        <React.Fragment key={node.address}>
          <ListItem
            className={classes.listItem}
            button
            style={currentNode && node.address === currentNode.address
              ? { backgroundColor: 'rgba(255, 255, 255, 0.2)' }
              : null}
            onClick={() => doSetCurrentNode(node)}
          >
            <div className={classes.nodeIcon}>
              {handleIcon(node)}
            </div>
            <ListItemText
              className={classes.listText}
              primary={(
                <Typography align="left" variant="subtitle1" color="primary">
                  {node.address}
                </Typography>
              )}
              secondary={(
                <>
                  {node.facility ? (
                    <span style={{ display: 'block' }}>
                      {node.facility.name}&ensp;Model: {node.model.name}
                    </span>
                  ) : null}
                  {node.sys ? (
                    <span style={{ display: 'block' }}>
                      SN: {node.sys.puckSn}&ensp;Ver: {node.sys.fullVer}
                    </span>
                  ) : null}
                  {node.status ? (
                    <span style={{ display: 'block' }}>
                      Battery: {Math.round(100 * node.status.batteryLevel) / 100}%
                      {/* &ensp; */}
                    </span>
                  ) : null}
                  {node.data ? (
                    <span>
                      Travel Time: {node.data.travelTimeS}s
                      &ensp;
                    </span>
                  ) : null}
                  {node.rssi ? (
                    <span>
                      RSSI: {node.rssi}
                    </span>
                  ) : null}
                </>
              )}
            />
            <div className={classes.nodeDelete}>
              <DeleteIcon className="deleteIcon" onClick={e => handleAlert(e, node)} />
            </div>
          </ListItem>
          <ListItem />
        </React.Fragment>
      ))}
    </List>
  )
}

DeviceList.propTypes = {
  sort: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired
}
