import {
  camelize as camelizeRaw,
  underscore as underscoreRaw,
} from 'inflection'
import {
  compose,
  join,
  partialRight,
  prepend,
  reverse,
  toUpper,
  unapply,
  map,
} from 'ramda'

export const ENTITIES_RECEIVED = 'ENTITIES_RECEIVED'
export const ENTITIES_REMOVED = 'ENTITIES_REMOVED'
export const ENTITIES_CLEARED = 'ENTITIES_CLEARED'

export const DEFAULT_ASYNC_ACTIONS = ['start', 'succeed', 'fail']

const camelize = partialRight(camelizeRaw, [true])
const underscore = join('_')

export const constantize = compose(
  toUpper,
  underscore
)

// signature: [action: String, name: String],
export const getActionType = compose(
  constantize,
  unapply(reverse)
)
export const getAsyncActionTypes = (...args) => {
  const typePrefix = getActionType(...args)
  return DEFAULT_ASYNC_ACTIONS.reduce(
    (acc, asyncAction) => ({
      ...acc,
      [asyncAction]: constantize([typePrefix, asyncAction]),
    }),
    { prefix: typePrefix }
  )
}
// signature: [action: String, name: String]
export const getActionCreatorName = compose(
  camelize,
  underscore,
  prepend('do'),
  map(underscoreRaw),
  unapply(reverse)
)

export const getActionIdentifiers = (...args) => ({
  type: getActionType(...args),
  actionName: getActionCreatorName(...args),
})

export const getAsyncActionIdentifiers = (...args) => ({
  types: getAsyncActionTypes(...args),
  actionName: getActionCreatorName(...args),
})
