import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'
import { createSelector } from 'redux-bundler'
import reduceReducers from 'reduce-reducers'
import ms from 'milliseconds'

const PLANT_LIST_SET_FILTERS = 'PLANT_LIST_SET_FILTERS'
const PLANT_LIST_SET_SEARCH = 'PLANT_LIST_SET_SEARCH'
const PLANT_LIST_SET_SORT = 'PLANT_LIST_SET_SORT'
const PLANT_LIST_SET_PAGINATION = 'PLANT_LIST_SET_PAGINATION'
const PLANT_LIST_SET_CURRENT_PLANT = 'PLANT_LIST_SET_CURRENT_PLANT'

const additionalState = {
  sort: 'label',
  search: '',
  pagination: {
    currentPage: 1,
    next: 2,
    count: 0,
    pageSize: 25,
    numPages: 0,
  },
  filters: {
    plantState: null,
    hideFinished: 'HIDE',
    plantExistsIn: null,
    phase: null,
    plantedDate: null,
    harvestedDate: null,
    dateDestroyed: null,
    cultivarName: null,
    location: null,
    difference: null,
  },
  currentPlant: {},
}

const plantListBundle = createAsyncResourceBundle({
  name: 'plantList',
  actionBaseType: 'PLANT_LIST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: async ({ apiFetch, getState, store }) => {
    const facility = store.selectCurrentFacilityId()
    const license = store.selectCurrentFacilityLicense()?.id
    const { plantList: { filters, sort, search } } = getState()

    /* eslint-disable babel/camelcase */
    const response = await apiFetch('/plants/', {
      facility,
      sort,
      search,
      license,
      cultivar_name: filters.cultivarName,
      room_name: filters.roomName,
      difference: filters.difference,
      plant_state: filters.plantState,
      phase: filters.phase,
      planted_date: filters.plantedDate,
      harvested_date: filters.harvestedDate,
      date_destroyed: filters.dateDestroyed,
      hide_finished: filters.hideFinished,
      exists_in: filters.plantExistsIn,
    })
    /* eslint-enable babel/camelcase */
    const numPages = Math.ceil(response.length / additionalState.pagination.pageSize)
    const next = response.length > additionalState.pagination.pageSize ? 2 : null
    store.doPlantListClearCurrentPlant()
    store.doPlantListSetPagination({
      currentPage: 1,
      count: response.length,
      numPages: numPages || 1,
      next,
    })
    return response
  }
})

export default {
  ...plantListBundle,
  reducer: reduceReducers(plantListBundle.reducer, (state, action) => {
    switch (action.type) {
      case PLANT_LIST_SET_SEARCH:
        return { ...state, search: action.payload }
      case PLANT_LIST_SET_SORT:
        return { ...state, sort: action.payload }
      case PLANT_LIST_SET_FILTERS:
        return { ...state, filters: { ...state.filters, ...action.payload } }
      case PLANT_LIST_SET_PAGINATION:
        return { ...state, pagination: { ...state.pagination, ...action.payload } }
      case PLANT_LIST_SET_CURRENT_PLANT:
        return { ...state, currentPlant: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doPlantListSetFilters: filters => ({ dispatch, store }) => {
    dispatch({ type: PLANT_LIST_SET_FILTERS, payload: filters })
    store.doMarkPlantListAsOutdated()
  },
  doPlantListSetSearch: search => ({ dispatch, store }) => {
    if (search === store.selectPlantListSearch()) return
    dispatch({ type: PLANT_LIST_SET_SEARCH, payload: search })
    store.doMarkPlantListAsOutdated()
  },
  doPlantListSetSort: sort => ({ dispatch, store }) => {
    if (sort === store.selectPlantListSort()) return
    dispatch({ type: PLANT_LIST_SET_SORT, payload: sort })
    store.doMarkPlantListAsOutdated()
  },
  doPlantListSetPagination: newPagination => ({ store, dispatch }) => {
    const { pagination } = store.selectPlantListRaw()
    const payload = { ...pagination, ...newPagination }
    dispatch({ type: PLANT_LIST_SET_PAGINATION, payload })
  },
  doPlantListUpdatePlant: payload => async ({ store, apiFetch }) => {
    try {
      const result = await apiFetch(
        `/plants/${payload.id}/`,
        { ...payload },
        { method: 'PUT' },
      )
      store.doMarkPlantListAsOutdated()
      store.doAddSnackbarMessage(`Successfully updated ${payload.label}`)
      return result
    } catch (error) {
      return error
    }
  },
  doPlantListFetchPlant: id => async ({ store, apiFetch }) => {
    try {
      const result = await apiFetch(`/plants/${id}/`)
      store.doPlantListSetCurrentPlant(result)
      return result
    } catch (error) {
      return error
    }
  },
  doPlantListDeletePlant: () => async ({ store, apiFetch }) => {
    const plant = store.selectPlantListCurrentPlant()
    try {
      const result = await apiFetch(`/plants/${plant.id}/`, {}, { method: 'DELETE' })
      store.doAddSnackbarMessage(`Successfully deleted plant ${plant.label}`)
      store.doPlantListClearCurrentPlant()
      store.doMarkPlantListAsOutdated()
      return result
    } catch (error) {
      return error
    }
  },
  doPlantListSetCurrentPlant: payload => ({ store, dispatch }) => {
    if (payload?.id === store.selectPlantListCurrentPlant()?.id) return
    dispatch({ type: PLANT_LIST_SET_CURRENT_PLANT, payload })
  },
  doPlantListClearCurrentPlant: () => ({ dispatch }) => {
    dispatch({ type: PLANT_LIST_SET_CURRENT_PLANT, payload: {} })
  },
  selectPlantListFilters: createSelector(
    'selectPlantListRaw',
    ({ filters }) => filters
  ),
  selectPlantListSearch: createSelector(
    'selectPlantListRaw',
    ({ search }) => search
  ),
  selectPlantListSort: createSelector(
    'selectPlantListRaw',
    ({ sort }) => sort
  ),
  selectPlantListCurrentPlant: createSelector(
    'selectPlantListRaw',
    ({ currentPlant }) => currentPlant
  ),
  selectCurrentPlantList: createSelector(
    'selectPlantListRaw',
    ({ data, pagination }) => {
      if (data?.length <= additionalState.pagination.pageSize) return data
      const { pageSize, currentPage } = pagination
      return data?.slice(((pageSize * currentPage) - pageSize), (pageSize * currentPage))
    }
  ),
  selectPlantListPagination: createSelector(
    'selectPlantListRaw',
    ({ pagination }) => pagination
  ),
  reactPlantListFetch: createSelector(
    'selectAuth',
    'selectPlantListShouldUpdate',
    'selectRouteInfo',
    ({ authenticated }, shouldUpdate, { url }) => {
      const params = ['facilities', 'metrc', 'plants']
      const urlHasParams = params.every(param => url.includes(param))
      if (authenticated && shouldUpdate && urlHasParams) {
        return { actionCreator: 'doFetchPlantList' }
      }
      return undefined
    }
  ),
}
