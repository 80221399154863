import { merge } from 'ramda'
import memoizeOne from 'memoize-one'
import { VictoryTheme } from 'victory'

import { EMPTY_OBJECT } from '~/Lib/Utils'

export const getLineStyles = memoizeOne(props => {
  const { graphs } = props
  return graphs.reduce(
    (acc, { bounds, id, lineColor }) => {
      const strokeOpacity = 1
      const strokeWidth = 2
      const addShadow = (bounds.max - bounds.min > bounds.max / 10 && bounds.max !== bounds.min)

      return {
        ...acc,
        [id]: {
          data: {
            stroke: lineColor,
            strokeOpacity,
            strokeWidth,
            filter: addShadow ? 'url(#lineShadow)' : 'none',
          },
          labels: {
            fill: lineColor,
          },
        },
      }
    },
    EMPTY_OBJECT
  )
})

export const NullComponent = () => null

export const getTheme = memoizeOne(theme => merge(VictoryTheme.material, {
  line: {
    style: {
      data: {
        stroke: theme.meter.blue.main,
        strokeWidth: 2,
      },
    },
  },
}))

export const getDomain = memoizeOne(({ xDomain }) => ({ y: [0, 1], x: xDomain }))
