import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

const otapStatusListBundle = createAsyncResourceBundle({
  name: 'otapStatusList',
  actionBaseType: 'OTAP_STATUS_LIST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch }) => apiFetch('/firmware/status/')
})

export default {
  ...otapStatusListBundle,
  reactOtapStatusListFetch: createSelector(
    'selectAuth',
    'selectOtapStatusListShouldUpdate',
    'selectRouteInfo',
    ({ authenticated }, shouldUpdate, { url }) => {
      if (authenticated && shouldUpdate && url === '/firmware/logs') {
        return { actionCreator: 'doFetchOtapStatusList' }
      }
      return undefined
    }
  ),
}
