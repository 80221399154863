import React, { useEffect } from 'react'
import { useConnect } from 'redux-bundler-hook'

import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import { formatDateTime } from '~/Lib/Utils'
import Loading from '~/UI/Shared/utils'
import MuiTitle from '~/UI/Shared/MuiTitle'

const TimestampChip = ({ ts }) => (
  <Chip
    style={{ marginLeft: '5px' }}
    color={ts ? 'primary' : 'secondary'}
    label={ts ? formatDateTime(ts) : 'No response yet'}
  />
)

const OtapStatusDetailComponent = () => {
  const {
    doOtapStatusSetRequest,
    otapStatus,
    routeParams,
  } = useConnect(
    'doOtapStatusSetRequest',
    'selectOtapStatus',
    'selectRouteParams',
  )

  useEffect(() => {
    const { id } = routeParams
    doOtapStatusSetRequest(id)
  }, [])

  if (!otapStatus) {
    return <Loading />
  }

  return (
    <>
      <MuiTitle title="Firmware Update Status" />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="h5" color="primary">Started</Typography>
          {formatDateTime(otapStatus.createdOn)}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5" color="primary">Last Updated</Typography>
          {formatDateTime(otapStatus.modifiedOn)}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5" color="primary">Target Version</Typography>
          {otapStatus.targetVersion}
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid container item xs={6} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary">Gateway Responses</Typography>
          </Grid>
          {Object.entries(otapStatus.gatewayResponses).map(([gatewaySn, ts]) => (
            <Grid item key={gatewaySn}>
              <div style={{ textAlign: 'center' }}>{gatewaySn}</div>
              <TimestampChip ts={ts} />
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={6} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary">Node Responses</Typography>
          </Grid>
          {Object.entries(otapStatus.nodeResponses).map(([nodeAddress, ts]) => {
            const serialNumber = otapStatus.nodes[nodeAddress]
            return (
              <Grid item key={nodeAddress}>
                <div style={{ textAlign: 'center' }}>{serialNumber} ({nodeAddress})</div>
                <TimestampChip ts={ts} />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary">Log</Typography>
        </Grid>
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell>Log Level</TableCell>
                <TableCell>Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {otapStatus.logEntries.map(entry => (
                <TableRow key={entry.id}>
                  <TableCell>
                    {formatDateTime(entry.ts)}
                  </TableCell>
                  <TableCell>{entry.level}</TableCell>
                  <TableCell>{entry.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  )
}

export default OtapStatusDetailComponent
