import React from 'react'
import { useConnect } from 'redux-bundler-hook'

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { formatDateTime } from '~/Lib/Utils'
import Loading from '~/UI/Shared/utils'

import Paginator from './paginator'

const DeviceEventsComponent = ({ deviceId }) => {
  const {
    deviceEvents,
    deviceEventsIsLoading,
    doDeviceEventsSetPage,
  } = useConnect(
    'selectDeviceEvents',
    'selectDeviceEventsIsLoading',
    'doDeviceEventsSetPage',
  )

  if (!deviceEvents && deviceEventsIsLoading) {
    return <Loading />
  }

  return deviceId && deviceEvents?.results?.length ? (
    <Grid container direction="column" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: '8px' }}>
      <Grid container style={{ flex: 1, overflowY: 'auto' }}>
        <div style={{ width: '100%', paddingRight: '5px' }}>
          { deviceEvents?.results?.map(event => (
            <ExpansionPanel key={event.id}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>
                    { formatDateTime(event.ts) } - { event.key }
                  </span>
                  { event.warning ? (
                    <ErrorIcon
                      fontSize="small"
                      style={{ color: 'yellow', marginLeft: '5px' }}
                    />
                  ) : null }
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ width: '100%', overflowX: 'auto', flexDirection: 'column' }}>
                <Typography variant="body1">Data:</Typography>
                <pre>{ JSON.stringify(event.data, null, 2) }</pre>
                <Typography variant="body1">Logs:</Typography>
                <pre>{ event.text }</pre>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </div>
      </Grid>
      <Paginator
        data={deviceEvents}
        isLoading={deviceEventsIsLoading}
        onChange={doDeviceEventsSetPage}
      />
    </Grid>
  ) : (
    <div style={{ position: 'relative', width: '100%', marginTop: '50px' }}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Typography variant="h5">
          No events
        </Typography>
      </div>
    </div>
  )
}

export default DeviceEventsComponent
