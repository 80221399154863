import { createMuiTheme } from '@material-ui/core/styles'
import createSpacing from '@material-ui/core/styles/createSpacing'
import { palettes } from './colors'

const spacing = createSpacing(8)

const defaultFontFamily = [
  'Akkurat',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif'
].join(', ')

const boldFontFamily = `"Akkurat Bold", ${defaultFontFamily}`

const colors = {
  background: {
    four: '#1d2836',
    two: '#314153',
  },
  select: {
    focused: 'rgba(255, 255, 255, 0.5)',
    active: 'rgba(255, 255, 255, 0.2)',
  },
  white: '#ffffff',
  grey: 'rgba(255, 255, 255, 0.54)',
  lightgrey: '#3e4860',
  blue: {
    light: '#82c6ff',
    lighter: '#57a8ef',
    main: '#338edc',
    darker: '#2376bb',
    dark: '#0d63a7',
    inactive: 'rgba(51, 142, 220, 0.54)',
    disabled: 'rgba(51, 142, 220, 0.38)',
    active: 'rgba(51, 142, 220, 0.12)',
  },
  room: {
    main: '#194a6f',
    active: '#338edc',
  },
  batch: {
    three: '#2bc6ba',
    four: '#23b4a6',
    five: '#1ba293',
    six: '#129180',
    seven: '#09806d',
    faded: 'rgba(27, 162, 147, 0.12)',
  },
  green: {
    one: '#adf487',
    five: '#478924',
    main: 'rgba(58, 234, 227, 0.5)',
  },
  error: {
    main: '#db1f40',
    notification: '#db2e4c',
  },
  orange: {
    one: '#ffad8a',
    five: '#ff6a13',
    main: '#ff975a'
  },
  purple: {
    five: '#7e5475',
  },
  plantscore: {
    one: '#db1f40',
    two: '#ce4618',
    three: '#c08813',
    four: '#a0b10e',
    five: '#51a209',
    six: '#0b9205',
    seven: '#028133',
    eight: '#00705b',
  },
}

const { AROYA: aroyaColors } = palettes

export const meter = {
  ...colors,
  dimensions: {
    defaults: {
      borderRadius: 6,
    },
  },
  states: {
    inactive: {
      backgroundColor: 'rgba(255, 255, 255, 0.06)',
    },
    active: {
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
    },
  },
  text: {
    inactive: 'rgba(255, 255, 255, 0.54)',
    disabled: 'rgba(255, 255, 255, 0.38)',
  },
  typography: {
    defaultFontFamily,
    boldFontFamily,
    title: {
      fontSize: '20.9px',
      letterSpacing: '0.3px',
      color: colors.white,
      textAlign: 'center',
      fontFamily: boldFontFamily,
    },
  },
  spacing: {
    grid: spacing(3),
  }
}

const displayTextDefaults = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const floorPlan = {
  facility: {
    wallColor: 'none',
    floorColor: 'rgba(51, 142, 220, 0.18)'
  },
  room: {
    fillColor: '#2376bb',
    fillHoverColor: '#338edc',
    fontSize: '34px',
    wallColor: 'rgba(255, 255, 255, 0.2)',
    wallWidth: '16px',
    noAssociationFillColor: 'auto',
    noAssociationFillOpacity: '0.5'
  },
  zone: {
    fillColor: '#57a8ef',
    fillHoverColor: '#82c6ff',
    fontSize: '18px',
    wallColor: 'rgba(255, 255, 255, 0.1)',
    wallWidth: '1px',
    noAssociationFillColor: 'auto',
    noAssociationFillOpacity: '0.5'
  },
  primary: '#338edc',
  activeBackground: 'rgba(51, 142, 220, 0.12)',
  textColor: 'white'
}

export const secondaryText = 'rgba(255, 255, 255, 0.54)'

const buildTheme = palette => (createMuiTheme({
  meter,
  floorPlan,
  aroya: { ...aroyaColors },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: palette.primary.main,
      },
    },
    MuiButton: {
      contained: {
        '&:not($containedPrimary):not($containedSecondary)': {
          opacity: 0.6,
          '&:hover': {
            opacity: 0.8
          }
        }
      },
      containedSecondary: {
        '&$disabled': {
          color: 'rgba(255, 255, 255, 0.38)'
        }
      }
    },
    MuiCheckbox: {
      root: {
        '&$colorPrimary': {
          color: palette.primary.main
        }
      }
    },
    MuiFormControl: {
      root: {
        margin: '8px',
      }
    },
    MuiFormHelperText: {
      root: {
        marginLeft: spacing(2),
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: palette.primary.main,
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: meter.background.four,
        color: meter.white,
        fontSize: spacing(1.75),
        paddingRight: spacing(6),
      },
      action: {
        position: 'absolute',
        right: spacing(2),
        top: spacing(),
      }
    },
    MuiChip: {
      root: {
        backgroundColor: meter.background.four,
        transition: 'none',
      },
      clickable: {
        '&:hover, &:focus': {
          backgroundColor: meter.background.four,
        },
        '&:active': {
          backgroundColor: meter.background.four,
        },
      }
    },
    MuiAvatar: {
      root: {
        padding: spacing(1.25, 2.5),
      },
      colorDefault: {
        backgroundColor: meter.green.main
      }
    },
    MuiIcon: {
      colorSecondary: {
        color: meter.text.inactive
      }
    },
    MuiInputBase: {
      root: {
        color: meter.white
      }
    },
    MuiInput: {
      root: {
        minHeight: '3.5rem',
        padding: '0 1rem',
        '&:before': {
          borderRadius: meter.dimensions.defaults.borderRadius,
          content: '"\u00a0"',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background: 'rgba(255, 255, 255, 0.02)',
          pointerEvents: 'none',
          borderBottomColor: palette.primary.light,
          borderBottomWidth: '1px !important',
        },
        '&$error': {
          color: meter.error.main,
          '&:before': {
            background: 'transparent',
            borderBottomColor: 'currentColor',
          },
        },
        '&$focused': {
          '&:before': {
            background: meter.states.active.background,
            borderBottomColor: 'currentColor',
          },
          '&$underline:before': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottom: 'none'
          },
          '&$underline:after': {
            borderBottomColor: palette.primary.main
          }
        },
      },
      underline: {
        '&:before': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderBottomWidth: '1px',
        },
        '&:after': {
          borderBottom: `1px solid ${palette.primary.main}`,
        },
        '&$disabled:before': {
          borderBottom: 'none',
          borderBottomLeftRadius: spacing(0.75),
          borderBottomRightRadius: spacing(0.75),
        },
      },
      input: {
        position: 'relative',
        top: spacing(0.5),
        padding: spacing(2.5, 0, 0.875),
        [['', 'hover', 'focus', 'active']
          .map(state => `&:-webkit-autofill${(state ? `:${state}` : '')}`)
          .join(', ')
        ]: {
          '-webkit-transition-delay': '99999s'
        }
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'relative',
      },
    },
    MuiInputLabel: {
      formControl: {
        left: spacing(2),
        transform: 'translate(0, 42px) scale(1)'
      },
      shrink: {
        transform: 'translate(0, 24px) scale(0.75)'
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: meter.blue.active,
        }
      },
      selected: {}
    },
    MuiListItemText: {
      primary: {
        color: palette.primary.main,
      }
    },
    MuiPopover: {
      paper: {
        overflowX: 'visible',
        overflowY: 'visible'
      }
    },
    MuiDialog: {
      paper: {
        overflowY: 'visible'
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: meter.dimensions.defaults.borderRadius,
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          background: 'transparent'
        }
      },
      icon: {
        color: palette.primary.main,
      },
    },
    MuiTab: {
      root: {
        '&$selected': {
          background: 'rgb(51, 142, 220, 0.12)',
          borderRadius: `
            ${meter.dimensions.defaults.borderRadius}px
            ${meter.dimensions.defaults.borderRadius}px
            0 0
          `,
        }
      },
      textColorPrimary: {
        color: palette.primary.main,
      },
    },
    MuiTableRow: {
      root: {
        background: meter.background.four,
        '&$hover:hover': {
          backgroundColor: meter.background.two,
        }
      },
      hover: {},
    },
  },
  palette,
  shape: {
    borderRadius: meter.dimensions.defaults.borderRadius,
  },
  spacing,
  typography: {
    useNextVariants: true,
    fontFamily: defaultFontFamily,
    h1: {
      ...displayTextDefaults,
      fontSize: '100.2px',
      letterSpacing: '-1.5px',
      color: meter.white,
      textAlign: 'center',
      fontFamily: boldFontFamily,
      lineHeight: 'normal',
    },
    h2: {
      ...displayTextDefaults,
      fontSize: '62.6px',
      letterSpacing: '-0.5px',
      color: meter.white,
      textAlign: 'center',
      fontFamily: boldFontFamily,
    },
    h3: {
      ...displayTextDefaults,
      fontSize: '50.1px',
      letterSpacing: '0px',
      color: meter.white,
      textAlign: 'center',
      fontFamily: boldFontFamily,
    },
    h4: {
      ...displayTextDefaults,
      fontSize: '35.5px',
      letterSpacing: '0.3px',
      color: meter.white,
      textAlign: 'center',
      fontFamily: boldFontFamily,
    },
    h5: {
      fontSize: '25.1px',
      letterSpacing: '0px',
      color: meter.white,
      fontFamily: boldFontFamily,
    },
    h6: meter.typography.title,
    subtitle1: {
      fontSize: '16.7px',
      letterSpacing: '0.2px',
      color: meter.white,
      fontFamily: boldFontFamily,
    },
    body1: {
      fontSize: spacing(1.75),
      letterSpacing: '0.5px',
      color: meter.grey,
    },
    body2: {
      fontSize: spacing(1.5),
      letterSpacing: '0.1px',
      color: meter.text.disabled,
    },
    button: {
      fontFamily: boldFontFamily,
      letterSpacing: '0.1px',
    },
  },
}))

export const greenPalette = {
  type: 'dark',
  primary: {
    light: meter.batch.three,
    main: meter.batch.five,
    dark: meter.batch.seven,
    active: 'rgba(58, 234, 227, 0.12)',
  },
  error: {
    main: meter.error.main
  },
  background: {
    paper: meter.background.four,
    secondary: 'rgba(255, 255, 255, 0.54)',
    light: 'rgba(255, 255, 255, 0.12)',
  },
  text: {
    primary: meter.white,
    secondary: secondaryText
  },
}

export const greenTheme = buildTheme(greenPalette)

export default buildTheme({
  type: 'dark',
  primary: {
    light: meter.blue.light,
    main: meter.blue.main,
    dark: meter.blue.dark,
    active: meter.blue.active,
  },
  error: {
    main: meter.error.main
  },
  background: {
    paper: meter.background.four,
    secondary: 'rgba(255, 255, 255, 0.54)'
  },
  text: {
    primary: meter.white,
    secondary: secondaryText
  },
})
