export const FloorPlanStyles = theme => ({
  button: {
    margin: theme.spacing(2)
  },
  root: {
    display: 'grid',
    height: '100%',
    gridTemplateAreas: [
      '"fp fpForm"',
    ].join('\n'),
    gridTemplateColumns: '1fr 400px',
    '& .no-room-association.active': {
      fill: 'auto',
      transition: 'opacity .3s ease-in-out'
    },
    '& .no-zone-association.active': {
      fill: 'auto',
      transition: 'opacity .3s ease-in-out'
    },
    '& .no-room-association.deactive': {
      fill: 'auto',
      opacity: 0.1,
      transition: 'opacity .3s ease-in-out',
      pointerEvents: 'none'
    },
    '& .no-zone-association.deactive': {
      fill: 'auto',
      opacity: 0.1,
      transition: 'opacity .3s ease-in-out',
      pointerEvents: 'none'
    },
    '& .room-associated': {
      fill: theme.meter.blue.main,
      transition: 'opacity .3s ease-in-out'
    },
    '& .zone-associated': {
      fill: theme.meter.blue.light,
      transition: 'opacity .3s ease-in-out'
    },
    '& .room-associated.active': {
      fill: theme.meter.blue.main,
      transition: 'opacity .3s ease-in-out'
    },
    '& .zone-associated.active': {
      fill: theme.meter.blue.light,
      transition: 'opacity .3s ease-in-out'
    },
    '& .room-associated.deactive': {
      fill: 'auto',
      opacity: 0.1,
      transition: 'opacity .3s ease-in-out',
      pointerEvents: 'none'
    },
    '& .zone-associated.deactive': {
      fill: 'auto',
      opacity: 0.1,
      transition: 'opacity .3s ease-in-out',
      pointerEvents: 'none'
    },
  },
  fpForm: {
    gridArea: 'fpForm',
    padding: '1rem',
  },
  fp: {
    gridArea: 'fp',
  },
  fpName: {
    gridArea: 'fpName',
    margin: '0',
  },
  fpNumber: {
    gridArea: 'fpNumber',
    margin: '0',
  },
  fpRoom: {
    gridArea: 'fpRoom',
    alignSelf: 'start',
    overflow: 'auto',
    height: 'calc(100vh - 270px)',
  },
  fpZone: {
    gridArea: 'fpZone',
    alignSelf: 'start',
    overflow: 'auto',
    height: '75vh'
  }
})

export const mappingCardStyles = () => ({
  zoneList: {
    margin: '2rem',
    marginBottom: '0',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridAutoRows: 'minmax(3rem, auto)',
    gridGap: '1rem',
  },
  checkbox: {
    marginLeft: '20px'
  },
  actions: {
    justifyContent: 'space-between'
  },
})
