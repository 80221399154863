import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import classNames from 'clsx'
import colorUtil from 'color'
import i18n from 'i18n-literally'
import memoizeOne from 'memoize-one'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popover,
  Popper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@material-ui/icons'
import { Autocomplete as MuiAutocomplete, createFilterOptions } from '@material-ui/lab'

import { inflect } from 'inflection'

import getGlobal from '~/Lib/getGlobal'
import createLogger from '~/Lib/Logging'
import {
  defer,
  EMPTY_OBJECT,
  noop,
  uniqueId,
} from '~/Lib/Utils'
import {
  shallowEquals,
} from '~/Lib/Utils/equality'
import TextField from '~/UI/Shared/Form/TextField'
import T from '~/UI/Shared/Typography'
import ArrowDown from '~/UI/Icons/ArrowDown'
import VirtualizedAutocomplete from '~/UI/Shared/VirtualizedAutocomplete'

import Tooltip from './Tooltip'

const displayName = 'Aroya/Select'
const logger = createLogger(displayName)
const DEFAULT_TESTID = 'aroya-select'

const useStyles = makeStyles(theme => {
  const color = colorUtil(theme.palette.primary.main)

  return {
    root: {
      display: 'contents',
      '&:not($input) label[class*=MuiFormLabel-root]': {
        ...theme.typography.button,
        lineHeight: 'inherit'
      },
      '&:not($input) [class*=MuiAutocomplete-inputRoot][class*="MuiInput-root"]': {
        height: 32,
        minHeight: 32,
        '&:before': {
          display: 'none',
        },
        '& [class*=MuiAutocomplete-input]:first-child': {
          top: -4,
        }
      },
      '&:not($input) [class*=MuiAutocomplete-root] [class*=MuiInputLabel-root]': {
        transform: 'translate(0, 6px) scale(1)',
        '&[class*=MuiInputLabel-shrink]': {
          color: color.alpha(0).string(),
        }
      },
      '&:not($title):not($input) [class*=MuiAutocomplete-root] $button': {
        height: 32,
        minHeight: 32,
        minWidth: 160,
        paddingBlock: 0,
        borderRadius: 16
      },
      '&:not($title):not($input) [class*=MuiAutocomplete-root] $button$rectangular': {
        height: '2.5rem',
        maxHeight: '2.5rem',
        borderRadius: theme.shape.borderRadius,
        '& [class*=MuiAutocomplete-inputRoot][class*="MuiInput-root"]': {
          height: '100%',
          minHeight: '100%',
        },
        '& [class*=MuiAutocomplete-endAdornment]': {
          top: 'calc(50%)',
          transform: 'translateY(-50%)'
        },
        '& [class*=MuiAutocomplete-input]:first-child': {
          top: 0,
        },
        '& [class*=MuiInputLabel-root]': {
          top: 2
        }
      },
      '&:not($input) [class*=MuiAutocomplete-root] [class*=MuiAutocomplete-endAdornment]': {
        marginRight: 0,
      },
      '&$title > $button': {
        height: '4rem'
      },
      '&$fullWidth > :first-child': {
        width: '100%',
      },
      '@media print': {
        '& [class*=-endAdornment], & [class*=-endIcon]': {
          display: 'none'
        },
        '& $button': {
          backgroundColor: 'transparent',
        },
        '& button *, & input': {
          color: theme.aroya.contrastInverse.main,
        }
      },
    },
    autocompleting: {},
    description: {
      '& .MuiFormControl-root': {
        height: '3.5rem!important',
        maxHeight: '3.5rem!important',
        '& .MuiInputBase-input': {
          '&.MuiInput-input': {
            '&.MuiAutocomplete-input': {
              paddingTop: 0,
            }
          }
        }
      },
      '& .MuiFormHelperText-root': {
        position: 'absolute',
        marginLeft: 0,
        marginTop: 4,
        top: '2rem',
        color: theme.palette.primary.inactive,
      }
    },
    button: {
      padding: theme.spacing(0.5, 2),
      pointerEvents: 'auto',
      backgroundColor: color.alpha(0.08).string(),
      '&$noWrap': {
        whiteSpace: 'nowrap'
      },
      '&$transparent': {
        backgroundColor: 'transparent',
      },
      '&:hover, &$transparent:hover': {
        backgroundColor: color.alpha(0.12).string(),
      },
      '& [class*=MuiAutocomplete-popupIndicator] svg': {
        fontSize: '1.25rem',
      },
      '& [class*=MuiButton-endIcon] svg': {
        transition: theme.transitions.create('transform'),
      },
      '&$open [class*=MuiButton-endIcon] svg': {
        transform: 'rotate(180deg)',
      },
      '& [class*=MuiInput-root]': {
        padding: 0,
      },
      '& [class*=MuiInput-input]': {
        fontFamily: theme.typography.boldFontFamily,
        fontWeight: 'bold',
        letterSpacing: 0.1
      },
      '&$rectangular': {
        padding: theme.spacing(1, 2),
        borderRadius: theme.shape.borderRadius,
        height: '2.5rem',
      },
    },
    fullWidth: {},
    input: {},
    noWrap: {},
    open: {},
    rectangular: {},
    transparent: {},
    popover: {},
    autocomplete: {
      minWidth: 160,
      paddingRight: theme.spacing(2),
    },
    title: {
      fontSize: 50,
      '&$root [class*=MuiAutocomplete-inputRoot][class*="MuiInput-root"]': {
        height: 75,
        minHeight: 75,
        color: theme.palette.text.primary,
      },
      '& [class*=MuiAutocomplete-root] $button': {
        height: '75px',
        backgroundColor: 'transparent',
        background: 'none',
        minWidth: 'auto',
        paddingLeft: 0,
        padding: 0,
      },
      '& input[class*=MuiAutocomplete-input]': {
        fontSize: '50px',
        left: 0,
        fontWeight: 'bold',
        flexGrow: '1',
      },
      '& [class*=MuiAutocomplete-endAdornment]': {
        position: 'static',
        display: 'flex',
        flexDirection: 'row-reverse'
      },
      '& [class*=MuiAutocomplete-endAdornment] svg': {
        fontSize: 35
      },
      '& > [class*=MuiAutocomplete-root]': {
        display: 'inline-flex',
        maxWidth: '100%',
      },
    },
    endIcon: {
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
    option: {},
    optionCheckbox: {},
    optionLabel: {},
    optionDescription: {},
    optionIcon: {},
    optionWrapper: {},
  }
}, { name: displayName })

const defaultGetOptionLabel = option => option?.label ?? option
const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: defaultGetOptionLabel
})
const creatableFilterOptions = (options, params) => {
  const { inputValue, getOptionLabel = defaultGetOptionLabel } = params
  const inputTrimmed = inputValue && inputValue.trim()
  const filtered = filterOptions(options, params)
  const inputExact = options.find(option => getOptionLabel(option) === inputTrimmed)
  return inputValue && !inputExact ? [
    ...filtered,
    {
      inputValue,
      label: i18n`Add "${inputValue}"`
    }
  ] : filtered
}

const popperOptions = {
  placement: 'bottom-start',
  modifiers: { flip: { enabled: false }, offset: { offset: '-16px, -4px' } }
}

const getPopperComponent = memoizeOne(menuWidth => popperProps => (
  <Popper
    {...popperProps}
    keepMounted
    popperOptions={popperOptions}
    style={{
      ...popperProps.style,
      width: menuWidth
    }}
  />
))

const getPaperComponent = memoizeOne(id => paperProps => (
  <Paper {...paperProps} data-testid={`${id}-menu`} />
))

const valueType = PropTypes.oneOfType([
  PropTypes.oneOf([null]),
  PropTypes.string,
  PropTypes.number,
  PropTypes.shape({}),
  PropTypes.symbol,
])

/**
 * A flexible selection component that handles single and multiple values, searching, tags when multiple
 * @component
 * @alias Select
 * @named_export SelectComponent
 * @composes
 * from: ~/src/UI/Shared/Button
 * propName: buttonProps
 * @example
 * {() => {
 *   const options = [
 *     { label: 'One', value: 1 },
 *     { label: 'Two', value: 2 },
 *     { label: 'Three', value: 3 },
 *     { label: 'Four', value: 4 },
 *     { label: 'Five', value: 5 },
 *     { label: 'Six', value: 6 },
 *     { label: 'Seven', value: 7 },
 *     { label: 'Eight', value: 8 },
 *     { label: 'Nine', value: 9 },
 *     { label: 'Ten', value: 10 },
 *   ]
 *   const [selected, setSelected] = React.useState(null)
 *   const selectedOption = selected && selected.value
 *     ? selected
 *     : (selected && options.find(option => option.value === selected))
 *
 *   return (
 *     <>
 *      {selectedOption ? (
 *         <Box textAlign="center" fontSize="2rem">
 *           selected:{'\u00a0'}{selectedOption.label}{'\u00a0'}({selectedOption.value})
 *         </Box>
 *       ) : null}
 *       <Box className="default-display">
 *         <Select
 *           rectangular
 *           label="Choose one"
 *           options={options}
 *           onChange={setSelected}
 *         />
 *         <Select
 *           autocomplete
 *           label="Choose one"
 *           options={options}
 *           onChange={setSelected}
 *         />
 *         <Select
 *           label="Choose one"
 *           options={options}
 *           onChange={setSelected}
 *         />
 *         <Select
 *           color="default"
 *           label="Choose one"
 *           options={options}
 *           onChange={setSelected}
 *         />
 *         <Select
 *           color="secondary"
 *           label="Choose one"
 *           options={options}
 *           onChange={setSelected}
 *         />
 *         <Select
 *           autocomplete
 *           danger
 *           rectangular
 *           label="Choose one"
 *           options={options}
 *           onChange={setSelected}
 *         />
 *       </Box>
 *     </>
 *   )
 * }}
 */
export const SelectComponent = ({
  autocomplete = null,
  autocompleteProps = EMPTY_OBJECT,
  buttonProps = EMPTY_OBJECT,
  checkbox = false,
  className = undefined,
  color = 'primary',
  creatable = false,
  'data-testid': dataTestId = DEFAULT_TESTID,
  disabled = false,
  error = null,
  fullWidth = false,
  helperText = null,
  id: idProp = dataTestId,
  input = false,
  label: labelProp = null,
  limitTags = 0,
  maxButtonOptions = 25,
  maxLength = Infinity,
  multiple = false,
  multipleKey = '',
  noAutoLabel = false,
  noWrap = false,
  options,
  placeholder = undefined,
  rectangular = true,
  title = false,
  transparent = false,
  value = null,
  virtualize = options.length > 150,
  getOptionSelected = null,
  onBlur = null,
  onChange,
  onClose = null,
  ...props
}) => {
  const classes = useStyles(props)
  const containerRef = useRef(null)
  const [addTooltipWrapper, setAddTooltipWrapper] = useState(false)
  const id = useMemo(() => (idProp === DEFAULT_TESTID ? uniqueId(DEFAULT_TESTID) : idProp), [idProp])
  const selectedOptions = useMemo(() => {
    if (getOptionSelected) {
      return options.filter(option => getOptionSelected(option, value))
    }
    return Array.isArray(value)
      ? options.filter(option => value.includes(option.value) || value.includes(option))
      : options.filter(option => {
        if (value && typeof value === 'object') {
          return shallowEquals(value, option.value)
        }
        return option.value === value || option === value
      })
  }, [value, options, getOptionSelected])
  const selectedOption = selectedOptions?.length ? selectedOptions[0] : null
  const selectedDescription = selectedOption?.description ?? null

  const [inputValue, setInputValue] = useState((input && autocompleteProps.inputValue) || (selectedOption?.label ?? ''))
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [open, setOpen] = useState(false)
  const autocompleting = Boolean(
    autocomplete
    || creatable
    || input
    || options.length > maxButtonOptions
  )
  const debounceToggle = useRef(0)
  const onToggle = useCallback(event => {
    const now = Date.now()
    if (now - debounceToggle.current < 16) return
    debounceToggle.current = now
    setMenuAnchor(old => {
      const nextAnchor = (old ? null : event?.target?.closest('button'))
      if (!nextAnchor && onClose?.call) {
        defer(onClose, defer.priorities.low)
      }
      if (!nextAnchor && onBlur?.call) {
        if (event?.persist?.call) event.persist()
        defer(() => onBlur(event), defer.priorities.low)
      }
      return nextAnchor
    })
  }, [setMenuAnchor, onClose, onBlur])
  const onInputChange = useCallback((event, newInputValue, reason) => {
    if (!creatable && !autocompleting) return
    const { target } = event ?? EMPTY_OBJECT
    if (reason === 'reset') {
      if (target == null) {
        setInputValue(old => {
          if (old.length === 1) {
            return old
          }
          return newInputValue
        })
        return
      }
    }
    logger('updating input value:', newInputValue, reason)
    // Strip off the 'Add' and double-quotes if present
    setInputValue(newInputValue.startsWith('Add "') ? newInputValue.slice(5, -1) : newInputValue)
  }, [autocompleting, creatable, setInputValue])
  let Unchecked = CheckBoxOutlineBlank
  let Checked = CheckBox
  if (checkbox && !multiple) {
    Unchecked = RadioButtonUnchecked
    Checked = RadioButtonChecked
  }

  let label = labelProp
  if (!noAutoLabel) {
    if (multiple) {
      if (value?.length > 1) {
        if (multipleKey) {
          label = `${value?.length} ${inflect(multipleKey, value?.length)}`
        } else if (typeof labelProp === 'string') {
          label = `${value?.length} ${inflect(labelProp, value?.length)}`
        } else {
          label = labelProp
        }
      } else {
        const selected = Array.isArray(value) ? value[0] : value
        label = options.find(option => option.value === selected || option === selected)?.label ?? labelProp
      }
    } else if (selectedOption) {
      label = selectedOption?.title
        ? (
          <Tooltip title={selectedOption.title}>
            {React.isValidElement(selectedOption.label)
              ? selectedOption.label
              : <span>{selectedOption.label}</span>}
          </Tooltip>
        )
        : selectedOption?.label
    }
  }

  useEffect(() => {
    const resizeCallback = () => {
      const addTooltip = containerRef.current
        && Array.from(containerRef.current.querySelectorAll('input, label, .MuiButton-label')).some(el => (autocompleting ? (
          el.clientWidth < el.scrollWidth
        ) : el.clientWidth < el.scrollWidth - 4))
      setAddTooltipWrapper(addTooltip)
    }
    getGlobal().addEventListener('resize', resizeCallback)
    resizeCallback()
    return () => {
      getGlobal().removeEventListener('resize', resizeCallback)
    }
  }, [autocompleting])

  let autocompleteValue = multiple ? value : selectedOption
  if (autocompleting && !multiple && selectedOption && inputValue !== selectedOption.label) {
    autocompleteValue = null
  }
  let autoCompleteInputValue = inputValue != null ? inputValue : (selectedOption?.label ?? '')
  if (!open && noAutoLabel) {
    autoCompleteInputValue = labelProp
  }

  const longestLabel = useMemo(() => Math.min(64, options.reduce((longest, option) => {
    const optionLabel = typeof option === 'string' ? option : (option.label ?? '')

    return longest > optionLabel.length ? longest : optionLabel.length
  }, 0)), [options])
  const minWidth = fullWidth ? undefined : `max(10rem, calc(${Math.max(5, inputValue.length * 1.25)}ch + ${title ? 150 : 88}px))`
  const menuWidth = `clamp(10rem, calc(${longestLabel + 2}ch + 2rem), 100vw)`
  const optionFilter = creatable ? creatableFilterOptions : filterOptions
  let Autocomplete = autocompleting ? MuiAutocomplete : 'div'
  const SelectWrapper = addTooltipWrapper ? Tooltip : React.Fragment
  const selectWrapperProps = addTooltipWrapper ? {
    title: React.isValidElement(label) ? label : <T.Tiny>{label}</T.Tiny>,
    arrow: true,
    variant: 'light'
  } : EMPTY_OBJECT

  if (autocompleting && virtualize) {
    Autocomplete = VirtualizedAutocomplete
  }
  logger.debug('vars:', {
    autocompleting,
    autocompleteValue,
    inputValue,
    label: labelProp,
    multipleLabel: label,
    open,
    selectedOption,
    selectedOptions,
    value,
    options,
  })

  return autocompleting ? (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.autocompleting]: true,
        [className]: Boolean(className),
        [classes.fullWidth]: fullWidth,
        [classes.title]: title,
        [classes.input]: input,
      })}
      data-is-select="true"
      ref={containerRef}
    >
      <SelectWrapper {...selectWrapperProps}>

        <Autocomplete
          forcePopupIcon
          selectOnFocus
          classes={{
            option: classes.optionWrapper,
            root: classNames({
              [classes.description]: Boolean(selectedDescription)
            }),
          }}
          clearOnBlur={creatable}
          closeIcon={<Close color={color} fontSize="small" />}
          disabled={disabled}
          freeSolo={creatable}
          handleHomeEndKeys={creatable}
          id={id}
          inputValue={autoCompleteInputValue}
          limitTags={limitTags}
          multiple={multiple}
          options={options}
          PaperComponent={getPaperComponent(id)}
          PopperComponent={getPopperComponent(menuWidth)}
          popupIcon={<ArrowDown color={color} />}
          style={minWidth ? { minWidth, maxWidth: 'max-content', width: 'max-content' } : undefined}
          value={autocompleteValue}
          filterOptions={optionFilter}
          getOptionLabel={defaultGetOptionLabel}
          getOptionSelected={getOptionSelected || undefined}
          onBlur={e => {
            if ((e.target.value === '' || e.target.value === selectedOption?.label) && !multiple && selectedOption?.label) {
              setInputValue(selectedOption.label)
            }
            if (props.onBlur?.call) {
              if (e?.persist?.call) e.persist()
              props.onBlur(e)
            }
          }}
          onChange={(ignore, option, acReason) => {
            let reason = acReason
            if (option == null) {
              onChange(option, reason)
              if (reason === 'cleared') {
                logger('cleared')
                setInputValue('')
              }
              return
            }
            let selected = typeof option === 'object' && 'value' in option
              ? option.value
              : option
            if (creatable && option.inputValue) {
              reason = 'creating'
              selected = option.inputValue
            }
            onChange(selected, reason)
          }}
          onClose={(_, reason) => {
            setOpen(false)
            if (onClose?.call) {
              onClose()
            }
            if (reason === 'select-option' || reason === 'escape') return
            if (!value && creatable && inputValue) {
              onChange({ inputValue }, reason)
            }
          }}
          onOpen={() => setOpen(true)}
          onInputChange={onInputChange}
          onKeyPress={event => {
            const { key } = event
            if (key === 'Enter') {
              let filteredOptions = optionFilter(options, { inputValue })
              const { 0: first } = options
              if (filteredOptions.length > 1 && typeof first?.label === 'string') {
                filteredOptions = filteredOptions.filter(opt => opt.label === inputValue)
              }
              if (filteredOptions.length !== 1) return
              const { 0: selected } = filteredOptions
              onChange(selected.value, 'choose-on-enter')
            }
          }}
          renderInput={iProps => (
            <TextField
              {...iProps}
              className={classNames({
                [classes.button]: !input,
                [classes.rectangular]: rectangular
              })}
              color={color}
              data-testid={id || dataTestId}
              fullWidth
              helperText={selectedDescription ?? null}
              label={selectedOption ? selectedOption.label : labelProp}
              maxLength={maxLength}
              placeholder={(input && placeholder) || null}
              onChange={noop}
            />
          )}
          renderOption={(option, { selected }) => (
            <Box
              title={option.title}
              width="100%"
              display="flex"
              paddingY="6px"
              color={option.color || 'primary.main'}
              gap="1ch"
              className={classes.option}
            >
              {(!option.label && multiple) || checkbox ? (
                <Box paddingX={2} className={classes.optionCheckbox}>
                  {selected
                    ? <Checked color={option.color ? 'inherit' : undefined} />
                    : <Unchecked color={option.color ? 'inherit' : undefined} />}
                </Box>
              ) : null}
              <T
                noWrap
                component="span"
                className={classes.optionLabel}
              >
                {option.label ?? option}
              </T>
              {option.description ? (
                <T.Tiny
                  component="span"
                  className={classes.optionDescription}
                >
                  {option.description}
                </T.Tiny>
              ) : null}
            </Box>
          )}
          {...autocompleteProps}
        />
      </SelectWrapper>
      {error || helperText ? (
        <FormHelperText error={Boolean(error)}>{error || helperText}</FormHelperText>
      ) : null}
    </div>
  ) : (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: Boolean(className),
        [classes.fullWidth]: fullWidth,
        [classes.title]: title,
      })}
      ref={containerRef}
    >
      <SelectWrapper {...selectWrapperProps}>
        <Button
          aria-expanded={String(Boolean(menuAnchor))}
          aria-haspopup="true"
          data-testid={dataTestId}
          classes={{ endIcon: classes.endIcon }}
          className={classNames({
            [classes.button]: true,
            [classes.transparent]: transparent,
            [classes.title]: title,
            [classes.noWrap]: noWrap,
            [classes.rectangular]: rectangular,
            [classes.open]: Boolean(menuAnchor)
          })}
          color={color}
          disabled={disabled}
          endIcon={<ArrowDown />}
          {...buttonProps}
          id={id ?? buttonProps?.id}
          onClick={onToggle}
        >
          <Box component="label" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {title ? label : (
              <T
                color="primary"
                component="span"
              >
                {label}
              </T>
            )}
            {selectedDescription ? (
              <T.Tiny
                component="span"
              >
                {selectedDescription}
              </T.Tiny>
            ) : null}
          </Box>
        </Button>
      </SelectWrapper>
      <Popover
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classNames(classes.popover, autocompleting && classes.autocomplete) }}
        open={Boolean(menuAnchor)}
        PaperProps={{ 'data-testid': `${id}-menu` }}
        onClose={onToggle}
      >
        <List role="menu" style={{ width: menuWidth }}>
          {options.map(option => {
            const { color: optionColor, description, Icon } = option
            let selected
            if (getOptionSelected) {
              selected = getOptionSelected(option, value)
            } else if (multiple && Array.isArray(value)) {
              if (value.length > 0) {
                selected = value.includes(option.value)
              } else {
                selected = (option.value === '' || option.value === null)
              }
            } else {
              selected = value === option.value
            }

            return (
              <ListItem
                button
                key={option.key || option.label}
                data-testid={`aroya-select-option-${option.key || option.label}`}
                role="menuitem"
                disabled={option.disabled}
                selected={transparent && checkbox ? false : selected}
                title={option.title}
                onClick={event => {
                  if (multiple) {
                    let selectedValue
                    if (Array.isArray(value)) {
                      selectedValue = value
                    } else {
                      selectedValue = value ? [value] : []
                    }
                    if (option.value) {
                      onChange(
                        selectedValue.includes(option.value)
                          ? selectedValue.filter(v => v !== option.value)
                          : [...selectedValue, option.value]
                      )
                    } else {
                      onChange([])
                    }
                  } else {
                    onChange(option.value)
                  }
                  if (!multiple) {
                    if (event?.persist?.call) {
                      event.persist()
                    }
                    onToggle(event)
                  }
                }}
                className={classes.option}
              >
                <Box display="contents" color={optionColor ?? 'primary.main'}>
                  {checkbox ? (
                    <Box component={ListItemIcon} color="inherit !important" className={classes.optionCheckbox}>
                      {selected
                        ? <Checked color="inherit" />
                        : <Unchecked color="inherit" />}
                    </Box>
                  ) : null}
                  <ListItemText disableTypography>
                    <T
                      color={optionColor ? 'inherit' : 'primary'}
                      component="span"
                      className={classes.optionLabel}
                    >
                      {option.label}
                    </T>
                    {description ? (
                      <T.Tiny
                        component="span"
                        className={classes.optionDescription}
                      >
                        {description}
                      </T.Tiny>
                    ) : null}
                  </ListItemText>
                  {Icon ? (
                    <ListItemSecondaryAction>
                      <Icon color={optionColor ? 'inherit' : undefined} className={classes.optionIcon} />
                    </ListItemSecondaryAction>
                  ) : null}
                </Box>
              </ListItem>
            )
          })}
        </List>
      </Popover>
      {error || helperText ? (
        <FormHelperText error={Boolean(error)}>{error || helperText}</FormHelperText>
      ) : null}
    </div>
  )
}
SelectComponent.displayName = 'Select'
SelectComponent.propTypes = {
  autocomplete: PropTypes.bool,
  autocompleteProps: PropTypes.shape({
    disableClearable: PropTypes.bool,
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  }),
  buttonProps: PropTypes.shape({
    id: PropTypes.string,
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
    disableRipple: PropTypes.bool,
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  }),
  checkbox: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  creatable: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.bool,
  label: PropTypes.node,
  /**
   * Number of tags to show in input when multiple is true
   * When 0 none will be shown
   * When -1 there is no limit
   */
  limitTags: PropTypes.number,
  maxButtonOptions: PropTypes.number,
  maxLength: PropTypes.number,
  multiple: PropTypes.bool,
  multipleKey: PropTypes.string,
  noAutoLabel: PropTypes.bool,
  noWrap: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      description: PropTypes.string,
      label: PropTypes.node.isRequired,
      value: valueType,
    })
  ])).isRequired,
  placeholder: PropTypes.string,
  rectangular: PropTypes.bool,
  title: PropTypes.bool,
  transparent: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(valueType),
    valueType,
  ]),
  virtualize: PropTypes.bool,
  getOptionSelected: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
}

export const FormikSelect = props => {
  const { field, form, alwaysShowError, noDirty, customOnChange, customValue, label, ...passthru } = props
  const { name } = field
  const { setFieldTouched, setFieldValue } = form

  const onChange = useCallback(
    (value, ...rest) => {
      setFieldTouched(name, true)
      if (customOnChange) {
        customOnChange(value, field, ...rest)
        return
      }
      setFieldValue(name, value)
    },
    [field, name, customOnChange, setFieldTouched, setFieldValue]
  )
  const { error, touched } = form.getFieldMeta(field.name)
  const onBlur = useCallback(() => setFieldTouched(name, true), [name, setFieldTouched])
  if (error) logger(field.name, 'error =>', error, touched)
  return (
    <SelectComponent
      {...passthru}
      {...field}
      label={label || field.value || ''}
      value={customValue || field.value}
      error={(noDirty || form.dirty) && (touched || alwaysShowError) ? error : undefined}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}

export default React.memo(SelectComponent)
