import createLogger from 'bows'
import qs from 'query-string'

import config from '~/App/config'
import { getTokens, refreshTokens } from '~/Lib/Auth'
import { getClientId } from '~/Lib/Utils'

const logger = createLogger('IO/API')

const IOError = {
  isIOError: true,
  get message() {
    return this.error.message
  },
  get status() {
    return this.error.status
  },
  get response() {
    return this.error.response
  },
}
function ioErrorFactory(message, status, response) {
  return Object.assign(Object.create(IOError), {
    error: {
      message,
      status,
      response,
    },
  })
}

const getDefaultHeaders = accessToken => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${accessToken}`,
  'X-Client': getClientId(),
  'X-Client-Version': process.env.npm_package_version,
})

const methods = {
  fetch: (apiFetch, url, payload, opts) => apiFetch(url, payload, {
    ...opts,
    method: 'GET',
  }),
  create: (apiFetch, url, payload, opts) => apiFetch(url, payload, {
    ...opts,
    method: 'POST',
  }),
  update: (apiFetch, url, payload, opts) => apiFetch(url, payload, {
    ...opts,
    method: 'PUT',
  }),
  destroy: (apiFetch, url, opts) => apiFetch(url, null, {
    ...opts,
    method: 'DELETE',
  }),
}
const addMethods = apiFetch => {
  const addons = {}
  Object.entries(methods).forEach(([method, handler]) => {
    addons[method] = handler.bind(null, apiFetch)
  })
  return Object.assign(apiFetch, addons)
}

const NO_BODY_METHODS = ['HEAD', 'OPTIONS']

const getApiFetch = store => {
  // TODO: Make apiFetch return body even on non-200 responses. See !14.
  let fourOhOnes = 0
  const apiFetch = async (path, body, opts = {}) => {
    if (!store.selectIsOnline()) throw ioErrorFactory('Internet unavailable')
    const {
      headers: optsHeaders,
      method = 'GET',
      allowedCodes = [],
      ...options
    } = opts
    const { access, refresh } = getTokens()

    const headers = new Headers({
      ...getDefaultHeaders(access),
      ...optsHeaders,
    })

    let url = path.startsWith('http') ? path : `${config.API_URL}${path}`
    if ((method === 'GET' || method === 'DELETE') && body) {
      url += `?${qs.stringify(body)}`
    }
    const payload = { ...options, method, headers }
    if (body && (method === 'POST' || method === 'PUT' || method === 'PATCH')) {
      payload.body = JSON.stringify({ ...body })
    }

    const response = await fetch(url, payload)

    if (!response.ok && !response.redirected) {
      if (response.status === 401) {
        fourOhOnes += 1
        if (fourOhOnes > 10) {
          // captureEvent({
          //   message: 'Logging out due to repeated authorization failures',
          //   extra: { auth: store.selectAuth(), tokens: getTokens() },
          //   level: 'warning',
          // })
          await store.doLogout()
          return false
        }
        if (refresh) {
          await refreshTokens(store)
          return apiFetch(path, body, opts)
        }
      }
    }

    if (store.selectMaintenanceModeEnabled() && response.ok) {
      store.doMaintenanceModeDisable()
    }

    if (response.headers.has('X-Maintenance')) {
      if (!store.selectMaintenanceModeEnabled()) {
        store.doMaintenanceModeEnable()
      }
    }

    try {
      let json
      if (
        response.status === 204
          || typeof response.json !== 'function'
          || NO_BODY_METHODS.includes(method)
      ) {
        json = null
      } else {
        json = await response.json()
      }
      if (!response.ok && !allowedCodes.includes(response.status)) {
        throw ioErrorFactory('Error: API request failed', response.status, json)
      }
      return json
    } catch (error) {
      if (
        error instanceof SyntaxError
        && error.toString().match(/JSON\.parse/)
      ) {
        const rawResponse = await response.text()
        logger.error('invalid response received:', error.toString())
        logger.error('raw response:', rawResponse)
      }
      throw error
    }
  }
  window.apiFetch = apiFetch
  return addMethods(apiFetch)
}

export default getApiFetch
