const MIN_DISTANCE_RATIO = 0.0
// Max distance from edge is at most 50% of the containing area
const MAX_DISTANCE_RATIO = 0.5

const MIN_USER_SPACE_WIDTH = 400
const MAX_USER_SPACE_WIDTH = 2200

/**
 * Get the appropriate user space width for an area's label viewBox.
 *
 * From the MDN web docs: "The viewBox attribute defines the position and dimension, in user space,
 * of an SVG viewport." This user space is mapped to the bounds of the associated SVG viewport,
 * so a higher value will scale down child elements, i.e. a smaller rendered label font size.
 *
 * This algorithm uses the distance from the label to the area's nearest edge to calculate the
 * proportion of the facility covered in that dimension, normalizes this value between 0 and 1,
 * and maps it to a user space value between the set minimum and maximum.
 *
 * @param {number} aspectRatio - The aspect ratio of the area, calculated via (width / height).
 * @param {number} distanceToAreaEdge - The distance between the label and the closest edge.
 * @param {number[]} facilityDimensions - The [width, height] dimensions of the facility.
 */
const getUserSpaceWidth = (
  aspectRatio,
  distanceToAreaEdge,
  facilityDimensions,
) => {
  const [facilityWidth, facilityHeight] = facilityDimensions

  const proportionOfSmallerDimension = aspectRatio < 0.7
    ? distanceToAreaEdge / facilityWidth
    : distanceToAreaEdge / facilityHeight

  // Normalization function: (val - min) / (max - min)
  const normalizedDistance = (
    (proportionOfSmallerDimension - MIN_DISTANCE_RATIO)
    / (MAX_DISTANCE_RATIO - MIN_DISTANCE_RATIO)
  )

  // The distance is inversed to map correctly to user space, and is raised to
  // a power to accentuate the shrinking affect with narrower dimensions.
  const userSpaceWidth = Math.round(
    (1 - normalizedDistance) ** 3
    * (MAX_USER_SPACE_WIDTH - MIN_USER_SPACE_WIDTH)
    + MIN_USER_SPACE_WIDTH
  )

  return userSpaceWidth
}

export default getUserSpaceWidth
