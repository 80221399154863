import React, { useCallback, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import MembershipForm from '~/Membership/form'

import styles from './styles'

const useStyles = makeStyles(styles)

const MembershipList = () => {
  const classes = useStyles()

  const [addMembershipFormOpen, setAddMembershipFormOpen] = useState(false)
  const [editMembershipFormOpen, setEditMembershipFormOpen] = useState(false)
  const [currentMembership, setCurrentMembership] = useState({})

  const {
    isGuestStaff,
    facility,
    doDeleteMembership,
    doSaveMembership,
  } = useConnect(
    'selectIsGuestStaff',
    'selectFacility',
    'doDeleteMembership',
    'doSaveMembership',
  )

  const getRoleNameById = useCallback(
    id => {
      for (let i = 0; i < facility.roles.length; i += 1) {
        if (facility.roles[i].id === id) return facility.roles[i].name
      }
      return undefined
    },
    [facility]
  )

  return (
    <>
      <MembershipForm
        onClose={() => setAddMembershipFormOpen(false)}
        onSave={doSaveMembership}
        open={addMembershipFormOpen}
      />
      <MembershipForm
        instance={currentMembership}
        onClose={() => setEditMembershipFormOpen(false)}
        onDelete={doDeleteMembership}
        onSave={doSaveMembership}
        open={editMembershipFormOpen}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            Memberships
          </Typography>
        </Grid>
        {!isGuestStaff && (
        <Grid item container xs={6} justify="flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={() => setAddMembershipFormOpen(true)}
          >
            Add Membership
          </Button>
        </Grid>
        )}
      </Grid>
      <Grid container spacing={1} direction="column" style={{ marginTop: '8px' }}>
        {facility?.memberships?.map(membership => (
          <Grid item key={membership.id}>
            <Paper
              className={isGuestStaff ? classes.nonClickableRow : classes.clickableRow}
              onClick={() => (
                !isGuestStaff && (
                  setCurrentMembership(membership),
                  setEditMembershipFormOpen(true)
                ))}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item style={{ flexGrow: 1 }}>
                  <Typography color="primary">
                    {`${membership.user?.firstName} ${membership.user?.lastName}`}
                  </Typography>
                  {membership.user?.email}
                </Grid>
                <Grid item>
                  <Typography color="textSecondary">
                    {getRoleNameById(membership.role)}
                  </Typography>
                </Grid>
                <Grid item>
                  {membership.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default MembershipList
