import React, { useEffect } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'

const HarvestHistory = ({ open, onClose, facility }) => {
  const { harvestHistory, doFetchHarvestHistory } = useConnect('selectHarvestHistory', 'doFetchHarvestHistory')

  useEffect(() => {
    doFetchHarvestHistory({ facility: facility.id })
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Harvest History</DialogTitle>
      <DialogContent>
        <List>
          {
            harvestHistory.map(monthData => (
              <ListItem
                style={{
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'space-between'
                }}
                key={monthData.date}
              >
                <Typography>
                  {monthData.date}
                </Typography>
                <Typography>
                  {monthData.count} plants
                </Typography>
              </ListItem>
            ))
          }
        </List>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default HarvestHistory
