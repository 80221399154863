import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'redux-bundler-react'
import createLogger from 'bows'

const logger = createLogger('Routes/NotFound')

export const NotFoundRoute = ({ routeInfo }) => {
  logger.warn("Couldn't find the requested page.")
  logger.debug(routeInfo)
  return <h1>Page Not Found</h1>
}

NotFoundRoute.propTypes = {
  routeInfo: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.string).isRequired,
    pattern: PropTypes.string.isRequired,
  }).isRequired,
}

export default connect(
  'selectRouteInfo',
  NotFoundRoute
)
