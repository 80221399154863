import React, { useState, useEffect } from 'react'
import { useConnect } from 'redux-bundler-hook'

import {
  Grid,
  TableHead,
  TableFooter,
  TableBody,
  TableRow,
  TablePagination,
  Typography,
} from '@material-ui/core'

import ListTable from '~/UI/Shared/ListTable'
import ListTableCell from '~/UI/Shared/ListTableCell'
import UserForm from '~/Users/form'

const SolusList = () => {
  const {
    solusUserListPagination,
    currentSolusUsersList,
    solusUsersListIsLoading,
    isGuestStaff,
    doUserUpdate,
    doUserFetch,
    doMarkSolusUsersListAsOutdated,
    doSolusUserListSetPage
  } = useConnect(
    'selectSolusUserListPagination',
    'selectCurrentSolusUsersList',
    'selectSolusUsersListIsLoading',
    'selectIsGuestStaff',
    'doUserUpdate',
    'doUserFetch',
    'doMarkSolusUsersListAsOutdated',
    'doSolusUserListSetPage',
  )

  useEffect(() => {
    doMarkSolusUsersListAsOutdated()
  }, [])

  const [currentUser, setCurrentUser] = useState({})
  const [editUserFormFormOpen, setEditUserFormFormOpen] = useState(false)

  const onChangePage = (event, page) => {
    doSolusUserListSetPage(
      page === solusUserListPagination.page
        ? solusUserListPagination.page + 1
        : solusUserListPagination.page - 1
    )
  }

  const handleClick = async solusUser => {
    const user = await doUserFetch(solusUser.user.id)
    setCurrentUser(user)
    setEditUserFormFormOpen(true)
  }

  return (
    <Grid item style={{ flexGrow: 1 }}>
      <UserForm
        instance={currentUser}
        onClose={() => setEditUserFormFormOpen(false)}
        onSave={doUserUpdate}
        open={editUserFormFormOpen}
      />
      <ListTable>
        <TableHead style={{ paddingLet: '50px' }}>
          <TableRow>
            <ListTableCell>Name</ListTableCell>
            <ListTableCell>Phone</ListTableCell>
            <ListTableCell>Email</ListTableCell>
            <ListTableCell>State</ListTableCell>
            <ListTableCell>Grow Type</ListTableCell>
            <ListTableCell>Total Space</ListTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentSolusUsersList.map(solusUser => (
            <React.Fragment key={solusUser.id}>
              <TableRow
                className={isGuestStaff ? 'default' : 'pointer'}
                onClick={() => !isGuestStaff && handleClick(solusUser)}
              >
                <ListTableCell style={{ maxWidth: '400px' }}>
                  <Typography variant="subtitle1" className="text-normal" noWrap>
                    {solusUser.user?.fullName}
                  </Typography>
                </ListTableCell>
                <ListTableCell>
                  <Typography variant="subtitle1" className="text-normal" noWrap>
                    {solusUser.user?.phoneNumber}
                  </Typography>
                </ListTableCell>
                <ListTableCell style={{ maxWidth: '400px' }}>
                  <Typography variant="subtitle1" className="text-normal" noWrap>
                    {solusUser.user?.email}
                  </Typography>
                </ListTableCell>
                <ListTableCell>
                  {solusUser.state}
                </ListTableCell>
                <ListTableCell>
                  {solusUser.growType}
                </ListTableCell>
                <ListTableCell>
                  {solusUser.totalSpace}
                </ListTableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={12}
              nextIconButtonProps={{ disabled: !solusUserListPagination.next || solusUsersListIsLoading }}
              backIconButtonProps={{ disabled: !solusUserListPagination.previous || solusUsersListIsLoading }}
              classes={{ spacer: 'hidden' }}
              count={solusUserListPagination.count}
              rowsPerPage={solusUserListPagination.pageSize}
              rowsPerPageOptions={[]}
              page={solusUserListPagination.page - 1}
              onChangePage={onChangePage}
              labelDisplayedRows={() => (
                `Page ${solusUserListPagination.page} of ${solusUserListPagination.numPages}`
              )}
            />
          </TableRow>
        </TableFooter>
      </ListTable>
    </Grid>
  )
}

export default SolusList
