import React, { useEffect, useState } from 'react'

import { Button, Grid } from '@material-ui/core'
import { useConnect } from 'redux-bundler-hook'
import { makeStyles } from '@material-ui/styles'
import classNames from 'clsx'

import MuiTitle from '~/UI/Shared/MuiTitle'
import Loading from '~/UI/Shared/utils'
import MissingFacilitiesDialog from './Map/dialog'
import Line from './Charts/line'
import Bar from './Charts/bar'
import styles from './styles'
import List from './list'
import Map from './Map/map'
import MapFilter from './Map/filters'

const CHART = 'chart'
const MAP = 'map'
const useStyles = makeStyles(styles)

export const SystemComponent = () => {
  const {
    facilities,
    systemChart,
    routeParams,
    systemChartStartMonth,
    systemChartBarGraph,
    systemChartIsLoading,
    systemChartAvailableMetrics,
    doUpdateUrl,
    doMarkSystemChartAsOutdated,
    doSystemChartSetStartDate,
    doSystemChartFilterMetrics,
    doSystemChartIsolateMetric,
    doSystemChartSetAvailableMetrics,
  } = useConnect(
    'selectFacilities',
    'selectSystemChart',
    'selectRouteParams',
    'selectSystemChartStartMonth',
    'selectSystemChartBarGraph',
    'selectSystemChartIsLoading',
    'selectSystemChartAvailableMetrics',
    'doUpdateUrl',
    'doMarkSystemChartAsOutdated',
    'doSystemChartSetStartDate',
    'doSystemChartFilterMetrics',
    'doSystemChartIsolateMetric',
    'doSystemChartSetAvailableMetrics',
  )

  const [facilityDialogOpen, setFacilityDialogOpen] = useState(false)

  const classes = useStyles()
  const { tab } = routeParams

  const missingFacilities = Object.values(facilities).filter(
    fac => fac.latitude === null || fac.longitude === null
  )

  useEffect(() => {
    doMarkSystemChartAsOutdated()
  }, [])

  return (
    <>
      <Grid>
        <MuiTitle title={tab === CHART ? 'System Statistics' : 'Customer Map'} />
      </Grid>
      {(!systemChart || systemChartIsLoading)
        ? <Loading />
        : (
          <Grid container>
            <Grid item xs={tab === CHART ? 9 : 12}>
              <Grid
                container
                className={classNames({
                  [classes.buttonRow]: true,
                  [classes.paddingRight]: tab === CHART,
                })}
                justify="space-between"
              >
                <Grid item>
                  <Button
                    size="small"
                    color="primary"
                    className={classNames({
                      [classes.button]: true,
                      [classes.selected]: tab === CHART,
                    })}
                    onClick={() => doUpdateUrl(`/system/${CHART}`)}
                  >
                    chart
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    className={classNames({
                      [classes.button]: true,
                      [classes.selected]: tab === MAP,
                    })}
                    onClick={() => doUpdateUrl(`/system/${MAP}`)}
                  >
                    map
                  </Button>
                </Grid>
                {tab === CHART ? (
                  <Grid item>
                    <Button
                      size="small"
                      color="primary"
                      className={classNames({
                        [classes.button]: true,
                        [classes.selected]: systemChartStartMonth === 6,
                      })}
                      onClick={() => {
                        doSystemChartSetStartDate(6)
                      }}
                    >
                      6M
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      className={classNames({
                        [classes.button]: true,
                        [classes.selected]: systemChartStartMonth === 3,
                      })}
                      onClick={() => {
                        doSystemChartSetStartDate(3)
                      }}
                    >
                      3M
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      className={classNames({
                        [classes.button]: true,
                        [classes.selected]: systemChartStartMonth === 1,
                      })}
                      onClick={() => {
                        doSystemChartSetStartDate(1)
                      }}
                    >
                      1M
                    </Button>
                  </Grid>
                ) : (
                  <Button
                    size="small"
                    color="primary"
                    className={classes.button}
                    onClick={() => { setFacilityDialogOpen(true) }}
                  >
                    {missingFacilities.length} {missingFacilities.length > 1 ? 'facilities' : 'facility'} not shown
                  </Button>
                )}
                {tab === MAP && (
                  <Grid item>
                    <MapFilter />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {tab === CHART ? (
              <>
                <Grid item xs={9}>
                  <Grid container direction="column">
                    <Line
                      chart={systemChart}
                      chartHeight={280}
                      chartWidth={1000}
                      availableMetrics={systemChartAvailableMetrics}
                    />
                    <Bar
                      chart={systemChartBarGraph}
                      chartHeight={280}
                      chartWidth={1000}
                      availableMetrics={systemChartAvailableMetrics}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <List
                    classes={classes}
                    chart={systemChart}
                    availableMetrics={systemChartAvailableMetrics}
                    filterMetrics={doSystemChartFilterMetrics}
                    setMetrics={doSystemChartSetAvailableMetrics}
                    isolateMetric={doSystemChartIsolateMetric}
                  />
                </Grid>
              </>
            ) : (
              <Map />
            )}
            {facilityDialogOpen && (
              <MissingFacilitiesDialog
                open={facilityDialogOpen}
                onClose={() => setFacilityDialogOpen(false)}
                facilities={missingFacilities}
              />
            )}
          </Grid>
        )}
    </>
  )
}

export default SystemComponent
