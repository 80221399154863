import React, { useState, useEffect } from 'react'
import { useConnect } from 'redux-bundler-hook'
import classNames from 'clsx'
import {
  Button,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from '@material-ui/core'
import { Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons/'

import ConfirmationDialog from '~/UI/Shared/ConfirmationDialog'
import ListTableCell from '~/UI/Shared/ListTableCell'
import ListTable from '~/UI/Shared/ListTable'
import Loading from '~/UI/Shared/utils'
import { EditForm, EntityDialogContent } from './form'

const EntityList = ({ classes, entityType }) => {
  const {
    me,
    currentPlantList,
    plantListIsLoading,
    plantListPagination,
    currentPackageList,
    packageListIsLoading,
    packageListPagination,
    currentPlantBatchList,
    plantBatchListIsLoading,
    plantBatchListPagination,
    currentHarvestBatchList,
    harvestBatchListIsLoading,
    harvestBatchListPagination,
    doMarkPlantListAsOutdated,
    doPlantListSetPagination,
    doMarkPlantBatchListAsOutdated,
    doPlantBatchListSetPagination,
    doMarkHarvestBatchListAsOutdated,
    doHarvestBatchListSetPagination,
    doMarkPackageListAsOutdated,
    doPackageListSetPagination,
  } = useConnect(
    'selectMe',
    'selectCurrentPlantList',
    'selectPlantListIsLoading',
    'selectPlantListPagination',
    'selectCurrentPackageList',
    'selectPackageListIsLoading',
    'selectPackageListPagination',
    'selectCurrentPlantBatchList',
    'selectPlantBatchListIsLoading',
    'selectPlantBatchListPagination',
    'selectCurrentHarvestBatchList',
    'selectHarvestBatchListIsLoading',
    'selectHarvestBatchListPagination',
    'doMarkPlantListAsOutdated',
    'doPlantListSetPagination',
    'doMarkPlantBatchListAsOutdated',
    'doPlantBatchListSetPagination',
    'doPlantBatchListSetPagination',
    'doMarkHarvestBatchListAsOutdated',
    'doHarvestBatchListSetPagination',
    'doMarkPackageListAsOutdated',
    'doPackageListSetPagination',
  )

  const [entityDialogOpen, setEntityDialogOpen] = useState(false)
  const [currentEntity, setCurrentEntity] = useState(null)
  const [editFormOpen, setEditFormOpen] = useState(false)

  const isSuperUser = me.isSuperuser

  const sharedFields = {
    cultivarName: 'Cultivar',
    isInAroya: 'AROYA',
    isInMetrc: 'Metrc',
  }

  const listMappings = {
    PLANTS: {
      data: currentPlantList,
      fetchList: doMarkPlantListAsOutdated,
      isLoading: plantListIsLoading,
      pagination: {
        get: plantListPagination,
        set: doPlantListSetPagination
      },
      columns: {
        label: 'Label',
        ...sharedFields,
        phase: 'Growth Phase',
        plantState: 'State',
        roomName: 'Location',
        plantedDate: 'Planted',
        harvestedDate: 'Harvested',
        dateDestroyed: 'Destroyed',
      }
    },
    PLANT_BATCHES: {
      data: currentPlantBatchList,
      fetchList: doMarkPlantBatchListAsOutdated,
      isLoading: plantBatchListIsLoading,
      pagination: {
        get: plantBatchListPagination,
        set: doPlantBatchListSetPagination
      },
      columns: {
        label: 'Label',
        ...sharedFields,
        plantType: 'Plant Type',
        roomName: 'Location',
        plantedDate: 'Planted',
        initialCount: 'Initial Count',
        untrackedCount: 'Untracked Count',
      },
    },
    HARVEST_BATCHES: {
      data: currentHarvestBatchList,
      fetchList: doMarkHarvestBatchListAsOutdated,
      isLoading: harvestBatchListIsLoading,
      pagination: {
        get: harvestBatchListPagination,
        set: doHarvestBatchListSetPagination
      },
      columns: {
        name: 'Name',
        ...sharedFields,
        roomName: 'Location',
        currentHarvestWeight: 'Current Weight',
        dateHarvested: 'Harvest Date',
        finishedDate: 'Finished Date',
      },
    },
    PACKAGES: {
      data: currentPackageList,
      fetchList: doMarkPackageListAsOutdated,
      isLoading: packageListIsLoading,
      pagination: {
        get: packageListPagination,
        set: doPackageListSetPagination
      },
      columns: {
        label: 'Label',
        ...sharedFields,
        roomName: 'Location',
        currentPackageQuantity: 'Quantity',
        finishedDate: 'Finished Date',
        packagedDate: 'Packaged Date',
      },
    }
  }

  useEffect(() => {
    listMappings[entityType].fetchList()
  }, [])

  const getTypographyColor = (diff, firstCell) => {
    if (diff) return 'secondary'
    if (firstCell) return 'textPrimary'
    return 'inherit'
  }

  const renderIcons = showCheck => (
    showCheck ? <CheckIcon color="primary" /> : <ClearIcon color="error" />
  )

  const renderHeadingCells = () => {
    const columns = listMappings[entityType].columns
    return (
      <>
        {Object.values(columns).map(category => (
          <ListTableCell key={category}>
            <Typography>
              {category}
            </Typography>
          </ListTableCell>
        ))}
        {isSuperUser && (
          <ListTableCell key="edit">
            <Typography>
              Edit
            </Typography>
          </ListTableCell>
        )}
      </>
    )
  }

  const renderAttribute = (entity, attr) => {
    if (!entity) return null
    if ('diffs' in entity && attr in entity.diffs) {
      const { [attr]: attrDiffs } = entity.diffs
      return attrDiffs.aroya ?? attrDiffs.metrc
    }
    return entity[attr] ?? null
  }

  const renderTableCells = () => {
    const selectedEntity = listMappings[entityType]

    const handleEditClicked = (e, entity) => {
      e.stopPropagation()
      setCurrentEntity(entity)
      setEditFormOpen(true)
    }

    return (
      selectedEntity.data.map(entity => (
        <TableRow
          key={entity.label ?? entity.name}
          className={classes.tableRow}
          onClick={() => {
            setEntityDialogOpen(true)
            setCurrentEntity(entity)
          }}
        >
          {Object.keys(selectedEntity.columns).map(attr => {
            const isFirstCell = attr === 'label' || attr === 'name'
            const narrowWidthCell = typeof entity[attr] === 'boolean'
            const midWidthCell = attr === 'roomName' || attr === 'cultivarName'
            const currentCellDiff = entity?.diffs?.[attr]

            return (
              <ListTableCell
                key={attr}
                className={classNames({
                  [classes.firstTableCell]: isFirstCell,
                  [classes.narrowWidthCell]: narrowWidthCell,
                  [classes.midWidthCell]: midWidthCell,
                  [classes.tableCell]: true,
                })}
              >
                <Typography
                  variant={isFirstCell ? 'subtitle2' : 'body1'}
                  color={getTypographyColor(currentCellDiff, isFirstCell)}
                  noWrap
                >
                  {narrowWidthCell ? renderIcons(entity[attr]) : renderAttribute(entity, attr)}
                </Typography>
              </ListTableCell>
            )
          })}
          {isSuperUser && (
            <ListTableCell>
              <Button
                color="primary"
                onClick={e => handleEditClicked(e, entity)}
                disabled={!entity.isInAroya}
              >
                Edit
              </Button>
            </ListTableCell>
          )}
        </TableRow>
      ))
    )
  }

  if (!listMappings[entityType].data || listMappings[entityType].isLoading) {
    return <Loading />
  }

  const pagination = listMappings[entityType].pagination.get

  const onChangePage = (event, page) => {
    listMappings[entityType].pagination.set({
      currentPage: page === pagination.currentPage
        ? pagination.currentPage + 1
        : pagination.currentPage - 1,
      next: page === pagination.numPages - 1
        ? null
        : pagination.currentPage + 1,
    })
  }

  return (
    <div className={classes.table}>
      <EditForm
        me={me}
        isOpen={editFormOpen}
        entityType={entityType}
        onClose={() => {
          setEditFormOpen(false)
          setCurrentEntity(null)
        }}
        entityId={currentEntity?.id}
      />
      <ConfirmationDialog
        open={entityDialogOpen}
        title={(
          <Typography variant="h6" noWrap>
            {currentEntity?.label ?? currentEntity?.name}
          </Typography>
        )}
        content={(
          <EntityDialogContent
            listMappings={listMappings}
            entityType={entityType}
            currentEntity={currentEntity}
          />
        )}
        confirmText="Ok"
        showCancel={false}
        onClose={() => setEntityDialogOpen(false)}
        onExited={() => setCurrentEntity(null)}
        onConfirm={() => setEntityDialogOpen(false)}
        maxWidth="lg"
      />
      <ListTable>
        <TableHead>
          <TableRow>
            {renderHeadingCells()}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableCells()}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={12}
              nextIconButtonProps={{ disabled: pagination.next === null }}
              classes={{ spacer: 'hidden' }}
              count={pagination.count}
              rowsPerPage={pagination.pageSize}
              rowsPerPageOptions={[]}
              page={pagination.currentPage - 1}
              onChangePage={onChangePage}
              labelDisplayedRows={() => `Page ${pagination.currentPage} of ${pagination.numPages}`}
            />
          </TableRow>
        </TableFooter>
      </ListTable>
    </div>
  )
}

export default EntityList
