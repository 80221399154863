import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import PropTypes from 'prop-types'
import {
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  Dialog,
} from '@material-ui/core'

const MissingFacilitesDialog = props => {
  const { deactivatedOrganizationsIds, doUpdateUrl } = useConnect('selectDeactivatedOrganizationsIds', 'doUpdateUrl')
  const { onClose, open, facilities } = props

  const handleClose = () => {
    onClose()
  }

  const handleListItemClick = facId => {
    onClose()
    doUpdateUrl(`/facilities/${facId}`)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Facilities Missing Location Data</DialogTitle>
      <List style={{ overflowY: 'auto' }}>
        {facilities.map(facility => !deactivatedOrganizationsIds.includes(facility.organization) && (
          <ListItem key={facility.id} button onClick={() => handleListItemClick(facility.id)}>
            <ListItemText primary={facility.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

MissingFacilitesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
}

export default MissingFacilitesDialog
