import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const ButtonComponent = ({ children, ...props }) => (
  <Button color="primary" variant="contained" {...props}>
    {children}
  </Button>
)

ButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ButtonComponent
