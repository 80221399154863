import React from 'react'

import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Close as CloseIcon } from '@material-ui/icons'

import { stylePropType } from '~/Lib/PropTypes'

const displayName = 'CloseButton'
const useStyles = makeStyles(theme => ({
  closeButton: {
    color: theme.meter.grey,
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'scale(0.75)',
    transformOrigin: 'top right',
    pointerEvents: 'auto',
  }
}), { name: displayName })

const CloseButtonComponent = ({ onClose, style, ...props }) => {
  const classes = useStyles(props)
  return (
    <IconButton
      className={classes.closeButton}
      onClick={onClose}
      style={style}
    >
      <CloseIcon />
    </IconButton>
  )
}
CloseButtonComponent.propTypes = {
  style: stylePropType,
  onClose: PropTypes.func.isRequired,
}
CloseButtonComponent.defaultProps = {
  style: undefined
}

export default React.memo(CloseButtonComponent)
