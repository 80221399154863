import React, { useState } from 'react'
import { useConnect } from 'redux-bundler-hook'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Grid,
  InputAdornment,
} from '@material-ui/core'
import classNames from 'clsx'

import SearchField from '~/UI/Shared/Form/SearchField'
import MuiTitle from '~/UI/Shared/MuiTitle'
import Icon from '~/UI/Shared/Icon'
import EntityListFilters from './filters'
import MetrcSort from './sort'
import EntityList from './list'
import LicenseSelector from './license'
import styles from './styles'

const useStyles = makeStyles(styles)

const ENTITIES = {
  PLANTS: 'plants',
  PLANT_BATCHES: 'plant-batches',
  HARVEST_BATCHES: 'harvest-batches',
  PACKAGES: 'packages',
}

const formatEntityName = entity => entity.replace('-', ' ')

const RenderTabs = ({ tabValue, onTabClick, classes }) => (
  <>
    <EntityListFilters currentEntity={tabValue.replace('-', '_').toUpperCase()} />
    {Object.values(ENTITIES).map(entity => (
      <Button
        key={entity}
        size="small"
        color="primary"
        onClick={() => onTabClick(entity)}
        className={classNames({
          [classes.button]: true,
          [classes.selected]: tabValue === entity,
        })}
      >
        {formatEntityName(entity)}
      </Button>
    ))}
  </>
)

export const MetrcFacilityComponent = () => {
  const classes = useStyles()
  const {
    facility,
    routeParams,
    plantListSearch,
    packageListSearch,
    plantBatchListSearch,
    harvestBatchListSearch,
    doUpdateUrl,
    doPackageListSetSearch,
    doPlantListSetSearch,
    doPlantBatchListSetSearch,
    doHarvestBatchListSetSearch,
    doMarkPlantListAsOutdated,
    doMarkPlantBatchListAsOutdated,
    doMarkHarvestBatchListAsOutdated,
    doMarkPackageListAsOutdated,
  } = useConnect(
    'selectFacility',
    'selectRouteParams',
    'selectPlantListSearch',
    'selectPackageListSearch',
    'selectPlantBatchListSearch',
    'selectHarvestBatchListSearch',
    'doUpdateUrl',
    'doPackageListSetSearch',
    'doPlantListSetSearch',
    'doPlantBatchListSetSearch',
    'doHarvestBatchListSetSearch',
    'doMarkPlantListAsOutdated',
    'doMarkPlantBatchListAsOutdated',
    'doMarkHarvestBatchListAsOutdated',
    'doMarkPackageListAsOutdated',
  )

  const [tabValue, setTabValue] = useState(routeParams?.entity)

  const setSearch = search => {
    switch (tabValue) {
      case ENTITIES.PLANTS:
        doPlantListSetSearch(search)
        break
      case ENTITIES.PLANT_BATCHES:
        doPlantBatchListSetSearch(search)
        break
      case ENTITIES.HARVEST_BATCHES:
        doHarvestBatchListSetSearch(search)
        break
      case ENTITIES.PACKAGES:
        doPackageListSetSearch(search)
        break
      default:
        break
    }
  }

  const getSearch = () => {
    switch (tabValue) {
      case ENTITIES.PLANTS:
        return plantListSearch
      case ENTITIES.PLANT_BATCHES:
        return plantBatchListSearch
      case ENTITIES.HARVEST_BATCHES:
        return harvestBatchListSearch
      case ENTITIES.PACKAGES:
        return packageListSearch
      default:
        break
    }
    return ''
  }

  const markOutdated = () => {
    if (tabValue.includes(ENTITIES.PLANTS)) doMarkPlantListAsOutdated()
    if (tabValue.includes(ENTITIES.PLANT_BATCHES)) doMarkPlantBatchListAsOutdated()
    if (tabValue.includes(ENTITIES.HARVEST_BATCHES)) doMarkHarvestBatchListAsOutdated()
    if (tabValue.includes(ENTITIES.PACKAGES)) doMarkPackageListAsOutdated()
  }

  const handleTabClick = entity => {
    setSearch('')
    doUpdateUrl(`/facilities/${facility.id}/metrc/${entity}`)
    setTabValue(entity)
  }

  const licensesAvailable = facility?.licenses?.length > 1

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <MuiTitle title={facility.name} />
        </Grid>
        <Grid
          item
          container
          xs={7}
          spacing={2}
          justify="flex-end"
        >
          <Grid item xs={3}>
            <SearchField
              fullWidth
              value={getSearch()}
              onEmpty={() => setSearch('')}
              label={`Search ${formatEntityName(tabValue)}`}
              onChange={({ searchTerms }) => setSearch(searchTerms || '')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon={SearchIcon} />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={3} style={{ marginTop: '16px' }}>
            <MetrcSort
              tabValue={tabValue.replace('-', '_').toUpperCase()}
            />
          </Grid>
          {licensesAvailable && (
            <Grid item xs={3} style={{ marginTop: '16px' }}>
              <LicenseSelector
                facility={facility}
                markOutdated={markOutdated}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end">
        <Grid
          container
          xs={12}
          justify="space-between"
          className={classes.filterNav}
        >
          <Grid item>
            <RenderTabs classes={classes} tabValue={tabValue} onTabClick={handleTabClick} />
          </Grid>
        </Grid>

        <EntityList
          classes={classes}
          entityType={tabValue.replace('-', '_').toUpperCase()}
        />
      </Grid>
    </>
  )
}
