import React from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Field } from 'formik'
import * as yup from 'yup'

import {
  FormDialog,
  SelectField,
  TextField,
  ToggleField,
} from '~/UI/Shared/Form/FormDialog'

const MembershipForm = props => {
  const { instance } = props

  const { facility } = useConnect('selectFacility')

  const roleChoices = facility.roles?.map(role => ({ label: role.name, value: role.id }))

  const initialValues = {
    user: {
      firstName: '',
      lastName: '',
      email: '',
    },
    role: roleChoices && roleChoices[0]?.value,
    hidden: false,
  }

  if (instance.id) {
    Object.keys(initialValues.user).forEach(field => {
      initialValues.user[field] = instance.user[field] ?? ''
    })
    Object.keys(initialValues).forEach(field => {
      if (field === 'user') return
      initialValues[field] = instance[field] ?? ''
    })
  }

  const validationSchema = yup.object().shape({
    user: yup.object().shape({
      firstName: yup.string().max(30),
      lastName: yup.string().max(150),
      email: yup.string().email('Please enter a valid email').required('Please enter an email address'),
    }),
    role: yup.number().required('Please select a role'),
    hidden: yup.boolean(),
  })

  return (
    <FormDialog
      formatInstance={obj => `${obj.user?.firstName} ${obj.user?.lastName}`}
      initialValues={initialValues}
      label="Membership"
      softDeletes
      validationSchema={validationSchema}
      {...props}
    >
      <Field component={TextField} label="Email" name="user.email" type="email" />
      <Field component={TextField} label="First Name" name="user.firstName" />
      <Field component={TextField} label="Last Name" name="user.lastName" />
      <Field component={SelectField} label="Role" name="role" options={roleChoices || []} />
      <Field component={ToggleField} label="Hidden" name="hidden" />
    </FormDialog>
  )
}

MembershipForm.defaultProps = {
  instance: {},
}

export default MembershipForm
