export default theme => ({
  button: {
    padding: '5px 10px',
    margin: '10px'
  },
  selected: {
    color: theme.meter.blue.light,
    textDecoration: 'underline',
  },
  firstTableCell: {
    minWidth: '250px'
  },
  tableCell: {
    maxWidth: '150px'
  },
  narrowWidthCell: {
    maxWidth: '50px',
  },
  midWidthCell: {
    maxWidth: '200px',
  },
  table: {
    width: '100%',
    maxWidth: '100%',
  },
  tableRow: {
    cursor: 'pointer',
  },
  oneThirdField: {
    margin: 0,
    maxWidth: '32%'
  }
})
