import React from 'react'

import PropTypes from 'prop-types'
import { trim } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Grid,
  InputAdornment,
  Typography,
} from '@material-ui/core'
import {
  MailOutline as MailOutlineIcon,
  VpnKey as KeyIcon,
} from '@material-ui/icons'

import { isValidEmail } from '~/Lib/Validators'
import Button from '~/UI/Shared/Button'
import FormikField from '~/UI/Shared/Form/FormikField'
import TextField from '~/UI/Shared/Form/TextField'
import Icon from '~/UI/Shared/Icon'

const containerStyle = { paddingRight: 16 }

export class LoginComponent extends React.PureComponent {
  static propTypes = {
    doLogin: PropTypes.func.isRequired,
    auth: PropTypes.shape({
      authenticated: PropTypes.bool,
      loginFailed: PropTypes.bool,
      loginMessage: PropTypes.string,
      loginProcessing: PropTypes.bool,
    }).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
    }
    this.setState = this.setState.bind(this)
    this.onSubmit = event => {
      event.preventDefault()
      if (this.isFormValid()) {
        props.doLogin(this.state)
      }
    }
  }

  isFormValid() {
    const { email, password } = this.state
    return isValidEmail(email) && trim(password).length
  }

  render() {
    const {
      auth: { authenticated, loginProcessing, loginFailed, loginMessage }
    } = this.props

    if (authenticated) {
      return null
    }

    return (
      <Grid data-testid="login-route" container spacing={0} style={containerStyle} id="login">
        <Grid item xs={12}>
          <Typography variant="h4" color="primary">
            Please login to continue
          </Typography>
        </Grid>
        {loginFailed ? (
          <Grid data-testid="login-failed-banner" item xs={12}>
            <div className="alert alert-danger">{loginMessage}</div>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <form onSubmit={this.onSubmit}>
            <FormikField
              required="Please enter an email address"
              type="email"
              margin="none"
              id="email"
              name="email"
              label="Email Address"
              value={this.state.email}
              onChange={this.setState}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon={MailOutlineIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              required
              margin="none"
              id="password"
              name="password"
              type="password"
              label="Password"
              value={this.state.password}
              onChange={this.setState}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon={KeyIcon} />
                  </InputAdornment>
                ),
              }}
            />

            <p className="text-center mar-t-xl">
              <Button
                type="submit"
                disabled={loginProcessing || !this.isFormValid()}
              >
                Login
              </Button>
            </p>
          </form>
        </Grid>
      </Grid>
    )
  }
}

const Connected = props => {
  const connectedProps = useConnect(
    'doLogin',
    'selectAuth',
  )

  return <LoginComponent {...connectedProps} {...props} />
}

export default Connected
