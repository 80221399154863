import React, { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  // ClickAwayListener,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  // Clear as ClearIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import classNames from 'classnames'

import SnoozeDialog from '~/Facility/snooze'
import { EMPTY_OBJECT, formattedDate, memoize } from '~/Lib/Utils'
// import SensorIcon from '~/UI/Icons/Sensor'
import SleepIcon from '~/UI/Icons/Sleep'
import Icon from '~/UI/Shared/Icon'

import styles from './styles'

const useStyles = makeStyles(styles)

const getStatusIcon = memoize(level => {
  let IconComponent = InfoIcon
  let iconProps = { color: 'primary' }

  if (level === 'ERROR' || level === 'DANGER') {
    IconComponent = ErrorIcon
    iconProps = { color: 'error' }
  } else if (level === 'WARNING') {
    IconComponent = WarningIcon
    iconProps = { style: { color: 'yellow' } }
  }

  return () => <Icon icon={IconComponent} {...iconProps} />
})

export default props => {
  const {
    facility: facilityId,
    notifications,
  } = props

  const classes = useStyles()

  const { devices, facilities } = useConnect('selectDevices', 'selectFacilities')

  // const [openNotification, setOpenNotification] = useState(null)
  const [currentSnoozeObj, setCurrentSnoozeObj] = useState(EMPTY_OBJECT)

  const facility = facilityId ? facilities[facilityId] : null

  return (
    <Paper className={classes.notificationsRoot}>
      <SnoozeDialog
        {...currentSnoozeObj}
        open={currentSnoozeObj !== EMPTY_OBJECT}
        onClose={() => {
          // setOpenNotification(null)
          setCurrentSnoozeObj(EMPTY_OBJECT)
        }}
      />
      <Grid container alignItems="center" style={{ padding: '16px' }}>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">
            Recent Notifications
          </Typography>
        </Grid>
        { facility && (
          <Grid item>
            <Tooltip placement="top" title={`Mute notifications from ${facility.name}`}>
              <IconButton
                style={{ padding: '8px' }}
                onClick={() => setCurrentSnoozeObj({ facility })}
              >
                <Icon icon={SleepIcon} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {notifications?.length ? (
        // <ClickAwayListener onClickAway={() => {
        //   console.log('clicking away')
        //   setOpenNotification(null)
        // }}>
        <List>
          {notifications.map(notification => {
            const IconComponent = getStatusIcon(notification.level)
            const device = devices[notification.device]
            // const deviceId = device?.puck || device?.id
            // const linkDevice = devices[deviceId]
            const formatDateTime = ts => (ts ? formattedDate(ts, 'en-US', {
              month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
            }) : 'Never')

            const itemClasses = classNames({
              [classes.notificationItem]: true,
              // [classes.notificationItemOpen]: openNotification === notification.id,
            })

            return (
              <div
                key={notification.id}
                className={itemClasses}
              >
                <ListItem
                  button
                  disableGutters
                  className={classes.notificationItemContent}
                  // onClick={() => setOpenNotification(notification.id)}
                  onClick={() => setCurrentSnoozeObj({ device })}
                >
                  <ListItemIcon style={{ paddingLeft: 12, minWidth: 46 }}>
                    <IconComponent />
                  </ListItemIcon>
                  <ListItemText>
                    <Grid container alignItems="center">
                      <Grid item style={{ flexGrow: 1 }}>
                        <Typography variant="subtitle2">
                          { notification.subject }
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">
                          { formatDateTime(notification.ts) }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="body2" className="linebreaksbr">
                      { notification.message }
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* <Grid className={classes.notificationItemActions} container alignItems="center" justify="space-evenly">
                  <Grid item>
                    <Tooltip placement="top" title={`Device page for ${linkDevice?.serialNumber}`}>
                      <IconButton style={{ padding: '8px' }} href={`/devices/${deviceId}`}>
                        <Icon icon={SensorIcon} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip placement="top" title={`Mute notifications from ${device?.serialNumber}`}>
                      <IconButton
                        style={{ padding: '8px' }}
                        onClick={() => {
                          setCurrentSnoozeObj({ device })
                          setOpenNotification(null)
                        }}
                      >
                        <Icon icon={SleepIcon} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip placement="top" title="Dismiss">
                      <IconButton style={{ padding: '8px' }} onClick={() => {}}>
                        <Icon icon={ClearIcon} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid> */}
              </div>
            )
          })}
        </List>
        // </ClickAwayListener>
      ) : (
        <Typography className="text-center" style={{ padding: '16px' }}>
          {'There doesn\'t seem to be anything here'}
        </Typography>
      )}
    </Paper>
  )
}
