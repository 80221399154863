export const DeviceStyles = () => ({
  root: {
    display: 'grid',
    height: '100%',
    width: '100%',
    gridTemplateAreas: [
      '"nodeTitle  nodeId graphToggle filters"',
      '"nodeList nodeDetails nodeDetails nodeDetails"',
    ].join('\n'),
    gridTemplateColumns: '300px 1fr 500px 500px',
    gridTemplateRows: '75px 1fr',
    gridColumnGap: '10px',
  },
  nodeTitle: {
    gridArea: 'nodeTitle',
  },
  graphToggle: {
    gridArea: 'graphToggle',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  nodeId: {
    gridArea: 'nodeId',
    alignSelf: 'center'
  },
  filters: {
    gridArea: 'filters',
    display: 'flex',
    alignSelf: 'center'
  },
  nodeList: {
    gridArea: 'nodeList',
  },
  nodeDetails: {
    gridArea: 'nodeDetails',
    display: 'grid',
    height: '100%',
    gridTemplateAreas: [
      '"nodeChart"',
      '"nodePackets"',
    ].join('\n'),
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr 200px',
    gridRowGap: '10px',
  },
  nodeChart: {
    gridArea: 'nodeChart',
  },
  nodePackets: {
    overflow: 'auto',
    gridArea: 'nodePackets',
  },
})

export const DeviceListStyles = theme => ({
  listRoot: {
    maxWidth: 360,
    overflow: 'auto',
    maxHeight: '80vh',
    paddingInlineEnd: '0.25rem'
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '25px',
    height: '100px',

    display: 'grid',
    gridTemplateAreas: [
      '"nodeIcon listText nodeDelete"',
    ].join('\n'),
    gridTemplateColumns: '30px 1fr 30px',
    gridColumnGap: '5px',
    alignContent: 'space-evenly'
  },
  nodeIcon: {
    gridArea: 'nodeIcon',
  },
  listText: {
    gridArea: 'listText',
  },
  nodeDelete: {
    gridArea: 'nodeDelete',
    '&:hover': {
      color: 'red',
    },
  }
})

export const DevicePacketStyles = () => ({
  packetRoot: {
    display: 'grid',
    height: '100%',
    gridTemplateAreas: [
      '"pStatus pData pSys pBoot"',
    ].join('\n'),
    gridColumnGap: '10px',
  },
  pStatus: {
    gridArea: 'pStatus',
  },
  pData: {
    gridArea: 'pData',
  },
  pSys: {
    gridArea: 'pSys',
  },
  pBoot: {
    gridArea: 'pBoot',
  },
})
