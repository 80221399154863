/* eslint-disable consistent-return */
import PropTypes from 'prop-types'
import { getDateTime } from './Utils'

const {
  prototype: { toString },
} = Object
const getType = o => {
  const name = o?.constructor?.name
  if (name) return name
  // eslint-disable-next-line no-useless-escape
  return toString.call(o).split(/[\[ \]]/)[2] || 'Unknown'
}
const repr = obj => {
  // eslint-disable-next-line no-self-compare
  if (obj !== obj) return String(obj)
  const type = getType(obj)
  if (obj == null) return type
  return `${type}(${
    type === 'Object' ? `{ ${Object.keys(obj).join(', ')} }` : String(obj)
  })`
}
export const defaultInvalidMsg = (prop, name, component) => `Invalid prop '${name}' of type '${repr(prop)}' supplied to '${component}'.`
export const formatErrorMessage = message => message.replace(/(\s)\s+/g, '$1')
const MemoSymbol = Symbol.for('react.memo')
const ElementSymbol = Symbol.for('react.element')
export const innerRenderableType = prop => {
  const type = getType(prop)
  if (['String', 'Function', 'Null'].some(allowed => allowed === type)) {
    return true
  }
  if (prop && typeof prop.render === 'function') {
    return true
  }
  const reactType = prop && prop.$$typeof
  if (reactType === MemoSymbol || reactType === ElementSymbol) {
    return true
  }
  return false
}

export const errorMessagePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
])

const isRequiredFactory = propType => (...args) => {
  const [props, name, component] = args
  const prop = props[name]
  if (!(name in props) || prop == null || prop === '') {
    return new Error(
      `'${name}' is required by '${component}', but it wasn't provided.`
    )
  }
  return propType(...args)
}
export const propTypeFactory = (validator, opts = {}) => {
  /* eslint-disable no-param-reassign */
  validator.isRequired = isRequiredFactory(validator)
  if (opts.nullable) {
    validator.nullable = (props, name, ...args) => {
      const prop = props[name]
      if (prop === null) return undefined
      return validator(props, name, ...args)
    }
  }
  return validator
  /* eslint-enable no-param-reassign */
}
const isMissing = prop => (prop == null || (typeof prop === 'object' && !Object.keys(prop).length))

export const atLeastOneRequired = (propTypes = {}) => {
  const list = Object.entries(propTypes)
  if (!list.length || list.length === 1) {
    throw new Error(
      'atLeastOneRequired requires at least two defined propTypes. For one, please use propType.isRequired.'
    )
  }
  return list.reduce((config, entry, index) => {
    const [key, propType] = entry
    const otherProps = list.slice().filter((_, i) => i !== index)
    return {
      ...config,
      [key]: (props, propName, componentName, ...rest) => {
        const prop = props[propName]
        const missing = isMissing(prop)
        const propTypeError = propType(props, propName, componentName, ...rest)
        if (missing || propTypeError) {
          const one = otherProps.find(([oKey, oPropType]) => {
            const oProp = props[oKey]
            return oProp && !oPropType(props, oKey, componentName, ...rest)
          })
          if (!one) {
            return new Error(
              formatErrorMessage(`
              Invalid prop '${propName}' passed to '${componentName}'.
              ${missing ? 'It did not exist, ' : ''}${
  propTypeError
    ? `It failed validation: [${propTypeError.toString()}], `
    : ' '
}and it's alternates: [${otherProps
  .map(([name]) => name)
  .join(', ')}]
              were not present or failed validation.
            `)
            )
          }
          if (propTypeError) {
            return propTypeError
          }
        }
      },
    }
  }, {})
}

const findExcluded = (excludeProps, props, propName, componentName) => {
  const foundExcluded = excludeProps.filter(ex => {
    const exProp = props[ex]
    if (typeof exProp === 'undefined' || exProp === null) return false
    return true
  })
  if (props[propName] && foundExcluded.length) {
    return new Error(
      `[${componentName}] cannot have both [${propName}] and [${foundExcluded.join(
        ', '
      )}] props.`
    )
  }
}

export const exclusiveOf = (propType, ...excludeProps) => {
  const validator = (props, propName, componentName, ...rest) => findExcluded(excludeProps, props, propName, componentName)
    || propType(props, propName, componentName, ...rest)

  validator.isRequired = (props, propName, componentName, ...rest) => findExcluded(excludeProps, props, propName, componentName)
    || propType.isRequired(props, propName, componentName, ...rest)

  return validator
}

export const datePropType = propTypeFactory((props, name, component) => {
  const prop = props[name]
  if (!prop) return undefined
  const date = getDateTime(prop)
  if (!date.isValid) {
    return new Error(
      formatErrorMessage(`
      ${defaultInvalidMsg(prop, name, component)}
      Value ${repr(prop)} is not a valid date string or object.
    `)
    )
  }
})

const newChildIdPattern = /^([a-z]+)(_|-)(new-)?(\d+)/i
const idPropTypeStringTests = [
  prop => prop.match(newChildIdPattern),
  prop => prop === '',
  prop => prop.toLowerCase() === 'new',
]
const idPropTypeStringValid = prop => typeof prop === 'string' && idPropTypeStringTests.some(test => test(prop))
export const idPropType = propTypeFactory(
  (props, name, component) => {
    const prop = props[name]
    if (prop === undefined) {
      return prop
    }
    const typeIsInvalid = String(prop) !== prop && Number(prop) !== +prop

    if (idPropTypeStringValid(prop)) {
      return undefined
    }
    // eslint-disable-next-line no-self-compare
    if (typeIsInvalid || !Number(prop)) {
      return new Error(
        formatErrorMessage(`
      ${defaultInvalidMsg(prop, name, component)}
      Expected a whole Number greater than 0 or a String convertible to such a Number.
    `)
      )
    }
  },
  { nullable: true }
)

export const idAndNameShape = PropTypes.shape({
  id: idPropType,
  name: PropTypes.string,
})

export const numberOrEmptyType = propTypeFactory((props, name, component) => {
  const prop = props[name]
  const isNumber = Number(prop) === +prop
  if (isNumber || prop === '') {
    return undefined
  }
  return new Error(
    formatErrorMessage(
      `${defaultInvalidMsg(
        prop,
        name,
        component
      )} Expected number or empty string.`
    )
  )
})

export const paginationPropTypes = PropTypes.shape({
  count: PropTypes.number,
  current: PropTypes.number,
  next: PropTypes.number,
  numPages: PropTypes.number,
  pageSize: PropTypes.number,
  previous: PropTypes.number,
  results: PropTypes.arrayOf(idPropType),
})

export const renderableType = propTypeFactory((props, name, component) => {
  const prop = props[name]
  if (Array.isArray(prop) && prop.every(item => innerRenderableType(item))) {
    return undefined
  }
  if (innerRenderableType(prop)) {
    return undefined
  }

  return new Error(
    formatErrorMessage(`
    Invalid prop '${name}' supplied to '${component}'.
    Expected a renderable type: a single (String, Function, { render() {} }, null)
    or an Array of the same.
    Received: ${repr(prop)}
  `)
  )
})

export const regexPropType = (regex, nullable = false) => propTypeFactory(
  (props, name, component) => {
    const prop = props[name]
    if (typeof prop !== 'string' || !regex.test(prop)) {
      return new Error(
        formatErrorMessage(`
        ${defaultInvalidMsg(prop, name, component)}
        Expected ${repr(prop)} to be a string matching pattern (${regex})
      `)
      )
    }
  },
  { nullable }
)

export const stylePropType = PropTypes.objectOf(PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string
]))
