import React, { useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryBrushContainer,
  createContainer,
} from 'victory'

import { formatDate } from '~/UI/Shared/utils'
import SelectField from '~/UI/Shared/Form/SelectField'
import { DataDialog } from './dialog'
import { DeviceChartStyles as styles } from './styles'

const useStyles = makeStyles(styles)

const units = [
  { label: 'Minutes', value: 'minutes' },
  { label: 'Hours', value: 'hours' },
  { label: 'Days', value: 'days' }
]

const durations = [
  { label: '1', value: 1 },
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '30', value: 30 },
]

export const DataChart = props => {
  const classes = useStyles()
  const theme = useTheme()
  const barChartRef = useRef(null)
  const brushChartRef = useRef(null)
  const { currentDevice } = props
  const [unit, setUnit] = useState({ label: 'Minutes', value: 'minutes' })
  const [duration, setDuration] = useState({ label: '10', value: 10 })
  const [barWidth, setBarWidth] = useState(null)
  const [barHeight, setBarHeight] = useState(null)
  const [brushWidth, setBrushWidth] = useState(null)
  const [brushHeight, setBrushHeight] = useState(null)
  const [data, setData] = useState(null)
  const [dialog, setDialog] = useState(false)
  const [packetStart, setPacketStart] = useState(null)
  const [zoomDomain, setZoomDomain] = useState(null)
  const [selectedDomain, setSelectedDomain] = useState(null)
  const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi')

  const sortArray = array => {
    const sortedArr = [...array]
    return sortedArr.sort((a, b) => {
      if (a.beat > b.beat) return 1
      if (a.beat < b.beat) return -1
      return 0
    })
  }

  const getDomain = () => {
    const sortedArr = sortArray(currentDevice.dataArray)
    const start = new Date(`${sortedArr[sortedArr.length - 100].beat} UTC`)
    const end = new Date(`${sortedArr[sortedArr.length - 1].beat} UTC`)
    return { x: [start, end] }
  }

  const getTheme = useMemo(() => {
    const style = { ...VictoryTheme.material }
    style.bar.style.data.fill = theme.meter.blue.main
    style.bar.style.data.strokeWidth = 0
    style.axis.style.grid.stroke = 'none'
    return style
  }, [])

  const getDate = date => {
    const { x } = date
    const y = moment(x).add(duration.value, unit.value).toDate()
    let yHr = y.getHours()
    let yMin = y.getMinutes()

    if (yHr < 10) { yHr = `0${yHr}` }
    if (yMin < 10) { yMin = `0${yMin}` }

    const from = formatDate(x)
    const to = `${yHr}:${yMin}`
    return `${from}-${to}`
  }

  useEffect(() => {
    const sortedArr = sortArray(currentDevice.dataArray)
    const dataSet = []
    let count = 0
    const initDate = new Date(`${sortedArr[0].beat}  UTC`)
    let current = moment(initDate).toDate()
    let end = moment(current).add(duration.value, unit.value).toDate()

    sortedArr.forEach(packet => {
      const currentTime = new Date(`${packet.beat} UTC`)
      if (currentTime < end) {
        count += 1
      } else {
        dataSet.push({ x: current, y: count })
        current = currentTime
        count = 1
        end = moment(currentTime).add(duration.value, unit.value).toDate()
      }
    })
    end = moment(end).subtract(duration.value, unit.value).toDate()
    dataSet.push({ x: end, y: count })

    setData(dataSet)
  }, [currentDevice, duration, unit])

  useEffect(() => {
    if (currentDevice.dataArray.length > 100) {
      const domain = getDomain()
      setZoomDomain(domain)
      setSelectedDomain(domain)
    }
  }, [currentDevice])

  useEffect(() => {
    if (barChartRef.current) {
      setBarHeight(barChartRef.current.offsetHeight)
      setBarWidth(barChartRef.current.offsetWidth)
    }
    if (brushChartRef.current) {
      setBrushHeight(brushChartRef.current.offsetHeight)
      setBrushWidth(brushChartRef.current.offsetWidth)
    }
  }, [])

  return (
    <div className={classes.chartRoot}>
      {dialog ? (
        <DataDialog
          unit={unit}
          duration={duration}
          data={currentDevice.dataArray}
          startTime={packetStart}
          onClose={() => setDialog(false)}
        />
      ) : null}
      <div className={classes.chartActions}>
        <div className={classes.interval}>
          <SelectField
            dense
            placeholder="Unit"
            options={units}
            value={unit}
            onChange={e => setUnit(e)}
          />
        </div>
        <div className={classes.duration}>
          <SelectField
            dense
            placeholder="Duration"
            options={durations}
            value={duration}
            onChange={e => setDuration(e)}
          />
        </div>
      </div>
      <div ref={barChartRef} className={classes.mainChart}>
        {barWidth && barHeight ? (
          <VictoryChart
            domainPadding={{ x: [50, 50] }}
            scale={{ x: 'time' }}
            theme={getTheme}
            height={barHeight}
            width={barWidth}
            containerComponent={(
              <VictoryZoomVoronoiContainer
                responsive={false}
                zoomDimension="x"
                labels={d => getDate(d)}
                zoomDomain={zoomDomain}
                onZoomDomainChange={domain => setSelectedDomain(domain)}
              />
            )}
          >
            <VictoryBar
              data={data}
              barWidth={15}
              labels={d => d.y}
              events={[{
                target: 'data',
                eventHandlers: {
                  onClick: () => [
                    {
                      target: 'data',
                      mutation: bar => {
                        setPacketStart(bar.datum.x)
                        setDialog(true)
                      }
                    }
                  ]
                }
              }]}
            />
            <VictoryAxis />
            <VictoryAxis
              dependentAxis
              tickFormat={t => Math.round(t)}
            />
          </VictoryChart>
        ) : null}
      </div>
      <div ref={brushChartRef} className={classes.brushChart}>
        {brushHeight && brushWidth ? (
          <VictoryChart
            theme={getTheme}
            domainPadding={{ x: [25, 25] }}
            padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
            width={brushWidth}
            height={brushHeight}
            scale={{ x: 'time' }}
            containerComponent={(
              <VictoryBrushContainer
                responsive={false}
                brushStyle={{ stroke: 'transparent', fill: 'black', fillOpacity: 0.4 }}
                brushDimension="x"
                brushDomain={selectedDomain}
                onBrushDomainChange={domain => setZoomDomain(domain)}
              />
            )}
          >
            <VictoryAxis />
            <VictoryBar data={data} />
          </VictoryChart>
        ) : null}
      </div>
    </div>
  )
}

DataChart.propTypes = {
  currentDevice: PropTypes.shape({
    dataArray: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
}
