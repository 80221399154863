import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

const nodeListBundle = createAsyncResourceBundle({
  name: 'nodeList',
  actionBaseType: 'NODE_LIST',
  staleAfter: ms.minutes(5),
  retryAfter: ms.seconds(10),
  getPromise: ({ apiFetch }) => apiFetch('/nodes/').then(payload => {
    const result = []
    Object.keys(payload).map(node => {
      const device = payload[node]
      device.address = node
      result.push(device)
      return device
    })
    return result
  })
})

nodeListBundle.doDeleteNode = payload => async ({ apiFetch }) => {
  try {
    if (payload !== null) {
      const response = await apiFetch(`/nodes/${payload}/`, null, { method: 'DELETE' })
      return response
    }
    return null
  } catch (error) {
    return error
  }
}

nodeListBundle.doSetCurrentNode = payload => async ({ apiFetch, dispatch }) => {
  try {
    const response = await apiFetch(`/nodes/${payload.address}/`)
    let result = await response

    result = result[payload.address]
    result.address = payload.address

    dispatch({ type: 'CURRENT_NODE', payload: result })
    return result
  } catch (error) {
    dispatch({ type: 'CLEAR_NODE', payload: {} })
    return error
  }
}

nodeListBundle.selectCurrentNode = createSelector(
  'selectNodeListRaw',
  ({ currentNode }) => currentNode
)

nodeListBundle.reactNodeListFetch = createSelector(
  'selectAuth',
  'selectNodeListShouldUpdate',
  'selectRouteInfo',
  ({ authenticated }, shouldUpdate, { url }) => {
    if (authenticated && shouldUpdate && url === '/nodes') {
      return {
        actionCreator: 'doFetchNodeList'
      }
    }
    return undefined
  }
)

const oldReducer = nodeListBundle.reducer
nodeListBundle.reducer = (outerState, outerAction) => [
  oldReducer,
  (state, action) => {
    switch (action.type) {
      case 'CURRENT_NODE':
        return { ...state, currentNode: action.payload }
      case 'CLEAR_NODE':
        return { ...state, currentNode: action.payload }
      default:
        return state
    }
  }
].reduce((stateOut, reducer) => reducer(stateOut, outerAction), outerState)

export default nodeListBundle
