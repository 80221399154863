import React, { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import classNames from 'classnames'
import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { reorder } from '~/Lib/Data'
import ConfirmationDialog from '~/UI/Shared/ConfirmationDialog'
import { FloorPlan } from './FloorPlan'
import styles from './styles'

const useStyles = makeStyles(styles)

const FloorplanListRow = ({ onClick, plan, isGuestStaff, deactivating }) => {
  const classes = useStyles()
  const rowClasses = classNames({
    [classes.nonClickableRow]: isGuestStaff,
    [classes.clickableRow]: !isGuestStaff,
    [classes.deactivatingRow]: deactivating,
  })
  return (
    <Paper
      className={rowClasses}
      onClick={onClick}
      style={{ marginTop: '8px', border: classes.deactivatingRow }}
    >
      <Grid container spacing={2} alignItems="center">
        {!isGuestStaff && (
        <Grid item style={{ cursor: 'grab' }}>
          <DragIndicatorIcon />
        </Grid>
        )}
        <Grid item style={{ flexGrow: 1, marginLeft: isGuestStaff ? '15px' : '0' }}>
          <Typography color="primary">
            {plan.name}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

const DraggableFloorplanListRow = ({ idx, onClick, plan, isGuestStaff, deactivating }) => (
  <Draggable draggableId={`plan_${plan.id}`} index={idx}>
    {provided => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{ ...provided.draggableProps.style, marginTop: '8px' }}
      >
        <FloorplanListRow
          idx={idx}
          key={plan.id}
          onClick={onClick}
          plan={plan}
          isGuestStaff={isGuestStaff}
          deactivating={deactivating}
        />
      </div>
    )}
  </Draggable>
)

const FloorplanList = () => {
  const [deactivating, setDeactivating] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [floorPlanForm, setFloorPlanForm] = useState(false)
  const [floorPlan, setFloorPlan] = useState(null)

  const {
    isGuestStaff,
    facility,
    rooms,
    zones,
    doReorderFloorPlans,
    doDeactivateFloorPlan,
  } = useConnect(
    'selectIsGuestStaff',
    'selectFacility',
    'selectRooms',
    'selectZones',
    'doReorderFloorPlans',
    'doDeactivateFloorPlan',
  )

  const expandedFacility = {
    ...facility,
    rooms: facility.rooms?.map(roomId => ({
      ...rooms[roomId],
      zones: rooms[roomId]?.zones.map(zoneId => zones[zoneId]),
    })),
  }

  const onFloorPlanClose = () => {
    setFloorPlan(null)
    setFloorPlanForm(false)
  }

  const onEditFloorplan = fp => {
    setFloorPlan(fp)
    setFloorPlanForm(true)
  }

  const onFloorplanDragEnd = result => {
    if (!result.destination) return
    if (result.source.index === result.destination.index) return

    const items = reorder(
      expandedFacility.floorplans,
      result.source.index,
      result.destination.index
    )

    expandedFacility.floorplans = items
    doReorderFloorPlans(items.map(plan => plan.id))
  }

  const handleRowClick = (_isGuestStaff, _deactivating, plan) => {
    setFloorPlan(plan)
    if (!_isGuestStaff) {
      if (_deactivating) {
        setConfirmationOpen(true)
      } else {
        onEditFloorplan(plan)
      }
    }
  }

  return (
    <>
      <ConfirmationDialog
        open={confirmationOpen}
        title="Deactivate Floorplan"
        content="Are you sure you want to deactivate floorplan?"
        destructive
        confirmText="Deactivate"
        cancelText="Cancel"
        onClose={() => setConfirmationOpen(false)}
        onConfirm={() => {
          doDeactivateFloorPlan(floorPlan)
          setDeactivating(false)
          setConfirmationOpen(false)
        }}
      />
      {floorPlanForm ? (
        <FloorPlan
          floorPlan={floorPlan}
          facility={expandedFacility}
          onClose={() => onFloorPlanClose()}
          onUpload={() => {
            onFloorPlanClose()
            window.location.reload()
          }}
        />
      ) : null}
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs={4} style={{ marginBottom: '6px' }}>
          <Typography variant="subtitle1">
            Floor Plans
          </Typography>
        </Grid>
        {!isGuestStaff && (
          <Grid container item xs={8} spacing={2} alignItems="center" justify="flex-end">
            {deactivating && (
              <Typography variant="subtitle2">
                Click plan to deactivate.
              </Typography>
            )}
            <Grid item justify="flex-end">
              <Button
                color="secondary"
                variant={deactivating ? 'contained' : 'outlined'}
                onClick={() => setDeactivating(!deactivating)}
              >
                {deactivating ? 'Cancel' : 'Deactivate'}
              </Button>
            </Grid>
            <Grid item justify="flex-end">
              <Button
                color="primary"
                variant={deactivating ? 'outlined' : 'contained'}
                onClick={() => setFloorPlanForm(true)}
              >
                Add Floor Plan
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      {!isGuestStaff ? (
        <DragDropContext onDragEnd={onFloorplanDragEnd}>
          <Droppable droppableId="floorplanDroppable">
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {expandedFacility?.floorplans?.map((plan, idx) => (
                  <DraggableFloorplanListRow
                    idx={idx}
                    key={plan.id}
                    onClick={() => handleRowClick(isGuestStaff, deactivating, plan)}
                    plan={plan}
                    isGuestStaff={isGuestStaff}
                    deactivating={deactivating}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : expandedFacility?.floorplans?.map(plan => (
        <FloorplanListRow
          key={plan.id}
          onClick={() => handleRowClick(isGuestStaff, deactivating, plan)}
          plan={plan}
          isGuestStaff={isGuestStaff}
          deactivating={deactivating}
        />
      ))}
    </>
  )
}

export default FloorplanList
