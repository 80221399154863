import Container from './container'

export default class Room extends Container {
  constructor({ zones, facility, ...args }) {
    super(args)
    this.facility = facility
    this.zones = zones
  }

  updateRoom({ state }) {

  }

  updateZone({ id, state }) {

  }
}
