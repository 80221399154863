import React from 'react'
import { useConnect } from 'redux-bundler-hook'

import { titleize } from 'inflection'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import SelectField from '~/UI/Shared/Form/SelectField'

const UpdateDialog = props => {
  const {
    onClose,
    onSubmit,
    open,
    updateAllNodes,
  } = props

  const {
    doOtapNodeListSetImage,
    devices,
    otapImageChoices,
    otapNodeListSelectedImage,
    otapNodeListDeviceTypeFilter,
    otapNodeListSelectedNodes,
  } = useConnect(
    'doOtapNodeListSetImage',
    'selectDevices',
    'selectOtapImageChoices',
    'selectOtapNodeListSelectedImage',
    'selectOtapNodeListDeviceTypeFilter',
    'selectOtapNodeListSelectedNodes',
  )

  const deviceType = titleize(otapNodeListDeviceTypeFilter)

  if (updateAllNodes) {
    return (
      <Dialog fullWidth open={open} onClose={onClose} scroll="body">
        <DialogTitle>Update All {deviceType}s</DialogTitle>
        <DialogContent style={{ overflow: 'visible' }}>
          <DialogContentText>
            Select an image to send to all {deviceType.toLowerCase()}s:
          </DialogContentText>
          <SelectField
            dense
            placeholder="Select Firmware Image"
            options={otapImageChoices}
            value={otapNodeListSelectedImage}
            onChange={opt => doOtapNodeListSetImage(opt)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Nevermind
          </Button>
          <Button
            onClick={onSubmit}
            color="secondary"
            autoFocus
            disabled={!otapNodeListSelectedImage}
          >
            Update Them All!
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose} scroll="body">
      <DialogTitle>Update Some Devices</DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <DialogContentText>
          The following nodes will be updated:
          <ul>
            {otapNodeListSelectedNodes.map(nodeAddress => (
              <li key={nodeAddress}>
                {Object.values(devices).find(device => device.nodeAddress === nodeAddress)?.serialNumber}
                {` (${nodeAddress})`}
              </li>
            ))}
          </ul>
        </DialogContentText>
        <SelectField
          dense
          placeholder="Select Firmware Image"
          options={otapImageChoices}
          value={otapNodeListSelectedImage}
          onChange={opt => doOtapNodeListSetImage(opt)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Nevermind
        </Button>
        <Button
          onClick={onSubmit}
          color="secondary"
          autoFocus
          disabled={!otapNodeListSelectedImage}
        >
          DO IT
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateDialog
