import polylabel from 'polylabel'

import getUserSpaceWidth from './getUserSpaceWidth'

const REGEX_SVG_POINTS = /([\d-.,]+)/g
const TEXT_ROTATION_ASPECT_THRESHOLD = 0.7

const createSvgElement = (name, attributes, options) => ({
  name,
  type: options?.type ?? 'element',
  value: options?.value ?? '',
  attributes,
  children: options?.children ?? [],
})

/**
 * Mutates the children of the given SVG group, including sizing inner text appropriately.
 * @param {Object} group - An SVG object representing a room or zone area.
 * @param {number[]} [facilityDimensions] - The width and height of the facility.
 */
const addInnerSvgContents = (group, facilityDimensions) => {
  const areaId = group.attributes.id
  const [area, id] = areaId.split('-')

  // defs is expected to be the last child (of 3) in the group
  const defs = group.children[group.children.length - 1]

  const clipPath = defs.children[0]
  // Note: mutates the original `group` parameter
  clipPath.attributes.id = `${areaId}-clip-path`
  clipPath.attributes.fill = 'transparent'

  // Remove duplicate path element from the group
  if (group.children.length && Array.isArray(group.children)) {
    group.children.splice(1, 1)
  }

  // Match each "x,y" coordinate string and extract into array: [[x1, y1], [x2, y2], ...]
  const svgPoints = group.children[0].attributes.d.match(REGEX_SVG_POINTS)
  const pathPoints = svgPoints.reduce((significantPoints, points) => {
    const coordinates = points.split(',').map(Number)
    significantPoints.push(coordinates)

    return significantPoints
  }, [])

  // Technically the pole of inaccessibility, not the centroid, this marks the
  // point furthest from the edge of the polygon, rather than the center of mass.
  const centroid = polylabel([[...pathPoints]])
  group.centroid = {
    x: centroid[0],
    y: centroid[1]
  }

  const minMaxPoints = pathPoints.reduce((acc, [x, y]) => {
    const [
      [minX, minY],
      [maxX, maxY],
    ] = acc

    return [
      [Math.min(minX, x), Math.min(minY, y)],
      [Math.max(maxX, x), Math.max(maxY, y)],
    ]
  }, [[Infinity, Infinity], [-Infinity, -Infinity]])

  const maxWidth = Math.abs(minMaxPoints[0][0] - minMaxPoints[1][0])
  const maxHeight = Math.abs(minMaxPoints[0][1] - minMaxPoints[1][1])

  const isRoom = areaId.includes('room')

  const aspectRatio = maxWidth / maxHeight
  const textShouldRotate = aspectRatio < TEXT_ROTATION_ASPECT_THRESHOLD

  const userSpaceWidth = getUserSpaceWidth(
    aspectRatio,
    centroid.distance,
    facilityDimensions,
  )

  const foreignObjectIconContainer = createSvgElement('foreignObject', {
    class: 'gc-foreign-object-icon-container',
    id: `${areaId}-foreign-object-icon-container`,
    width: '100%',
    height: '100%',
    x: '-50%',
    y: '80%',
    areaId,
    stroke: 'none',
    'stroke-width': 0,
  }, {
    children: [createSvgElement('div', {
      xmlns: 'http://www.w3.org/1999/xhtml',
      class: 'gc-foreign-object-inner-icon-container',
      id: `${areaId}-foreign-object-inner-icon-container`,
      areaId,
      style: 'width: 100%; position: relative; text-align: center;',
    })]
  })

  const text = createSvgElement('text', {
    id: `${areaId}-text`,
    style: 'pointer-events: none; user-select: none;',
    fill: '#ffffff',
    'fill-opacity': 1,
    stroke: 'transparent',
    'stroke-width': '0',
    'text-anchor': 'middle',
    dy: '50%',
  })
  text.children.push(createSvgElement('', {}, {
    type: 'text',
    value: `${area.charAt(0).toUpperCase() + area.substr(1)} ${id}`,
  }))

  const iconSvgWrapper = createSvgElement('svg', {
    viewBox: '0 0 100 32',
    x: 0,
    y: 0,
    width: maxWidth,
    preserveAspectRatio: 'xMinYMin meet',
    overflow: 'auto',
  })
  iconSvgWrapper.children.push(foreignObjectIconContainer)

  const textSvgWrapper = createSvgElement('svg', {
    id: `${areaId}-svg-wrapper`,
    viewBox: `0 0 ${userSpaceWidth} 16`,
    x: `${centroid[0]}`,
    y: `${(isRoom ? centroid[1] - 16 : centroid[1])}`,
    width: '100%',
    preserveAspectRatio: 'xMinYMin meet',
    overflow: 'auto',
    areaId,
    stroke: 'transparent',
    'stroke-width': '0',
  })
  textSvgWrapper.children.push(createSvgElement(
    'g',
    textShouldRotate ? {
      transform: 'rotate(-90, 0, 0)',
    } : {},
    {
      children: [text, iconSvgWrapper],
    },
  ))

  const [
    [
      topleftX,
      topleftY
    ],
  ] = minMaxPoints

  const polygonCoordinates = pathPoints.map(point => [point[0] - topleftX, point[1] - topleftY])

  const clipPathPolygon = { ...clipPath }
  clipPathPolygon.name = 'polygon'
  clipPathPolygon.attributes = {
    points: polygonCoordinates.map(point => point.join(',')).join(' ')
  }

  const newClipPathPolygonId = `${areaId}-clip-polygon-path`

  const newClipPathPolygon = createSvgElement('clipPath', {
    id: newClipPathPolygonId,
    clipPathUnits: 'userSpaceOnUse',
  })
  newClipPathPolygon.children.push(clipPathPolygon)

  const dot = createSvgElement('circle', {
    id: `${areaId}-centroid`,
    cx: `${centroid[0]}`,
    cy: `${centroid[1]}`,
    r: '1',
    fill: 'transparent',
    'stroke-width': 0,
    stroke: 'none',
  })

  const foreignObject = createSvgElement('foreignObject', {
    class: 'gc-foreign-object',
    id: `${areaId}-foreign-object`,
    'clip-path': `url(#${newClipPathPolygonId})`,
    width: maxWidth,
    height: maxHeight,
    x: minMaxPoints[0][0],
    y: minMaxPoints[0][1],
    areaId,
    stroke: 'none',
    'stroke-width': 0,
  })
  foreignObject.children.push(createSvgElement('div', {
    xmlns: 'http://www.w3.org/1999/xhtml',
    class: 'gc-foreign-object-inner-container',
    id: `${areaId}-foreign-object-inner-container`,
    areaId,
  }))

  // Add clip path URL attribute to path element
  group.children[0].attributes['clip-path'] = `url(#${areaId}-clip-path)`

  // group.children: [path, defs] -> [defs, path]
  group.children.reverse()

  defs.children.push(newClipPathPolygon)

  group.children.push(dot)
  group.children.push(foreignObject)
  group.children.push(textSvgWrapper)
}

export default addInnerSvgContents
