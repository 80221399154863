import React from 'react'
import PropTypes from 'prop-types'

import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import Icon from '~/UI/Shared/Icon'

const styles = theme => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: 0,
  },
  icon: {
    color: theme.palette.text.primary,
  },
})

class SnackBarComponent extends React.PureComponent {
  static propTypes = {
    snackbarMessages: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        message: PropTypes.node,
      })
    ).isRequired,
    doDismissSnackbarMessage: PropTypes.func.isRequired,
    classes: PropTypes.objectOf(PropTypes.string),
    clickaway: PropTypes.bool,
  }

  static defaultProps = {
    classes: {},
    clickaway: false,
  }

  state = {
    snackbar: null,
    open: false,
  }

  componentDidMount() {
    this.calcState()
  }

  componentDidUpdate(oldProps) {
    if (oldProps !== this.props) this.calcState()
  }

  onClose = (event, reason) => {
    if (reason === 'clickaway' && !this.props.clickaway) {
      return
    }
    this.setState({ open: false })
  }

  dismissCurrent = () => {
    const {
      snackbar: { key },
    } = this.state
    this.props.doDismissSnackbarMessage(key)
  }

  calcState() {
    const {
      snackbarMessages: [current, next],
    } = this.props
    this.setState({
      snackbar: current,
      open: !!current && !next,
    })
  }

  render() {
    const { classes } = this.props
    const { open, snackbar } = this.state

    if (!snackbar) return ''

    const { key, message } = snackbar
    return (
      <Snackbar
        key={key}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={5000}
        ContentProps={{ 'aria-describedby': key }}
        message={<span id={key}>{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.onClose}
          >
            <Icon icon={CloseIcon} />
          </IconButton>,
        ]}
        onClose={this.onClose}
        onExited={this.dismissCurrent}
      />
    )
  }
}

export default withStyles(styles)(SnackBarComponent)
