/* eslint-disable
  jsx-a11y/click-events-have-key-events,
  jsx-a11y/no-static-element-interactions */
/*
  Disabling these eslint rules because we're not actually doing the bad things they're intended
  to prevent. The onclick handler is only handling <a> tag clicks which are fired even when a user
  activates the link via the keyboard.
*/
import React from 'react'

import classNames from 'clsx'
import memoizeOne from 'memoize-one'
import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import {
  AppBar,
  CircularProgress,
  CssBaseline,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  ErrorOutline as ErrorOutlineIcon,
  Menu as MenuIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons'

import * as Sentry from '@sentry/browser'
import { getNavHelper } from 'internal-nav-helper'

import config from '~/App/config'
import addLogger from '~/Lib/Logging'
import { idAndNameShape, renderableType } from '~/Lib/PropTypes'
import {
  defer,
  EMPTY_OBJECT,
  noop,
  useBreakpoint,
  useIsBreakpointDown,
} from '~/Lib/Utils'
import EmptyState from '~/UI/Shared/EmptyState'
import Head from '~/UI/Shared/Head'
import Icon from '~/UI/Shared/Icon'

import Nav from './Nav'
import SnackBar from './SnackBar'
import styles from './styles'

const getCombinedProps = memoizeOne((connectedProps, props) => ({
  ...connectedProps,
  ...props,
}))

const getErrorClasses = memoizeOne(({ errorIcon: headerIcon }) => ({ headerIcon }))

const SomethingWentWrong = ({ classes, message, onClick }) => (
  <EmptyState
    dataTestId="aroya-app-error"
    classes={getErrorClasses(classes)}
    heading={(
      <div className={classes.errorHeading}>
        {message && <Typography gutterBottom variant="h2">{message}</Typography>}
        <Typography gutterBottom align="center" variant="subtitle1">
          We apologize for any inconvenience. Our engineering team has been notified of this issue.
          <br />
          Please refresh the page and try again.
        </Typography>
        <br />
      </div>
    )}
    headingProps={{ variant: 'h3' }}
    HeaderIcon={ErrorOutlineIcon}
    FabIcon={RefreshIcon}
    fabLabel="refresh page"
    fabProps={{ onClick }}
  />
)
SomethingWentWrong.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  message: PropTypes.string,
  onClick: PropTypes.func.isRequired
}
SomethingWentWrong.defaultProps = {
  message: null,
}

const notProd = process.env.NODE_ENV !== 'production'
const debugOn = localStorage.debug === 'true'

const Boom = () => {
  throw new Error('BOOM!')
}
const BlowUp = notProd || debugOn ? () => <Boom /> : noop
const DefaultRoute = () => <div />
const SpinnerRoute = () => (
  <Grid item xs={12} className="text-center">
    <CircularProgress />
  </Grid>
)

export class AppComponent extends React.PureComponent {
  static displayName = 'App'

  static propTypes = {
    breakpoint: PropTypes.string.isRequired,
    dimensions: PropTypes.shape({
      height: PropTypes.objectOf(PropTypes.number),
      width: PropTypes.objectOf(PropTypes.number),
    }),
    doLogout: PropTypes.func.isRequired,
    doUpdateDimensions: PropTypes.func.isRequired,
    doUpdateUrl: PropTypes.func.isRequired,
    doDismissSnackbarMessage: PropTypes.func.isRequired,
    isOnline: PropTypes.bool.isRequired,
    isLoggingOut: PropTypes.bool,
    isSmDown: PropTypes.bool.isRequired,
    doMarkMeAsOutdated: PropTypes.func.isRequired,
    doMarkOrganizationListAsOutdated: PropTypes.func.isRequired,
    doMarkLoggerListAsOutdated: PropTypes.func.isRequired,
    doMarkNodeListAsOutdated: PropTypes.func.isRequired,
    meIsLoading: PropTypes.bool.isRequired,
    organizations: PropTypes.objectOf(idAndNameShape),
    organizationListIsLoading: PropTypes.bool.isRequired,
    auth: PropTypes.shape({
      authenticated: PropTypes.bool,
    }).isRequired,
    maintenanceModeEnabled: PropTypes.bool.isRequired,
    me: PropTypes.shape({ version: PropTypes.string }),
    routeInfo: PropTypes.shape({
      url: PropTypes.string,
      params: PropTypes.objectOf(PropTypes.string),
    }),
    route: (props, propName, component) => {
      const route = props[propName]
      return renderableType(
        ...(route && route.component
          ? [route, 'component', `${component}.route`]
          : [props, propName, component])
      )
    },
    inflight: PropTypes.shape({
      read: PropTypes.number,
      write: PropTypes.number,
    }),
    snackbarMessages: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        message: PropTypes.node,
      })
    ),
  }

  static defaultProps = {
    dimensions: { height: EMPTY_OBJECT, width: EMPTY_OBJECT },
    inflight: { read: 0, write: 0 },
    isLoggingOut: false,
    me: EMPTY_OBJECT,
    organizations: EMPTY_OBJECT,
    route: DefaultRoute,
    routeInfo: EMPTY_OBJECT,
    snackbarMessages: [],
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  state = {
    hasError: false,
    drawerOpen: false,
    routeKey: 1,
  }

  componentDidMount() {
    if (!this.props.auth.authenticated && !this.publicAccess) {
      defer(() => {
        this.props.doUpdateUrl('/login')
      }, defer.priorities.high)
    }
    this.redirectToSystemStats(this.currentUrlIsRoot)
    defer(this.props.doUpdateDimensions, defer.priorities.low)
    this.props.doMarkMeAsOutdated()
    this.props.doMarkOrganizationListAsOutdated()
    this.props.doMarkNodeListAsOutdated()
    this.props.doMarkLoggerListAsOutdated()
  }

  componentDidUpdate(prevProps) {
    this.redirectToSystemStats(this.currentUrlIsRoot)
    this.onNavigation(prevProps)
  }

  componentDidCatch(error, info) {
    this.logger.error(JSON.stringify({ error: { message: error.message, stackTrace: error.stack }, info }, null, 2))
    if (config.SENTRY_DSN) {
      Sentry.withScope(scope => {
        scope.setExtras(info)
        Sentry.captureException(error)
      })
    }
  }

  get publicAccess() {
    return this.props.route?.publicAccess ?? false
  }

  get showSpinner() {
    const { me, meIsLoading, organizations, organizationListIsLoading } = this.props
    if (!me?.version && meIsLoading) return true
    if (!Object.keys(organizations).length && organizationListIsLoading) return true
    return false
  }

  onDrawerToggle = () => this.setState(
    ({ drawerOpen }) => ({ drawerOpen: !drawerOpen }),
    () => defer(this.props.doUpdateDimensions, 500)
  )

  onNavigation(prevProps) {
    const { hasError } = this.state
    const { route: prevRoute } = prevProps
    const { route: currRoute } = this.props

    if (prevRoute !== currRoute) {
      if (hasError) this.resetError()
    }
  }

  redirectToSystemStats(test = () => true) {
    if (test() && this.props.auth.authenticated) {
      defer(() => {
        this.props.doUpdateUrl('/system/chart')
      }, defer.priorities.high)
    }
  }

  resetError = () => {
    this.setState(({ routeKey }) => ({
      hasError: false,
      routeKey: routeKey + 1,
      blowUp: undefined,
    }))
  }

  currentUrlIsRoot = () => this.props.routeInfo.url === '/'

  render() {
    const {
      auth: { authenticated },
      breakpoint,
      classes,
      inflight,
      isLoggingOut,
      isOnline,
      isSmDown,
      maintenanceModeEnabled,
      me,
      route,
      routeInfo: { url: currentURL },
      doUpdateUrl,
    } = this.props
    let Route = route.component || route
    let { viewMode } = route

    const apiVersion = me?.version ?? null
    const apiIsProd = config.API_URL === 'https://api.aroya.io/portal_api'
    const isDev = process.env.NODE_ENV !== 'production'

    if (this.showSpinner) {
      Route = SpinnerRoute
      viewMode = 'fullscreen'
    }

    if (maintenanceModeEnabled) {
      return (
        <Typography variant="h5" className={classes.maintenanceMessage}>
          AROYA is down for scheduled maintenance. Please contact us if you have any questions.
        </Typography>
      )
    }

    if (this.state.blowUp) {
      Route = BlowUp
    }

    return (
      <>
        {route.title ? <Head><title>{route.title}</title></Head> : <Head />}
        <CssBaseline />
        {apiIsProd && isDev ? (
          <div data-trackheight="prodBanner" className={classes.prodBanner}>
            <Typography variant="h3" align="center" style={{ lineHeight: '48px' }}>
              PRODUCTION
            </Typography>
          </div>
        ) : null}
        {inflight.read ? (
          <LinearProgress
            variant="query"
            classes={{ root: classes.readProgress }}
          />
        ) : null}
        <div
          id="wrapper"
          className={classNames({
            scroller: true,
            [classes.root]: true,
            [viewMode]: !!viewMode,
          })}
          onClick={getNavHelper(doUpdateUrl)}
        >
          <div id="version" onClick={() => this.setState({ blowUp: true })}>
            {`v${process.env.npm_package_version}`}
            {apiVersion ? `/v${apiVersion}` : null}
          </div>
          {viewMode && viewMode === 'fullscreen' ? null : (
            <>
              <AppBar
                position="absolute"
                className={classNames({
                  [classes.appBar]: true,
                  [classes.appBarShift]: this.state.drawerOpen,
                })}
                data-trackheight="appBar"
              >
                <Toolbar disableGutters={!this.state.drawerOpen}>
                  <IconButton
                    color="primary"
                    aria-label="open drawer"
                    onClick={this.onDrawerToggle}
                    className={classNames({
                      [classes.menuButton]: true,
                      [classes.hide]: this.state.drawerOpen,
                    })}
                  >
                    <Icon icon={MenuIcon} />
                  </IconButton>
                  {localStorage.debug ? (
                    <Typography className={classes.debugInfo}>
                      {[
                        `breakpoint: ${breakpoint}`,
                        `res: ${
                          this.props.dimensions.width.window
                        }x${
                          this.props.dimensions.height.window
                        } (${
                          this.props.dimensions.width.window / this.props.dimensions.height.window > 1
                            ? 'landscape'
                            : 'portrait'
                        })`,
                        `content: ${this.props.dimensions.width.content}x${this.props.dimensions.height.content}`
                      ].join('\n')}
                    </Typography>
                  ) : null}
                  {!isOnline && (
                    <span className={classes.offlineIndicator}>Offline</span>
                  )}
                </Toolbar>
              </AppBar>
              <Drawer
                variant={isSmDown ? 'temporary' : 'permanent'}
                classes={{
                  root: classes.drawer,
                  paper: classes.drawerPaper,
                }}
                className={
                  this.state.drawerOpen ? null : classes.drawerPaperClose
                }
                role="navigation"
                data-state={this.state.drawerOpen ? 'open' : 'closed'}
                data-trackwidth="navDrawer"
                open={this.state.drawerOpen}
              >
                <Nav
                  currentURL={currentURL}
                  drawerOpen={this.state.drawerOpen}
                  drawerToggle={this.onDrawerToggle}
                  doUpdateUrl={this.props.doUpdateUrl}
                  doLogout={this.props.doLogout}
                />
              </Drawer>
            </>
          )}
          <main id="content" className={classes.content}>
            {this.state.hasError ? (
              <SomethingWentWrong
                classes={classes}
                message={this.state.errorMessage}
                onClick={this.resetError}
              />
            ) : (
              <Route key={this.state.routeKey} {...route.additionalProps} />
            )}
          </main>
        </div>
        {!authenticated || isLoggingOut ? null : (
          <>
            <SnackBar
              doDismissSnackbarMessage={this.props.doDismissSnackbarMessage}
              snackbarMessages={this.props.snackbarMessages}
            />
            {inflight.write ? (
              <CircularProgress
                color="secondary"
                classes={{ root: classes.writeProgress }}
              />
            ) : null}
          </>
        )}
        <div id="modalRoot" />
        <div id="menuRoot" />
      </>
    )
  }
}

addLogger(AppComponent)

const useStyles = makeStyles(styles, { name: AppComponent.displayName })
const Connected = props => {
  const connectedProps = useConnect(
    'selectDimensions',
    'selectIsOnline',
    'selectIsLoggingOut',
    'selectAuth',
    'selectMaintenanceModeEnabled',
    'selectMe',
    'selectMeIsLoading',
    'selectOrganizations',
    'selectOrganizationListIsLoading',
    'selectRoute',
    'selectRouteInfo',
    'selectSnackbarMessages',
    'selectInflight',
    'doUpdateDimensions',
    'doUpdateUrl',
    'doLogout',
    'doMarkOrganizationListAsOutdated',
    'doMarkLoggerListAsOutdated',
    'doMarkMeAsOutdated',
    'doMarkNodeListAsOutdated',
    'doDismissSnackbarMessage',
  )
  const combinedProps = getCombinedProps(connectedProps, props)
  const breakpoint = useBreakpoint()
  const isSmDown = useIsBreakpointDown('sm', breakpoint)
  const classes = useStyles(combinedProps)

  return (
    <AppComponent
      {...combinedProps}
      breakpoint={breakpoint}
      classes={classes}
      isSmDown={isSmDown}
    />
  )
}

Connected.displayName = `Connected(${AppComponent.displayName})`

export default Connected
