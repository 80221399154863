import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'

const ListTable = withStyles(() => ({
  root: {
    borderCollapse: 'separate',
    borderSpacing: '0px 15px',
  }
}))(Table)

export default ListTable
