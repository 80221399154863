import React from 'react'
import { capitalize } from 'inflection'
import { useConnect } from 'redux-bundler-hook'

import { Grid, InputAdornment } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import Icon from '~/UI/Shared/Icon'
import SearchField from '~/UI/Shared/Form/SearchField'
import MuiSelectField from '~/UI/Shared/Form/SelectField'
import styles from './styles'

const useStyles = makeStyles(styles)

export const getFacilityAttrOptions = (attr, mePacket) => (
  attr === 'facilityTiers'
    ? ([
      { label: 'All', value: 'all' },
      { label: 'None', value: '' },
      ...Object.keys(mePacket[attr]).map(option => (
        { label: capitalize(option), value: mePacket[attr][option] }
      ))
    ])
    : ([
      ...mePacket[attr].map(value => ({ label: capitalize(value), value })),
      { label: 'All', value: 'all' },
    ])
)

const OrganizationFiltersComponent = () => {
  const classes = useStyles()
  const {
    me,
    organizationListRaw,
    organizationListOrgFilter,
    organizationListFacFilter,
    organizationListAppFilter,
    organizationListTierFilter,
    organizationListSupportFilter,
    organizationListStatusFilter,
    organizationListSearch,
    doOrganizationListSetOrgFilter,
    doOrganizationListSetFacFilter,
    doOrganizationListSetAppFilter,
    doOrganizationListSetTierFilter,
    doOrganizationListSetSupportFilter,
    doOrganizationListSetStatusFilter,
    doOrganizationSetSearch,
  } = useConnect(
    'selectMe',
    'selectOrganizationListRaw',
    'selectOrganizationListOrgFilter',
    'selectOrganizationListFacFilter',
    'selectOrganizationListAppFilter',
    'selectOrganizationListTierFilter',
    'selectOrganizationListSupportFilter',
    'selectOrganizationListStatusFilter',
    'selectOrganizationListSearch',
    'doOrganizationListSetOrgFilter',
    'doOrganizationListSetFacFilter',
    'doOrganizationListSetAppFilter',
    'doOrganizationListSetTierFilter',
    'doOrganizationListSetSupportFilter',
    'doOrganizationListSetStatusFilter',
    'doOrganizationSetSearch',
  )

  const options = [
    { label: 'Active', value: 'active' },
    { label: 'Deactivated', value: 'deactive' },
    { label: 'All', value: 'all' },
  ]

  const facOptions = [
    ...options,
    { label: 'Outdoor', value: 'outdoor' },
  ]

  const { isLoading } = organizationListRaw

  const OrganizationFilter = (
    <MuiSelectField
      fullWidth
      className={classes.selectField}
      placeholder="Organizations"
      color="primary"
      label="Organizations"
      options={options}
      value={organizationListOrgFilter}
      onChange={doOrganizationListSetOrgFilter}
      disabled={isLoading}
    />
  )

  const FacilityFilter = (
    <MuiSelectField
      fullWidth
      className={classes.selectField}
      placeholder="Facilities"
      color="primary"
      label="Facilities"
      options={facOptions}
      value={organizationListFacFilter}
      onChange={doOrganizationListSetFacFilter}
      disabled={isLoading}
    />
  )

  const AppFilter = (
    <MuiSelectField
      fullWidth
      className={classes.selectField}
      placeholder="App"
      color="primary"
      label="App"
      options={getFacilityAttrOptions('appTypes', me).filter(({ value }) => value !== 'all')}
      value={organizationListAppFilter}
      onChange={doOrganizationListSetAppFilter}
      disabled={isLoading}
    />
  )

  const TierFilter = (
    <MuiSelectField
      fullWidth
      className={classes.selectField}
      placeholder="Tier"
      color="primary"
      label="Tier"
      options={getFacilityAttrOptions('facilityTiers', me)}
      value={organizationListTierFilter}
      onChange={doOrganizationListSetTierFilter}
      disabled={isLoading}
    />
  )

  const SupportFilter = (
    <MuiSelectField
      fullWidth
      className={classes.selectField}
      placeholder="Support Category"
      color="primary"
      label="Support Category"
      options={getFacilityAttrOptions('supportCategories', me)}
      value={organizationListSupportFilter}
      onChange={doOrganizationListSetSupportFilter}
      disabled={isLoading}
    />
  )

  const StatusFilter = (
    <MuiSelectField
      fullWidth
      className={classes.selectField}
      placeholder="Status"
      color="primary"
      label="Status"
      options={getFacilityAttrOptions('statusTypes', me)}
      value={organizationListStatusFilter}
      onChange={doOrganizationListSetStatusFilter}
      disabled={isLoading}
    />
  )

  const OrganizationSearch = (
    <SearchField
      fullWidth
      label="Search"
      value={organizationListSearch}
      onEmpty={() => doOrganizationSetSearch('')}
      onChange={({ searchTerms }) => doOrganizationSetSearch(searchTerms)}
      InputProps={{
        startAdornment: (
          <InputAdornment>
            <Icon icon={SearchIcon} />
          </InputAdornment>
        )
      }}
    />
  )

  return (
    <Grid container justify="flex-end" alignItems="center" wrap="wrap" spacing={1} className={classes.filters}>
      <Grid item lg={1} md={4} sm={6} xs={12} style={{ minWidth: '256px' }}>
        {OrganizationFilter}
      </Grid>
      <Grid item lg={1} md={4} sm={6} xs={12} style={{ minWidth: '256px' }}>
        {FacilityFilter}
      </Grid>
      <Grid item lg={1} md={4} sm={6} xs={12} style={{ minWidth: '256px' }}>
        {AppFilter}
      </Grid>
      <Grid item lg={1} md={4} sm={6} xs={12} style={{ minWidth: '256px' }}>
        {TierFilter}
      </Grid>
      <Grid item lg={1} md={4} sm={6} xs={12} style={{ minWidth: '256px' }}>
        {SupportFilter}
      </Grid>
      <Grid item lg={1} md={4} sm={6} xs={12} style={{ minWidth: '256px' }}>
        {StatusFilter}
      </Grid>
      <Grid item lg={1} md={4} sm={6} xs={12} style={{ minWidth: '256px' }} className={classes.searchField}>
        {OrganizationSearch}
      </Grid>
    </Grid>
  )
}

export default OrganizationFiltersComponent
