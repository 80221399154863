import * as R from 'ramda'
import { getDateTime } from '~/Lib/Utils'

export const validIdOrUndefined = val => Number(val) || undefined
export const validIdOrNil = val => Number(val) || null

export const validIdArray = R.compose(R.filter(R.identity), R.map(validIdOrNil))

export const nonEmptyStringOrNil = val => {
  if (typeof val === 'string') {
    const trimmed = R.trim(val)
    if (trimmed.length) return trimmed
  }
  return null
}

export const nonEmptyArrayOrNil = val => {
  if (Array.isArray(val) && val.length > 0) {
    const cleaned = val.filter(o => (Number(o) === o ? true : o))
    if (cleaned.length) return cleaned
  }
  return null
}

export const toISODateOrNil = date => {
  const dateTime = getDateTime(date)
  if (dateTime.isValid) return dateTime.toISODate()
  return null
}

export const phoneNumberOrNil = phoneNumber => {
  const phone = R.trim(phoneNumber)
  return phone.length
    ? phone
      .replace(/^\s*\+?1?\s*/, '')
      .replace(/[^\d]/g, '')
      .slice(0, 10)
    : null
}

export const formatPhoneNumber = phoneNumber => {
  const cleaned = (`${phoneNumber}`).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumber
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const sortNestedArrayBy = (attr, list) => (
  R.sortBy(R.compose(R.toLower, R.prop(attr)), list)
)
