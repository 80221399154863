import React from 'react'
import { capitalize } from 'inflection'
import memoize from 'nano-memoize'
import { useConnect } from 'redux-bundler-hook'

import { Grid, InputAdornment } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import Icon from '~/UI/Shared/Icon'
import SearchField from '~/UI/Shared/Form/SearchField'
import Select from '~/UI/Shared/Select'

const DEFAULT_OPTIONS = {
  DEFAULT: {
    position: 'end',
    option: { label: 'All', value: 'all' }
  },
  facilityTiers: {
    position: 'start',
    option: { label: 'None', value: 'NONE' }
  }
}

const useStyles = makeStyles(theme => ({
  filters: {
    marginBottom: theme.spacing(0.5),
  },
  selectField: {
    marginTop: 23,
    '& button': {
      marginTop: 23,
    }
  }
}))

export const getFacilityAttrOptions = memoize((attr, mePacket) => {
  let options = Array.isArray(mePacket[attr])
    ? mePacket[attr].map(value => ({ label: capitalize(value), value }))
    : Object.entries(mePacket[attr]).map(([key, value]) => ({ label: capitalize(key), value }))

  const { [attr]: defaultOption = DEFAULT_OPTIONS.DEFAULT } = DEFAULT_OPTIONS
  const { position, option } = defaultOption
  if (position === 'start') options = [option, ...options]
  if (position === 'end') options.push(option)
  return options
})

const MapFilterComponent = () => {
  const classes = useStyles()
  const {
    me,
    facilityListRaw,
    facilityListTierFilter,
    facilityListStatusFilter,
    facilityListSearch,
    doFacilityListSetTierFilter,
    doFacilityListSetStatusFilter,
    doFacilitySetSearch,
  } = useConnect(
    'selectMe',
    'selectFacilityListRaw',
    'selectFacilityListTierFilter',
    'selectFacilityListStatusFilter',
    'selectFacilityListSearch',
    'doFacilityListSetTierFilter',
    'doFacilityListSetStatusFilter',
    'doFacilitySetSearch',
  )

  const { isLoading } = facilityListRaw

  return (
    <Grid container alignItems="center" wrap="wrap" spacing={1} className={classes.filters}>
      <Grid item lg={1} md={4} sm={6} xs={12} style={{ minWidth: '150px' }}>
        <Select
          fullWidth
          placeholder="Tier"
          className={classes.selectField}
          color="primary"
          label="Tier"
          multiple
          checkbox
          options={getFacilityAttrOptions('facilityTiers', me)}
          value={facilityListTierFilter}
          onChange={doFacilityListSetTierFilter}
          disabled={isLoading}
        />
      </Grid>
      <Grid item lg={1} md={4} sm={6} xs={12} style={{ minWidth: '150px' }}>
        <Select
          fullWidth
          placeholder="Status"
          className={classes.selectField}
          color="primary"
          label="Status"
          options={getFacilityAttrOptions('statusTypes', me)}
          value={facilityListStatusFilter}
          onChange={doFacilityListSetStatusFilter}
          disabled={isLoading}
        />
      </Grid>
      <Grid item lg={1} md={4} sm={6} xs={12} style={{ minWidth: '240px' }}>
        <SearchField
          fullWidth
          label="Search"
          value={facilityListSearch}
          onEmpty={() => doFacilitySetSearch('')}
          onChange={({ searchTerms }) => doFacilitySetSearch(searchTerms)}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <Icon icon={SearchIcon} />
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

export default MapFilterComponent
