import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const ConfirmationDialog = props => {
  const {
    open,
    title,
    content,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    showCancel = true,
    onClose,
    onConfirm,
    destructive,
    maxWidth,
    onExited
  } = props

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
        onExited={onExited}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {showCancel && (
            <Button
              onClick={onClose}
              color={destructive ? 'primary' : 'secondary'}
            >
              {cancelText}
            </Button>
          )}
          <Button
            onClick={onConfirm}
            color={destructive ? 'secondary' : 'primary'}
            autoFocus
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationDialog
