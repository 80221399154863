import React, { useEffect, useState } from 'react'

import { uniq } from 'ramda'
import { useConnect } from 'redux-bundler-hook'
import { Button, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'

import ConfirmationDialog from '~/UI/Shared/ConfirmationDialog'
import SearchField from '~/UI/Shared/Form/SearchField'
import Icon from '~/UI/Shared/Icon'
import MuiTitle from '~/UI/Shared/MuiTitle'
import Loading from '~/UI/Shared/utils'

import UpdateDialog from './dialog'
import OtapNodeList from './list'
import FirmwareFilters from './filters'
import TreeView from './treeview'
import styles from './styles'

const useStyles = makeStyles(styles)

const BulkUpdateDialog = ({ open, onClose, onSubmit }) => (
  <UpdateDialog
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
    updateAllNodes
  />
)

const OtapNodeListComponent = () => {
  const classes = useStyles()
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false)
  const [bulkUpdateDialogOpen, setBulkUpdateDialogOpen] = useState(false)
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)

  const {
    doMarkOtapImageListAsOutdated,
    doOtapNodeListFetchNodesForFacility,
    doMarkOtapNodeListAsOutdated,
    doOtapNodeListSetSelectedNodes,
    doOtapNodeListSetSelectedFacility,
    doOtapNodeListSetSearch,
    doOtapSubmitBulkUpdates,
    doOtapSubmitUpdate,
    doUpdateUrl,
    otapNodeList,
    otapNodeListSelectedNodes,
    otapNodeListDeviceTypeFilter,
    otapNodeListSelectedFacility,
    otapNodeListSearch,
    isGuestStaff,
  } = useConnect(
    'doMarkOtapImageListAsOutdated',
    'doOtapNodeListFetchNodesForFacility',
    'doMarkOtapNodeListAsOutdated',
    'doOtapNodeListSetSelectedNodes',
    'doOtapNodeListSetSelectedFacility',
    'doOtapNodeListSetSearch',
    'doOtapSubmitBulkUpdates',
    'doOtapSubmitUpdate',
    'doUpdateUrl',
    'selectOtapNodeList',
    'selectOtapNodeListSelectedNodes',
    'selectOtapNodeListDeviceTypeFilter',
    'selectOtapNodeListSelectedFacility',
    'selectOtapNodeListSearch',
    'selectIsGuestStaff',
  )

  const toggleNodeSelected = node => {
    if (otapNodeListSelectedFacility === node.facility) doOtapNodeListSetSelectedFacility(null)
    if (otapNodeListSelectedNodes.includes(node.nodeAddress)) {
      doOtapNodeListSetSelectedNodes(otapNodeListSelectedNodes.filter(n => n !== node.nodeAddress))
    } else {
      doOtapNodeListSetSelectedNodes([...otapNodeListSelectedNodes, node.nodeAddress])
    }
  }

  const onClickUpdate = () => {
    setUpdateDialogOpen(true)
    doMarkOtapImageListAsOutdated()
  }

  const selectAllNodesOnPage = () => {
    const nodeAddresses = otapNodeList?.results?.map(node => node.nodeAddress)
    doOtapNodeListSetSelectedNodes(uniq([...otapNodeListSelectedNodes, ...nodeAddresses]))
  }

  const clearAllNodes = () => {
    doOtapNodeListSetSelectedNodes([])
    doOtapNodeListSetSelectedFacility(null)
  }

  const onSubmitUpdate = async () => {
    const response = await doOtapSubmitUpdate()
    if (!response.success) {
      setUpdateDialogOpen(false)
    }
    if (response.success) {
      clearAllNodes()
    }
  }

  const singleNodeSelected = () => {
    const singleNode = otapNodeListSelectedNodes?.length === 1
    const singleNodeHasFacility = otapNodeList?.results?.find(node => node.nodeAddress === otapNodeListSelectedNodes?.[0])?.facility
    return singleNode && singleNodeHasFacility
  }

  const selectAllFacilityNodes = async () => {
    const nodeAddress = otapNodeListSelectedNodes?.[0]
    const facility = otapNodeList?.results?.find(node => node.nodeAddress === nodeAddress)?.facility
    const nodes = facility ? await doOtapNodeListFetchNodesForFacility(facility) : []
    const nodeAddresses = nodes.map(node => node.nodeAddress)
    doOtapNodeListSetSelectedNodes([...otapNodeListSelectedNodes, ...nodeAddresses])
    doOtapNodeListSetSelectedFacility(facility)
  }

  useEffect(() => {
    doMarkOtapImageListAsOutdated()
    doMarkOtapNodeListAsOutdated()
  }, [])

  return (
    <>
      <Grid container justify="space-between">
        <Grid item xs={4}>
          <MuiTitle title="Update Firmware" />
        </Grid>
        <Grid item container xs={8} spacing={2} justify="flex-end">
          <Grid item xs={4}>
            <SearchField
              fullWidth
              dense
              label="Search Nodes"
              value={otapNodeListSearch}
              onEmpty={() => doOtapNodeListSetSearch('')}
              onChange={({ searchTerms }) => { if (!searchTerms.length || searchTerms.length >= 3) doOtapNodeListSetSearch(searchTerms) }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon={SearchIcon} />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end">
        <Grid container item xs={12} justify="space-between" className={classes.filterNav}>
          <Grid item>
            {!isGuestStaff && (
              <>
                <FirmwareFilters />
                <Button
                  size="small"
                  color="primary"
                  disabled={otapNodeListSelectedNodes?.length === 0}
                  onClick={onClickUpdate}
                  className={classes.actionButton}
                >
                  Update Selected
                </Button>
              </>
            )}
            <Button
              size="small"
              color="primary"
              onClick={selectAllNodesOnPage}
              className={classes.actionButton}
            >
              Select Page
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={clearAllNodes}
              className={classes.actionButton}
            >
              Clear
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => doUpdateUrl('/firmware/logs')}
              className={classes.actionButton}
            >
              View Logs
            </Button>
            {singleNodeSelected() && (
              <Button
                size="small"
                color="primary"
                onClick={selectAllFacilityNodes}
                className={classes.actionButton}
              >
                Select All Facility Nodes
              </Button>
            )}
          </Grid>
          <Grid item>
            {!isGuestStaff && (
            <Button
              size="small"
              color="primary"
              onClick={() => setBulkUpdateDialogOpen(true)}
              className={classes.actionButton}
              disabled={!!otapNodeListSelectedNodes?.length}
            >
              Update All {otapNodeListDeviceTypeFilter}s
            </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {otapNodeList ? (
        <Grid container xs={12} justify="space-between">
          <Grid item xs={2} className={classes.treeContainer}>
            <TreeView
              toggleNodeSelected={toggleNodeSelected}
            />
          </Grid>
          <Grid item xs={10}>
            <OtapNodeList
              nodeList={otapNodeList}
              selectedNodes={otapNodeListSelectedNodes}
              toggleNodeSelected={toggleNodeSelected}
              isGuestStaff={isGuestStaff}
            />
          </Grid>
        </Grid>
      ) : <Loading />}
      <UpdateDialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        onSubmit={onSubmitUpdate}
      />
      <BulkUpdateDialog
        open={bulkUpdateDialogOpen}
        onClose={() => setBulkUpdateDialogOpen(false)}
        onSubmit={() => {
          setBulkUpdateDialogOpen(false)
          setConfirmationDialogOpen(true)
        }}
      />
      <ConfirmationDialog
        destructive
        title={`UPDATE ALL ${otapNodeListDeviceTypeFilter.toUpperCase()}S?`}
        content={`Are you sure you want to update all ${otapNodeListDeviceTypeFilter.toLowerCase()}s across all facilities?`}
        confirmText="Yes, do it!"
        cancelText="Cancel"
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        onConfirm={doOtapSubmitBulkUpdates}
      />
    </>
  )
}

export default OtapNodeListComponent
