import React from 'react'
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  // TODO: VictoryCursorContainer,
} from 'victory'

import { useTheme } from '@material-ui/core/styles'
import { formatDateForChart, getTheme } from '../utils'

const Chart = props => {
  const {
    chart,
    availableMetrics,
    chartWidth,
    chartHeight
  } = props

  const theme = useTheme()

  return (
    <VictoryChart
      width={chartWidth}
      height={chartHeight}
      theme={getTheme(theme)}
      scale={{ x: 'time' }}
      padding={{ top: 10, bottom: 30, left: 20, right: 40 }}

    >
      <VictoryAxis />
      <VictoryAxis
        dependentAxis
        orientation="right"
        offsetX={40}
        style={{
          ticks: { stroke: '#fff', size: 3 }
        }}
      />
      {chart.filter(metric => availableMetrics.includes(metric.label))
        .map(graph => {
          const color = graph.color
          return (
            <VictoryLine
              key={graph.label}
              style={{
                data: { stroke: color, strokeWidth: 1 },
              }}
              data={formatDateForChart(graph.data)}
            />
          )
        })}
    </VictoryChart>
  )
}

export default Chart
