import { CHART_PADDING, SIDEBAR_WIDTH } from './utils'

const MOBILE_LANDSCAPE_CHART_HEIGHT = 'calc(100vh - 28px)'

export const emptyStyle = {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  width: '100%',
  height: '100%',
  '& > :not(button)': {
    width: '100%',
  },
  '& > h6': {
    padding: '10px 0',
  },
  '& > svg': {
    fontSize: 72,
    marginBottom: 0,
  }
}

const fiftyPercent = {
  minHeight: '50vh',
  maxHeight: '50vh',
}

export default theme => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: '25vh',
    position: 'relative',
    '&:not([data-loading="true"]):hover $chartCursor': {
      visibility: 'visible',
    },
    '&[data-loading="true"] $loader': {
      display: 'flex',
    },
    '&[data-loading="true"] $mainGraph': {
      display: 'none',
    },
    '&$sidebarOpen $content': {
      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    },
    '&$sidebarOpen $sidebar': {
      height: '100%',
      width: SIDEBAR_WIDTH,
      opacity: 1,
      paddingLeft: 24,
    },
    [`${theme.breakpoints.down('md')} and (max-aspect-ratio: 3/4)`]: fiftyPercent,
    [`${theme.breakpoints.down('sm')} and (min-aspect-ratio: 4/3)`]: {
      minHeight: MOBILE_LANDSCAPE_CHART_HEIGHT,
      maxHeight: MOBILE_LANDSCAPE_CHART_HEIGHT,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(),
      minHeight: '60vh',
      maxHeight: '60vh',
    },
    '&$growlog': {
      minHeight: '40vh',
      maxHeight: '40vh',
      [`${theme.breakpoints.down('sm')} and (min-aspect-ratio: 4/3)`]: {
        minHeight: MOBILE_LANDSCAPE_CHART_HEIGHT,
        maxHeight: MOBILE_LANDSCAPE_CHART_HEIGHT,
      },
      [theme.breakpoints.up('xl')]: fiftyPercent,
    }
  },
  growlog: {},
  sidebarOpen: {},
  content: {
    width: '100%',
    height: '100%',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  empty: {
    color: theme.meter.white,
    ...emptyStyle,
  },
  loader: {
    ...emptyStyle,
    display: 'none',
  },
  sidebar: {
    position: 'absolute',
    right: 0,
    top: theme.spacing(5),
    width: 0,
    opacity: 0,
    transition: `${theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })}, ${theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeIn,
      delay: theme.transitions.duration.leavingScreen / 2,
      duration: theme.transitions.duration.leavingScreen,
    })}`,
  },
  mainGraph: {
    pointerEvents: 'none',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    pointerEvents: 'none',
  },
  mainGraphTitle: {
    position: 'absolute',
    '& p:not(:nth-last-child(n+1)):after': {
      content: '", "',
    },
    '& p:not(:first-child):nth-last-child(2):after': {
      content: '" and "',
    },
  },
  yAxisLabel: {
    position: 'absolute',
    fontSize: 12,
    width: theme.spacing(6.25),
    textAlign: 'center',
  },
  xAxis: {
    stroke: theme.meter.text.disabled,
    strokeWidth: 2,
  },
  chartCursor: {
    pointerEvents: 'none',
    position: 'absolute',
    top: CHART_PADDING.top + theme.spacing(1.25),
    zIndex: 7,
    borderLeft: `2px solid ${theme.meter.grey}`,
    visibility: 'hidden',
    [theme.breakpoints.only('sm')]: {
      top: CHART_PADDING.top + theme.spacing(0.25),
    },
    [theme.breakpoints.down('xs')]: {
      top: CHART_PADDING.top - theme.spacing(1.5),
      bottom: CHART_PADDING.bottom + theme.spacing(3),
    },
    // data flags
    '& $label': {
      ...theme.typography.body2,
      position: 'absolute',
      left: 4,
      width: 'max-content',
      padding: theme.spacing(0.5),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      backgroundColor: theme.meter.background.four,
      borderRadius: theme.meter.dimensions.defaults.borderRadius,
      transform: 'translateY(50%)',
      transition: theme.transitions.create(['bottom'], {
        easing: theme.transitions.easing.easeIn,
        duration: 16,
      }),
      '& > span:not(:first-child)': {
        display: 'table',
      },
    },
    '&$chartCursorAddOpen': {
      visibility: 'visible',
      '& $labels': {
        opacity: 0,
      }
    },
    // add button
    '& > $addAnnotation': {
      position: 'absolute',
      top: 0,
      height: '100%',
      '& > [class*=MuiSpeedDial-root] > [class*=MuiFab-root]': {
        transformOrigin: 'top left',
        transform: 'translate(-50%)',
        width: 32,
        height: 32,
        minHeight: 'auto',
      },
      '& > [class*=MuiSpeedDial-root]': {
        position: 'absolute',
        left: -1,
        bottom: 0,
        transform: 'translate(-50%, 16px)',
        pointerEvents: 'auto',
        alignItems: 'center',
        '& [class*=MuiSvgIcon-root]': {
          fontSize: 18,
        }
      },
      '& [class*=MuiSpeedDial-actionsClosed]': {
        height: 0,
      },
      '& > $cursorTimestamp': {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 'max-content',
        lineHeight: 1.25,
        textAlign: 'center',
        transform: 'translate(-50%, -100%)',
        padding: theme.spacing(0.25, 0.5),
        background: theme.meter.background.four,
        boxShadow: theme.shadows[1],
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.9em',
          top: 8,
        }
      },
      // mobile landscape
      [`${theme.breakpoints.down('sm')} and (min-aspect-ratio: 4/3)`]: {
        '&$addAnnotationRight [class*=MuiSpeedDial-actions]': {
          position: 'absolute',
          transform: 'translate(48px, 72px)'
        },
        '&$addAnnotationLeft [class*=MuiSpeedDial-actions]': {
          position: 'absolute',
          transform: 'translate(-48px, 72px)'
        },
      }
    },
    '& $labelsTruncated $label:after': {
      position: 'absolute',
      display: 'flex',
      content: '"\u2026"',
      bottom: 0,
      left: 0,
      right: 0,
      height: 16,
      lineHeight: '8px',
      width: '100%',
      justifyContent: 'center',
      verticalAlign: 'middle',
      zIndex: 1000,
      backgroundColor: theme.meter.background.four,
    }
  },
  chartCursorAddOpen: {},
  addAnnotation: {},
  addAnnotationRight: {},
  addAnnotationLeft: {},
  cursorTimestamp: {},
  label: {},
  labels: {
    opacity: 1,
    transition: theme.transitions.create(['opacity'], { delay: 100 }),
  },
  labelsTruncated: {},
})
