import React from 'react'
import PropTypes from 'prop-types'
import { dtLabelSanitizer, EMPTY_OBJECT } from '~/Lib/Utils'
import { stylePropType } from '~/Lib/PropTypes'

const defaultStyle = {
  textTransform: 'none',
}
const DataTypeName = ({ name, style }) => (
  <span
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: dtLabelSanitizer(name) }}
    style={{ ...style, ...defaultStyle }}
  />
)

DataTypeName.propTypes = {
  name: PropTypes.string.isRequired,
  style: stylePropType,
}

DataTypeName.defaultProps = {
  style: EMPTY_OBJECT,
}

export default React.memo(DataTypeName)
