import React from 'react'

import {
  Grid,
  IconButton,
  Paper,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const Paginator = props => {
  const { data, isLoading, onChange } = props

  if (!data) return null

  const { count, current, next, previous } = data
  const pageSize = data.page_size || data.pageSize

  return (
    <Paper style={{ marginTop: '5px' }}>
      <Grid container justify="space-between" alignItems="center" style={{ marginTop: '5px' }}>
        <Grid item>
          <IconButton
            disabled={!previous || isLoading}
            onClick={() => onChange(previous)}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
        <Grid item>
          { (current - 1) * pageSize + 1 } to { Math.min(current * pageSize, count) } of { count }
        </Grid>
        <Grid item>
          <IconButton
            disabled={!next || isLoading}
            onClick={() => onChange(next)}
          >
            <ChevronRightIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default React.memo(Paginator)
