import {
  pipe,
  prop,
} from 'ramda'
import {
  // createRouteBundle,
  createSelector,
} from 'redux-bundler'

import {
  camelize,
  singularize,
} from 'inflection'

import {
  debounce,
  defer,
  EMPTY_ARRAY,
} from '~/Lib/Utils'

// import routes from './routes'

export const OPEN_DIALOG = 'OPEN_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'
export const SET_DIALOG_ENTITY = 'SET_DIALOG_ENTITY'
export const CLEAR_DIALOG_ENTITY = 'CLEAR_DIALOG_ENTITY'

const defaultState = Object.freeze({ isOpen: false, stack: EMPTY_ARRAY, entity: null })
const debouncedDoOpen = debounce(({ dispatch }) => dispatch({ type: OPEN_DIALOG }), 150, true)
const debouncedDoClose = debounce(({ dispatch }) => dispatch({ type: CLOSE_DIALOG }), 150, true)
const getUUID = ({ schema, id }) => [schema, id].join('_')

// const routingBundle = Object.entries(createRouteBundle(routes, { routeInfoSelector: 'selectHash' }))
//   .reduce((acc, [key, value]) => (key.startsWith('select') ? {
//     ...acc,
//     [key.replace('select', 'selectDialog')]: value
//   } : acc), {})

export default {
  // ...routingBundle,
  name: 'dialog',
  reducer: (state = defaultState, action = {}) => {
    switch (action.type) {
      case OPEN_DIALOG:
        return { ...state, isOpen: true }
      case CLOSE_DIALOG:
        return { ...state, isOpen: false }
      case SET_DIALOG_ENTITY:
        return { ...state, entity: action.payload }
      case CLEAR_DIALOG_ENTITY:
        return { ...state, entity: defaultState.entity }
      default:
        return state
    }
  },
  selectDialog: prop('dialog'),
  selectDialogIsOpen: createSelector('selectDialog', pipe(prop('isOpen'), Boolean)),
  // selectDialogRouteParams: createSelector('selectDialogRouteInfo', prop('params')),
  // selectDialogRoute: createSelector('selectDialogRouteInfo', prop('page')),
  selectDialogEntity: createSelector('selectDialog', prop('entity')),
  doOpenDialog: () => debouncedDoOpen,
  doCloseDialog: () => debouncedDoClose,
  doCloseDialogRoute: () => ({ store, dispatch }) => {
    dispatch({ actionCreator: 'doUpdateHash', args: [''] })
    defer(() => {
      const {
        dialogEntity: entity,
        dialogRouteInfo
      } = store.select(['selectDialogEntity', 'selectDialogRouteInfo'])
      const uuid = dialogRouteInfo?.params ? getUUID(dialogRouteInfo?.params) : null
      if (entity && uuid !== entity) {
        const [schema] = entity.split('_')
        const actionName = `do${camelize(singularize(schema))}SetCurrent`
        dispatch({ actionCreator: actionName, args: [null] })
        dispatch({ type: CLEAR_DIALOG_ENTITY })
      }
    }, defer.priorities.low)
  },
  doSetDialogEntity: payload => ({ store, dispatch }) => {
    dispatch({ type: SET_DIALOG_ENTITY, payload })
    const entities = store.selectEntities()
    const [schema, idString] = payload.split('_')
    const id = Number(idString)

    if (entities[payload]) {
      const setActionName = `do${camelize(singularize(schema))}SetCurrent`
      dispatch({ actionCreator: setActionName, args: [id] })
    }

    const fetchActionName = `do${camelize(singularize(schema))}Fetch`
    dispatch({ actionCreator: fetchActionName, args: [id] })
  },
  // reactSetCurrentEntity: createSelector(
  //   'selectDialogRouteInfo',
  //   'selectDialogEntity',
  //   (info, currentUUID) => {
  //     if (info && info.params) {
  //       const { params } = info
  //       if (params.id && params.schema) {
  //         const nextUUID = getUUID(params)
  //         return nextUUID !== currentUUID
  //           ? { actionCreator: 'doSetDialogEntity', args: [nextUUID] }
  //           : undefined
  //       }
  //     }
  //     return undefined
  //   }
  // )
}
