import React, { useEffect, useState } from 'react'
import { useConnect } from 'redux-bundler-hook'

import classNames from 'clsx'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  InputAdornment,
  List,
  ListItem,
  Paper,
  Popover,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import SingleSelect from '~/UI/Shared/Form/SingleSelect'
import SearchField from '~/UI/Shared/Form/SearchField'
import MuiTitle from '~/UI/Shared/MuiTitle'
import Loading from '~/UI/Shared/utils'
import Icon from '~/UI/Shared/Icon'

import UserList from './list'
import SolusUsersList from './solus/solusList'

const USERS = 'USERS'
const SOLUS = 'SOLUS'

const useStyles = makeStyles(theme => ({
  button: {
    padding: '5px 10px',
    margin: '10px'
  },
  selected: {
    color: theme.meter.blue.light,
    textDecoration: 'underline',
  },
  marginLeftSmall: {
    marginLeft: '3rem'
  }
}))

const SortBy = ({ isLoading, getSort, setSort }) => (
  <FormControl>
    <InputLabel>Sort By</InputLabel>
    <Select
      value={getSort() || 'last_name'}
      onChange={setSort}
      disabled={isLoading}
    >
      <MenuItem value="last_name">NAME</MenuItem>
      <MenuItem value="email">EMAIL</MenuItem>
    </Select>
  </FormControl>
)

export const Users = () => {
  const classes = useStyles()
  const {
    isGuestStaff,
    userListSort,
    userListSearch,
    solusUserListSort,
    solusUserListSearch,
    currentUserList,
    userListIsLoading,
    userListFilters,
    solusUserListFilters,
    solusUsersListIsLoading,
    doUserSave,
    doUserListSetSort,
    doUserListSetSearch,
    doUserListSetFilters,
    doUserListSetPage,
    doSolusUsersListSetSearch,
    doSolusUsersListSetSort,
    doSolusUserListSetPage,
    doSolusUsersListSetFilters,
  } = useConnect(
    'selectIsGuestStaff',
    'selectUserListSort',
    'selectUserListSearch',
    'selectUserListFilters',
    'selectCurrentUserList',
    'selectUserListIsLoading',
    'selectSolusUserListSort',
    'selectSolusUserListSearch',
    'selectSolusUserListFilters',
    'selectSolusUsersListIsLoading',
    'doUserSave',
    'doUserListSetSort',
    'doUserListSetSearch',
    'doUserListSetFilters',
    'doUserListSetPage',
    'doSolusUsersListSetSearch',
    'doSolusUsersListSetSort',
    'doSolusUserListSetPage',
    'doSolusUsersListSetFilters',
  )

  const [createUserFormOpen, setCreateUserFormOpen] = useState(false)
  const [displayList, setDisplayList] = useState(USERS)
  const [anchorEl, setAnchorEl] = useState(null)
  const filterPopoverOpen = Boolean(anchorEl)

  const displayingUsers = displayList === USERS
  const displayingSolusUsers = displayList === SOLUS
  const isLoading = displayingUsers ? userListIsLoading : solusUsersListIsLoading

  const getSort = () => {
    if (displayingSolusUsers) return solusUserListSort
    return userListSort
  }

  const setSort = e => {
    if (displayingUsers) {
      doUserListSetSort(e.target.value)
      doUserListSetPage(1)
    }
    if (displayingSolusUsers) {
      doSolusUsersListSetSort(e.target.value)
      doSolusUserListSetPage(1)
    }
  }

  const getSearch = () => {
    if (displayingSolusUsers) return solusUserListSearch
    return userListSearch
  }

  const setSearch = searchTerms => {
    if (displayingUsers) doUserListSetSearch(searchTerms)
    if (displayingSolusUsers) doSolusUsersListSetSearch(searchTerms)
  }

  const handleFilterChanged = val => {
    if (displayingUsers) {
      doUserListSetPage(1)
      doUserListSetFilters({ isSolusUser: val })
    } else {
      doSolusUserListSetPage(1)
      doSolusUsersListSetFilters({ growType: val })
    }
  }

  useEffect(() => {
    setSearch('')
  }, [displayList])

  if (!currentUserList) { return <Loading /> }

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item style={{ flexGrow: 1 }}>
          <MuiTitle title={displayingUsers ? 'Users' : 'Solus Users'} />
        </Grid>
        {!isGuestStaff && displayingUsers && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCreateUserFormOpen(true)}
              disabled={isLoading}
            >
              Add AROYA Staff
            </Button>
          </Grid>
        )}
        <Grid item style={{ marginTop: '-30px' }}>
          <SortBy isLoading={isLoading} getSort={getSort} setSort={setSort} />
        </Grid>
        <Grid item style={{ flexBasis: '300px', marginTop: '-30px' }}>
          <SearchField
            fullWidth
            label="Search"
            value={getSearch()}
            onEmpty={() => setSearch('')}
            onChange={({ searchTerms }) => setSearch(searchTerms)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon={SearchIcon} />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <>
          <Button
            size="small"
            color="primary"
            onClick={event => setAnchorEl(event.target)}
            disabled={isLoading}
          >
            Filter by
            <Icon icon={ExpandMoreIcon} />
          </Button>
          <Popover
            open={filterPopoverOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            <Paper style={{ minWidth: '350px', padding: '8px' }}>
              <List>
                <ListItem>
                  <Grid
                    container
                    spacing={2}
                    wrap="nowrap"
                    alignItems="center"
                    justify="space-between"
                  >
                    {displayingUsers ? (
                      <>
                        <Typography variant="body1">Has Solus Profile</Typography>
                        <SingleSelect
                          color="primary"
                          options={[
                            { label: 'Either', value: null },
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                          ]}
                          value={userListFilters.isSolusUser}
                          onChange={handleFilterChanged}
                        />
                      </>
                    ) : (
                      <>
                        <Typography variant="body1">Grow Type</Typography>
                        <SingleSelect
                          color="primary"
                          options={[
                            { label: 'All', value: null },
                            { label: 'Home Grower', value: 'Home Grower' },
                            { label: 'Commercial', value: 'Commercial' },
                          ]}
                          value={solusUserListFilters.growType}
                          onChange={handleFilterChanged}
                        />
                      </>
                    )}
                  </Grid>
                </ListItem>
              </List>
            </Paper>
          </Popover>
        </>
        <Button
          size="small"
          color="primary"
          className={classNames({
            [classes.button]: true,
            [classes.marginLeftSmall]: true,
            [classes.selected]: displayingUsers,
          })}
          onClick={() => setDisplayList(USERS)}
          disabled={isLoading}
        >
          Users
        </Button>
        <Button
          size="small"
          color="primary"
          className={classNames({
            [classes.button]: true,
            [classes.selected]: displayingSolusUsers,
          })}
          onClick={() => setDisplayList(SOLUS)}
          disabled={isLoading}
        >
          Solus
        </Button>
      </Grid>
      {displayingUsers && (
        <UserList
          currentUserList={currentUserList}
          saveCreatedUser={doUserSave}
          createUserFormOpen={createUserFormOpen}
          setCreateUserFormOpen={setCreateUserFormOpen}
        />
      )}
      {displayingSolusUsers && (
        <SolusUsersList />
      )}
    </>
  )
}

export default Users
