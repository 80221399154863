import React, { useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryAxis,
  VictoryVoronoiContainer,
} from 'victory'

import { formatDate } from '~/UI/Shared/utils'
import { DeviceChartStyles as styles } from './styles'

const useStyles = makeStyles(styles)

export const DataLineChart = props => {
  const classes = useStyles()
  const theme = useTheme()
  const barChartRef = useRef(null)
  const { currentDevice } = props
  const [barWidth, setBarWidth] = useState(null)
  const [barHeight, setBarHeight] = useState(null)
  const [data, setData] = useState(null)

  const getTheme = useMemo(() => {
    const style = { ...VictoryTheme.material }
    style.bar.style.data.fill = theme.meter.blue.main
    style.bar.style.data.strokeWidth = 0
    style.axis.style.grid.stroke = 'none'
    return style
  }, [])

  useEffect(() => {
    const { dataArray } = currentDevice
    const pvData = []
    dataArray.forEach(reading => {
      const point = {}
      if (reading.data[0].rawData.match(/(?<=PV:)\d+/)) {
        point.x = new Date(`${reading.data[0].ts} UTC`)
        point.y = Number(reading.data[0].rawData.match(/(?<=PV:)\d+/)[0])
        pvData.push(point)
      }
    })
    setData(pvData)
  }, [currentDevice])

  useEffect(() => {
    if (barChartRef.current) {
      setBarHeight(barChartRef.current.offsetHeight)
      setBarWidth(barChartRef.current.offsetWidth)
    }
  }, [])

  return (
    <div className={classes.chartRoot}>
      <div ref={barChartRef} className={classes.mainChart}>
        {barWidth && barHeight ? (
          <VictoryChart
            scale={{ x: 'time' }}
            theme={getTheme}
            height={barHeight}
            width={barWidth}
            containerComponent={(
              <VictoryVoronoiContainer
                labels={d => `${formatDate(d.x)}, PV: ${d.y}`}
              />
            )}
          >
            <VictoryLine
              style={{
                data: { stroke: theme.meter.blue.main }
              }}
              data={data}
            />
            <VictoryAxis />
            <VictoryAxis
              dependentAxis
              tickFormat={t => Math.round(t)}
            />
          </VictoryChart>
        ) : null}
      </div>
    </div>
  )
}

DataLineChart.propTypes = {
  currentDevice: PropTypes.shape({
    dataArray: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
}
