import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import { EMPTY_OBJECT } from '~/Lib/Utils'
import MuiSelectField from '~/UI/Shared/Form/SelectField'

const MetrcSort = ({ tabValue }) => {
  const {
    plantListSort,
    plantBatchListSort,
    harvestBatchListSort,
    packageListSort,
    doPlantListSetSort,
    doPlantBatchListSetSort,
    doHarvestBatchListSetSort,
    doPackageListSetSort,
  } = useConnect(
    'selectPlantListSort',
    'selectPlantBatchListSort',
    'selectHarvestBatchListSort',
    'selectPackageListSort',
    'doPlantListSetSort',
    'doPlantBatchListSetSort',
    'doHarvestBatchListSetSort',
    'doPackageListSetSort',
  )

  const sharedSortFields = [
    { label: 'Cultivar', value: 'cultivar_name' },
  ]

  const sortFields = {
    PLANTS: [
      { label: 'Label', value: 'label' },
      ...sharedSortFields,
      { label: 'Growth Phase', value: 'phase' },
      { label: 'State', value: 'plant_state' },
      { label: 'Location', value: 'room_name' },
      { label: 'Planted', value: 'planted_date' },
      { label: 'Harvested', value: 'harvested_date' },
      { label: 'Destroyed', value: 'date_destroyed' },
    ],
    PLANT_BATCHES: [
      { label: 'Label', value: 'label' },
      ...sharedSortFields,
      { label: 'Plant Type', value: 'plant_type' },
      { label: 'Initial Count', value: 'initial_count' },
      { label: 'Untracked Count', value: 'untracked_count' },
      { label: 'Planted', value: 'planted_date' },
    ],
    HARVEST_BATCHES: [
      { label: 'Name', value: 'name' },
      ...sharedSortFields,
      { label: 'Location', value: 'room_name' },
      { label: 'Current Weight', value: 'current_harvest_weight' },
      { label: 'Harvest Date', value: 'date_harvested' },
      { label: 'Finished Date', value: 'finished_date' },
    ],
    PACKAGES: [
      { label: 'Label', value: 'label' },
      ...sharedSortFields,
      { label: 'Location', value: 'room_name' },
      { label: 'Quantity', value: 'current_package_quantity' },
      { label: 'Finished Date', value: 'finished_date' },
      { label: 'Packaged Date', value: 'packaged_date' },
    ],
  }

  const getSort = () => {
    switch (tabValue.toUpperCase()) {
      case 'PLANTS':
        return sortFields[tabValue].find(opt => opt.value === plantListSort)
      case 'PLANT_BATCHES':
        return sortFields[tabValue].find(opt => opt.value === plantBatchListSort)
      case 'HARVEST_BATCHES':
        return sortFields[tabValue].find(opt => opt.value === harvestBatchListSort)
      case 'PACKAGES':
        return sortFields[tabValue].find(opt => opt.value === packageListSort)
      default:
        return EMPTY_OBJECT
    }
  }

  const setSort = value => {
    switch (tabValue) {
      case 'PLANTS':
        return doPlantListSetSort(value)
      case 'PLANT_BATCHES':
        return doPlantBatchListSetSort(value)
      case 'HARVEST_BATCHES':
        return doHarvestBatchListSetSort(value)
      case 'PACKAGES':
        return doPackageListSetSort(value)
      default:
        return Promise.resolve(null)
    }
  }

  return (
    <div>
      <MuiSelectField
        fullWidth
        placeholder="Sort By"
        color="primary"
        options={sortFields[tabValue]}
        value={getSort()}
        onChange={({ value }) => setSort(value)}
      />
    </div>
  )
}

export default MetrcSort
