import React from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import DeviceUnknownIcon from '@material-ui/icons/DeviceUnknown'
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord'
import RouterIcon from '@material-ui/icons/Router'
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna'

const DeviceIcon = props => {
  const { online, noderole, ...iconProps } = props

  let Icon
  let helpText

  switch (noderole) {
    case 'node':
    case 'endnode':
      Icon = FiberSmartRecordIcon
      helpText = 'End Node'
      break
    case 'repeater':
    case 'router':
      Icon = DeviceHubIcon
      helpText = 'Router'
      break
    case 'sink':
      Icon = SettingsInputAntennaIcon
      helpText = 'Sink'
      break
    case 'gateway':
      Icon = RouterIcon
      helpText = 'Gateway'
      break
    default:
      Icon = DeviceUnknownIcon
      helpText = 'Role Unknown'
      break
  }

  switch (online) {
    case true:
      iconProps.color = 'primary'
      break
    case false:
      iconProps.style = { color: 'yellow' }
      helpText += ' (offline)'
      break
    default:
      break
  }

  return <Tooltip title={helpText}><Icon {...iconProps} /></Tooltip>
}

export default React.memo(DeviceIcon)
