import memoizeOne from 'memoize-one'
import {
  clamp,
  groupBy,
  map,
  mean,
  prop,
  sort,
} from 'ramda'

import * as ms from 'milliseconds'

import {
  compileTemplate,
  EMPTY_ARRAY,
  getCollidesWith,
  memoize,
} from '~/Lib/Utils'

import {
  CHART_ID,
  getBounds,
  getNormalized,
  getPaddingForChart,
  GRAPH_X_AXIS_CLASSNAME,
  SIDEBAR_WIDTH,
} from '../utils'

const EMPTY_DT_DATA = {
  labels: EMPTY_ARRAY,
  collisions: EMPTY_ARRAY,
}

const labelHeight = 18
const labelPadding = 8
const labelLength = label => {
  if (label.colliding) {
    return label.colliding.length * labelHeight + labelPadding
  }
  return labelHeight + labelPadding
}
const collidesWith = getCollidesWith(
  label => label.bottom - labelLength(label) / 2,
  labelLength
)

const MAX_INDIVIDUAL_LABELS = 9

const groupByDate = groupBy(({ x }) => x && x.split('T').shift())
export const getGroupedData = memoizeOne(data => map(groupByDate, data))

export const getTrueHeight = memoizeOne(props => {
  const { height } = props
  const { top, bottom } = getPaddingForChart(props)
  return height - (top + bottom)
})

const bottomGetter = prop('bottom')
const collisionReducer = (acc, label, _, labels) => {
  let alreadyGrouped
  const prev = acc.find(set => {
    alreadyGrouped = set.colliding.find(l => l.id === label.id)
    const collidesWithGroup = collidesWith(label, set)

    return alreadyGrouped || collidesWithGroup
  })

  if (prev) {
    if (!alreadyGrouped) {
      prev.colliding.push(label)
      prev.bottom = mean(prev.colliding.map(bottomGetter))
    }
    return acc
  }

  const colliding = labels.filter(
    innerLabel => label !== innerLabel && collidesWith(label, innerLabel)
  )
  if (colliding.length) {
    colliding.unshift(label)

    return [
      ...acc,
      {
        colliding,
        bottom: mean(colliding.map(bottomGetter)),
      },
    ]
  }
  return acc
}

const bottomSort = sort((a, b) => a.bottom - b.bottom)
export const getDataForDt = memoize((dt, props) => {
  const { data, dataInterval } = props
  let { graphs } = props
  graphs = graphs.map(graph => {
    if (!graph.balloonTemplate) return graph
    return {
      ...graph,
      balloonTemplate: compileTemplate(graph.balloonTemplate),
    }
  })
  if (!dt?.valueOf()) return EMPTY_DT_DATA
  const bounds = getBounds(props)
  const height = getTrueHeight(props)
  const dateStr = dt.toISOString()
  const dateKey = dateStr?.split('T').shift()
  const dateInt = dt.getTime()
  const grouped = getGroupedData(data)

  // labels are sorted by bottom
  let labels = bottomSort(graphs?.reduce?.(
    (acc, {
      id,
      lineColor: color,
      balloonTemplate,
      unit,
    }) => {
      const sorted = sort(
        (a, b) => Math.abs(new Date(a.x) - dateInt) - Math.abs(new Date(b.x) - dateInt),
        (grouped?.[id]?.[dateKey] ?? EMPTY_ARRAY).filter(({ y }) => y != null)
      ).slice(0, 6)

      const [match] = sorted
      const msDiff = match ? Math.abs(dateInt - new Date(match.x)) : Infinity

      if (!match || match.y === null || msDiff > ms.minutes(dataInterval) * 1.5) return acc
      const { x, y: value } = match
      const y = getNormalized(value, bounds[unit])
      const bottom = y * height

      return [
        ...acc,
        {
          value,
          x: +new Date(x),
          y,
          id,
          balloonTemplate,
          color,
          bottom,
        },
      ]
    },
    EMPTY_ARRAY
  ) ?? EMPTY_ARRAY)

  let collisions = EMPTY_ARRAY
  const totalHeight = labelPadding + labels.length * labelHeight

  if (labels.length <= MAX_INDIVIDUAL_LABELS) {
    collisions = labels.reduce(collisionReducer, EMPTY_ARRAY)
    if (collisions.length) {
      collisions = labels
        .slice()
        .reverse()
        .reduce(collisionReducer, collisions)
    }
  } else {
    const calculatedBottom = mean(labels.map(bottomGetter))
    const middle = height / 2
    const heightDiff = (height - Math.min(height, totalHeight)) / 2
    const bottom = clamp(middle - heightDiff, middle + heightDiff, calculatedBottom)

    collisions = [
      {
        colliding: labels,
        bottom,
        truncated: totalHeight > height,
      },
    ]
    labels = EMPTY_ARRAY
  }

  if (collisions !== EMPTY_ARRAY) {
    collisions.forEach(({ colliding }) => colliding.sort((a, b) => b.y - a.y))
  }

  return {
    labels,
    collisions,
  }
})

const getDefaultLabelStyles = props => ({
  maxHeight: getTrueHeight(props)
})

export const getCursorHandler = memoizeOne(({
  cursor,
  setCursorState,
  props,
  mounted,
  addOpen
}) => {
  let timeoutId = null
  const xAxisLine = document.querySelector(`.${GRAPH_X_AXIS_CLASSNAME}`)
  const chart = document.getElementById(CHART_ID)
  const bottom = (chart?.getBoundingClientRect()?.bottom ?? 0) - (xAxisLine?.getBoundingClientRect()?.bottom ?? 0)
  cursor.style.bottom = `${bottom || 0}px`
  return event => {
    if (addOpen.current) return
    const { clientX, clientY } = event
    const {
      top,
      left,
      width: originalWidth,
      sidebarOpen,
      xScale,
    } = props.current
    const {
      left: paddingLeft,
      right: paddingRight,
    } = getPaddingForChart(props.current)

    let outOfBounds = false
    let dt = null
    let x = clientX - left
    const y = clientY - top

    const width = sidebarOpen
      ? originalWidth - paddingRight - SIDEBAR_WIDTH
      : originalWidth - paddingRight

    if (x - paddingLeft < 0 || x > width) {
      outOfBounds = true
      x = 0
    }
    if (!outOfBounds) {
      if (timeoutId) {
        cancelAnimationFrame(timeoutId)
      }
      // eslint-disable-next-line no-param-reassign
      cursor.style.left = `${x}px`

      dt = xScale?.invert?.(x)
      const style = x > ((width + paddingLeft) / 2)
        ? {
          transform: 'translate(calc(-100% - 8px), 50%)',
          maxHeight: getTrueHeight(props.current)
        }
        : getDefaultLabelStyles(props.current)

      timeoutId = requestAnimationFrame(() => {
        timeoutId = null
        if (mounted.current) setCursorState({ x, y, dt, style })
      })
    }
  }
})
