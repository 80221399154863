import React from 'react'

import { renderableType } from '~/Lib/PropTypes'

const IconComponent = ({ icon, ...props }) => {
  if (
    typeof icon === 'object'
    && (
      icon?.$$typeof === Symbol.for('react.element')
      || icon?.$$typeof === Symbol.for('react.fragment')
    )
  ) {
    return React.cloneElement(icon, { color: 'primary', ...props })
  }

  return React.createElement(icon, { color: 'primary', ...props })
}

IconComponent.propTypes = {
  icon: renderableType.isRequired,
}

export default IconComponent
