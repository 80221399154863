import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { datePropType } from '~/Lib/PropTypes'
import { DevicePacketStyles as styles } from './styles'

const useStyles = makeStyles(styles)

export const Packets = props => {
  const classes = useStyles()
  const { currentDevice } = props

  return (
    <div className={classes.packetRoot}>
      {currentDevice.status ? (
        <div className={classes.pStatus}>
          <pre style={{ padding: '0 1rem 0', color: 'white', fontSize: '14px' }}>
            Status: {JSON.stringify(currentDevice.status, null, 2)}
          </pre>
        </div>
      ) : null}
      {currentDevice.data ? (
        <div className={classes.pData}>
          <pre style={{ padding: '0 1rem 0', color: 'white', fontSize: '14px' }}>
            Data: {JSON.stringify(currentDevice.data, null, 2)}
          </pre>
        </div>
      ) : null}
      {currentDevice.sys ? (
        <div className={classes.pSys}>
          <pre style={{ padding: '0 1rem 0', color: 'white', fontSize: '14px' }}>
            Sys: {JSON.stringify(currentDevice.sys, null, 2)}
          </pre>
        </div>
      ) : null}
      {currentDevice.boot ? (
        <div className={classes.pBoot}>
          <pre style={{ padding: '0 1rem 0', color: 'white', fontSize: '14px' }}>
            Boot: {JSON.stringify(currentDevice.boot, null, 2)}
          </pre>
        </div>
      ) : null}
    </div>
  )
}

Packets.propTypes = {
  currentDevice: PropTypes.shape({
    node: PropTypes.shape({}),
    data: PropTypes.shape({
      beat: datePropType,
      data: PropTypes.arrayOf(PropTypes.shape({
        rawData: PropTypes.string,
        ts: datePropType,
      })),
      environment: PropTypes.string,
      gatewaySn: PropTypes.string,
      puckAddress: PropTypes.string,
      sink: PropTypes.string,
      travelTime: PropTypes.number,
    }),
    sys: PropTypes.shape({
      devVer: PropTypes.number,
      maintVer: PropTypes.number,
      majorVer: PropTypes.number,
      minorVer: PropTypes.number,
      fullVer: PropTypes.string,
      puckSn: PropTypes.string,
      interval: PropTypes.number,
      pvVer: PropTypes.number,
      dsStatus: PropTypes.number,
      otapSeq: PropTypes.number,
      fwInfo: PropTypes.string,
      ts: datePropType
    }),
    boot: PropTypes.objectOf(PropTypes.string),
    status: PropTypes.shape({}),
  })
}

Packets.defaultProps = {
  currentDevice: { node: null, data: null, sys: null, boot: null }
}
