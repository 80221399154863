import { createSelector } from 'redux-bundler'
import reduceReducers from 'reduce-reducers'

import createEntityBundle from '~/Lib/createEntityBundle'
import createListBundle from '~/Lib/createListBundle'
import { Solus as schema } from '~/Store/Schemas'

const SOLUS_USER_LIST_SET_PAGE = 'SOLUS_USER_LIST_SET_PAGE'
const SOLUS_USER_LIST_SET_SEARCH = 'SOLUS_USER_LIST_SET_SEARCH'
const SOLUS_USER_LIST_SET_FILTERS = 'SOLUS_USER_LIST_SET_FILTERS'

const initialState = {
  page: 1,
  search: '',
  sort: '',
  filters: {
    growType: null,
  },
}

export const solus = createEntityBundle({
  name: 'solusUsers',
  apiConfig: { schema },
})

const initialSolusList = createListBundle({
  entityName: 'solusUsers',
  schema,
  urlTest: url => url.startsWith('/users'),
  fetchHandler: ({ apiFetch, getState }) => {
    const { solusUsersList } = getState()
    const { page, filters, search, sort } = solusUsersList
    /* eslint-disable babel/camelcase */
    const payload = {
      page: page ?? 1,
      sort: sort ?? 'last_name',
      search,
      grow_type: filters.growType,
    }
    /* eslint-enable babel/camelcase */
    return apiFetch('/solus/', payload)
  }
})

export const solusList = {
  ...initialSolusList,
  reducer: reduceReducers(initialSolusList.reducer, (state, action) => {
    switch (action.type) {
      case SOLUS_USER_LIST_SET_PAGE:
        return { ...state, ...action.payload }
      case SOLUS_USER_LIST_SET_SEARCH:
        return { ...state, search: action.payload }
      case SOLUS_USER_LIST_SET_FILTERS:
        return { ...state, filters: { ...state.filters, ...action.payload } }
      default:
        if (!Object.keys(initialState).every(key => key in state)) {
          return { ...initialState, ...state }
        }
        return state
    }
  }),
  selectCurrentSolusUsersList: createSelector(
    'selectSolusUsers',
    'selectSolusUsersListRaw',
    (solusUsers, { data }) => data?.results?.map(id => solusUsers[id])
  ),
  selectSolusUserListPagination: createSelector(
    'selectSolusUsersList',
    ({ page, next, previous, numPages, pageSize, count }) => (
      { page, next, previous, numPages, pageSize, count }
    )
  ),
  selectSolusUserListFilters: createSelector(
    'selectSolusUsersListRaw',
    ({ filters }) => filters
  ),
  selectSolusUserListSort: createSelector(
    'selectSolusUsersListRaw',
    ({ sort }) => sort
  ),
  selectSolusUserListSearch: createSelector(
    'selectSolusUsersListRaw',
    ({ search }) => search
  ),
  doSolusUserListSetPage: page => ({ store, dispatch }) => {
    dispatch({ type: SOLUS_USER_LIST_SET_PAGE, payload: { page } })
    store.doMarkSolusUsersListAsOutdated()
  },
  doSolusUsersListSetSearch: searchTerms => ({ store, dispatch }) => {
    store.doSolusUserListSetPage(1)
    dispatch({ type: SOLUS_USER_LIST_SET_SEARCH, payload: searchTerms })
  },
  doSolusUsersListSetFilters: filters => ({ dispatch, store }) => {
    store.doSolusUserListSetPage(1)
    dispatch({ type: SOLUS_USER_LIST_SET_FILTERS, payload: filters })
  },
}
