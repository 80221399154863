import { prop } from 'ramda'

export const getId = prop('id')

let uniqueIdCounter = 0
export const uniqueId = prefix => {
  uniqueIdCounter += 1
  return String(prefix) + uniqueIdCounter
}

export const randomId = (length = 10) => [...crypto.getRandomValues(new Uint8Array(length))]
  .map(i => i.toString(36))
  .join('')

export const getClientId = () => {
  let clientId = sessionStorage.getItem('aroyaClientId')
  if (!clientId) {
    clientId = randomId()
    sessionStorage.setItem('aroyaClientId', clientId)
  }
  return clientId
}

export const uniqueChildIdFactory = (() => {
  const cache = {}
  let calls = 0
  return name => id => {
    const key = `${name}-${id}`
    const cached = cache[key]
      ? cache[key]
      : (cache[key] = {
        key,
        count: 0,
        ts: Date.now(),
      })
    cached.count += 1
    calls += 1
    if (calls % 100 === 0) {
      requestIdleCallback(
        () => {
          Object.entries(cache)
            .sort((a, b) => b.ts - a.ts)
            .slice(500)
            .forEach(entry => delete cache[entry.key])
        },
        { timeout: 2000 }
      )
    }
    return `${key}-${cached.count}`
  }
})()
