import React from 'react'
import PropTypes from 'prop-types'
import { omit } from 'ramda'

import Typography from '@material-ui/core/Typography'
import { formattedDate, useBreakpoint } from '~/Lib/Utils'
import { datePropType, renderableType } from '~/Lib/PropTypes'

const DefaultComponent = props => <Typography align="left" color="primary" component="span" {...props} />

const nativeOmitter = omit(['color', 'inline', 'variant'])

const defaultBreakpointFormats = {
  xs: formattedDate.SHORT_DATE,
}

export const FormattedDateComponent = props => {
  const breakpoint = useBreakpoint()
  const {
    label,
    date,
    locale,
    format = defaultBreakpointFormats[breakpoint],
    component,
    native,
    ...other
  } = props
  const Parent = component && native ? component : DefaultComponent

  return (
    <Parent component={native ? undefined : component} {...(native ? nativeOmitter(other) : other)}>
      {label && typeof label !== 'string' ? label : null}
      {label && typeof label === 'string' ? `${label}: ` : null}
      {formattedDate(date, locale, format)}
    </Parent>
  )
}

FormattedDateComponent.propTypes = {
  component: renderableType,
  date: datePropType.isRequired,
  format: PropTypes.shape({
    minute: PropTypes.string,
    hour: PropTypes.string,
    day: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
  }),
  label: renderableType,
  locale: PropTypes.string.isRequired,
  native: PropTypes.bool,
}

FormattedDateComponent.defaultProps = {
  component: null,
  format: undefined,
  label: null,
  native: false,
}

export default React.memo(props => {
  const connectedProps = {
    locale: 'en-US'
  }
  return <FormattedDateComponent {...connectedProps} {...props} />
})
