const drawerWidth = 240

export default theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    '& #menuRoot, & #modalRoot': {
      position: 'fixed',
      height: '100vh',
      width: '100vw',
      top: 0,
      left: 0,
    },
  },
  dialogOpen: {
    filter: 'blur(3px)',
  },
  mainHeader: {
    fontSize: '4rem',
  },
  logoutBtn: {
    position: 'fixed',
    top: '1rem',
    right: '1rem',
  },
  readProgress: {
    position: 'absolute',
    width: '100%',
    display: 'none',
  },
  writeProgress: {
    position: 'fixed',
    zIndex: 1400,
    bottom: 24,
    left: 24,
  },
  offlineIndicator: {
    position: 'fixed',
    right: '.25em',
    top: '1em',
    color: 'var(--state-danger, #db1f40)',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  appBar: {
    pointerEvents: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& button': {
      pointerEvents: 'auto',
    },
  },
  appBarShift: {
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }
  },
  menuButton: {
    margin: theme.spacing(0, 4.25, 0, 1.5),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: theme.spacing(),
    }
  },
  menuToggle: {
    paddingTop: theme.spacing(),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    position: 'sticky',
    top: 0,
    left: 0,
    overflow: 'hidden',
  },
  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
    }
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(6),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(9),
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    padding: theme.spacing(3),
    marginTop: '30px',
    width: `calc(100vw - ${theme.spacing(9)}px)`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  errorHeading: {
    marginTop: theme.spacing(),
    flex: '0 0 100%',
  },
  errorIcon: {
    color: theme.meter.orange.five,
  },
  maintenanceMessage: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '200px',
    fontFamily: 'Akkurat',
  },
  debugInfo: {
    height: 48,
    fontSize: '0.9em',
    lineHeight: 1.3,
    padding: theme.spacing(0.5, 1, 0.5, 0.5),
    position: 'fixed',
    top: 0,
    right: 0,
    flexGrow: 1,
    whiteSpace: 'pre-line',
    pointerEvents: 'none',
  },
})
