import { useConnect } from 'redux-bundler-hook'
import React, { useMemo, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import * as yup from 'yup'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Typography,
} from '@material-ui/core'

import { EMPTY_OBJECT } from '~/Lib/Utils'
import { FormDialog, TextField, ToggleField } from '~/UI/Shared/Form/FormDialog'
import TextSelect from '~/UI/Shared/Form/TextField'

const staffOptions = [
  { label: 'Staff', value: 'isStaff' },
  { label: 'Guest Staff', value: 'isGuestStaff' },
  { label: 'Not Staff', value: 'notStaff' },
]

const phoneRegExp = /^([+])\d{11}$/

const validationSchema = yup.object().shape({
  firstName: yup.string().max(30).required('Please enter a First Name'),
  lastName: yup.string().max(150).required('Please enter a Last Name'),
  phoneNumber: yup.string()
    .matches(phoneRegExp, '11 digit number prepended with +. eg. +15092223333'),
  email: yup.string().email('Please enter a valid email').required('Please enter an email address'),
  isActive: yup.boolean(),
  isStaff: yup.boolean(),
  isGuestStaff: yup.boolean(),
  userType: yup.string().required(),
})

const UserForm = props => {
  const {
    me,
    doResetPassword,
  } = useConnect(
    'selectMe',
    'doResetPassword',
  )

  const { instance = EMPTY_OBJECT, onClose } = props
  const [resetConfirmationOpen, setResetConfirmationOpen] = useState(false)

  const initialValues = useMemo(() => {
    let userType = 'isGuestStaff'
    if (instance.isStaff) userType = 'isStaff'
    if (instance.id && !instance.isStaff && !instance.isGuestStaff) userType = 'notStaff'

    return {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      isActive: true,
      isStaff: userType === 'isStaff',
      isGuestStaff: userType === 'isGuestStaff',
      userType,
      defaultRole: '',
      ...instance,
      flags: { ...me.flags.reduce((obj, { key }) => (
        instance && 'flags' in instance
          ? { ...obj, [key]: Boolean(instance.flags[key]) }
          : { ...obj, [key]: false }), {}) },
    }
  }, [instance, me.flags])

  const roleOptions = me.defaultRoles?.map(
    role => ({ label: role.name, value: role.id })
  ) ?? []
  roleOptions.unshift({ label: 'No Role', value: '' })

  const handleReset = obj => {
    doResetPassword(obj)
    setResetConfirmationOpen(false)
    onClose()
  }

  const resetButton = (
    <Button color="secondary" onClick={() => setResetConfirmationOpen(true)}>
      Reset Password
    </Button>
  )

  const StaffSelectController = () => {
    const { values, setFieldValue } = useFormikContext()
    React.useEffect(() => {
      if (values.userType === 'isStaff') {
        setFieldValue('isStaff', true)
        setFieldValue('isGuestStaff', false)
      }
      if (values.userType === 'isGuestStaff') {
        setFieldValue('isStaff', false)
        setFieldValue('isGuestStaff', true)
      }
      if (values.userType === 'notStaff') {
        setFieldValue('isStaff', false)
        setFieldValue('isGuestStaff', false)
      }
    }, [values, setFieldValue])
    return null
  }

  return (
    <>
      {resetConfirmationOpen ? (
        <Dialog
          open={resetConfirmationOpen}
          onClose={() => setResetConfirmationOpen(false)}
        >
          <DialogContent>
            <DialogContentText>
              Are you sure you want to reset the users password?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => setResetConfirmationOpen(false)}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={() => handleReset(instance)}
            >
              Reset
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <FormDialog
        fullScreen
        customAction={instance.isActive ? resetButton : null}
        formatInstance={obj => `${obj.firstName} ${obj.lastName}`}
        initialValues={initialValues}
        label="User"
        validationSchema={validationSchema}
        {...props}
      >
        <section data-testid="user-form" style={{ columns: 4, columnGap: '1.5rem' }}>
          <Field component={TextField} label="First Name" name="firstName" />
          <Field component={TextField} label="Last Name" name="lastName" />
          <Field component={TextField} label="Email" name="email" type="email" />
          <Field component={TextField} label="Phone Number" name="phoneNumber" />
          <Field name="userType">
            {({ field, form }) => (
              <TextSelect
                {...field}
                fullWidth
                select
                label="Staff Status"
                onChange={data => {
                  const { [field.name]: value } = data
                  form.setFieldValue(field.name, value)
                }}
              >
                {staffOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </TextSelect>
            )}
          </Field>
          <StaffSelectController />
          <Field component={ToggleField} label="Active" name="isActive" />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {me.flags.map(flag => (
              <div key={flag.key} style={{ breakInside: 'avoid-column' }}>
                <Field
                  className="mar-t-xs"
                  component={ToggleField}
                  label={(
                    <>
                      {flag.key}
                      <Typography component="span" display="block" variant="body2">
                        {flag.description}
                      </Typography>
                    </>
                  )}
                  name={`flags.${flag.key}`}
                />
              </div>
            ))}
          </div>
        </section>
      </FormDialog>
    </>
  )
}

export default UserForm
