import React, { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { CircularProgress, Grid } from '@material-ui/core'

import { ChartCard, ChecklistCard, NotificationsCard } from './Cards'
import ChartDialog from './chart'
import Picker from './picker'

const DashboardComponent = () => {
  const [currentChart, setCurrentChart] = useState(null)

  const {
    dashboard,
    dashboardIsLoading,
    facility,
    organization,
    routeParams,
    doClearDashboardChart,
    doDashboardChartClearParams,
    doDashboardChartSetData,
    doDashboardChartSetParams,
    doFacilityFetch,
    doFacilitySetCurrent,
    doMarkDashboardAsOutdated,
    doOrganizationFetch,
    doOrganizationSetCurrent,
    doClearPlantList,
    doClearPlantBatchList,
    doClearHarvestBatchList,
    doClearPackageList,
  } = useConnect(
    'selectDashboard',
    'selectDashboardIsLoading',
    'selectFacility',
    'selectOrganization',
    'selectRouteParams',
    'doClearDashboardChart',
    'doDashboardChartClearParams',
    'doDashboardChartSetData',
    'doDashboardChartSetParams',
    'doFacilityFetch',
    'doFacilitySetCurrent',
    'doMarkDashboardAsOutdated',
    'doOrganizationFetch',
    'doOrganizationSetCurrent',
    'doClearPlantList',
    'doClearPlantBatchList',
    'doClearHarvestBatchList',
    'doClearPackageList',
  )

  const { objType, id } = routeParams

  const clearMetrcLists = () => {
    doClearPlantList()
    doClearPlantBatchList()
    doClearHarvestBatchList()
    doClearPackageList()
  }

  useEffect(() => {
    if (objType === 'organization') {
      doOrganizationSetCurrent(id)
      doOrganizationFetch(id)
      doFacilitySetCurrent(null)
    } else if (objType === 'facility') {
      clearMetrcLists()
      doFacilitySetCurrent(id)
      doFacilityFetch(id)
      doOrganizationSetCurrent(null)
    }
    doMarkDashboardAsOutdated()
  }, [routeParams])

  const obj = objType === 'organization' ? organization : facility
  const facilities = objType === 'organization' ? obj.facilities : [obj.id]

  const isLoading = () => {
    if (!obj && obj.inflight) return true
    if (obj.payloadType !== 'entity' && obj.inflight) return true
    if (!dashboard && dashboardIsLoading) return true
    if (dashboard?.[objType] !== obj.id && dashboardIsLoading) return true
    return false
  }

  const handleCloseChart = () => {
    doClearDashboardChart()
    doDashboardChartClearParams()
    setCurrentChart(null)
  }

  const handleSetChart = chart => {
    doDashboardChartSetData(chart.data)
    setCurrentChart(chart)
    doDashboardChartSetParams({
      ...chart.params,
      facilities,
    })
  }

  if (isLoading()) {
    return (
      <Grid item style={{ flexGrow: 1 }} className="text-center">
        <CircularProgress className="text-center mar-t-xl" />
      </Grid>
    )
  }

  return (
    <>
      <ChartDialog
        chart={currentChart}
        onClose={handleCloseChart}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Picker />
        </Grid>

        {objType && (
          <>
            <Grid item xs={12} lg={9} style={{ height: 'calc(100vh - 175px)', overflowY: 'auto' }}>
              <ChecklistCard {...dashboard} />
              <svg height={0} width={0}>
                <defs>
                  <filter id="lineShadow" width="200%" height="200%">
                    <feOffset result="offOut" in="SourceGraphic" dx="0.2" dy="0.2" />
                    <feColorMatrix
                      result="matrixOut"
                      in="offOut"
                      type="matrix"
                      values="0.15 0 0 0 0 0 0.15 0 0 0 0 0 0.15 0 0 0 0 0 1 0"
                    />
                    <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="0.2" />
                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                  </filter>
                </defs>
              </svg>
              <Grid container spacing={2}>
                {dashboard?.charts?.map(chart => (
                  <ChartCard
                    key={chart.label}
                    {...chart}
                    loading={dashboardIsLoading}
                    onClick={() => handleSetChart(chart)}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={3} style={{ height: 'calc(100vh - 175px)', overflowY: 'auto' }}>
              <NotificationsCard {...dashboard} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default DashboardComponent
