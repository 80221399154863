import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

const otapImageListBundle = createAsyncResourceBundle({
  name: 'otapImageList',
  actionBaseType: 'OTAP_IMAGE_LIST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const deviceTypeFilter = store.selectOtapNodeListDeviceTypeFilter()
    return apiFetch('/firmware/', { type: deviceTypeFilter })
  }
})

export default {
  ...otapImageListBundle,
  reactOtapImageListFetch: createSelector(
    'selectAuth',
    'selectOtapImageListShouldUpdate',
    'selectRouteInfo',
    ({ authenticated }, shouldUpdate, { url }) => {
      if (authenticated && shouldUpdate && url === '/firmware') {
        return { actionCreator: 'doFetchOtapImageList' }
      }
      return undefined
    }
  ),
  selectOtapImageChoices: createSelector(
    'selectOtapImageListRaw',
    'selectMe',
    ({ data }, me) => data?.map(
      image => ({
        label: image.version === me?.latestFwVersion ? `${image.version} - (latest version)` : image.version,
        value: image.path,
      })
    )
  ),
}
