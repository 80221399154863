import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'
import { createSelector } from 'redux-bundler'
import reduceReducers from 'reduce-reducers'
import ms from 'milliseconds'

const HARVEST_BATCH_LIST_SET_FILTERS = 'HARVEST_BATCH_LIST_SET_FILTERS'
const HARVEST_BATCH_LIST_SET_SEARCH = 'HARVEST_BATCH_LIST_SET_SEARCH'
const HARVEST_BATCH_LIST_SET_SORT = 'HARVEST_BATCH_LIST_SET_SORT'
const HARVEST_BATCH_LIST_SET_PAGINATION = 'HARVEST_BATCH_LIST_SET_PAGINATION'
const HARVEST_BATCH_LIST_SET_CURRENT_BATCH = 'HARVEST_BATCH_LIST_SET_CURRENT_BATCH'

const additionalState = {
  sort: 'name',
  search: '',
  pagination: {
    currentPage: 1,
    next: 2,
    count: 0,
    pageSize: 25,
    numPages: 0,
  },
  filters: {
    dateHarvested: null,
    finishedDate: null,
    harvestBatchExistsIn: null,
    cultivarName: null,
    roomName: null,
    difference: null,
    hideFinished: 'HIDE',
  },
  currentBatch: {}
}

const harvestBatchListBundle = createAsyncResourceBundle({
  name: 'harvestBatchList',
  actionBaseType: 'HARVEST_BATCH_LIST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: async ({ apiFetch, getState, store }) => {
    const facility = store.selectCurrentFacilityId()
    const license = store.selectCurrentFacilityLicense()?.id
    const { harvestBatchList: { filters, search, sort } } = getState()

    /* eslint-disable babel/camelcase */
    const response = await apiFetch('/harvest_batches/', {
      facility,
      sort,
      search,
      license,
      cultivar_name: filters.cultivarName,
      room_name: filters.roomName,
      difference: filters.difference,
      finished_date: filters.finishedDate,
      date_harvested: filters.dateHarvested,
      hide_finished: filters.hideFinished,
      exists_in: filters.harvestBatchExistsIn,
    })
    /* eslint-enable babel/camelcase */
    const numPages = Math.ceil(response.length / additionalState.pagination.pageSize)
    const next = response.length > additionalState.pagination.pageSize ? 2 : null
    store.doHarvestBatchListClearCurrentBatch()
    store.doHarvestBatchListSetPagination({
      currentPage: 1,
      count: response.length,
      numPages: numPages || 1,
      next,
    })
    return response
  }
})

export default {
  ...harvestBatchListBundle,
  reducer: reduceReducers(harvestBatchListBundle.reducer, (state, action) => {
    switch (action.type) {
      case HARVEST_BATCH_LIST_SET_SEARCH:
        return { ...state, search: action.payload }
      case HARVEST_BATCH_LIST_SET_SORT:
        return { ...state, sort: action.payload }
      case HARVEST_BATCH_LIST_SET_FILTERS:
        return { ...state, filters: { ...state.filters, ...action.payload } }
      case HARVEST_BATCH_LIST_SET_PAGINATION:
        return { ...state, pagination: { ...state.pagination, ...action.payload } }
      case HARVEST_BATCH_LIST_SET_CURRENT_BATCH:
        return { ...state, currentBatch: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doHarvestBatchListSetFilters: filter => ({ dispatch, store }) => {
    dispatch({ type: HARVEST_BATCH_LIST_SET_FILTERS, payload: filter })
    store.doMarkHarvestBatchListAsOutdated()
  },
  doHarvestBatchListSetSearch: search => ({ dispatch, store }) => {
    if (search === store.selectHarvestBatchListSearch()) return
    dispatch({ type: HARVEST_BATCH_LIST_SET_SEARCH, payload: search })
    store.doMarkHarvestBatchListAsOutdated()
  },
  doHarvestBatchListSetSort: sort => ({ dispatch, store }) => {
    if (sort === store.selectHarvestBatchListSort()) return
    dispatch({ type: HARVEST_BATCH_LIST_SET_SORT, payload: sort })
    store.doMarkHarvestBatchListAsOutdated()
  },
  doHarvestBatchListSetPagination: newPagination => ({ store, dispatch }) => {
    const { pagination } = store.selectHarvestBatchListRaw()
    const payload = { ...pagination, ...newPagination }
    dispatch({ type: HARVEST_BATCH_LIST_SET_PAGINATION, payload })
  },
  doHarvestBatchListUpdateBatch: payload => async ({ store, apiFetch }) => {
    try {
      const result = await apiFetch(
        `/harvest_batches/${payload.id}/`,
        { ...payload },
        { method: 'PUT' },
      )
      store.doMarkHarvestBatchListAsOutdated()
      store.doAddSnackbarMessage(`Successfully updated ${payload.name}`)
      return result
    } catch (error) {
      return error
    }
  },
  doHarvestBatchListFetchBatch: id => async ({ store, apiFetch }) => {
    try {
      const result = await apiFetch(`/harvest_batches/${id}/`)
      store.doHarvestBatchListSetCurrentBatch(result)
      return result
    } catch (error) {
      return error
    }
  },
  doHarvestBatchListSetCurrentBatch: payload => async ({ store, dispatch }) => {
    if (payload.id === store.selectHarvestBatchListCurrentBatch()?.id) return
    dispatch({ type: HARVEST_BATCH_LIST_SET_CURRENT_BATCH, payload })
  },
  doHarvestBatchListClearCurrentBatch: () => async ({ dispatch }) => {
    dispatch({ type: HARVEST_BATCH_LIST_SET_CURRENT_BATCH, payload: {} })
  },
  selectHarvestBatchListFilters: createSelector(
    'selectHarvestBatchListRaw',
    ({ filters }) => filters
  ),
  selectHarvestBatchListSearch: createSelector(
    'selectHarvestBatchListRaw',
    ({ search }) => search
  ),
  selectHarvestBatchListSort: createSelector(
    'selectHarvestBatchListRaw',
    ({ sort }) => sort
  ),
  selectHarvestBatchListCurrentBatch: createSelector(
    'selectHarvestBatchListRaw',
    ({ currentBatch }) => currentBatch
  ),
  selectCurrentHarvestBatchList: createSelector(
    'selectHarvestBatchListRaw',
    ({ data, pagination }) => {
      if (data?.length <= additionalState.pagination.pageSize) return data
      const { pageSize, currentPage } = pagination
      return data?.slice(((pageSize * currentPage) - pageSize), (pageSize * currentPage))
    }
  ),
  selectHarvestBatchListPagination: createSelector(
    'selectHarvestBatchListRaw',
    ({ pagination }) => pagination
  ),
  reactHarvestBatchListFetch: createSelector(
    'selectAuth',
    'selectHarvestBatchListShouldUpdate',
    'selectRouteInfo',
    ({ authenticated }, shouldUpdate, { url }) => {
      const params = ['facilities', 'metrc', 'harvest-batches']
      const urlHasParams = params.every(param => url.includes(param))
      if (authenticated && shouldUpdate && urlHasParams) {
        return { actionCreator: 'doFetchHarvestBatchList' }
      }
      return undefined
    }
  ),
}
