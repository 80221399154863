/* eslint-disable no-underscore-dangle */
import { camelize, singularize } from 'inflection'
import { createSelector as createBundleSelector } from 'redux-bundler'

import { EMPTY_OBJECT } from '~/Lib/Utils'

const bundleSelectorFactory = (...args) => {
  const selector = createBundleSelector(...args)
  selector.__bundle__ = true
  return selector
}

export const selectEntityRootFactory = name => bundleSelectorFactory(
  state => state?.entities,
  entities => entities?.[name] ?? EMPTY_OBJECT
)
export const selectRootFactory = name => state => state?.[name] ?? EMPTY_OBJECT
export const selectEntitiesMapFactory = rootSelectorName => bundleSelectorFactory(rootSelectorName, root => root?.byId ?? EMPTY_OBJECT)
export const selectInflightFactory = rootSelectorName => bundleSelectorFactory(rootSelectorName, root => root?.inflight ?? EMPTY_OBJECT)
export const selectDirtyFactory = rootSelectorName => bundleSelectorFactory(rootSelectorName, root => root?.dirty ?? EMPTY_OBJECT)

export const selectCurrentIdFactory = (name, idAttribute, rootSelectorName) => bundleSelectorFactory(
  rootSelectorName,
  'selectRouteInfo',
  'selectRouteParams',
  (root, { url }, routeParams) => {
    if (url.indexOf(name) > -1 && routeParams) {
      const { [idAttribute]: id } = routeParams
      if (id) {
        return Number(id) == id ? Number(id) : id // eslint-disable-line eqeqeq
      }
    }
    return root?.current
  }
)

export const selectCurrentEntityFactory = (...selectorNames) => bundleSelectorFactory(...selectorNames, (id, inflight, ...sources) => sources.reduce(
  (acc, source) => {
    if (!source || !id) return acc
    const data = source[id]
    return { ...acc, ...data }
  },
  { inflight: inflight?.[id] }
))

export const entitySelectorsFactory = ({
  name,
  idAttribute = 'id',
  readOnly = false,
  singularName = singularize(name),
}) => {
  const selectName = `select${camelize(name)}`
  const singularTitle = camelize(singularName)
  const selectSingularName = `select${singularTitle}`
  const selectCurrentIdName = `selectCurrent${singularTitle}Id`
  const rootSelectorName = `${selectName}Root`
  const rootEntitySelectorName = `${selectName}EntityRoot`
  const inflightSelectorName = `${selectName}Inflight`

  const defaultSelectors = {
    [rootSelectorName]: selectRootFactory(name),
    [rootEntitySelectorName]: selectEntityRootFactory(name),
    [selectName]: selectEntitiesMapFactory(rootEntitySelectorName),
    [inflightSelectorName]: selectInflightFactory(rootSelectorName),
    [selectCurrentIdName]: selectCurrentIdFactory(
      name,
      idAttribute,
      rootSelectorName
    ),
  }

  if (readOnly) {
    return {
      ...defaultSelectors,
      [selectSingularName]: selectCurrentEntityFactory(
        selectCurrentIdName,
        inflightSelectorName,
        selectName
      ),
    }
  }

  const dirtySelectorName = `${selectName}Dirty`
  return {
    ...defaultSelectors,
    [dirtySelectorName]: selectDirtyFactory(rootSelectorName),
    [selectSingularName]: selectCurrentEntityFactory(
      selectCurrentIdName,
      inflightSelectorName,
      selectName,
      dirtySelectorName
    ),
  }
}
