import { DateTime } from 'luxon'
import humanizeDuration from 'humanize-duration'

const DEFAULT_OPTS = { locale: 'en-US' }

export const getDateTime = (dateOrString, opts = DEFAULT_OPTS) => {
  if (dateOrString === 'now') return DateTime.local()
  if (dateOrString instanceof DateTime) return dateOrString
  if (typeof dateOrString === 'number') return DateTime.fromMillis(dateOrString, opts)
  const isString = typeof dateOrString === 'string'
  let date = isString
    ? DateTime.fromISO(dateOrString, opts)
    : DateTime.fromJSDate(dateOrString, opts)
  if (!date.isValid && dateOrString && isString) {
    date = DateTime.fromJSDate(new Date(dateOrString), opts)
  }
  return date
}

export const getDate = dateOrString => {
  if (dateOrString instanceof Date) return dateOrString
  return getDateTime(dateOrString).toJSDate()
}

export const getDayFraction = (date = new Date()) => {
  const hour = date.getHours()
  const minutes = date.getMinutes()
  return (hour + minutes / 60) / 24
}

const DATE_FORMATTERS = {
  FULL_DATE: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  },
  LONG_DATE: {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
  SHORT_DATE: {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  },
  FULL_TIME: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  },
  LONG_TIME: {
    hour: '2-digit',
    minute: '2-digit',
  },
  SHORT_TIME: {
    hour: 'numeric',
    minute: 'numeric',
  },
}

export const formattedDate = (
  date,
  locale = 'en-US',
  format = DATE_FORMATTERS.LONG_DATE
) => getDateTime(date, { locale }).toLocaleString(format)
Object.assign(formattedDate, DATE_FORMATTERS)

export const formattedTime = value => {
  const datetime = getDateTime(value)
  if (datetime.isValid) {
    const formatted = datetime.toFormat('h:mma')
    switch (formatted) {
      case '12:00AM':
        return 'midnight'
      case '12:00PM':
        return 'noon'
      default:
        return formatted
    }
  }
  return 'N/A'
}

const humanizeDaysMap = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
}
export const humanizeDays = n => (n < 11 ? humanizeDaysMap[Math.round(n)] : n)

export const shortDurationHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    }
  },
  delimiter: ' ',
  largest: 2,
  round: true,
  spacer: '',
  units: ['y', 'w', 'd', 'h', 'm', 's'],
})

export const getShortDateDiff = datetime => {
  const diff = getDateTime('now').diff(getDateTime(datetime))
  if (Math.abs(diff.as('minutes')) < 1) return '<1m'
  return shortDurationHumanizer(diff.as('milliseconds'))
}

export const getDateDiff = (firstDate, secondDate = getDateTime('now')) => {
  const diffDuration = secondDate.diff(firstDate)
  const daysDiff = Math.floor(diffDuration.as('days'))
  const absDiff = Math.abs(daysDiff)

  let text = ''
  if (absDiff > 20) {
    text = humanizeDuration(Math.abs(diffDuration.as('milliseconds')), {
      round: true,
      units: [absDiff > 60 ? 'mo' : 'w'],
    })
  } else if (absDiff > 1) {
    text = humanizeDuration(Math.abs(diffDuration.as('milliseconds')), {
      round: true,
      units: ['d'],
    })
  } else if (absDiff === 1) {
    text = daysDiff > 0 ? 'yesterday' : 'tomorrow'
  } else {
    text = 'today'
  }

  return { daysDiff, text }
}

export const getDueDateDiff = dueDate => {
  if (!dueDate) return { daysDiff: 0, text: '' }

  const { daysDiff, text: rawText } = getDateDiff(getDateTime(dueDate))

  let text = rawText
  if (daysDiff > 1) text = `${text} ago`
  if (daysDiff < -1) text = `in ${text}`

  return { daysDiff, text }
}

export const isEqualDateTime = (left, right) => DateTime.isDateTime(left) && DateTime.isDateTime(right) && left.equals(right)

export const formatDateTime = ts => DateTime.fromISO(ts).toLocaleString({
  dateStyle: 'short',
  timeStyle: 'long',
})
