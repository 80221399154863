import bows from 'bows'
import config from '~/App/config'

const logger = bows('Lib/Auth')

export const AUTH_URL = '/token/'
export const AUTH_REFRESH_URL = `${config.API_URL}${AUTH_URL}refresh/`

export const getTokens = () => {
  const storedTokens = localStorage.getItem(config.AUTH_TOKEN_KEY)
  if (storedTokens) {
    try {
      return JSON.parse(storedTokens)
    } catch (err) {
      logger.error('Error parsing auth token JSON')
      localStorage.removeItem(config.AUTH_TOKEN_KEY)
    }
  }
  return { access: null, refresh: null, user: null }
}

export const setTokens = payload => {
  if (payload) {
    localStorage.setItem(config.AUTH_TOKEN_KEY, JSON.stringify(payload))
  }
  return payload
}

export const clearTokens = () => {
  localStorage.removeItem(config.AUTH_TOKEN_KEY)
}

// {"code":"token_not_valid"}
export const refreshTokens = async store => {
  const oldTokens = getTokens()
  const { pathname, search } = window.location
  const fullPath = `${pathname}${search}`

  if (fullPath.match(/^\/?login/)) return false

  const response = await fetch(AUTH_REFRESH_URL, {
    body: JSON.stringify({ refresh: oldTokens.refresh }),
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    mode: 'cors',
  })

  let updatedAccess

  try {
    const data = await response.json()

    if (response.status !== 200) {
      const loginURL = `/login?next=${fullPath}`

      logger.warn(
        'Invalid refresh token, redirecting to login page.',
        JSON.stringify(
          {
            data,
            status: response.status,
            statusText: response.statusText,
            loginURL,
          },
          null,
          2
        )
      )

      clearTokens()
      store.doAuthReset()
      store.doUpdateUrl(loginURL)

      return false
    }
    updatedAccess = data
  } catch (err) {
    logger.error('Token refresh failed:', err.toString())
    return false
  }

  return setTokens({ ...oldTokens, ...updatedAccess })
}
