import { createSelectorCreator, defaultMemoize } from 'reselect'
import {
  filter,
  flatten,
  join,
  map,
  pipe,
  trim,
  uniq,
} from 'ramda'

import { shallowEquals } from './equality'
import { EMPTY_ARRAY } from './constants'

export * from './constants'
export * from './datetime'
export * from './timing'
export * from './dom'
export * from './hooks'
export * from './ids'
export {
  default as memoize,
  shallowEqualsMemoizer,
} from './memoizer'
export * from './styles'
export * from './validation'

export const noop = () => null
const notANumber = o => typeof o !== 'number'
export const errorParser = response => {
  if (typeof response === 'string' || !response) return [response ?? '']

  let responseObj = response
  if (response.error) {
    if (response.error.response) {
      responseObj = response.error.response
      if (typeof responseObj === 'string') {
        return [responseObj]
      }
    } else if (response.error.message) {
      responseObj = response.error.message
      if (typeof responseObj === 'string') {
        return [responseObj]
      }
    }
  }

  let errorMsg = EMPTY_ARRAY
  if (Array.isArray(responseObj)) {
    errorMsg = responseObj.every(str => typeof str === 'string')
      ? responseObj
      : responseObj.filter(notANumber).map(errorParser)
  }
  if (Object.keys(responseObj)?.length) {
    errorMsg = Object.values(responseObj).filter(notANumber).map(errorParser)
  }

  return errorMsg
}

/**
 * Returns api errors as string with breaks
 * @param {string|object|array} response
 */
export const parseApiErrors = pipe(errorParser, flatten, map(trim), filter(Boolean), uniq, join('\n'))

export const formatError = error => {
  if (!error || typeof error === 'string') return error ? trim(error) : ''
  if (Array.isArray(error)) return error.map(formatError).join('\n')
  return String(error)
}

export const formattedNumber = (n, precision = 0) => {
  if (n === parseInt(n, 10)) return n
  const factor = 10 ** precision
  const number = Math.round(n * factor) / factor
  // Only remove trailing zeroes when there IS a decimal point
  return Number(
    number
      .toFixed(precision)
      .replace(/\.\d+$/, decimals => (decimals.match(/\.[0]+$/) ? '' : decimals.replace(/[0]+$/, '')))
  )
}

export const toNumber = rawValue => (
  typeof rawValue === 'string'
    ? Number(rawValue.replace(/[^\d.]+/g, ''))
    : Number(rawValue)
)

export const downsampleToMax = (array, max, startIndex = 0) => {
  const overage = array.length / max
  const k = overage >= 2 ? 2 ** Math.round(Math.log2(overage)) : Math.round(overage)

  if (k < 2) return array
  return array.filter((_, index) => (overage > 2
    ? (startIndex + index) % k === 0
    : (startIndex + index) % k !== 0))
}

export const initials = string => (typeof string === 'string'
  ? string
    .split(/([a-zA-Z]+\s+)/)
    .filter(s => s)
    .map(s => (s.match(/^\d+$/) ? ` ${s}` : s.charAt(0).toUpperCase()))
    .join('')
  : '')

const innerNonWord = /([a-zA-Z0-9])[\W_]+([a-zA-Z0-9])/g
const outerNonWord = /^[\W_]+|[\W_]+$/g
export const slugify = string => (
  String(string)
    .replace(innerNonWord, '$1-$2')
    .replace(outerNonWord, '')
    .toLowerCase()
)

export const createShallowEqualsSelector = createSelectorCreator(
  defaultMemoize,
  shallowEquals
)
const stringables = ['string', 'number']
/**
 * Utility to get text from rendered React node
 * @param {ReactNode} node
 */
export const getNodeText = node => {
  if (stringables.includes(typeof node)) {
    return String(node)
  }
  if (Array.isArray(node)) {
    return node.map(getNodeText).join('')
  }
  if (typeof node === 'object' && node?.props?.children) {
    return getNodeText(node.props.children)
  }
  return ''
}
