import React from 'react'
import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'
import { makeStyles } from '@material-ui/styles'

import Checkbox from '@material-ui/core/Checkbox'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'

import ListTable from '~/UI/Shared/ListTable'
import ListTableCell from '~/UI/Shared/ListTableCell'
import { DeviceIcons } from '~/Device/utils'
import styles from './styles'

const useStyles = makeStyles(styles)

const OtapNodeList = props => {
  const classes = useStyles()
  const { toggleNodeSelected, isGuestStaff } = props
  const {
    doOtapNodeListSetPage,
    otapNodeList,
    otapNodeListSelectedNodes,
  } = useConnect(
    'doOtapNodeListSetPage',
    'selectOtapNodeList',
    'selectOtapNodeListSelectedNodes',
  )

  const onChangePage = (event, page) => {
    doOtapNodeListSetPage(page === otapNodeList.current ? otapNodeList.current + 1 : otapNodeList.current - 1)
  }

  return (
    <ListTable className={classes.otapList}>
      <TableHead>
        <TableRow>
          {!isGuestStaff && <ListTableCell>Select</ListTableCell>}
          <ListTableCell>Serial Number</ListTableCell>
          <ListTableCell>Node Address</ListTableCell>
          <ListTableCell>Current Version</ListTableCell>
          <ListTableCell>Model</ListTableCell>
          <ListTableCell>Facility</ListTableCell>
          <ListTableCell align="center">Status</ListTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {otapNodeList.results.map(node => (
          <TableRow
            style={{ cursor: !isGuestStaff ? 'pointer' : 'default' }}
            hover={!isGuestStaff ? 'false' : 'true'}
            key={node.id}
            onClick={() => (!isGuestStaff && toggleNodeSelected(node))}
          >
            {!isGuestStaff && (
              <ListTableCell>
                <Checkbox checked={otapNodeListSelectedNodes.includes(node.nodeAddress)} />
              </ListTableCell>
            )}
            <ListTableCell>
              {node.serialNumber}
            </ListTableCell>
            <ListTableCell>
              {node.nodeAddress}
            </ListTableCell>
            <ListTableCell>
              {node.version ?? 'Unknown'}
            </ListTableCell>
            <ListTableCell>
              {node.modelName}
            </ListTableCell>
            <ListTableCell>
              {node.facilityName}
            </ListTableCell>
            <ListTableCell>
              <DeviceIcons device={node} />
            </ListTableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            colSpan={7}
            nextIconButtonProps={{ disabled: otapNodeList.next === null }}
            classes={{ spacer: 'hidden' }}
            count={otapNodeList.count}
            rowsPerPage={otapNodeList.pageSize}
            rowsPerPageOptions={[]}
            page={otapNodeList.current - 1}
            onChangePage={onChangePage}
            labelDisplayedRows={() => `Page ${otapNodeList.current} of ${otapNodeList.numPages}`}
          />
        </TableRow>
      </TableFooter>
    </ListTable>
  )
}

OtapNodeList.propTypes = {
  toggleNodeSelected: PropTypes.func.isRequired,
  isGuestStaff: PropTypes.bool.isRequired,
}

export default OtapNodeList
