import React, { useState } from 'react'
import { DateTime, Duration } from 'luxon'
import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  Chip,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Paper,
  Popover,
  Switch,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import SingleSelect from '~/UI/Shared/Form/SingleSelect'
import ConfirmationDialog from '~/UI/Shared/ConfirmationDialog'
import Icon from '~/UI/Shared/Icon'

import DeviceFormComponent from './form'
import RoutingData from './routingData'
import { TIMEFRAME_PRESETS } from './constants'

const SORT_OPTIONS = [
  { label: 'Gateway Number', value: 'gateway_sn' },
  { label: 'Serial Number', value: 'serial_number' },
  { label: 'Recently Added', value: '-created_on' },
  { label: 'Last Communicated', value: '-last_communication' },
  { label: 'Signal Strength', value: 'rssi' },
  { label: 'Battery Level', value: 'battery' },
]

const SortPicker = ({ deviceListSort, doDeviceListSetSort }) => (
  <SingleSelect
    color="primary"
    options={SORT_OPTIONS}
    value={deviceListSort}
    onChange={opt => doDeviceListSetSort(opt)}
  />
)

const DeviceFiltersComponent = props => {
  const {
    leftCol,
    centerCol,
    rightCol,
    exportButtonDisabled,
    setExportButtonDisabled,
  } = props

  const {
    device,
    isGuestStaff,
    currentDeviceId,
    deviceChartDuration,
    deviceChartIsLoading,
    deviceListIsLoading,
    deviceChartShowAxes,
    deviceEventsFilter,
    deviceEventsIsLoading,
    deviceEventsKeyChoices,
    deviceListCurrentFacility,
    deviceListCurrentModel,
    deviceListCurrentOrganization,
    deviceListCurrentRoom,
    deviceListFacilityChoices,
    deviceListModelChoices,
    deviceListOnlineFilter,
    deviceListRetiredFilter,
    deviceListWirepasFilter,
    deviceListOrganizationChoices,
    currentFacilityRooms,
    doDeviceReset,
    deviceListSort,
    doClearDeviceFilters,
    doDeviceChartSetParams,
    doDeviceChartSetShowAxes,
    doDeviceEventsSetFilter,
    doDeviceListSetFacility,
    doDeviceListSetModel,
    doDeviceListSetOnlineFilter,
    doDeviceListSetRetiredFilter,
    doDeviceListSetWirepasFilter,
    doDeviceListSetOrganization,
    doDeviceListSetRoom,
    doDeviceListSetSort,
    doDeviceExportData,
    doMarkDeviceChartAsOutdated,
    doMarkDeviceEventsAsOutdated,
    doMarkDeviceListAsOutdated,
    doAddSnackbarMessage,
  } = useConnect(
    'selectDevice',
    'selectIsGuestStaff',
    'selectCurrentDeviceId',
    'selectDeviceChartDuration',
    'selectDeviceChartIsLoading',
    'selectDeviceListIsLoading',
    'selectDeviceChartShowAxes',
    'selectDeviceEventsFilter',
    'selectDeviceEventsIsLoading',
    'selectDeviceEventsKeyChoices',
    'selectDeviceListCurrentFacility',
    'selectDeviceListCurrentModel',
    'selectDeviceListCurrentOrganization',
    'selectDeviceListCurrentRoom',
    'selectDeviceListFacilityChoices',
    'selectDeviceListModelChoices',
    'selectDeviceListOnlineFilter',
    'selectDeviceListRetiredFilter',
    'selectDeviceListWirepasFilter',
    'selectDeviceListOrganizationChoices',
    'selectCurrentFacilityRooms',
    'selectDeviceListSort',
    'doClearDeviceFilters',
    'doDeviceReset',
    'doDeviceChartSetParams',
    'doDeviceChartSetShowAxes',
    'doDeviceEventsSetFilter',
    'doDeviceListSetFacility',
    'doDeviceListSetModel',
    'doDeviceListSetOnlineFilter',
    'doDeviceListSetRetiredFilter',
    'doDeviceListSetWirepasFilter',
    'doDeviceListSetOrganization',
    'doDeviceListSetRoom',
    'doDeviceListSetSort',
    'doDeviceExportData',
    'doMarkDeviceChartAsOutdated',
    'doMarkDeviceEventsAsOutdated',
    'doMarkDeviceListAsOutdated',
    'doAddSnackbarMessage',
  )

  const [isDeviceFormDialogOpen, setIsDeviceFormDialogOpen] = useState(false)
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const sortFilterOpen = Boolean(anchorEl)
  const sortFilterId = sortFilterOpen ? 'sort-filter' : null

  const deviceFormDialogOpen = () => {
    setIsDeviceFormDialogOpen(true)
  }

  const resetDevice = () => {
    // eslint-disable-next-line babel/camelcase
    doDeviceReset({ gateway_sn: device.serialNumber })
  }

  const deviceFormDialogClose = success => {
    setIsDeviceFormDialogOpen(false)

    if (success !== null) {
      doAddSnackbarMessage(
        success
          ? 'Successfully updated device information'
          : 'Unable to save device information'
      )
      if (success) {
        doMarkDeviceListAsOutdated()
        doMarkDeviceChartAsOutdated()
      }
    }
  }

  const packetFilters = [
    { label: 'All Events', value: null },
    { label: 'Warnings Only', value: 'warningsOnly' },
    ...deviceEventsKeyChoices.map(key => ({ label: key, value: key })),
  ]

  const roomOptions = [
    { label: 'All Rooms', value: null },
    ...currentFacilityRooms.map(room => (
      { label: room.name, value: room.id }
    )),
  ]

  const filters = [
    {
      label: 'Status',
      options: [
        { label: 'All Devices', value: null },
        { label: 'Online', value: true },
        { label: 'Offline', value: false },
      ],
      onChange: doDeviceListSetOnlineFilter,
      value: deviceListOnlineFilter,
    },
    {
      label: 'Model',
      options: deviceListModelChoices,
      onChange: doDeviceListSetModel,
      value: deviceListCurrentModel,
    },
    {
      label: 'Organization',
      options: deviceListOrganizationChoices,
      onChange: doDeviceListSetOrganization,
      value: deviceListCurrentOrganization,
    },
    {
      label: 'Facility',
      options: deviceListFacilityChoices,
      onChange: id => doDeviceListSetFacility(id ?? null),
      value: deviceListCurrentFacility,
    },
    {
      label: 'Retired',
      options: [
        { label: 'All Devices', value: null },
        { label: 'True', value: true },
        { label: 'False', value: false },
      ],
      onChange: doDeviceListSetRetiredFilter,
      value: deviceListRetiredFilter,
    },
    {
      label: 'Wirepas',
      options: [
        { label: 'All Devices', value: null },
        { label: 'Device 5', value: 'device_5' },
        { label: 'Gateway 5', value: 'gateway_5' },
        { label: 'Device 4', value: 'device_4' },
        { label: 'Gateway 4', value: 'gateway_4' },
      ],
      onChange: doDeviceListSetWirepasFilter,
      value: deviceListWirepasFilter,
    },
  ]

  if (deviceListCurrentFacility) {
    filters.push({
      label: 'Room',
      options: roomOptions,
      onChange: doDeviceListSetRoom,
      value: deviceListCurrentRoom,
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item alignItems="center" spacing={1} xs={leftCol + centerCol} style={{ flexWrap: 'nowrap' }}>
          <Grid item>
            <Button
              color="primary"
              onClick={event => setAnchorEl(event.target)}
            >
              Sort &amp; Filter
              <Icon icon={ExpandMore} />
            </Button>
            <Popover
              id={sortFilterId}
              open={sortFilterOpen}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              <Paper style={{ minWidth: '350px', padding: '8px' }}>
                <List>
                  <ListItem>
                    <Grid container spacing={2} wrap="nowrap" alignItems="center" justify="space-between">
                      <Typography variant="body1">Sort devices</Typography>
                      <SortPicker deviceListSort={deviceListSort} doDeviceListSetSort={doDeviceListSetSort} />
                    </Grid>
                  </ListItem>
                  { filters.map(({ label, options, onChange, value }) => (
                    <ListItem key={label}>
                      <Grid container spacing={2} wrap="nowrap" alignItems="center" justify="space-between">
                        <Typography variant="body1">{ label }</Typography>
                        <SingleSelect
                          color="primary"
                          options={options}
                          value={value}
                          onChange={onChange}
                          disabled={deviceListIsLoading}
                        />
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Popover>
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              { filters.filter(({ value }) => value !== undefined).map(({ label, options, onChange, value }) => (
                <Grid item key={label}>
                  <Chip
                    color="primary"
                    size="small"
                    label={`${label}: ${options.find(opt => opt.value === value)?.label}`}
                    onDelete={() => {
                      onChange(null)
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          {!!device.id && !isGuestStaff && (
            <>
              <Grid item>
                <RoutingData device={device} />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={resetDevice}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={deviceFormDialogOpen}
                >
                  Edit
                </Button>
              </Grid>
            </>
          )}
          {device.id && (
            <Grid item>
              <Button
                color="primary"
                onClick={() => {
                  setConfirmationDialogOpen(true)
                }}
                disabled={exportButtonDisabled}
              >
                Export Device Data
              </Button>
            </Grid>
          )}
          <Grid item>
            <FormControlLabel
              control={(
                <Switch
                  checked={deviceChartShowAxes}
                  onChange={event => doDeviceChartSetShowAxes(event.target.checked)}
                  color="primary"
                />
              )}
              label="Show Y Axis"
            />
          </Grid>
          {Object.entries(TIMEFRAME_PRESETS).map(([label, duration]) => (
            <Grid item key={label}>
              <Button
                color={deviceChartDuration === Duration.fromObject(duration).as('hours') ? 'primary' : 'inherit'}
                onClick={() => {
                  doDeviceChartSetParams({
                    start: DateTime.utc().minus(duration),
                    end: null,
                  })
                }}
              >
                { label }
              </Button>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={rightCol}>
          <Grid container alignItems="center" justify="space-between" style={{ height: '100%' }}>
            <Grid item>
              <SingleSelect
                color="primary"
                size="small"
                label="hmm"
                options={packetFilters}
                value={deviceEventsFilter}
                onChange={doDeviceEventsSetFilter}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                size="small"
                disabled={deviceChartIsLoading || deviceEventsIsLoading}
                onClick={doClearDeviceFilters}
              >
                Clear filters
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                size="small"
                disabled={deviceChartIsLoading || deviceEventsIsLoading}
                onClick={() => {
                  doMarkDeviceChartAsOutdated()
                  doMarkDeviceEventsAsOutdated()
                  doMarkDeviceListAsOutdated()
                }}
              >
                Refresh
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        title="Export Device Data"
        content={`Are you sure you want to export data for ${device.deviceName}`}
        confirmText="Export"
        cancelText="Cancel"
        onClose={() => setConfirmationDialogOpen(false)}
        onConfirm={() => {
          doDeviceExportData(currentDeviceId)
          setExportButtonDisabled(true)
          setConfirmationDialogOpen(false)
        }}
      />
      {isDeviceFormDialogOpen && (
        <DeviceFormComponent
          device={device}
          onClose={deviceFormDialogClose}
        />
      )}
    </>
  )
}

export default DeviceFiltersComponent
