import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import { DateTime } from 'luxon'
import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

const DASHBOARD_CHART_DATA_UPDATED = 'DASHBOARD_CHART_DATA_UPDATED'
const DASHBOARD_CHART_PARAMS_CLEARED = 'DASHBOARD_CHART_PARAMS_CLEARED'
const DASHBOARD_CHART_PARAMS_UPDATED = 'DASHBOARD_CHART_PARAMS_UPDATED'

const additionalState = {
  params: {
    start: DateTime.utc().minus({ days: 1 }).toISO(),
    end: null,
    dataTypes: [],
    grouping: 'FACILITY',
    facilities: [],
    rooms: [],
    zones: [],
    devices: [],
  },
}

const dashboardChartBundle = createAsyncResourceBundle({
  name: 'dashboardChart',
  actionBaseType: 'DASHBOARD_CHART',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const params = store.selectDashboardChartParams()
    return apiFetch('/dashboard/chart/', params)
  }
})

export default {
  ...dashboardChartBundle,
  reducer: reduceReducers(dashboardChartBundle.reducer, (state, action) => {
    switch (action.type) {
      case DASHBOARD_CHART_DATA_UPDATED:
        return { ...state, data: action.payload }
      case DASHBOARD_CHART_PARAMS_CLEARED:
        return { ...state, params: additionalState.params }
      case DASHBOARD_CHART_PARAMS_UPDATED:
        return { ...state, params: { ...state.params, ...action.payload } }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  selectDashboardChartParams: createSelector(
    'selectDashboardChartRaw',
    ({ params }) => params
  ),
  doDashboardChartClearParams: () => ({ dispatch }) => (
    dispatch({ type: DASHBOARD_CHART_PARAMS_CLEARED })
  ),
  doDashboardChartSetParams: params => ({ dispatch, store }) => {
    // start or end undefined means leave it unchanged, null means clear it

    const { start: initialStart, end: initialEnd, ...payload } = params

    let start = initialStart
    let end = initialEnd

    if (start instanceof String) {
      start = DateTime.fromISO(start)
    }
    if (start instanceof DateTime) {
      start = start.toUTC().toISO()
    }
    if (end instanceof String) {
      end = DateTime.fromISO(end)
    }
    if (end instanceof DateTime) {
      end = end.toUTC().toISO()
    }

    if (start !== undefined) payload.start = start
    if (end !== undefined) payload.end = end

    dispatch({ type: DASHBOARD_CHART_PARAMS_UPDATED, payload })
    store.doFetchDashboardChart()
  },
  doDashboardChartSetData: data => ({ dispatch }) => {
    dispatch({ type: DASHBOARD_CHART_DATA_UPDATED, payload: data })
  },
}
