import { curry, pick, prop } from 'ramda'
import { createSelector } from 'redux-bundler'

import {
  defer,
  deferred,
  EMPTY_OBJECT,
  throttle,
} from '~/Lib/Utils'

export const DIMENSIONS_UPDATED = 'DIMENSIONS_UPDATED'
export const DIMENSIONS_ORIENTATION_PORTRAIT = 'DIMENSIONS_ORIENTATION_PORTRAIT'
export const DIMENSIONS_ORIENTATION_LANDSCAPE = 'DIMENSIONS_ORIENTATION_LANDSCAPE'

const CONTENT_HEIGHT_SUBTRAHENDS = ['appBar', 'prodBanner', 'versionBanner']
const CONTENT_WIDTH_SUBTRAHENDS = ['navDrawer']

const calcOrientation = (height = 0, width = 0) => (height > width ? DIMENSIONS_ORIENTATION_PORTRAIT : DIMENSIONS_ORIENTATION_LANDSCAPE)

const trackWidth = (initial = { window: window.innerWidth, content: window.innerWidth }) => {
  let payload = initial
  const tracked = document.querySelectorAll('[data-trackwidth]')

  if (initial.window !== window.innerWidth) {
    payload = { ...payload, window: window.innerWidth }
  }

  if (!tracked.length) return payload

  payload = Array.from(tracked).reduce((acc, el) => {
    if (!el.dataset.trackwidth) return acc
    const { [el.dataset.trackwidth]: oldWidth } = acc
    const newWidth = el.getBoundingClientRect().width
    if (oldWidth === newWidth) return acc
    return { ...acc, [el.dataset.trackwidth]: newWidth }
  }, payload)

  const content = payload.window < 960
    ? payload.window
    : payload.window - CONTENT_WIDTH_SUBTRAHENDS.reduce((acc, key) => acc + (payload[key] || 0), 0)

  if (payload.content !== content) {
    payload = { ...payload, content }
  }

  return payload
}

const trackHeight = (initial = { window: window.innerHeight, content: window.innerHeight }) => {
  let payload = initial
  const tracked = document.querySelectorAll('[data-trackheight]')

  if (initial.window !== window.innerHeight) {
    payload = { ...payload, window: window.innerHeight }
  }

  if (!tracked.length) return payload

  payload = Array.from(tracked).reduce((acc, el) => {
    if (!el.dataset.trackheight) return acc
    const { [el.dataset.trackheight]: oldHeight } = acc
    const newHeight = el.getBoundingClientRect().height

    if (oldHeight === newHeight) return acc

    return {
      ...acc,
      [el.dataset.trackheight]: newHeight,
    }
  }, payload)

  const content = payload.window - CONTENT_HEIGHT_SUBTRAHENDS.reduce((acc, key) => acc + (payload[key] || 0), 0)

  if (payload.content !== content) {
    payload = { ...payload, content }
  }

  return payload
}

const trackOrientation = (w = window) => calcOrientation(w.innerHeight, w.innerWidth)

const dispatcher = throttle(
  (dispatch, payload) => dispatch({
    type: DIMENSIONS_UPDATED,
    payload,
  }),
  33
)

const defaultState = {
  width: trackWidth(),
  height: trackHeight(),
  orientation: trackOrientation(),
}

const isDimensionsPayload = payload => payload && Object.keys(defaultState).some(key => key in payload)

export default {
  name: 'dimensions',
  getMiddleware: () => curry((store, next, action) => {
    if (!action?.payload?.url) {
      next(action)
      return
    }
    const { url: prevUrl } = store.selectRouteInfo()
    const { payload: { url } } = action
    // eslint-disable-next-line no-restricted-globals
    const [, suffix] = url.split(location.host)
    const [path] = suffix.split('?')
    if (path !== prevUrl) {
      defer(() => store?.doUpdateDimensions?.(), 500)
    }
    next(action)
  }),
  init: store => {
    const resizeHandler = () => {
      dispatcher(store.dispatch, {
        width: trackWidth(store.selectWidths()),
        height: trackHeight(store.selectHeights()),
        orientation: trackOrientation(),
      })
    }
    const deferredResizeHandler = deferred(resizeHandler, 1000)
    window.addEventListener('resize', resizeHandler)
    window.addEventListener('orientationchange', deferredResizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
      window.removeEventListener('orientationchange', deferredResizeHandler)
    }
  },
  reducer: (state = defaultState, action = {}) => {
    if (action.type === DIMENSIONS_UPDATED) {
      const changed = Object.keys(action.payload).filter(key => action.payload[key] !== state[key])

      if (changed.length > 0) {
        return {
          ...state,
          ...pick(changed, action.payload),
        }
      }
    }
    return state
  },
  doUpdateDimensions: payload => ({ dispatch, store }) => dispatcher(
    dispatch, (
      isDimensionsPayload(payload)
        ? payload
        : {
          width: trackWidth(store.selectWidths()),
          height: trackHeight(store.selectHeights()),
          orientation: trackOrientation(),
        }
    )
  ),
  doUpdateHeights: () => ({ dispatch, store }) => dispatcher(dispatch, {
    height: trackHeight(store.selectHeights()),
  }),
  doUpdateWidths: () => ({ dispatch, store }) => dispatcher(dispatch, {
    width: trackWidth(store.selectWidths()),
  }),
  doUpdateOrientation: () => ({ dispatch }) => dispatcher(dispatch, {
    orientation: trackOrientation(),
  }),
  selectDimensions: state => state.dimensions,
  selectWidths: createSelector('selectDimensions', prop('width')),
  selectHeights: createSelector('selectDimensions', prop('height')),
  selectOrientation: createSelector('selectDimensions', prop('orientation')),
  selectIsLandscape: createSelector(
    'selectOrientation',
    orientation => orientation === DIMENSIONS_ORIENTATION_LANDSCAPE
  ),
  selectIsPortrait: createSelector(
    'selectOrientation',
    orientation => orientation === DIMENSIONS_ORIENTATION_PORTRAIT
  ),
  selectIsMobile: createSelector(
    'selectWidths',
    'selectHeights',
    'selectIsLandscape',
    ({ window: windowWidth }, { window: windowHeight }, isLandscape) => (isLandscape
      ? windowWidth < 950 && windowHeight < 500
      : windowWidth < 500 && windowHeight < 950)
  ),
  reactUpdateDimensions: createSelector('selectDimensions', dimensions => {
    const { width, height, orientation } = dimensions

    let payload = EMPTY_OBJECT
    const newWidth = trackWidth(width)
    if (newWidth !== width) {
      payload = { ...payload, width: newWidth }
    }

    const newHeight = trackHeight(height)
    if (newHeight !== height) {
      payload = { ...payload, height: newHeight }
    }

    const newOrientation = trackOrientation()
    if (newOrientation !== orientation) {
      payload = { ...payload, orientation: newOrientation }
    }

    if (payload !== EMPTY_OBJECT) {
      return { actionCreator: 'doUpdateDimensions', args: [payload] }
    }
    return undefined
  })
}
