import { schema } from 'normalizr'
import { EMPTY_OBJECT } from '~/Lib/Utils'

export const Zone = new schema.Entity('zones')

export const Room = new schema.Entity('rooms', {
  zones: [Zone],
})

export const Facility = new schema.Entity('facilities', {
  rooms: [Room],
})

export const Organization = new schema.Entity('organizations', {
  facilities: [Facility],
})

export const Device = new schema.Entity('devices')

Device.define({
  organization: Organization,
  facility: Facility,
  puck: Device,
  gateway: Device,
  sensor: Device,
})

export const DataType = new schema.Entity(
  'dataTypes',
  {},
  {
    idAttribute: 'key',
    processStrategy: ({ template, ...rest }) => ({
      ...rest,
      template,
      units: template.replace(/\s*\{\{ value \}\}\s*/, ''),
    }),
  }
)

export const Model = new schema.Entity('models', EMPTY_OBJECT, { idAttribute: 'key' })
export const Unit = new schema.Entity('units')

export const User = new schema.Entity('users', {
  models: [Model],
  dataTypes: [DataType],
  units: [Unit],
})

export const Solus = new schema.Entity('solusUsers')
export const Logger = new schema.Entity('loggers')
