const deferrerPriorities = {
  high: fn => requestAnimationFrame(() => requestAnimationFrame(fn)),
  medium: setImmediate,
  low: typeof requestIdleCallback !== 'undefined'
    ? fn => requestIdleCallback(fn, { timeout: 500 })
    : fn => setTimeout(fn, 250)
}

export const defer = (fn, deferrer = deferrerPriorities.medium, ...args) => {
  if (typeof deferrer === 'number') {
    return setTimeout(fn, deferrer, ...args)
  }
  if (typeof deferrer === 'function') {
    return deferrer(fn)
  }
  return deferrerPriorities.medium(fn)
}
defer.priorities = deferrerPriorities

export const deferred = (fn, priority) => (...args) => defer(() => fn(...args), priority)

export const deferUntil = (fn, test, tick = 33, timeout = 10000) => {
  let abort = false
  const start = Date.now()
  const deferrer = () => {
    if (!abort && (test() || Date.now() - start >= timeout)) {
      fn()
      return
    }
    if (!abort) {
      defer(deferrer, tick)
    }
  }

  deferrer()
  // return cancel function
  return function cancel() {
    abort = true
  }
}

export const debounce = (fn, wait = 66, leading = false) => {
  let timeout

  const debounced = (...args) => {
    const later = () => {
      timeout = null
      if (!leading) fn(...args)
    }
    const callNow = leading && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) fn(...args)
  }

  debounced.cancel = () => {
    clearTimeout(timeout)
    timeout = null
  }

  return debounced
}

export const throttle = (fn, wait = 250, leading = false) => {
  let timeout
  let previous = 0
  const clear = () => {
    clearTimeout(timeout)
    timeout = null
  }

  const throttled = (...args) => {
    const now = Date.now()
    previous = !previous && !leading ? now : previous
    const remaining = wait - (now - previous)
    const later = () => {
      timeout = null
      if (!leading) fn(...args)
    }
    if (remaining <= 0 || remaining > wait) {
      if (timeout) clear()
      previous = now
      fn(...args)
      return
    }
    if (!timeout && !leading) {
      timeout = setTimeout(later, remaining)
    }
  }

  throttled.cancel = () => {
    clear()
    previous = 0
  }

  return throttled
}
