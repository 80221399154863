import { always, prop } from 'ramda'
import createDOMPurify from 'dompurify'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

export const preventDefault = event => event?.preventDefault?.()

const mustachePattern = /{{\s*(\w+)\s*}}/g
const templateRenderer = (template, data) => template.replace(mustachePattern, (m, key) => (
  data[key] != null ? String(data[key]) : ''
))
export const compileTemplate = template => templateRenderer.bind(null, template)

const focusablePattern = /a|button|fieldset|form|image|input|legend|select|textarea/
export const isFocusable = el => el.tagName.match(focusablePattern)

export const dtLabelSanitizer = (createSanitizer => {
  const sanitizer = createSanitizer(window)
  sanitizer.setConfig({ ALLOWED_TAGS: ['sup', 'sub'] })
  return sanitizer.sanitize.bind(sanitizer)
})(createDOMPurify)

export const getCollidesWith = (edge, length) => {
  const edgeGetter = typeof edge === 'function' ? edge : prop(edge)
  const lengthGetter = typeof length === 'function' ? length : always(length)

  return (a, b) => {
    const aEdge = edgeGetter(a)
    const bEdge = edgeGetter(b)
    const aLength = lengthGetter(a)
    const bLength = lengthGetter(b)
    const collides = (
      // a's low edge collides with b's high edge
      (aEdge <= bEdge && aEdge + aLength >= bEdge)
      // b's low edge collides with a's high edge
      || (aEdge >= bEdge && aEdge <= bEdge + bLength)
    )
    return collides
  }
}

export const useBreakpoint = () => {
  const theme = useTheme()
  if (!theme?.breakpoints) return 'xs'

  const keys = [...theme.breakpoints.keys].reverse()

  return keys.reduce((output, key) => {
    const matches = useMediaQuery(theme.breakpoints.only(key))

    return !output && matches ? key : output
  }, null) ?? 'xs'
}

export const useIsBreakpointDown = (max, breakpoint) => {
  const theme = useTheme()
  if (!theme?.breakpoints) return false

  const keys = [...theme.breakpoints.keys].reverse()

  if (!keys.includes(max)) {
    throw new Error(`Invalid breakpoint provided. Valid breakpoints are: ${keys.join(', ')}`)
  }

  return keys.indexOf(max) <= keys.indexOf(breakpoint)
}
