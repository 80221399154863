import React from 'react'

import classNames from 'clsx'

import { Fab, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',
    placeContent: 'center',
    justifyContent: 'center',
    '&$inline $headerIcon': {
      fontSize: theme.spacing(9),
      marginRight: theme.spacing(),
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    '&$inline $heading': {
      margin: theme.spacing(0, 1.75, 0, 0),
      flex: '0 0 auto',
      [theme.breakpoints.down('xs')]: {
        ...theme.typography.body1,
        flex: '0 1 auto',
        paddingBottom: theme.spacing(),
      },
    },
  },
  inline: {},
  headerIcon: {
    fontSize: theme.typography.h1.fontSize,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    }
  },
  heading: {
    flex: '0 0 100%',
    margin: theme.spacing(0, 0, 2),
    textAlign: 'center',
  },
  fab: {
    paddingRight: 14,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 2,
    }
  },
  fabIcon: {
    marginRight: 4,
    fill: '#fff',
  },
}), { name: 'EmptyState' })

export const EmptyStateComponent = props => {
  const {
    className,
    dataTestId = 'aroya-empty-state',
    FabIcon,
    fabLabel,
    fabProps,
    HeaderIcon,
    heading,
    headingProps,
    inline,
    style,
  } = props
  const classes = useStyles(props)

  return (
    <div
      data-testid={dataTestId}
      className={classNames(classes.root, inline && classes.inline, className)}
      style={style}
    >
      {HeaderIcon ? <div><HeaderIcon className={classes.headerIcon} /></div> : null}
      {typeof heading === 'string' ? (
        <Typography
          gutterBottom
          variant="subtitle1"
          className={classes.heading}
          {...headingProps}
        >
          {heading}
        </Typography>
      ) : heading}
      {fabLabel || FabIcon ? (
        <div>
          <Fab
            variant="extended"
            color="primary"
            size="small"
            {...fabProps}
            className={classes.fab}
          >
            {FabIcon ? <FabIcon className={classes.fabIcon} /> : null}
            {fabLabel}
          </Fab>
        </div>
      ) : null}
    </div>
  )
}

export default EmptyStateComponent
