import React, { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Menu, MenuItem, Typography } from '@material-ui/core'
import { ExpandMore as ExpandIcon } from '@material-ui/icons'

import { capitalize } from 'inflection'

import Button from '~/UI/Shared/Button'
import Icon from '~/UI/Shared/Icon'
import { sortNestedArrayBy } from '~/Lib/Data'

export default () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const {
    facilities,
    organizations,
    fullOrganizationList,
    routeParams,
  } = useConnect(
    'selectFacilities',
    'selectOrganizations',
    'selectFullOrganizationList',
    'selectRouteParams',
  )

  const { objType, id } = routeParams
  const objId = parseInt(id, 10)

  const selectedName = objType === 'facility' ? facilities[objId]?.name : organizations[objId]?.name

  const orgs = fullOrganizationList
    .filter(organization => organization.active)
    .map(org => ({
      ...org,
      facilities: org.facilities.filter(fac => fac.active)
    }))

  const opts = sortNestedArrayBy('name', orgs)
    .reduce((acc, org) => ([
      ...acc,
      {
        key: `org_${org.id}`,
        objType: 'organization',
        objId: org.id,
        name: org.name,
      },
      ...sortNestedArrayBy('name', org.facilities).map(
        fac => ({
          key: `fac_${fac.id}`,
          objType: 'facility',
          objId: fac.id,
          name: fac.name,
        })
      ),
    ]), [])

  return (
    <>
      <Button
        color="primary"
        style={{ textTransform: 'unset' }}
        variant="text"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <Typography
          noWrap
          align="left"
          color="inherit"
          variant="h3"
        >
          {objType ? (
            <>
              { capitalize(objType) }: { selectedName }
            </>
          ) : 'Select a facility'}
        </Typography>
        <Icon icon={ExpandIcon} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: '90vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          },
        }}
      >
        {opts.map(opt => (
          <MenuItem
            key={opt.key}
            component="a"
            href={`/dashboard/${opt.objType}/${opt.objId}`}
            onClick={() => setAnchorEl(null)}
            selected={objType === opt.objType && objId === opt.objId}
          >
            {opt.objType === 'organization' ? (
              <Typography variant="subtitle1" style={{ padding: '8px' }}>
                { opt.name }
              </Typography>
            ) : (
              opt.name
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
