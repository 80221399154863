import React, { useEffect, useCallback } from 'react'
import { useConnect } from 'redux-bundler-hook'

import {
  Typography,
  List,
  ListItem,
  Grid
} from '@material-ui/core'
import Loading from '~/UI/Shared/utils'
import Paginator from './paginator'

const OSErrorsComponent = ({ deviceId }) => {
  const {
    irrigationErrors,
    doFetchDeviceIrrigationErrors,
  } = useConnect(
    'selectIrrigationErrors',
    'doFetchDeviceIrrigationErrors'
  )

  useEffect(() => {
    doFetchDeviceIrrigationErrors(deviceId, 1)
  }, [])

  const paginationChange = useCallback(page => {
    doFetchDeviceIrrigationErrors(deviceId, page)
  }, [])

  if (!deviceId || !irrigationErrors) {
    return <Loading />
  }

  return (
    <div style={{ position: 'relative', width: '100%', marginTop: '50px' }}>
      <h4>Irrigation Errors</h4>
      <Paginator
        data={irrigationErrors}
        isLoading={false}
        onChange={paginationChange}
      />
      <div>
        <List style={{ width: '100%' }}>
          {irrigationErrors && irrigationErrors.results.length ? irrigationErrors.results.map((ierr, index) => (
            <ListItem
              key={ierr.id}
              style={{ border: '1px white solid' }}
            >
              <Grid container style={{ marginLeft: '-10px', display: 'flex' }} justify="space-between" wrap="nowrap">
                <div style={{ display: 'flex', justify: 'space-between' }}>
                  <Typography style={{ color: '#fff', paddingRight: '3%' }}>
                    {index + 1}
                  </Typography>
                  <div>
                    <Typography variant="body2" style={{ color: '#fff' }}>
                      Controller Id:{ierr.controller}
                    </Typography>
                    <Typography variant="body2" style={{ color: '#fff' }}>
                      Message:{ierr.message}
                    </Typography>
                    <Typography variant="body2" />
                  </div>
                </div>
              </Grid>
            </ListItem>

          )) : null}
        </List>
      </div>
    </div>
  )
}

export default OSErrorsComponent
