import React, { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'

import SelectField from '~/UI/Shared/Form/SelectField'
import MuiTitle from '~/UI/Shared/MuiTitle'
import Loading from '~/UI/Shared/utils'

import { LoggerList } from './list'
import { LoggerStyles as styles } from './styles'

const useStyles = makeStyles(styles)

export const LoggerComponent = () => {
  const classes = useStyles()
  const [selectedFacility, setSelectedFacility] = useState({
    label: 'All Facilities',
    value: null,
  })
  const {
    facilities,
    loggerList,
    currentLoggerList,
    doFacilityFetch,
    doLoggerListSetPage,
    doLoggerListSetFilter,
  } = useConnect(
    'selectFacilities',
    'selectLoggerList',
    'selectCurrentLoggerList',
    'doFacilityFetch',
    'doLoggerListSetPage',
    'doLoggerListSetFilter',
  )

  const getFacilities = useCallback(() => {
    const facilityOptions = [{ label: 'All Facilities', value: null }]
    Object.values(facilities)
      .filter(fac => fac.active)
      ?.forEach(facility => facilityOptions.push({ label: facility.name, value: facility.id }))
    return facilityOptions
  }, [facilities])

  const selectFacility = facility => {
    setSelectedFacility(facility)
    if (facility.value) {
      doFacilityFetch(facility.value)
      doLoggerListSetPage(1)
      doLoggerListSetFilter(facility.value)
    } else {
      doLoggerListSetFilter(null)
    }
  }

  const handlePageChange = pageNum => {
    const page = loggerList.current + pageNum
    doLoggerListSetPage(page)
  }

  useEffect(() => {
    selectFacility({ label: 'All Facilities', value: null })
  }, [])

  if (!loggerList) {
    return <Loading />
  }

  return (
    <div className={classes.root}>
      <div className={classes.loggerTitle}>
        <MuiTitle title="Loggers" />
      </div>
      <div className={classes.filters}>
        <SelectField
          dense
          placeholder="Select Facility"
          options={getFacilities()}
          value={selectedFacility}
          onChange={fac => selectFacility(fac)}
        />
      </div>
      <div className={classes.page}>
        <Button disabled={loggerList.current === 1} onClick={() => handlePageChange(-1)}>
          -
        </Button>
        {loggerList.current}
        <Button
          disabled={loggerList.current === loggerList.numPages}
          onClick={() => handlePageChange(1)}
        >
          +
        </Button>
      </div>
      <div className={classes.loggerList}>
        <LoggerList
          loggerList={currentLoggerList}
          classes={classes}
        />
      </div>
    </div>
  )
}
