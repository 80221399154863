import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const HeadComponent = ({ children }) => (
  <Helmet
    titleTemplate="%s | AROYA PORTAL"
    defaultTitle="AROYA PORTAL"
  >
    {children}
  </Helmet>
)

HeadComponent.propTypes = {
  children: PropTypes.node
}

HeadComponent.defaultProps = {
  children: null,
}

export default HeadComponent
