import React, { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import FacilityForm from '~/Facility/form'
import { formatDateTime } from '~/Lib/Utils'
import LicenseSelector from '~/Metrc/license'
import ConfirmationDialog from '~/UI/Shared/ConfirmationDialog'
import MuiTitle from '~/UI/Shared/MuiTitle'

import FloorplanList from './floorplans'
import HarvestHistroy from './HarvestHistory'
import HistoricMetrcImport from './historic_metrc_import'
import LoggerList from './loggers'
import MembershipList from './memberships'
import FacilityMetrcRow from './metrc'
import RoomList from './rooms'
import styles from './styles'
import Topology from './topology'

const useStyles = makeStyles(styles)

const totalSpaceFormat = space => (<span>{space} ft<sup>2</sup></span>)

export const FacilityDetailComponent = () => {
  const facilityInfoFields = [
    { key: 'location', name: 'Location' },
    { key: 'timezone', name: 'Timezone' },
    { key: 'totalSpace', name: 'Total Space', format: totalSpaceFormat },
    { key: 'crmFacilityId', name: 'Salesforce ID', format: val => val ?? 'N/A' }
  ]

  const facilityStatusFields = [
    { key: 'app', name: 'App', default: 'AROYA' },
    { key: 'tier', name: 'Tier', default: 'None' },
    { key: 'status', name: 'Status', default: 'Active' },
    { key: 'supportCategory', name: 'Support Category', default: 'Internal' },
  ]

  const classes = useStyles()
  const [editFacilityFormOpen, setEditFacilityFormOpen] = useState(false)
  const [harvestHistoryFormOpen, setHarvestHistoryFormOpen] = useState(false)
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false)
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [currentLicenseSync, setCurrentLicenseSync] = useState({})

  const {
    isGuestStaff,
    facility,
    routeParams,
    currentFacilityId,
    currentFacilityLicense,
    doFacilityDelete,
    doFacilityFetch,
    doUpdateUrl,
    doFacilitySave,
    doFacilityExportData,
    doSetCurrentFacilityLicense,
  } = useConnect(
    'selectIsGuestStaff',
    'selectFacility',
    'selectRouteParams',
    'selectCurrentFacilityId',
    'selectCurrentFacilityLicense',
    'doFacilityDelete',
    'doFacilityFetch',
    'doUpdateUrl',
    'doFacilitySave',
    'doFacilityExportData',
    'doSetCurrentFacilityLicense',
  )

  useEffect(() => {
    doFacilityFetch(routeParams.id)
  }, [])

  useEffect(() => {
    doSetCurrentFacilityLicense(facility?.licenses?.[0] ?? null)
  }, [facility])

  useEffect(() => {
    setCurrentLicenseSync(
      facility?.metrcSyncInfo?.byLicense?.find(
        l => l.license === currentFacilityLicense?.id
      )
    )
  }, [currentFacilityLicense])

  if (!facility || (facility.payloadType !== 'entity' && facility.inflight)) {
    return (
      <Grid item style={{ flexGrow: 1 }} className="text-center">
        <CircularProgress className="text-center mar-t-xl" />
      </Grid>
    )
  }

  return (
    <>
      <HarvestHistroy
        facility={facility}
        open={harvestHistoryFormOpen}
        onClose={() => setHarvestHistoryFormOpen(false)}
      />
      <FacilityForm
        instance={facility}
        onClose={() => setEditFacilityFormOpen(false)}
        onDelete={async instance => {
          await doFacilityDelete(instance)
          doUpdateUrl('/organizations')
        }}
        onSave={doFacilitySave}
        open={editFacilityFormOpen}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        title="Export Facility Data"
        content={`Are you sure you want to export data for ${facility.name}`}
        confirmText="Export"
        cancelText="Cancel"
        onClose={() => setConfirmationDialogOpen(false)}
        onConfirm={() => {
          doFacilityExportData(currentFacilityId)
          setExportButtonDisabled(true)
          setConfirmationDialogOpen(false)
        }}
      />

      <Grid container className={classes.headerRow}>
        <MuiTitle title={facility?.name} style={{ whiteSpace: 'nowrap' }} />
        <div className={classes.subheaderRow}>
          {facility.metrc && facility.licenses.length > 1 && (
            <Grid className={classes.licenseChooser} item>
              <LicenseSelector facility={facility} />
            </Grid>
          )}
          {!isGuestStaff && (
            <Grid
              container
              spacing={2}
              justify="flex-end"
              style={{ flexBasis: 'max-content' }}
            >
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setEditFacilityFormOpen(true)}
                >
                  Edit Facility
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setHarvestHistoryFormOpen(true)}
                >
                  View Harvest History
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setConfirmationDialogOpen(true)}
                  disabled={exportButtonDisabled}
                >
                  Export Facility Data
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>

      <div className={classes.facilityGrid}>
        <div className={classes.facilityInfo}>
          <Paper className={classes.facilitySection}>
            <Grid
              container
              spacing={2}
              justify="space-between"
              wrap="nowrap"
              alignItems="center"
            >
              {facilityInfoFields.map(field => (
                <Grid item key={`${field.key}`}>
                  <Typography color="textSecondary">
                    {field.name}
                  </Typography>
                  <span style={{ color: '#338edc' }}>{field.format ? field.format(facility[field.key]) : facility[field.key]}</span>
                </Grid>
              ))}
              {currentLicenseSync?.lastMetrcSync ? (
                <Grid item>
                  <Typography color="textSecondary">Last Metrc Sync</Typography>
                  <span style={{ color: '#338edc' }}>{formatDateTime(currentLicenseSync.lastMetrcSync)} </span>
                </Grid>
              ) : null}
              {facilityStatusFields.map(field => (
                <Grid item key={`${field.key}`}>
                  <Typography color="textSecondary">
                    {field.name}
                  </Typography>
                  <span style={{ color: '#338edc' }}>{facility[field.key] ? facility[field.key] : field.default}</span>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </div>
        {facility.metrc && (
          <FacilityMetrcRow
            facility={facility}
            syncInfo={currentLicenseSync}
          />
        )}
        <div className={classes.facilityGridCell}>
          <Paper className={classes.facilitySection}>
            <MembershipList />
          </Paper>
        </div>
        <div className={classes.facilityGridCell}>
          <Paper className={classes.facilitySection}>
            <RoomList />
          </Paper>
        </div>
        <div className={classes.facilityGridCell}>
          <Paper className={classes.facilitySection}>
            <LoggerList />
          </Paper>
        </div>
        <div className={classes.facilityGridCell}>
          <Paper className={classes.facilitySection}>
            <FloorplanList />
          </Paper>
        </div>
        {facility.metrc && (
          <div className={classes.facilityGridCell}>
            <Paper className={classes.facilitySection}>
              <HistoricMetrcImport />
            </Paper>
          </div>
        )}
      </div>
      <div>
        <Topology facility={facility} />
      </div>
    </>
  )
}
