import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'
import { createSelector } from 'redux-bundler'
import reduceReducers from 'reduce-reducers'
import ms from 'milliseconds'

const PACKAGE_LIST_SET_FILTERS = 'PACKAGE_LIST_SET_FILTERS'
const PACKAGE_LIST_SET_SEARCH = 'PACKAGE_LIST_SET_SEARCH'
const PACKAGE_LIST_SET_SORT = 'PACKAGE_LIST_SET_SORT'
const PACKAGE_LIST_SET_PAGINATION = 'PACKAGE_LIST_SET_PAGINATION'
const PACKAGE_LIST_SET_CURRENT_PACKAGE = 'PACKAGE_LIST_SET_CURRENT_PACKAGE'

const additionalState = {
  sort: 'label',
  search: '',
  pagination: {
    currentPage: 1,
    next: 2,
    count: 0,
    pageSize: 25,
    numPages: 0,
  },
  filters: {
    finishedDate: null,
    packageExistsIn: null,
    packagedDate: null,
    cultivarName: null,
    roomName: null,
    difference: null,
    hideFinished: 'HIDE',
  },
  currentPackage: {},
}

const packageListBundle = createAsyncResourceBundle({
  name: 'packageList',
  actionBaseType: 'PACKAGE_LIST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: async ({ apiFetch, store, getState }) => {
    const facility = store.selectCurrentFacilityId()
    const license = store.selectCurrentFacilityLicense()?.id
    const { packageList: { filters, search, sort } } = getState()

    /* eslint-disable babel/camelcase */
    const response = await apiFetch('/packages/', {
      facility,
      search,
      sort,
      license,
      cultivar_name: filters.cultivarName,
      room_name: filters.roomName,
      difference: filters.difference,
      finished_date: filters.finishedDate,
      packaged_date: filters.packagedDate,
      hide_finished: filters.hideFinished,
      exists_in: filters.packageExistsIn,
    })
    /* eslint-enable babel/camelcase */
    const numPages = Math.ceil(response.length / additionalState.pagination.pageSize)
    const next = response.length > additionalState.pagination.pageSize ? 2 : null
    store.doPackageListClearCurrentPackage()
    store.doPackageListSetPagination({
      currentPage: 1,
      count: response.length,
      numPages: numPages || 1,
      next,
    })
    return response
  }
})

export default {
  ...packageListBundle,
  reducer: reduceReducers(packageListBundle.reducer, (state, action) => {
    switch (action.type) {
      case PACKAGE_LIST_SET_SEARCH:
        return { ...state, search: action.payload }
      case PACKAGE_LIST_SET_SORT:
        return { ...state, sort: action.payload }
      case PACKAGE_LIST_SET_FILTERS:
        return { ...state, filters: { ...state.filters, ...action.payload } }
      case PACKAGE_LIST_SET_PAGINATION:
        return { ...state, pagination: { ...state.pagination, ...action.payload } }
      case PACKAGE_LIST_SET_CURRENT_PACKAGE:
        return { ...state, currentPackage: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doPackageListSetFilters: filter => ({ dispatch, store }) => {
    dispatch({ type: PACKAGE_LIST_SET_FILTERS, payload: filter })
    store.doMarkPackageListAsOutdated()
  },
  doPackageListSetSearch: search => ({ dispatch, store }) => {
    if (search === store.selectPackageListSearch()) return
    dispatch({ type: PACKAGE_LIST_SET_SEARCH, payload: search })
    store.doMarkPackageListAsOutdated()
  },
  doPackageListSetSort: sort => ({ dispatch, store }) => {
    if (sort === store.selectPackageListSort()) return
    dispatch({ type: PACKAGE_LIST_SET_SORT, payload: sort })
    store.doMarkPackageListAsOutdated()
  },
  doPackageListSetPagination: newPagination => ({ store, dispatch }) => {
    const { pagination } = store.selectPackageListRaw()
    const payload = { ...pagination, ...newPagination }
    dispatch({ type: PACKAGE_LIST_SET_PAGINATION, payload })
  },
  doPackageListUpdatePackage: payload => async ({ store, apiFetch }) => {
    try {
      const result = await apiFetch(
        `/packages/${payload.id}/`,
        { ...payload },
        { method: 'PUT' },
      )
      store.doMarkPackageListAsOutdated()
      store.doAddSnackbarMessage(`Successfully updated ${payload.label}`)
      return result
    } catch (error) {
      return error
    }
  },
  doPackageListFetchPackage: id => async ({ store, apiFetch }) => {
    try {
      const result = await apiFetch(`/packages/${id}/`)
      store.doPackageListSetCurrentPackage(result)
      return result
    } catch (error) {
      return error
    }
  },
  doPackageListSetCurrentPackage: payload => async ({ store, dispatch }) => {
    if (payload.id === store.selectPackageListCurrentPackage()?.id) return
    dispatch({ type: PACKAGE_LIST_SET_CURRENT_PACKAGE, payload })
  },
  doPackageListClearCurrentPackage: () => async ({ dispatch }) => {
    dispatch({ type: PACKAGE_LIST_SET_CURRENT_PACKAGE, payload: {} })
  },
  selectPackageListFilters: createSelector(
    'selectPackageListRaw',
    ({ filters }) => filters
  ),
  selectPackageListSearch: createSelector(
    'selectPackageListRaw',
    ({ search }) => search
  ),
  selectPackageListSort: createSelector(
    'selectPackageListRaw',
    ({ sort }) => sort
  ),
  selectPackageListCurrentPackage: createSelector(
    'selectPackageListRaw',
    ({ currentPackage }) => currentPackage
  ),
  selectCurrentPackageList: createSelector(
    'selectPackageListRaw',
    ({ data, pagination }) => {
      if (data?.length <= additionalState.pagination.pageSize) return data
      const { pageSize, currentPage } = pagination
      return data?.slice(((pageSize * currentPage) - pageSize), (pageSize * currentPage))
    }
  ),
  selectPackageListPagination: createSelector(
    'selectPackageListRaw',
    ({ pagination }) => pagination
  ),
  reactPackageListFetch: createSelector(
    'selectAuth',
    'selectPackageListShouldUpdate',
    'selectRouteInfo',
    ({ authenticated }, shouldUpdate, { url }) => {
      const params = ['facilities', 'metrc', 'packages']
      const urlHasParams = params.every(param => url.includes(param))
      if (authenticated && shouldUpdate && urlHasParams) {
        return { actionCreator: 'doFetchPackageList' }
      }
      return undefined
    }
  ),
}
