import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import memoizeOne from 'memoize-one'

import createLogger from '~/Lib/Logging'
import { EMPTY_OBJECT } from '~/Lib/Utils'
import FormattedDate from '~/UI/Shared/FormattedDate'
import { CHART_PADDING } from '../utils'

import Labels from './Labels'
import { getCursorHandler, getDataForDt } from './utils'

const logger = createLogger('Chart/Cursor')

const getHeight = memoizeOne(({ breakpoint, height: chartHeight }) => {
  let height = chartHeight - CHART_PADDING.bottom - 34

  if (breakpoint === 'xs') {
    height = chartHeight + 4 - CHART_PADDING.bottom
  }
  if (breakpoint === 'sm') {
    height = chartHeight - 9 - CHART_PADDING.bottom
  }

  return { height }
})

export const VictoryGraphCursor = props => {
  const { classes } = props

  const cursorEl = useRef(null)
  const propsRef = useRef(props)
  const mountedRef = useRef(false)
  const addOpenRef = useRef(false)
  useEffect(() => {
    propsRef.current = props
  }, [props])

  const [cursorState, setCursorState] = useState({
    x: null,
    y: null,
    dt: null,
    style: EMPTY_OBJECT,
  })

  useEffect(() => {
    mountedRef.current = true
    const cursorHandler = getCursorHandler({
      cursor: cursorEl.current,
      setCursorState,
      props: propsRef,
      mounted: mountedRef,
      addOpen: addOpenRef
    })
    window.addEventListener('mousemove', cursorHandler, { capture: true })
    return () => {
      mountedRef.current = false
      window.removeEventListener('mousemove', cursorHandler)
    }
  }, [])

  const { dt, style } = cursorState

  const labelsProps = dt ? getDataForDt(dt, props) : null
  if (getDataForDt.keys().length > 5000) {
    getDataForDt.clear()
  }

  return (
    <div
      ref={cursorEl}
      data-testid="room-graph-cursor"
      className={classNames({
        [classes.chartCursor]: true,
      })}
      style={getHeight(props)}
    >
      {labelsProps ? (
        <Labels {...labelsProps} classes={classes} style={style} />
      ) : null}
      <div className={classNames({ [classes.addAnnotation]: true })}>
        {dt ? (
          <FormattedDate
            className={classes.cursorTimestamp}
            data-testid="room-dashboard-add-annotation-ts"
            date={dt}
            format={{
              year: '2-digit',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              timeZoneName: 'short',
            }}
            variant="body2"
          />
        ) : null}
      </div>
    </div>
  )
}

export default React.memo(VictoryGraphCursor)
