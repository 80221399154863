import React, { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import SelectField from '~/UI/Shared/Form/SelectField'

import { mappingCardStyles as styles } from './styles'
import { calculateSqftFromAST } from './utils'

const useStyles = makeStyles(styles)

// TODO: `FloorPlan` handles coordination between this component and `GroundControl`, and should probably hold assignment logic.

export const MappingCard = props => {
  // TODO: Update prop names with parent component, and/or rafactor logic and data management
  const { areaAssociations, currentRoom, currentZone, handleZone: parentHandleZone, rooms, select: mapSelection, onClose, onSave } = props
  const { ast, areaBoundClientRect, areaId, groundsDictionary } = mapSelection

  const [room, setRoom] = useState(null)
  const [zone, setZone] = useState(null)
  const [zoneMap, setZoneMap] = useState({})
  const [calculateSqft, setCalculateSqft] = useState(true)

  const classes = useStyles()

  const handleMapUpate = (mapThing, facilityThing, modalThing) => {
    const map = { ...mapThing }
    if (facilityThing) {
      map[facilityThing.id] = modalThing
      map[modalThing] = facilityThing.id
      map[`${facilityThing.id}-name`] = facilityThing.name
    }
    return map
  }

  useEffect(() => {
    const modalRoom = groundsDictionary[currentRoom]
    let map = {}
    let mappedKey
    let facilityRoom
    let facilityZone

    if (modalRoom && modalRoom.uuid) {
      modalRoom.zones.forEach(modalZone => {
        mappedKey = groundsDictionary[modalZone].uuid
        if (mappedKey) {
          facilityRoom = rooms.find(fRoom => fRoom.id === modalRoom.uuid)
          facilityZone = facilityRoom.zones.find(fzone => fzone.id === mappedKey)
          map = handleMapUpate(map, facilityZone, modalZone)
        }
      })

      if (facilityRoom) {
        setRoom({ ...facilityRoom, value: facilityRoom.name, label: facilityRoom.name })
      }
      if (facilityZone) {
        setZone({ ...facilityZone, value: facilityZone.name, label: facilityZone.name })
      }
      setZoneMap(map)
    }
  }, [])

  const handleZone = () => {
    let map = { ...zoneMap }
    let mappedKey

    if (map[zone.id]) {
      mappedKey = map[zone.id]
      delete map[mappedKey]
    }
    if (map[currentZone]) {
      mappedKey = map[currentZone]
      delete map[mappedKey]
    }

    map = handleMapUpate(map, zone, currentZone)

    setZoneMap(map)
    parentHandleZone(map)
  }

  const handleSave = () => {
    const tempAst = { ...ast }
    const { rooms: roomList, zones } = tempAst
    const associations = { ...areaAssociations }

    // Remove previous room/zone ID associations from AST if selected room was already assigned
    Object.keys(roomList).forEach(x => {
      if (roomList[x].uuid === room.id) {
        roomList[x].uuid = null
        roomList[x].zones.forEach(z => { zones[z].uuid = null })
      }
    })
    roomList[currentRoom].uuid = room.id
    let id = null
    roomList[currentRoom].zones.forEach(key => {
      if (zoneMap[key]) {
        id = zoneMap[key]
        zones[key].uuid = id
        associations.zones[id] = { name: zoneMap[`${id}-name`] }
      } else {
        zones[key].uuid = null
      }
    })
    associations.rooms[room.id] = { name: room.value }

    if (calculateSqft) {
      const zoneSqftMappings = calculateSqftFromAST(tempAst)
      onSave(tempAst, associations, zoneSqftMappings)
    } else {
      onSave(tempAst, associations)
    }
  }

  const roomSelectOptions = useCallback(options => (
    options.map(option => ({ ...option, label: `${option.name} (${option.zones.length} zones)`, value: option.name }))
  ), [rooms])

  const assignable = () => zone && currentZone && !zoneMap[currentZone] && !zoneMap[zone.id]

  const clearAssignments = () => {
    setZoneMap({})
    setZone(null)
  }

  const facilityRoomZoneCount = room && room.zones ? room.zones.length : 0
  const floorplanRoomZoneCount = ast.rooms && ast.rooms[areaId] ? ast.rooms[areaId].zones.length : 0

  return (
    <Popover
      open
      anchorReference="anchorPosition"
      anchorPosition={{
        left: areaBoundClientRect.left + areaBoundClientRect.width + 20,
        top: areaBoundClientRect.top
      }}
      style={{ pointerEvents: 'none' }}  // Disables click-away close (needed to interact with floor plan)
      onClose={(onClose)}
    >
      <Paper elevation={1} style={{ pointerEvents: 'auto', width: 650 }}>
        <DialogTitle>Assign Facility Zones</DialogTitle>

        <DialogContent style={{ overflowY: 'visible' }}>
          <SelectField
            dense
            value={room ? { value: room.name, label: room.name } : null}
            options={roomSelectOptions(rooms)}
            placeholder="Select Room"
            onChange={setRoom}
          />

          {room && (
            <>
              {facilityRoomZoneCount !== floorplanRoomZoneCount ? (
                <Typography color="primary" style={{ margin: '4px' }}>
                  Warning: Zone count does not match the selected room.
                </Typography>
              ) : null}
              <div className={classes.zoneList}>
                {room.zones.map(z => (
                  <Button
                    key={z.id}
                    size="large"
                    color={z === zone ? 'primary' : 'default'}
                    variant={z === zone || zoneMap[z.id] ? 'contained' : 'outlined'}
                    disabled={zoneMap[z.id]}
                    onClick={() => setZone(z)}
                  >
                    {z.name}
                  </Button>
                ))}
                <Button
                  style={{ gridColumn: 'span 4' }}
                  color={assignable() ? 'primary' : 'default'}
                  variant={assignable() ? 'outlined' : ''}
                  disabled={!assignable()}
                  onClick={handleZone}
                >
                  Assign
                </Button>
                <Typography style={{ gridColumn: 'span 4', textAlign: 'center' }}>Select zone name and corresponding area on the map.</Typography>
              </div>
            </>
          )}
        </DialogContent>

        <DialogActions className={classes.actions}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={calculateSqft}
                className={classes.checkbox}
                onChange={() => setCalculateSqft(!calculateSqft)}
                color="primary"
              />
            )}
            label="Automatically Calculate Square Foot"
            labelPlacement="end"
          />
          <div>
            <Button color="secondary" onClick={clearAssignments}>
              Clear Assignments
            </Button>

            <Button
              disabled={!room}
              color="primary"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Paper>
    </Popover>
  )
}

// TODO: After refactoring local state, review which props should be required and/or have defaults.
MappingCard.propTypes = {
  areaAssociations: PropTypes.objectOf(PropTypes.object),
  currentRoom: PropTypes.string.isRequired,
  currentZone: PropTypes.string,
  rooms: PropTypes.arrayOf(PropTypes.object).isRequired,
  select: PropTypes.shape({
    ast: PropTypes.object.isRequired,
    areaBoundClientRect: PropTypes.object.isRequired,
    areaId: PropTypes.string.isRequired,
    groundsDictionary: PropTypes.object.isRequired,
  }).isRequired,
  handleZone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

MappingCard.defaultProps = {
  areaAssociations: {},
  currentZone: '',
}
