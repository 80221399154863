import memoizeOne from 'memoize-one'
import { object as shapeFactory } from 'yup'

import { EMPTY_OBJECT } from './constants'

export const VALID = Object.freeze({})

const validateWithSchema = memoizeOne((validationSchema, data) => {
  if (!data) return null
  const result = Object.entries(validationSchema).reduce(
    (acc, [key, validator]) => {
      try {
        validator.validateSync(data[key])
        return acc
      } catch (validationError) {
        const { errors } = validationError
        return { ...acc, [key]: errors }
      }
    },
    EMPTY_OBJECT
  )
  return result === EMPTY_OBJECT ? null : result
})

export const isNumber = n => !Number.isNaN(parseFloat(n)) && Number.isFinite(Number(n))

const isValid = memoizeOne(
  (validate, data) => !!data && validate(data) === null
)

export const getValidator = memoizeOne(schema => {
  if (typeof schema === 'function') {
    return schema
  }
  const validate = validateWithSchema.bind(null, schema)
  return [validate, isValid.bind(null, validate)]
})

export const useValidate = memoizeOne((factory, props) => {
  const schema = factory(props)
  const shape = shapeFactory().shape(schema)

  const validator = async (values, context) => {
    const options = { abortEarly: false, context: { ...context, values } }
    const valid = await shape.isValid(values, { ...options })
    if (valid) {
      return null
    }

    try {
      await shape.validate(values, { ...options })
      return null
    } catch (error) {
      if (error.inner && error.inner?.length) {
        return error.inner.reduce(
          (errors, { path, message }) => ({
            ...errors,
            [path]: message,
          }),
          EMPTY_OBJECT
        )
      }
      return { [error.path]: error.message }
    }
  }
  validator.schema = schema

  return validator
})

export const getType = o => {
  const name = o?.constructor?.name
  if (name) return name
  // eslint-disable-next-line no-useless-escape
  return toString.call(o).split(/[\[ \]]/)[2] || 'Unknown'
}
