import { TableCell, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ListTableCell from '~/UI/Shared/ListTableCell'

export const BlankCell = withStyles(() => ({
  body: {
    border: 'none',
  },
}))(TableCell)

export const HeaderCell = withStyles(theme => ({
  body: {
    background: theme.meter.background.four,
    padding: '15px',
  },
}))(TableCell)

export const TransparentRow = withStyles(() => ({
  root: {
    background: 'none !important',
    padding: '10px',
  },
}))(TableRow)

export const MembershipCell = withStyles(() => ({
  body: {
    padding: '10px'
  },
}))(ListTableCell)
