/* eslint-disable no-param-reassign */
import bows from 'bows'

const now = () => new Date().toISOString().replace(/^.*:([\d.]+)Z$/, '$1')
/* eslint-disable no-console, no-underscore-dangle */
if (
  process.env.NODE_ENV !== 'production'
  || (localStorage.debug === 'true' && !console.__ts__)
) {
  ['log', 'debug', 'info', 'warn', 'error'].forEach(method => {
    const original = console[method]
    console[method] = (...args) => original.call(console, ...args, '|', now())
  })
  console.__ts__ = true
}
/* eslint-enable no-console, no-underscore-dangle */

export default (Component, customName = '') => {
  const identifier = Component.displayName || customName || Component
  if (!identifier) {
    throw new TypeError(
      `No Component.displayName (${
        Component.displayName
      }) and no custom identifier (${customName || Component}) was provided.`
    )
  }

  const [namespace, ...path] = identifier
    .replace('Component', '')
    .replace(/([a-z])([A-Z])/, '$1 $2')
    .replace('/', ' ')
    .split(' ')
  const subPath = path.length
    ? `${(`[${path.join('').slice(0, 15)}]`).padEnd(15)} |`
    : ''
  const logger = bows(namespace, subPath)

  if (Component.prototype) {
    Component.prototype.logger = logger
  }

  return logger
}
