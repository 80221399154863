import React, { useState, useCallback } from 'react'
import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  Grid,
  List,
  ListItem,
  Paper,
  Popover,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import SingleSelect from '~/UI/Shared/Form/SingleSelect'
import Icon from '~/UI/Shared/Icon'

const FirmwareFiltersComponent = () => {
  const {
    doOtapNodeListSetDeviceType,
    doOtapNodeListSetFacilityFilter,
    doOtapNodeListSetOnlineFilter,
    doOtapNodeListSetVersionFilter,
    facilities,
    currentFacilityList,
    otapNodeListRaw,
    otapNodeListDeviceTypeFilter,
    otapNodeListFacilityFilter,
    otapNodeListOnlineFilter,
    otapNodeListVersionFilter,
  } = useConnect(
    'doOtapNodeListSetDeviceType',
    'doOtapNodeListSetFacilityFilter',
    'doOtapNodeListSetOnlineFilter',
    'doOtapNodeListSetVersionFilter',
    'selectFacilities',
    'selectCurrentFacilityList',
    'selectOtapNodeListRaw',
    'selectOtapNodeListDeviceTypeFilter',
    'selectOtapNodeListFacilityFilter',
    'selectOtapNodeListOnlineFilter',
    'selectOtapNodeListVersionFilter',
  )

  const [anchorEl, setAnchorEl] = useState(null)
  const filterPopoverOpen = Boolean(anchorEl)

  const deviceTypeOptions = [
    { label: 'Puck', value: 'puck' },
    { label: 'Sink', value: 'sink' },
  ]

  const deviceStatusOptions = [
    { label: 'All Devices', value: null },
    { label: 'Online', value: true },
    { label: 'Offline', value: false },
  ]

  const versionFilterOptions = [
    { label: 'All Versions', value: null },
    { label: 'Latest Version', value: 'latest' },
    { label: 'Older Versions', value: 'older' },
  ]

  const getFacilities = useCallback(() => [
    { label: 'All Facilities', value: -1 },
    ...currentFacilityList.filter(fac => fac.active).map(fac => ({ label: fac.name, value: fac.id })),
  ], [facilities])

  const filters = [
    {
      label: 'Device Type',
      options: deviceTypeOptions,
      onChange: doOtapNodeListSetDeviceType,
      value: deviceTypeOptions?.find(opt => opt.value === otapNodeListDeviceTypeFilter)?.value,
    },
    {
      label: 'Facility',
      options: getFacilities(),
      onChange: e => doOtapNodeListSetFacilityFilter(e === -1 ? undefined : e),
      value: getFacilities()?.find(opt => opt.value === otapNodeListFacilityFilter)?.value || -1,
    },
    {
      label: 'Status',
      options: deviceStatusOptions,
      onChange: doOtapNodeListSetOnlineFilter,
      value: otapNodeListOnlineFilter,
    },
    {
      label: 'Version',
      options: versionFilterOptions,
      onChange: doOtapNodeListSetVersionFilter,
      value: otapNodeListVersionFilter,
    },
  ]

  return (
    <>
      <Button
        size="small"
        color="primary"
        onClick={event => setAnchorEl(event.target)}
      >
        Filter by
        <Icon icon={ExpandMore} />
      </Button>
      <Popover
        open={filterPopoverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <Paper style={{ minWidth: '350px', padding: '8px' }}>
          <List>
            {filters.map(({ label, options, onChange, value }) => (
              <ListItem key={label}>
                <Grid container spacing={2} wrap="nowrap" alignItems="center" justify="space-between">
                  <Typography variant="body1">{ label }</Typography>
                  <SingleSelect
                    color="primary"
                    options={options}
                    value={value}
                    onChange={onChange}
                    disabled={otapNodeListRaw.isLoading}
                  />
                </Grid>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popover>
    </>
  )
}

export default FirmwareFiltersComponent
