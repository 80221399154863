import pointInSvgPolygon from 'point-in-svg-polygon'
import { parseSync } from 'svgson'

import Facility from '../classes/facility'
import Floorplan from '../classes/floorplan'
import Room from '../classes/room'
import Zone from '../classes/zone'
import addInnerSvgContents from './addInnerSvgContents'

const AST_VERSION = process.env.AST_VERSION
const REGEX_PATH_POINTS = /-?\d+(\.\d+)?,-?\d+(\.\d+)?/g

/**
 * Constructs an SVG element (AST) given a path group.
 * // TODO - Change props to params?
 * @param {Object} svgGroupObjs - Top-level SVG groups
 * @param {Object} svgPathObjs - Path objects of each SVG group
 * @param {Array} pair - List of SVG object keys matching this path group
 * @param {Object} props1
 * @param {Object} props1.attributes - SVG attributes associated with this path group
 * @param {Object} props2
 * @param {string} props2.type - Used to denote special case 'facility'
 * @param {Object} props2.theme - Styling specifications, expected fields: { fill, walls }
 * @returns {Object} SVG AST object
 */
const groupSvgPaths = (svgGroupObjs, svgPathObjs, pair, { attributes = {} }, { type = null, theme = {} }) => {
  const children = {}

  pair.forEach((group, idx) => {
    const child = svgGroupObjs[group].children[0]
    const { attributes } = child

    attributes.fill = theme.fill

    if (type === 'facility' && idx === 1) {
      attributes.fill = theme.fill
    } else if (type === 'facility' && idx === 2) {
      attributes.fill = theme.walls
    }

    attributes.dataId = idx
    return children[Object
      .keys(svgGroupObjs[group].childrenObj)
      .forEach(pathKey => {
        children[pathKey] = svgPathObjs[pathKey]
      })
    ]
  })

  const clipPath = {
    name: 'clipPath',
    type: 'element',
    value: '',
    attributes: {
      id: '',
      clipPathUnits: 'userSpaceOnUse'
    },
    children: [children[Object.keys(children)[1]]]
  }

  const defs = {
    name: 'defs',
    type: 'element',
    value: '',
    attributes: {},
    children: [clipPath]
  }

  const result = {
    uuid: attributes.dataId,
    name: 'g',
    type: 'element',
    value: '',
    attributes,
    children: [...Object.keys(children).map(key => children[key]), defs]
  }

  return result
}

/**
 * Creates the root SVG element (AST) of the floor plan.
 * // TODO - Change prop to params?
 * @param {Object} prop - Dimension attributes of the root SVG
 * @param {*} prop.width
 * @param {*} prop.height
 * @param {*} prop.viewBox
 * @returns {Object} Root SVG AST object for the floor plan
 */
const createFloorplan = ({ width, height, actualViewbox: viewBox }) => ({
  name: 'svg',
  type: 'element',
  value: '',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    'xmlns:xlink': 'http://www.w3.org/1999/xlink',
    'xml:space': 'preserve',
    width: Number(width.replace('pt', '')),
    height: Number(height.replace('pt', '')),
    viewBox
  },
  children: [{
    name: 'g',
    type: 'element',
    value: '',
    attributes: {
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
      fillOpacity: 0
    },
    children: []
  }]
})

/**
 * Creates facility branch of AST, including facility data and its SVG AST
 * // TODO - Most fields within `facility-0` appear undefined. Trim?
 * // TODO - Change prop to param?
 * @param {Object} svgGroupObjs - Top-level SVG groups
 * @param {Object} svgPathObjs - Path objects of each SVG group
 * @param {Number} facilityIndex
 * @param {array} pair - List of SVG object keys matching this path group
 * @param {Object} props
 * @param {Object} props.theme - Styling specifications, expected fields: { fill, walls }
 * @returns {Object} SVG AST object representing the facility
 */
const createFacility = (svgGroupObjs, svgPathObjs, facilityIndex, pair, { theme }) => {
  const facility = new Facility({
    id: `facility-${facilityIndex}`,
    uuid: null,
    name: `Facility ${facilityIndex}`,
    svgson: groupSvgPaths(
      svgGroupObjs,
      svgPathObjs,
      pair,
      {
        attributes: {
          id: `facility-${facilityIndex}`,
          dataId: facilityIndex,
          class: 'facility',
          fill: 'transparent'
        }
      },
      {
        type: 'facility',
        theme: {
          fill: theme.facility.floorColor,
          walls: theme.facility.wallColor,
          strokeColor: theme.facility.wallColor
        }
      }
    )
  })

  return {
    ...facility,
    svgson: {
      ...facility.svgson,
      children: facility.svgson.children.slice(0, 1),
    }
  }
}

/**
 * Converts a floor plan SVG created with https://floorplancreator.net/ into a representative AST
 * @param {string} svg - String representation of a floor plan SVG
 * @param {Object} props
 * @param {Object} props.theme - Specifies various colors and other properties to style the SVG
 * @returns {Object} Floor plan AST
 */
const parseSvgMap = (svg, theme) => {
  // Parse SVG string into JSON AST
  const svgAst = parseSync(svg)
  const { width, height, viewBox } = svgAst.attributes
  const { children: svgGroups } = svgAst.children[0]

  // Capture transform attributes to normalize SVG groups/paths
  const transformAttributes = svgGroups[0].attributes.transform.split(' ')
  const translateXY = transformAttributes
    .filter(item => item.includes('translate'))
    .slice(-1)[0]
    .match(/([\d-.]+)/g)
    .map(Number)

  const scaleFactor = transformAttributes
    .filter(item => item.includes('scale'))[0]
    .match(/([\d.]+)/g)
    .map(Number)[0]
  const actualWidth = Math.floor(Number(width.replace(/[a-z]/gi, '')) / scaleFactor)
  const actualHeight = Math.floor(Number(height.replace(/[a-z]/gi, '')) / scaleFactor)

  let actualViewbox = viewBox.split(' ')
  actualViewbox[2] = actualWidth
  actualViewbox[3] = actualHeight
  actualViewbox = actualViewbox.join(' ')

  const floorplan = {}
  const rooms = {}
  const zones = {}

  const floorPlanIndex = 0
  const facilityIndex = 0

  // TODO - As far as I can tell, the only fields accessed are `width`, `height`, and `svgson`.
  // `id`, `name`, and `facility` always use index 0, which is only useful if one AST saved all
  // facility floor plans (which may happen in the future). The `rooms` and `zones` fields appear
  // to always hold empty arrays. - AH
  floorplan[`floorplan-${floorPlanIndex}`] = new Floorplan({
    id: `floorplan-${floorPlanIndex}`,  // `floorplan-0`
    name: `Floorplan ${floorPlanIndex}`,  // `Floorplan 0`
    facility: `facility-${facilityIndex}`,  // `facility-0`
    rooms: Object.keys(rooms),  // always empty array?
    zones: Object.keys(zones),  // always empty array?
    width: Number(width.replace(/[a-z]/gi, '')),
    height: Number(height.replace(/[a-z]/gi, '')),
    svgson: createFloorplan({ actualViewbox, height, width })
  })

  const svgGroupObjs = {}
  const svgPathObjs = {}

  // This loop stores all top-level SVG groups in `svgPathObjs`, stripped of their transform
  // attribute and then xy-translated to compensate. Each group should have a single child object
  // representing its path element, subsequently stored in `svgPathObjs`.
  // NOTE - Due to object reference, the original `group` objects are also mutated.
  svgGroups.forEach((group, idx) => {
    delete group.attributes.transform

    svgGroupObjs[`group-${idx}`] = group
    svgGroupObjs[`group-${idx}`].children.forEach(c => {
      if (c.attributes.d) {
        const pointsRegex = /([\d-.,]+)/g
        let pp = 0
        // eslint-disable-next-line no-plusplus
        let attrD = c.attributes.d.replace(pointsRegex, () => `(xy${pp++})`)

        const pathPoints = c.attributes.d.match(pointsRegex).map(point => point.split(','))
        pathPoints.forEach((point, indx) => {
          point[0] = (Number(point[0]) + Number(translateXY[0])).toFixed(4)
          point[1] = (Number(point[1]) + Number(translateXY[1])).toFixed(4)
          attrD = attrD.replace(`(xy${indx})`, () => point.join(','))
        })

        c.attributes.d = attrD
      }

      svgGroupObjs[`group-${idx}`].childrenObj = {}
      svgGroupObjs[`group-${idx}`].childrenObj[`path-${idx}`] = c
      svgPathObjs[`path-${idx}`] = c
    })
  })

  // Here, the group objects stored in `svgGroupObjs` reference those in `svgGroups`.
  // Therefore, the following expressions evaluate to the same path object:
  //   `svgGroups[i].children[0]`
  //   `svgGroups[i].childrenObj['path-i']`
  //   `svgGroupObjs['group-i'].children[0]`
  //   `svgGroupObjs['group-i'].childrenObj['path-i']`
  //   `svgPathObjs['path-i']`

  // TODO - Reduce object duplication described above

  const strokeAndFillGroupPairs = {}
  const facility = {}
  let singlet

  // Loop back through the top-level SVG groups, storing overlapping paths together as a path group.
  // Many paths are duplicated, because floorplancreator's SVG groups a shape's stroke separately
  // from the shape's fill. The first few groups define the shape of the facility (usually in the
  // order of outer stroke, inner stroke, fill shape).
  Object.keys(svgGroupObjs).forEach(groupKey => {
    const pathA = svgGroupObjs[groupKey].children[0].attributes.d

    // Find all overlapping paths where path B is a subset of (or equal to) path A
    const pair = Object.keys(svgGroupObjs).filter(key => {
      const pathB = svgGroupObjs[key].children[0].attributes.d
      return pathA?.includes(pathB)
    })

    const svgPathGroupId = `${pair.sort().join('_')}`

    // Due to the way floorplancreator constructs the SVG, counting path data subsets indicates
    // whether the given group represents a facility, room/zone, or line/text. Lines and text have
    // no path data, and can be ignored. Room and zone paths appear exactly twice, and the path
    // overlap of the initial groups define the facility.

    if (pair.length === 1 && !Object.keys(facility).length) {
      singlet = pair
    } else if (pair.length === 2) {
      if (singlet && !Object.keys(facility).length) {
        facility[`facility-${facilityIndex}`] = createFacility(
          svgGroupObjs,
          svgPathObjs,
          facilityIndex,
          [...pair, ...singlet],
          { type: 'facility', theme }
        )
      } else {
        // These path groups should each represent a room or zone
        strokeAndFillGroupPairs[svgPathGroupId] = groupSvgPaths(
          svgGroupObjs,
          svgPathObjs,
          pair,
          {
            attributes: {
              fill: theme.room.fillColor || 'transparent',
              stroke: 'rgba(0, 0, 0, 0)',
              strokeWidth: '2'
            }
          },
          { theme, type: null },
        )
      }
    } else if (pair.length === 3) {
      facility[`facility-${facilityIndex}`] = createFacility(
        svgGroupObjs,
        svgPathObjs,
        facilityIndex,
        pair,
        { type: 'facility', theme }
      )
    }
  })

  let roomIndex = 0
  let zoneIndex = 0

  // Create room and zone SVG AST objects
  Object.keys(strokeAndFillGroupPairs).forEach(groupPairKey => {
    const childObjects = strokeAndFillGroupPairs[groupPairKey].children
    const pathString = childObjects[1].attributes.d
    const segments = pointInSvgPolygon.segments(pathString)
    const roomZones = {}

    Object.keys(strokeAndFillGroupPairs).forEach(groupPairKey2 => {
      if (groupPairKey !== groupPairKey2) {
        const childObjects2 = strokeAndFillGroupPairs[groupPairKey2].children
        const points = [...new Set(childObjects2[1].attributes.d.match(REGEX_PATH_POINTS))].map(point => point.split(','))
        const isZone = [...new Set(points.map(point => pointInSvgPolygon.isInside(point, segments)))]

        if (isZone.length === 1 && isZone[0] === true) {
          strokeAndFillGroupPairs[groupPairKey2].attributes.areaId = `zone-${zoneIndex}`
          strokeAndFillGroupPairs[groupPairKey2].attributes.id = `zone-${zoneIndex}`
          strokeAndFillGroupPairs[groupPairKey2].attributes.fill = theme.zone.fillColor || 'transparent'

          addInnerSvgContents(
            strokeAndFillGroupPairs[groupPairKey2],
            [actualWidth, actualHeight],
          )

          roomZones[`zone-${zoneIndex}`] = strokeAndFillGroupPairs[groupPairKey2]

          strokeAndFillGroupPairs[groupPairKey2].children[1].attributes.stroke = theme.zone.wallColor || 'transparent'
          strokeAndFillGroupPairs[groupPairKey2].children[1].attributes['stroke-width'] = theme.zone.wallWidth || 0

          zones[`zone-${zoneIndex}`] = new Zone({
            id: `zone-${zoneIndex}`,
            uuid: null,
            facility: `facility-${facilityIndex}`,
            room: `room-${roomIndex}`,
            name: `Zone ${zoneIndex}`,
            centroid: strokeAndFillGroupPairs[groupPairKey2].centroid,
            svgson: strokeAndFillGroupPairs[groupPairKey2]
          })

          zoneIndex += 1
        }
      }
    })

    if (Object.keys(roomZones).length) {
      strokeAndFillGroupPairs[groupPairKey].attributes.areaId = `room-${roomIndex}`
      strokeAndFillGroupPairs[groupPairKey].attributes.id = `room-${roomIndex}`
      strokeAndFillGroupPairs[groupPairKey].attributes.stroke = theme.room.wallColor || 'transparent'
      strokeAndFillGroupPairs[groupPairKey].attributes['stroke-width'] = theme.room.wallWidth || 0

      addInnerSvgContents(
        strokeAndFillGroupPairs[groupPairKey],
        [actualWidth, actualHeight],
      )

      rooms[`room-${roomIndex}`] = new Room({
        id: `room-${roomIndex}`,
        uuid: null,
        name: `Room ${roomIndex}`,
        facility: `facility-${facilityIndex}`,
        zones: Object.keys(roomZones),
        centroid: strokeAndFillGroupPairs[groupPairKey].centroid,
        svgson: strokeAndFillGroupPairs[groupPairKey]
      })
      roomIndex += 1
    }
  })

  facility.rooms = Object.keys(rooms)
  facility.zones = Object.keys(zones)

  const ast = {
    astVersion: AST_VERSION,
    floorplan,
    facility,
    rooms,
    zones,
    pathGroups: strokeAndFillGroupPairs,
  }

  return ast
}

export default parseSvgMap
