import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'

const MuiTitle = props => {
  const { title, variant, color } = props

  return (
    <Typography
      variant={variant}
      align="left"
      color={color}
      className="pageHeading"
      style={{ overflow: 'visible' }}
      {...props}
    >
      {title}
    </Typography>
  )
}

MuiTitle.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

MuiTitle.defaultProps = {
  color: 'primary',
  variant: 'h3',
}

export default MuiTitle
