import React, { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core'

import {
  KeyboardDatePicker,
} from '@material-ui/pickers'

import { formatDateTime, getDateTime } from '~/Lib/Utils'

const HistoricMetrcImport = () => {
  const [startDate, setStartDate] = useState(getDateTime('now'))
  const [endDate, setEndDate] = useState(getDateTime('now'))

  const {
    isGuestStaff,
    facility,
    historicImport,
    doFetchHistoricImport,
  } = useConnect(
    'selectIsGuestStaff',
    'selectFacility',
    'selectHistoricImport',
    'doFetchHistoricImport',
  )

  const canFetchMetrcData = startDate < Date.now() && endDate <= Date.now() && startDate < endDate && facility.id

  const startHistoricalImport = () => {
    if (canFetchMetrcData) {
      const params = {
        startDate: startDate.toISO(),
        endDate: endDate.toISO(),
        facilityId: facility.id,
      }
      doFetchHistoricImport(params)
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            Historic data import
          </Typography>
        </Grid>
        {!isGuestStaff && (
          <Grid item container xs={6} justify="flex-end">
            <Button
              color="primary"
              variant="contained"
              justify="flex-end"
              onClick={() => { startHistoricalImport() }}
              disabled={!canFetchMetrcData}
            >
              Import METRC data
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1} direction="column" style={{ marginTop: '8px' }}>
        {historicImport.errorType !== null && (
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Typography color="textSecondary" style={{ minWidth: '150px' }}>
                Error unable to start import: {historicImport.errorType.response}
              </Typography>
            </Grid>
          </Grid>
        )}
        {historicImport.lastSuccess !== null && (
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Typography color="textSecondary" style={{ minWidth: '150px' }}>
                Successfuly started import at: {formatDateTime(getDateTime(historicImport.lastSuccess))}
              </Typography>
            </Grid>
          </Grid>
        )}
        <KeyboardDatePicker
          label="Start date"
          format="MM/dd/yyyy"
          margin="normal"
          onChange={newDate => setStartDate(newDate)}
          value={startDate}
          defaultValue={null}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          label="End date"
          format="MM/dd/yyyy"
          margin="normal"
          onChange={newDate => setEndDate(newDate)}
          value={endDate}
          defaultValue={null}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </>
  )
}

export default HistoricMetrcImport
