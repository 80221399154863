import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import { makeStyles } from '@material-ui/styles'
import { toolTipStyles } from '../styles'

const useStyles = makeStyles(toolTipStyles)

export const MarkerToolTip = props => {
  const classes = useStyles()
  const { doUpdateUrl } = useConnect('doUpdateUrl')
  const { facility, organizations: orgs, monitoredSpace } = props

  const organization = Object.values(orgs).find(org => org.id === facility.organization && org.active)
  const handleClick = () => doUpdateUrl(`/facilities/${facility.id}`)

  return (
    <div className={classes.container}>
      <p className={classes.body2}>{organization.name}</p>
      <div className={classes.wrapper}>
        <span className={(facility.monitoredSpace ? classes.active : classes.inactive)} />
        <button
          type="button"
          onClick={handleClick}
          className={classes.header}
        >
          {facility.name}
        </button>
      </div>
      <p className={classes.body1}>{monitoredSpace}</p>
    </div>
  )
}

export const ClusterToolTip = props => {
  const classes = useStyles()
  const { organizations: orgs, facilities } = props

  const orgsWithFacilities = new Set(facilities.map(fac => fac.organization))
  const organizations = Object.values(orgs).filter(org => (
    org.active && orgsWithFacilities.has(org.id)
  ))

  return (
    <div className={classes.container}>
      {organizations.map(org => (
        <React.Fragment key={org.id}>
          <p key={org.name} className={classes.body2}>{org.name}</p>
          {facilities.map(fac => fac.organization === org.id && (
            <div key={fac.id} className={classes.wrapper}>
              <p key={fac.name} className={classes.clusterHeader}>{fac.name}</p>
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  )
}
